import React, { Component } from 'react'
import { Slider } from 'antd'

import * as Utils from '../../../components/Utils'
import * as AudioPlayer from '../../../components/AudioPlayer'

export default class SeekSlider extends Component {
	state = {
		time: 0
	}

	componentDidMount() {
		this.isiOS = Utils.isClientIOS()
		this.player = AudioPlayer.setVolumeSlider(this.didSeek)
	}

	componentWillReceiveProps(props) {
		if (
			(this.props.track._id &&
				props.track._id !== this.props.track._id) ||
			this.props.playing !== props.playing
		) {
			this.setState({ time: 0 })
			this.player = AudioPlayer.setVolumeSlider(this.didSeek)
		}
	}

	didSeek = (time, player) => {
		if (player === this.player) {
			this.setState({ time })
		}
	}

	render() {
		const { style, track, playing } = this.props

		const start = track.start === -1 || this.isiOS ? 0 : track.start
		const end = track.end === -1 ? track.duration / 1000 : track.end

		return (
			<Slider
				min={start}
				max={end}
				disabled={!playing}
				value={this.state.time}
				style={style}
				tipFormatter={Utils.formatMinutes}
				onChange={(v) => AudioPlayer.seek(v, this.player)}
			/>
		)
	}
}
