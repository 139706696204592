import React, { Component } from 'react'
import { Modal, Alert } from 'antd'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import 'react-big-calendar/lib/css/react-big-calendar.css'

import Loading from '../../components/LoadingPage'

const localizer = momentLocalizer(moment)

let formats = {
	dayFormat: (date, culture, localizer) =>
		localizer.format(date, 'ddd D', culture),

	timeGutterFormat: (time, culture, localizer) =>
		localizer.format(time, 'HH:mm', culture),

	eventTimeRangeFormat: ({ start }, culture, localizer) =>
		localizer.format(start, 'HH:mm', culture)
}

class StatsCalendar extends Component {
	state = {
		events: [],
		error: null,
		loading: false
	}

	componentDidMount() {
		this.loadEvents()
	}

	loadEvents = async () => {
		const { client, device, intl } = this.props
		try {
			this.setState({ loading: true, error: null })
			const result = await client.query({
				variables: { uuid: device.uuid },
				query: gql`
					query fetchStats($uuid: String!) {
						stats(deviceUuid: $uuid) {
							date
							event
							message
						}
					}
				`
			})

			let events = []
			let dates = {}

			// Count events for each day and hour
			for (let evt of result.data.stats) {
				if (evt.event) {
					const evtMoment = moment(evt.date)
					const evtDate = evtMoment.format('YYYY-MM-DD')

					let date = dates[evtDate] || {}
					let dateEventCount = date[`${evt.event}`] || 0
					date[`${evt.event}`] = ++dateEventCount
					dates[evtDate] = date
				}
			}

			// Create calendar events for each day and hour
			for (let day in dates) {
				const dayStats = dates[day]
				for (let evt in dayStats) {
					events.push({
						start: moment(day),
						end: moment(day).add(1, 'h'),
						title: `${intl.formatMessage({ id: `history.events.${evt}` })}: ${dayStats[evt]}`,
						event: dayStats,
						allDay: true
					})
				}
			}

			this.setState({ events, error: null, loading: false })
		} catch (e) {
			this.setState({ error: e.message, loading: false })
		}
	}

	render() {
		const { device, intl, onClose } = this.props
		const { events, loading, error } = this.state
		return (
			<Modal
				visible={true}
				width="80vw"
				title={intl.formatMessage(
					{ id: 'devices.info.stats.title' },
					{ name: device.nickname || device.name || '' }
				)}
				onCancel={onClose}
				footer={[]}
			>
				{loading && <Loading />}
				{!loading && error && (
					<Alert
						message={intl.formatMessage({ id: 'error' })}
						description={error}
						type="error"
						showIcon
					/>
				)}
				{!loading && !error && (
					<Calendar
						localizer={localizer}
						events={events}
						formats={formats}
						selectable={true}
						style={{ height: 1000 }}
						popup={true}
						views={['month']}
						defaultView="month"
						step={60}
						timeslots={1}
						defaultDate={new Date()}
						showMultiDayTimes={true}
						eventPropGetter={(event, start, end, selected) => ({
							style: {
								fontSize: 10
							}
						})}
					/>
				)}
			</Modal>
		)
	}
}

export default withApollo(injectIntl(StatsCalendar))
