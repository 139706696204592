import React, { Component } from 'react'
import { Modal, Alert, Select, TreeSelect } from 'antd'
const { TreeNode } = TreeSelect
import { injectIntl } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import removeAccents from 'remove-accents'

import { displayGraphQLErrors } from '../../components/Utils'

class SelectTeamV2Display extends Component {
	componentDidMount() {
		if (this.props.onFetch) {
			this.props.onFetch(this.props.teams)
		}
	}

	render() {
		const {
			intl,
			selected,
			onChange,
			multiple,
			teams,
			keyprops,
			onFetch,
			hideNoParents,
			...rest
		} = this.props

		/**
		 * The teams has a property called a which is the parent team id
		 * We need to build a tree structure from this matching the antd tree-select expected structure
		 */
		const renderTreeNodes = (parentId) => {
			return teams
				.filter((team) => team.a === parentId)
				.map((team) => {
					return (
						<TreeNode
							key={team[keyprops]}
							value={team[keyprops]}
							title={team.n}
						>
							{renderTreeNodes(team._id)}
						</TreeNode>
					)
				})
		}

		return (
			<TreeSelect
				{...rest}
				multiple={multiple}
				mode={multiple ? 'multiple' : null}
				value={selected || 'none'}
				showSearch
				treeDefaultExpandAll
				filterTreeNode={(input, treeNode) =>
					removeAccents(treeNode.props.title.toLowerCase()).indexOf(
						removeAccents(input.toLowerCase())
					) > -1
				}
				onChange={(ids) => {
					if (multiple) {
						// pass entire team objects not just ids
						if (ids.length === 0) {
							onChange([])
						} else {
							onChange(
								teams.filter(
									(t) => ids.indexOf(t[keyprops]) > -1
								)
							)
						}
					} else if (ids && ids !== 'none') {
						for (let t of teams) {
							if (t._id === ids) {
								onChange(t)
								break
							}
						}
					} else if (ids === null || ids === 'none') {
						onChange(null)
					}
				}}
				data-test="select-team-input"
			>
				{!hideNoParents && (
					<TreeNode
						key="none"
						value="none"
						title={intl.formatMessage({
							id: 'teams.actions.noParent'
						})}
					/>
				)}
				{renderTreeNodes(null)}
			</TreeSelect>
		)
	}
}

SelectTeamV2Display.defaultProps = {
	keyprops: '_id'
}

class ISelectTeamV2 extends Component {
	render() {
		const { withDevices, hideNoParents, teamsFilter, onFetch, ...rest } =
			this.props
		return (
			<Query
				query={
					withDevices ?
						gql`
							query selectTeamsQuery {
								teams {
									_id
									n
									a
									path
									maxDevices
									messagesEnabled
									parentMessagesEnabled
									thirdPartyDevicesEnabled
									parentThirdPartyDevicesEnabled
									children
									devices {
										_id
									}
								}
							}
						`
					:	gql`
							query selectTeamsQuery {
								teams {
									_id
									n
									a
									path
								}
							}
						`
				}
			>
				{({ loading, error, data }) => {
					if (loading) {
						return <Select {...rest} loading></Select>
					}
					if (error) {
						return (
							<Alert
								message={this.props.intl.formatMessage({
									id: 'error'
								})}
								description={displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					const teams = teamsFilter([...data.teams]).sort((a, b) => {
						const aa = a.path
						const bb = b.path
						if (!aa) {
							return 1
						}
						if (!bb) {
							return -1
						}
						return aa.localeCompare(bb)
					})

					return (
						<SelectTeamV2Display
							onFetch={onFetch}
							hideNoParents={hideNoParents}
							{...rest}
							teams={teams}
						/>
					)
				}}
			</Query>
		)
	}
}

ISelectTeamV2.defaultProps = {
	teamsFilter: (teams) => teams,
	hideNoParents: false
}

export const SelectTeamV2 = withApollo(injectIntl(ISelectTeamV2))

export class SelectTeamModal extends Component {
	state = {
		selected: this.props.selected
	}

	handleCancel = () => {
		this.props.onClose()
	}

	handleSelect = (team) => {
		this.props.onSelect(team)
		this.props.onClose()
	}

	render() {
		return (
			<Modal
				visible={true}
				title={this.props.title}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<SelectTeamV2
					onChange={(sel) => {
						if (sel === null) {
							this.handleSelect(null)
						} else if (this.props.multiple) {
							const selected = sel.map((t) => t._id)
							this.setState({ selected })
							this.props.onSelect(selected)
						} else {
							this.handleSelect(sel._id)
						}
					}}
					selected={this.state.selected}
					teamsFilter={this.props.teamsFilter}
					multiple={this.props.multiple}
					keyprops="_id"
					style={{ width: '100%' }}
					hideNoParents={this.props.hideNoParents}
				/>
			</Modal>
		)
	}
}
