import React, { Component } from 'react'
import { Modal, Button, Radio } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import * as Utils from '../../components/Utils'
import { SelectTeamV2 } from './SelectTeam'
import { TeamsTreeQuery, TeamDetailsQuery } from './Queries'

class DeleteTeamModal extends Component {
	state = {
		moveToTeam: null,
		choice: 2,
		loading: false
	}

	delete = async () => {
		try {
			let newTeam = null
			if (this.state.choice === 2) {
				if (this.state.moveToTeam) {
					newTeam = this.state.moveToTeam._id
				} else {
					throw new Error('server.TEAM_NOT_FOUND')
				}
			}

			this.setState({ loading: true })
			const dr = await this.props.client.mutate({
				variables: { id: this.props.team._id, newTeam },
				mutation: gql`
					mutation removeTeam($id: String!, $newTeam: String) {
						teamRemoveById(id: $id, reassignToTeam: $newTeam) {
							success
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: TeamsTreeQuery
					},
					{
						query: TeamDetailsQuery,
						variables: { id: this.props.team._id }
					}
				]
			})

			Utils.parseMutationResponse(dr)
			this.setState({ loading: false })
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}

		this.props.onClose()
	}

	render() {
		const { team, onClose, intl } = this.props
		const isTeam = (item) => item.teamId === team._id
		const isChildren = (item) =>
			item.teamId !== team._id && team.children.includes(item.teamId)
		return (
			<Modal
				title={intl.formatMessage({ id: 'actions.delete' })}
				visible={true}
				onCancel={onClose}
				footer={[
					<Button
						key="save"
						type="default"
						onClick={this.delete}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.delete" />
					</Button>
				]}
			>
				<p>
					<FormattedMessage
						id="teams.actions.delete.confirm"
						values={{ name: team.n }}
					/>
				</p>
				<ul>
					{team.children && team.children.length - 1 > 0 && (
						<li>
							<FormattedMessage
								id="teams.actions.delete.subteams"
								values={{ count: team.children.length - 1 }}
							/>
							:
							<ul>
								{team.users && team.users.length > 0 && (
									<li>
										<FormattedMessage
											id="teams.actions.delete.users"
											values={{
												count: team.childrenUsers.filter(
													isChildren
												).length
											}}
										/>
									</li>
								)}
								{team.devices && team.devices.length > 0 && (
									<li>
										<FormattedMessage
											id="teams.actions.delete.devices"
											values={{
												count: team.devices.filter(
													isChildren
												).length
											}}
										/>
									</li>
								)}
								{team.programs && team.programs.length > 0 && (
									<li>
										<FormattedMessage
											id="teams.actions.delete.programs"
											values={{
												count: team.programs.filter(
													isChildren
												).length
											}}
										/>
									</li>
								)}
								{team.messagePrograms &&
									team.messagePrograms.length > 0 && (
										<li>
											<FormattedMessage
												id="teams.actions.delete.messagePrograms"
												values={{
													count: team.messagePrograms.filter(
														isChildren
													).length
												}}
											/>
										</li>
									)}
							</ul>
						</li>
					)}
					{team.users && team.users.length > 0 && (
						<li>
							<FormattedMessage
								id="teams.actions.delete.users"
								values={{ count: team.users.length }}
							/>
						</li>
					)}
					{team.devices && team.devices.length > 0 && (
						<li>
							<FormattedMessage
								id="teams.actions.delete.devices"
								values={{
									count: team.devices.filter(isTeam).length
								}}
							/>
						</li>
					)}
					{team.programs && team.programs.length > 0 && (
						<li>
							<FormattedMessage
								id="teams.actions.delete.programs"
								values={{
									count: team.programs.filter(isTeam).length
								}}
							/>
						</li>
					)}
					{team.messagePrograms &&
						team.messagePrograms.length > 0 && (
							<li>
								<FormattedMessage
									id="teams.actions.delete.messagePrograms"
									values={{
										count: team.messagePrograms.filter(
											isTeam
										).length
									}}
								/>
							</li>
						)}
				</ul>

				<Radio.Group
					onChange={(e) => this.setState({ choice: e.target.value })}
					value={this.state.choice}
				>
					<Radio value={1}>
						<FormattedMessage id="teams.actions.delete.deleteAll" />
					</Radio>
					<div>
						<Radio value={2} className="radio-input-wrapper">
							<FormattedMessage id="teams.actions.delete.move" />
						</Radio>
						{this.state.choice === 2 && (
							<SelectTeamV2
								onChange={(team) =>
									this.setState({ moveToTeam: team })
								}
								selected={
									this.state.moveToTeam ?
										this.state.moveToTeam._id
									:	null
								}
								keyprops="_id"
								style={{ width: 200, marginLeft: 20 }}
								hideNoParents
							/>
						)}
					</div>
				</Radio.Group>
			</Modal>
		)
	}
}

export default injectIntl(withApollo(DeleteTeamModal))
