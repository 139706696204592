import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { List, Avatar, Alert, Tabs } from 'antd'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
	faThumbsUp,
	faThumbsDown,
	faPlay,
	faHeadphones
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../components/LoadingPage'
import * as Player from '../../components/AudioPlayer'
import * as Utils from '../../components/Utils'
import S from '../../components/Styles'

const TabPane = Tabs.TabPane

class ITracksDisplay extends Component {
	blacklist = async (track, blacklist) => {
		try {
			const { device, client } = this.props
			const r = await client.mutate({
				mutation: gql`
					mutation blacklistTracks(
						$deviceUuid: String
						$trackId: String
						$blacklist: Boolean
					) {
						blacklistTrack(
							deviceUuid: $deviceUuid
							trackId: $trackId
							blacklist: $blacklist
						) {
							success
							message
						}
					}
				`,
				variables: {
					deviceUuid: device.uuid,
					trackId: track,
					blacklist
				}
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { tracks, onSelect, canBlacklist, intl, device, subtitle } =
			this.props

		return (
			<List
				// we need to copy the array because "sort" try to mutate the original array
				// and the data fetching with apollo is not mutable
				dataSource={[...tracks].sort((a, b) =>
					a.title.localeCompare(b.title)
				)}
				renderItem={(item) => {
					let actions = [
						<a
							onClick={() => Player.playExtract(item._id)}
							key="play"
							title={intl.formatMessage({
								id: 'devices.player.track.preview'
							})}
						>
							<FontAwesomeIcon icon={faHeadphones} />
						</a>
					]

					if (canBlacklist) {
						const isBlacklisted =
							device.blacklist.indexOf(item._id) > -1
						actions.push(
							<a
								onClick={() =>
									this.blacklist(item._id, !isBlacklisted)
								}
								key="blacklist"
								title={intl.formatMessage({
									id:
										isBlacklisted ?
											'devices.actions.unblacklist'
										:	'devices.player.blacklist'
								})}
							>
								<FontAwesomeIcon
									icon={
										isBlacklisted ? faThumbsUp : (
											faThumbsDown
										)
									}
								/>
							</a>
						)
					}

					if (onSelect) {
						actions.push(
							<a
								onClick={() => onSelect(item._id)}
								key="select"
								title={intl.formatMessage({
									id: 'actions.select'
								})}
							>
								<FontAwesomeIcon icon={faPlay} />
							</a>
						)
					}

					return (
						<List.Item actions={actions}>
							<List.Item.Meta
								avatar={
									<Avatar
										shape="square"
										size="large"
										src={Utils.trackArtworkThumbUrl(item)}
									/>
								}
								title={
									subtitle ?
										`${item.title} - ${item.artist}`
									:	item.title
								}
								description={
									subtitle ? subtitle(item) : `${item.artist}`
								}
							/>
						</List.Item>
					)
				}}
			></List>
		)
	}
}

const TracksDisplay = injectIntl(withApollo(ITracksDisplay))

class TracksFetch extends Component {
	render() {
		const { tracks, intl, onSelect, device, canBlacklist, subtitle } =
			this.props
		return (
			<React.Fragment>
				{tracks && tracks.length > 0 ?
					<Query
						query={gql`
							query fetchTracks($tracks: [String!]!) {
								tracks: trackByIds(_ids: $tracks) {
									_id
									title
									artist
									artwork
								}
							}
						`}
						variables={{ tracks }}
					>
						{({ loading, error, data }) => {
							if ((!data || !data.tracks) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							return (
								<TracksDisplay
									tracks={data.tracks}
									onSelect={onSelect}
									device={device}
									canBlacklist={canBlacklist}
									subtitle={subtitle}
								/>
							)
						}}
					</Query>
				:	<div style={S.empty}>
						<FormattedMessage id="devices.details.blacklist.empty" />
					</div>
				}
			</React.Fragment>
		)
	}
}

export default injectIntl(TracksFetch)

class IProgramTracks extends Component {
	render() {
		const { program, intl, onSelect, device, canBlacklist, playlist } =
			this.props
		return (
			<React.Fragment>
				<Query
					query={gql`
						query fetchProgramTracks($id: String!) {
							programById(_id: $id) {
								_id
								playlists {
									_id
									name
									playlist {
										_id
										n
										a
										created
										tracks {
											_id
											title
											artist
											artwork
										}
									}
								}
							}
						}
					`}
					variables={{ id: program }}
				>
					{({ loading, error, data }) => {
						if ((!data || !data.programById) && loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={Utils.displayGraphQLErrors(
										error
									)}
									type="error"
									showIcon
								/>
							)
						}

						return (
							<Tabs>
								{data.programById.playlists.map((p) => {
									if (!playlist || p._id === playlist) {
										return (
											<TabPane tab={p.name} key={p._id}>
												<TracksDisplay
													tracks={p.playlist.tracks.filter(
														(track) =>
															track !== null
													)}
													onSelect={onSelect}
													device={device}
													canBlacklist={canBlacklist}
												/>
											</TabPane>
										)
									}
									return null
								})}
							</Tabs>
						)
					}}
				</Query>
			</React.Fragment>
		)
	}
}

export const ProgramTracks = injectIntl(IProgramTracks)
