import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Alert, Comment, Avatar, Form, Input, Button } from 'antd'
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl'

import Loading from './LoadingPage'
import * as Utils from './Utils'
import S from './Styles'
import * as Auth from '../auth'

class ICommentsDisplay extends Component {
	state = {
		loading: false,
		comment: null,
		editing: null,
		editingLoading: false,
		editingComment: null
	}

	submit = async (_) => {
		try {
			this.setState({ loading: true })

			if (this.state.comment) {
				const r = await this.props.client.mutate({
					variables: {
						created: new Date(),
						userId: Auth.cache.user._id,
						objectId: this.props.objectId,
						text: this.state.comment
					},
					mutation: gql`
						mutation commentCreate(
							$created: Date
							$userId: String
							$objectId: String
							$text: String
						) {
							commentCreate(
								record: {
									created: $created
									userId: $userId
									objectId: $objectId
									text: $text
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: CommentsFetchQuery,
							variables: { objectId: this.props.objectId }
						}
					],
					awaitRefetchQueries: true
				})
				Utils.parseMutationResponse(r)
				this.setState({ loading: false, comment: null })
			} else {
				throw new Error('Comment empty')
			}
		} catch (e) {
			console.log(e.message)
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	submitEditing = async (_) => {
		try {
			this.setState({ editingLoading: true })

			if (this.state.editingComment) {
				const r = await this.props.client.mutate({
					variables: {
						updated: new Date(),
						userId: Auth.cache.user._id,
						text: this.state.editingComment,
						id: this.state.editing._id
					},
					mutation: gql`
						mutation commentUpdate(
							$updated: Date
							$userId: String
							$text: String
							$id: String!
						) {
							commentUpdateById(
								_id: $id
								record: {
									updated: $updated
									userId: $userId
									text: $text
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: CommentsFetchQuery,
							variables: { objectId: this.props.objectId }
						}
					],
					awaitRefetchQueries: true
				})
				Utils.parseMutationResponse(r)
				this.setState({
					editingLoading: false,
					editingComment: null,
					editing: null
				})
			} else {
				throw new Error('Comment empty')
			}
		} catch (e) {
			console.log(e.message)
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	deleteComment = async (id) => {
		try {
			const r = await this.props.client.mutate({
				variables: { id },
				mutation: gql`
					mutation commentRemove($id: String!) {
						commentRemoveById(_id: $id) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: CommentsFetchQuery,
						variables: { objectId: this.props.objectId }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			console.log(e.message)
			Utils.displayError(e)
		}
	}

	render() {
		const { comments, writePermission, intl } = this.props
		return (
			<React.Fragment>
				{comments.map((c) => {
					return (
						<Comment
							key={`comment-${c._id}`}
							author={Utils.userName(c.user)}
							avatar={
								<Avatar
									src={Utils.userAvatar(c.user)}
									alt={Utils.userName(c.user)}
								/>
							}
							content={
								(
									this.state.editing &&
									this.state.editing._id === c._id
								) ?
									<Form style={{ marginTop: '-5px' }}>
										<Input.TextArea
											rows={2}
											placeholder={intl.formatMessage({
												id: 'comments.placeholder'
											})}
											onChange={(v) =>
												this.setState({
													editingComment:
														v.target.value
												})
											}
											value={this.state.editingComment}
										/>
										<Button
											loading={this.state.editingLoading}
											type="primary"
											style={{ float: 'right' }}
											onClick={this.submitEditing}
										>
											<FormattedMessage id="actions.save" />
										</Button>
									</Form>
								:	<span style={S.note}>{c.text}</span>
							}
							datetime={
								c.updated ?
									<FormattedMessage
										id="comments.updated"
										values={{
											created: (
												<FormattedDate
													value={c.created}
												/>
											),
											updated: (
												<FormattedDate
													value={c.updated}
												/>
											)
										}}
									/>
								:	<FormattedDate value={c.created} />
							}
							actions={
								(
									writePermission &&
									Auth.hasPermission(writePermission) &&
									!(
										this.state.editing &&
										this.state.editing._id === c._id
									)
								) ?
									[
										<span
											key="edit"
											onClick={() =>
												this.setState({
													editing: c,
													editingComment: c.text
												})
											}
										>
											<FormattedMessage id="actions.edit" />
										</span>,
										<span
											key="delete"
											onClick={() =>
												this.deleteComment(c._id)
											}
										>
											<FormattedMessage id="actions.delete" />
										</span>
									]
								:	null
							}
						/>
					)
				})}
				{writePermission && Auth.hasPermission(writePermission) && (
					<Comment
						avatar={
							<Avatar
								src={Utils.userAvatar(Auth.cache.user)}
								alt={Utils.userName(Auth.cache.user)}
							/>
						}
						content={
							<Form style={{ marginTop: '-5px' }}>
								<Input.TextArea
									rows={2}
									placeholder={intl.formatMessage({
										id: 'comments.placeholder'
									})}
									onChange={(v) =>
										this.setState({
											comment: v.target.value
										})
									}
									value={this.state.comment}
								/>
								<Button
									loading={this.state.loading}
									type="primary"
									style={{ float: 'right' }}
									onClick={this.submit}
								>
									<FormattedMessage id="actions.post" />
								</Button>
							</Form>
						}
					/>
				)}
			</React.Fragment>
		)
	}
}

export const CommentsDisplay = withApollo(injectIntl(ICommentsDisplay))

class CommentsFetch extends Component {
	render() {
		const { objectId, writePermission } = this.props
		return (
			<React.Fragment>
				{objectId && (
					<Query query={CommentsFetchQuery} variables={{ objectId }}>
						{({ loading, error, data }) => {
							if ((!data || !data.comments) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							return (
								<CommentsDisplay
									comments={data.comments}
									writePermission={writePermission}
									objectId={objectId}
								/>
							)
						}}
					</Query>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(CommentsFetch)

const CommentsFetchQuery = gql`
	query fetchComments($objectId: String!) {
		comments(filter: { objectId: $objectId }) {
			_id
			created
			updated
			user {
				_id
				username
				firstName
				lastName
			}
			objectId
			text
		}
	}
`
