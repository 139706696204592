import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Row, Col, Card, Button, Modal, Alert } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'

import ThirdPartyDevicePrograms from './ThirdPartyDevicePrograms'
import { TrackQuery, PlaylistQuery } from '../tracks/TrackInfoDisplay'
import S from '../../components/Styles'

import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'
import config from '../../config'

class ThirdPartyDevicePlayer extends Component {
	formatPosition = (durationInMilliseconds) => {
		var durationInSeconds = durationInMilliseconds / 1000
		var hours = Math.floor(durationInSeconds / 3600)
		var minutes = Math.floor((durationInSeconds - hours * 3600) / 60)
		var seconds = Math.floor(
			durationInSeconds - hours * 3600 - minutes * 60
		)

		if (hours < 10) {
			hours = '0' + hours
		}
		if (minutes < 10) {
			minutes = '0' + minutes
		}
		if (seconds < 10) {
			seconds = '0' + seconds
		}
		return hours + ':' + minutes + ':' + seconds
	}

	translateStatus = (state) => {
		const { intl } = this.props
		switch (state) {
			case 'PLAYBACK_STATE_PLAYING':
				return intl.formatMessage({
					id: 'thirdPartyDevices.player.status.playing'
				})
			case 'PLAYBACK_STATE_PAUSED':
				return intl.formatMessage({
					id: 'thirdPartyDevices.player.status.paused'
				})
			case 'PLAYBACK_STATE_IDLE':
				return intl.formatMessage({
					id: 'thirdPartyDevices.player.status.idle'
				})
			case 'PLAYBACK_STATE_BUFFERING':
				return intl.formatMessage({
					id: 'thirdPartyDevices.player.status.buffering'
				})
		}
	}

	onSonosSignOut = () => {
		const { intl, thirdPartyDevice, client } = this.props
		const thirdPartyDeviceId = thirdPartyDevice._id
		Modal.confirm({
			title: intl.formatMessage(
				{ id: 'devices.actions.delete.confirm' },
				{ name: thirdPartyDevice.nickname || '' }
			),
			okText: intl.formatMessage({ id: 'yes' }),
			okType: 'danger',
			cancelText: intl.formatMessage({ id: 'no' }),
			onOk: async () => {
				try {
					// remove the sonos access token to sign out
					const response = await client.mutate({
						variables: { id: thirdPartyDeviceId },
						mutation: gql`
							mutation updateThirdPartyDeviceToken(
								$id: MongoID!
							) {
								thirdPartyDeviceUpdateById(
									_id: $id
									record: { token: null }
								) {
									recordId
								}
							}
						`
					})
					Utils.parseMutationResponse(response)
				} catch (e) {
					Utils.displayError(e)
				}
			}
		})
	}

	render() {
		const { thirdPartyDevice, intl } = this.props
		return (
			<Row gutter={16}>
				<Col xs={24} lg={12} style={S.mobileCol}>
					<Card
						title={intl.formatMessage({
							id: 'allPrograms'
						})}
						bodyStyle={{
							...S.card.body,
							paddingBottom: 10,
							marginBottom: 10
						}}
					>
						<ThirdPartyDevicePrograms
							thirdPartyDeviceId={thirdPartyDevice._id}
							programs={thirdPartyDevice.programs}
						/>
					</Card>
				</Col>
				{thirdPartyDevice.sonos?.sonosErrorMessage && (
					<Col xs={24} style={S.mobileCol}>
						<Alert
							type="warning"
							showIcon
							message={`${intl.formatMessage({ id: 'thirdPartyDevices.details.sonos.somethingWentWrong' })}: ${thirdPartyDevice.sonos?.sonosErrorMessage}`}
						/>
					</Col>
				)}
				<Col xs={24} style={S.mobileCol}>
					{thirdPartyDevice.sonos?.controlApiNotRegistered ?
						<a
							href={`${config.sonos.baseUrl}login/v3/oauth?client_id=${config.sonos.clientId}&response_type=code&state=testState&scope=playback-control-all&redirect_uri=${config.vauxhall.baseUrl}sonos/sign-in?thirdPartyDeviceId=${thirdPartyDevice._id}`}
						>
							<Button>
								<FormattedMessage id="thirdPartyDevices.details.signIn" />
							</Button>
						</a>
					:	<Button onClick={this.onSonosSignOut}>
							<FormattedMessage id="thirdPartyDevices.details.signOut" />
						</Button>
					}
				</Col>
				{!thirdPartyDevice.sonos?.controlApiNotRegistered &&
					Auth.hasPermission('thirdPartyDevice:read:sonos') &&
					thirdPartyDevice.sonos?.groups?.length > 0 && (
						<Col xs={24} style={S.mobileCol}>
							<Card
								title={intl.formatMessage({
									id: 'thirdPartyDevices.info.groups'
								})}
							>
								{thirdPartyDevice.sonos.groups.map((group) => {
									return (
										<div id={group.id} key={group.id}>
											<b>{group.name}</b>
											<Row gutter={16}>
												<Col xs={8}>
													{group.metadata
														?.container && (
														<div>
															{group.metadata
																.container
																.itemType ===
																'program' && (
																<div>
																	{intl.formatMessage(
																		{
																			id: 'thirdPartyDevices.currentProgram'
																		}
																	)}
																	:{' '}
																	<a
																		href={`/programs/${group.metadata.container.itemId}`}
																	>
																		{
																			group
																				.metadata
																				.container
																				.name
																		}
																	</a>
																</div>
															)}
															{group.metadata
																.container
																.itemType ===
																'playlist' && (
																<div>
																	{intl.formatMessage(
																		{
																			id: 'manual'
																		}
																	)}
																</div>
															)}
														</div>
													)}

													{group.metadata
														?.playbackStatus && (
														<div>
															<div>
																{this.translateStatus(
																	group
																		.metadata
																		.playbackStatus
																		.playbackState
																)}
															</div>
															<div>
																{group.metadata
																	.playbackStatus
																	?.positionMillis &&
																	this.formatPosition(
																		group
																			.metadata
																			.playbackStatus
																			.positionMillis
																	)}
																/
																{group.metadata
																	.currentTrack
																	?.durationMillis &&
																	this.formatPosition(
																		group
																			.metadata
																			.currentTrack
																			.durationMillis
																	)}
															</div>
														</div>
													)}
												</Col>
												<Col xs={8}>
													{group.metadata
														?.currentTrack && (
														<PlaylistQuery
															playlistId={
																group.metadata
																	.currentTrack
																	.playlistId
															}
														/>
													)}
												</Col>
												<Col xs={8}>
													{group.metadata
														?.currentTrack && (
														<TrackQuery
															playState={
																group.metadata
																	.playbackStatus
																	.playbackState
															}
															trackId={
																group.metadata
																	.currentTrack
																	.trackId
															}
														/>
													)}
												</Col>
											</Row>
										</div>
									)
								})}
							</Card>
						</Col>
					)}
			</Row>
		)
	}
}

export default withApollo(injectIntl(ThirdPartyDevicePlayer))
