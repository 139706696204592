import React from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { SelectMultiplePrograms } from '../tracks/SelectProgram'
import { ThirdPartyDeviceProgramMutation } from './Queries'
import * as Utils from '../../components/Utils'

const ThirdPartyDevicePrograms = ({ thirdPartyDeviceId, programs, client }) => {
	const onSelect = async (programIds) => {
		try {
			const r = await client.mutate(
				ThirdPartyDeviceProgramMutation(thirdPartyDeviceId, programIds)
			)
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	return (
		<SelectMultiplePrograms
			selectedIds={programs.map((program) => program._id)}
			onSelect={(ids) => onSelect(ids)}
		/>
	)
}

export default withApollo(injectIntl(ThirdPartyDevicePrograms))
