import React, { Component } from 'react'
import { Modal, Alert, Divider, Button } from 'antd'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faPlay } from '@fortawesome/free-solid-svg-icons'

import { PlaylistCardDisplay } from '../programs/PlaylistDetails'
import S, { Colors } from '../../components/Styles'
import * as Utils from '../../components/Utils'
import Loading from '../../components/LoadingPage'
import config from '../../config'
import { ProgramDetailsQuery } from '../programs/Queries'
import { Default } from '../../components/Responsive'

class SelectPlaylist extends Component {
	state = {
		loading: null
	}

	handleCancel = () => {
		this.props.onClose()
	}

	selectPlaylist = (playlist) => {
		const { intl } = this.props

		Modal.confirm({
			title: intl.formatMessage({
				id: 'devices.player.forcePlaylist.skipOrLetFinish'
			}),
			okText: intl.formatMessage({
				id: 'devices.player.forcePlaylist.skip'
			}),
			okType: 'danger',
			cancelText: this.props.intl.formatMessage({
				id: 'devices.player.forcePlaylist.letFinish'
			}),
			onOk: () => this.performSelectPlaylist(true, playlist),
			onCancel: () => this.performSelectPlaylist(false, playlist)
		})
	}

	performSelectPlaylist = async (skip, playlist) => {
		const { client, device, zone } = this.props
		this.setState({ loading: playlist ? playlist._id : 'resume' })
		try {
			const r = await client.mutate({
				variables: {
					id: device._id,
					zoneId: zone._id,
					playlist: playlist ? playlist._id : null,
					command: skip ? 'selectPlaylistSkip' : 'selectPlaylist'
				},
				mutation: gql`
					mutation performSelectPlaylist(
						$id: String!
						$zoneId: String
						$playlist: String
						$command: String
					) {
						sendDeviceCommand(
							_id: $id
							zone: $zoneId
							command: $command
							param: $playlist
						) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(r)
			this.setState({ loading: null })
			this.props.onClose()
		} catch (e) {
			Utils.displayError(e)
			this.setState({ loading: null })
		}
	}

	render() {
		const { zone, intl } = this.props
		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id: 'devices.player.forcePlaylist'
				})}
				onCancel={this.handleCancel}
				footer={[]}
				width="80vw"
			>
				{zone.stateManualPlaylist && (
					<div>
						<div style={S.centered}>
							<Button
								type="primary"
								onClick={this.selectPlaylist}
								loading={this.state.loading === 'resume'}
							>
								<FontAwesomeIcon icon={faHistory} />
								&nbsp;&nbsp;
								<FormattedMessage id="devices.player.resumeSchedule" />
							</Button>
						</div>
						<Divider />
					</div>
				)}
				{zone && zone.stateProgram && (
					<Query
						pollInterval={config.longPollInterval}
						query={ProgramDetailsQuery}
						variables={{ id: zone.stateProgram._id }}
					>
						{({ loading, error, data }) => {
							if (loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							const program = data.programById
							if (program.playlists.length > 0) {
								const stats = Utils.programStats(program)

								return program.playlists.map((pl, i) => (
									<PlaylistCardDisplay
										key={pl._id}
										playlist={pl}
										color={Colors.playlistColors[i]}
										program={program}
										playlistStats={stats[pl._id]}
										genres={stats.genres}
										controls={[
											<Button
												key={`play-${pl._id}`}
												onClick={() =>
													this.selectPlaylist(pl)
												}
											>
												<FontAwesomeIcon
													icon={faPlay}
												/>
												<Default>
													<React.Fragment>
														&nbsp;&nbsp;
														<FormattedMessage id="devices.player.playNow" />
													</React.Fragment>
												</Default>
											</Button>
										]}
									/>
								))
							} else {
								return (
									<div style={S.empty}>
										<FormattedMessage id="programs.details.playlists.empty" />
									</div>
								)
							}
						}}
					</Query>
				)}
			</Modal>
		)
	}
}

export default injectIntl(withApollo(SelectPlaylist))
