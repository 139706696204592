import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
	List,
	Alert,
	Tag,
	Button,
	Tabs,
	InputNumber,
	Form,
	Select,
	Divider,
	Switch,
	Input
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Redirect, withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUser,
	faLink,
	faHdd,
	faCalendarAlt,
	faEdit,
	faUsers,
	faTrash,
	faPlus,
	faUserPlus,
	faClipboard,
	faCog,
	faCreditCard,
	faSyncAlt,
	faQrcode
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom'
import ipaddr from 'ipaddr.js'

import { withResponsive } from '../../components/Responsive'
import { DeviceListDisplay } from '../devices/DevicesTable'
import { ProgramsListDisplay } from '../programs/ProgramsTable'
import { MessageProgramsListDisplay } from '../messages/MessageProgramsTable'
import Billing from './payment/Billing'
import UsersTable from '../users/UsersTable'
import NewUserForm from '../users/NewUser'
import Title from '../../components/Title'
import Loading from '../../components/LoadingPage'
import TextInputModal from '../../components/TextInputModal'
import { SelectTeamModal } from './SelectTeam'
import * as Utils from '../../components/Utils'
import { TeamDetailsQuery, TeamsTreeQuery } from './Queries'
import * as Auth from '../../auth'
import S, { Colors, formItemLayoutEqual } from '../../components/Styles'
import Comments from '../../components/CommentsPage'
import { DeviceActionMutation } from '../devices/Queries'
import DeleteTeamModal from './DeleteTeamModal'
import PairDevice from '../devices/PairDevice'

import phoneBg from '../../img/phoneBg.png'
import emptyLogo from '../../img/emptyLogo.png'

const TabPane = Tabs.TabPane
const Option = Select.Option

const isCIDR = (ip) => {
	try {
		ipaddr.parseCIDR(ip)
		return true
	} catch (e) {
		return false
	}
}
class ITeamDetails extends Component {
	state = {
		showNewUser: false,
		renameModal: false,
		selectTeamModal: false,
		addSubteamModal: false,
		msgLoading: false,
		deleteModal: false,
		imageUploading: false,
		newIpToWhitelist: ''
	}

	editTeam = async (
		field,
		value,
		type,
		dontFetchTeamsTree = true,
		dontFetchChildrenTeams = true
	) => {
		try {
			let refetchQueries = [
				{
					query: TeamDetailsQuery,
					variables: { id: this.props.team._id }
				}
			]
			if (!dontFetchTeamsTree) {
				refetchQueries.push({
					query: TeamsTreeQuery
				})
			}
			if (!dontFetchChildrenTeams) {
				this.props.team.children?.forEach((childTeamId) => {
					refetchQueries.push({
						query: TeamDetailsQuery,
						variables: { id: childTeamId }
					})
				})
			}

			const r = await this.props.client.mutate({
				variables: { id: this.props.team._id, value },
				mutation: gql`
					mutation updateTeam($id: String!, $value: ${type}) {
						teamUpdateById(_id: $id, record: {
							${field}: $value
						}) {
							recordId
						}
					}
				`,
				refetchQueries,
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)
			await Auth.refreshUser(this.props.client)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	setMaxDevices = (value) => {
		let v = parseInt(value, 10)
		if (v === 0) {
			v = null
		}
		this.editTeam('maxDevices', v, 'Float', false)
	}

	setDeviceTTL = (value) => {
		this.editTeam('deviceTtl', parseInt(value, 10), 'Float', true)
	}

	setMessagesEnabled = async (value) => {
		await this.editTeam('messagesEnabled', value, 'Boolean', true)
	}

	setThirdPartyDevicesEnabled = async (value) => {
		await this.editTeam('thirdPartyDevicesEnabled', value, 'Boolean', true)
	}

	addIpWhitelist = async (value) => {
		// Display an error if the value is not a valid IP or a valid CIDR
		if (!ipaddr.isValid(value) && !isCIDR(value)) {
			Utils.displayError(new Error('INVALID_IP'))
			return
		}
		// Check the value is a valid CIDR
		if (!isCIDR(value)) {
			// If not convert it to a CIDR
			if (ipaddr.IPv4.isIPv4(value)) {
				value = `${value}/32`
			} else if (ipaddr.IPv6.isIPv6(value)) {
				value = `${value}/128`
			} else {
				Utils.displayError(new Error('INVALID_IP'))
				return
			}
		}

		// Check if the value is already in the whitelist
		if (
			this.props.team.ipWhitelist?.includes(value) ||
			this.props.team.parentIpWhitelist?.includes(value)
		) {
			Utils.displayError(new Error('DUPLICATE_IP'))
			return
		}
		const ipWhitelist =
			this.props.team.ipWhitelist ?
				[...this.props.team.ipWhitelist, value]
			:	[value]
		await this.editTeam('ipWhitelist', ipWhitelist, '[String]', true, false)
	}

	removeIpWhitelist = async (value) => {
		const ipWhitelist = this.props.team.ipWhitelist.filter(
			(ip) => ip !== value
		)
		await this.editTeam('ipWhitelist', ipWhitelist, '[String]', true, false)
	}

	syncAll = async (devices) => {
		for (let dev of devices) {
			try {
				const r = await this.props.client.mutate(
					DeviceActionMutation(dev, null, 'sync')
				)
				Utils.parseMutationResponse(r)
			} catch (e) {
				Utils.displayError(e)
			}
		}
	}

	onSelectFile = async (e) => {
		this.setState({ imageUploading: true })
		const file = e.target.files[0]

		try {
			const result = await this.props.client.mutate({
				variables: { file, id: this.props.team._id },
				mutation: gql`
					mutation updateTeamLogo($file: Upload!, $id: String) {
						teamUploadLogo(file: $file, id: $id) {
							success
							message
							url
						}
					}
				`,
				refetchQueries: [
					{
						query: TeamDetailsQuery,
						variables: { id: this.props.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(result)
			this.setState({ imageUploading: false })
		} catch (e) {
			this.setState({ imageUploading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { team, intl, client, mobileVersion } = this.props

		const fullIpWhitelist = [
			...(team.parentIpWhitelist || []),
			...(team.ipWhitelist || [])
		]

		Utils.setPageTitle(intl, null, team.n)

		let actions = []
		if (
			Auth.hasPermission('team:edit:setInfo') &&
			Auth.hasAccess('team:edit', team._id)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.rename' }),
				key: 'renameTeam',
				icon: faEdit,
				action: () => this.setState({ renameModal: true })
			})
		}
		if (Auth.hasAccess('team:add', team._id)) {
			actions.push({
				title: intl.formatMessage({ id: 'teams.actions.addSubteam' }),
				key: 'addSubteam',
				icon: faPlus,
				action: () => this.setState({ addSubteamModal: true })
			})
		}
		if (
			Auth.hasPermission('team:edit:setParent') &&
			Auth.hasAccess('team:edit', team._id)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'teams.actions.moveToParent' }),
				key: 'assignTeam',
				dataTest: 'assign-parent',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: true })
			})
		}
		if (Auth.hasAccess('team:delete', team._id)) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteTeam',
				dataTest: 'delete-team',
				icon: faTrash,
				action: () => this.setState({ deleteModal: true })
			})
		}

		let programCount = 0
		if (team.programs) {
			programCount += team.programs.length
		}
		if (team.messagesEnabled && team.messagePrograms) {
			programCount += team.messagePrograms.length
		}

		let preAuthDevices = []
		let authDevices = []
		if (team.devices) {
			preAuthDevices = team.devices.filter((d) => !!d.activationToken)
			authDevices = team.devices.filter((d) => !d.activationToken)
		}

		return (
			<div>
				<Title
					title={team.n}
					actions={actions}
					backUrl="/teams"
					backTitle="teams"
					dataTest="main-team-menu"
				>
					{team.a && (
						<div style={S.itemDetails.teamPath}>
							<Link to={`/teams/${team.a}`}>
								<Tag color="blue">
									{team.path
										.split('/')
										.slice(0, -1)
										.join('/')}
								</Tag>
							</Link>
						</div>
					)}
				</Title>

				{this.state.renameModal && (
					<TextInputModal
						initialValue={team.n}
						title={intl.formatMessage({
							id: 'teams.actions.renameTeam'
						})}
						actionLabel={intl.formatMessage({
							id: 'actions.rename'
						})}
						placeholder={intl.formatMessage({ id: 'team' })}
						onClose={() => this.setState({ renameModal: false })}
						mutation={(name) => ({
							variables: { id: team._id, name },
							mutation: gql`
								mutation updateTeamName(
									$id: String!
									$name: String!
								) {
									teamUpdateById(
										_id: $id
										record: { n: $name }
									) {
										recordId
									}
								}
							`,
							refetchQueries: [
								{
									query: TeamDetailsQuery,
									variables: { id: team._id }
								}
							]
						})}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={intl.formatMessage({
							id: 'teams.actions.moveToParentTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={team.a}
						teamsFilter={(teams) => {
							// Since the modal allow to select the parent team then we should remove its children
							const childTeamChildren = team.children
							return teams.filter(
								({ _id: teamId }) =>
									teamId === team.a ||
									(teamId !== team._id &&
										!childTeamChildren.includes(teamId))
							)
						}}
						onSelect={async (t) => {
							try {
								if (
									(t && t !== team._id && t !== team.a) ||
									t === null
								) {
									const r = await client.mutate({
										variables: { id: team._id, parent: t },
										mutation: gql`
											mutation updateTeamParent(
												$id: String!
												$parent: String
											) {
												teamUpdateById(
													_id: $id
													record: { a: $parent }
												) {
													recordId
												}
											}
										`,
										refetchQueries: [
											{
												query: TeamsTreeQuery
											},
											{
												query: TeamDetailsQuery,
												variables: { id: team._id }
											}
										]
									})
									Utils.parseMutationResponse(r)
								}
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{this.state.addSubteamModal && (
					<TextInputModal
						initialValue=""
						title={intl.formatMessage({
							id: 'teams.actions.newTeamName'
						})}
						actionLabel={intl.formatMessage({
							id: 'actions.create'
						})}
						placeholder={intl.formatMessage({ id: 'team' })}
						onClose={() =>
							this.setState({ addSubteamModal: false })
						}
						mutation={(name) => ({
							variables: { name, parent: team._id },
							mutation: gql`
								mutation createChildTeam(
									$name: String!
									$parent: String!
								) {
									teamCreate(
										record: {
											n: $name
											a: $parent
											maxDevices: 1
											deviceTtl: 184
										}
									) {
										recordId
									}
								}
							`,
							refetchQueries: [
								{
									query: TeamsTreeQuery
								}
							]
						})}
					/>
				)}
				{this.state.deleteModal && (
					<DeleteTeamModal
						team={team}
						onClose={() => this.setState({ deleteModal: false })}
					/>
				)}

				<Tabs>
					{Auth.hasAccess('user:browse', team._id) && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faUser} />{' '}
									<FormattedMessage id="users" /> (
									{team.users ? team.users.length : 0})
								</span>
							}
							key="1"
						>
							{Auth.hasAccess('user:add', team._id) && (
								<Button
									style={{ marginBottom: 15 }}
									onClick={() =>
										this.setState({ showNewUser: true })
									}
								>
									<FontAwesomeIcon icon={faUserPlus} />
									&nbsp;
									<FormattedMessage id="teams.addUser" />
								</Button>
							)}
							{this.state.showNewUser && (
								<NewUserForm
									team={team}
									onClose={() =>
										this.setState({ showNewUser: false })
									}
								/>
							)}
							<UsersTable
								users={team.users}
								team={team}
								defaultColumns={[
									'name',
									'email',
									'role',
									'actions'
								]}
							/>
						</TabPane>
					)}
					{Auth.hasAccess('program:browse', team._id) && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faCalendarAlt} />{' '}
									<FormattedMessage id="allPrograms" /> (
									{programCount})
								</span>
							}
							key="2"
						>
							<ProgramsListDisplay
								data={team.programs}
								defaultColumns={['name', 'playlists']}
								link
							/>
							{(team.messagesEnabled ||
								team.parentMessagesEnabled) && (
								<React.Fragment>
									<Divider
										orientation="left"
										style={{ marginTop: 40 }}
									>
										<FontAwesomeIcon icon={faCommentAlt} />{' '}
										<FormattedMessage id="messagePrograms" />
									</Divider>
									<MessageProgramsListDisplay
										data={team.messagePrograms}
										defaultColumns={['name']}
										link
									/>
								</React.Fragment>
							)}
						</TabPane>
					)}
					{Auth.hasAccess('device:browse', team._id) && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faHdd} />{' '}
									<FormattedMessage id="devices" /> (
									{authDevices ? authDevices.length : 0}
									{team.maxDevices && `/${team.maxDevices}`})
								</span>
							}
							key="3"
						>
							<Divider
								orientation="left"
								style={{ marginTop: 40 }}
							>
								<FontAwesomeIcon icon={faQrcode} />{' '}
								<FormattedMessage id="teams.details.devices.preauth" />
							</Divider>
							{Auth.hasAccess('device:add', team._id) &&
								Auth.hasPermission('device:read:magicLink') && (
									<Button
										style={{ marginBottom: 10 }}
										onClick={() =>
											this.setState({
												pairModal: true,
												pairTab: '2'
											})
										}
									>
										<FontAwesomeIcon icon={faQrcode} />
										&nbsp;
										<FormattedMessage id="teams.details.devices.preauth.new" />
									</Button>
								)}
							<DeviceListDisplay
								data={preAuthDevices}
								dontGreyOutPreactivated
								defaultColumns={['name', 'activationLink']}
								link
							/>
							<Divider
								orientation="left"
								style={{ marginTop: 40 }}
							>
								<FontAwesomeIcon icon={faHdd} />{' '}
								<FormattedMessage id="teams.details.devices.activated" />
							</Divider>
							{Auth.hasPermission('device:edit:syncContent') &&
								Auth.hasAccess('device:edit', team._id) && (
									<Button
										style={{ marginBottom: 10 }}
										onClick={() =>
											this.syncAll(team.devices)
										}
									>
										<FontAwesomeIcon icon={faSyncAlt} />
										&nbsp;
										<FormattedMessage id="programs.details.syncAllDevices" />
									</Button>
								)}
							{Auth.hasAccess('device:add', team._id) && (
								<Button
									style={{ marginBottom: 10, marginLeft: 10 }}
									onClick={() =>
										this.setState({
											pairModal: true,
											pairTab: '1'
										})
									}
								>
									<FontAwesomeIcon icon={faLink} />
									&nbsp;
									<FormattedMessage id="devices.pair.action" />
								</Button>
							)}
							<DeviceListDisplay
								data={authDevices}
								defaultColumns={[
									'name',
									'status',
									'sync',
									'os'
								]}
								link
							/>

							{this.state.pairModal && (
								<PairDevice
									team={team}
									activeTab={this.state.pairTab}
									onClose={(e) =>
										this.setState({ pairModal: false })
									}
								/>
							)}
						</TabPane>
					)}
					{Auth.hasPermission('team:read:billingPlan') && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faCreditCard} />{' '}
									<FormattedMessage id="billing.title" />
								</span>
							}
							key="4"
						>
							<Billing team={team} />
						</TabPane>
					)}
					{(Auth.hasPermission('team:read:note') ||
						Auth.hasPermission('team:edit:setIpWhitelist')) && (
						<TabPane
							tab={
								<span>
									<FontAwesomeIcon icon={faCog} />{' '}
									<FormattedMessage id="settings" />
								</span>
							}
							key="5"
						>
							<Form.Item
								label={intl.formatMessage({
									id: 'teams.details.maxDevices'
								})}
								{...formItemLayoutEqual}
							>
								<InputNumber
									min={0}
									defaultValue={0}
									formatter={(v) =>
										`${v}` === `0` || isNaN(v) ?
											intl.formatMessage({
												id: 'teams.details.maxDevices.unlimited'
											})
										:	`${v}`
									}
									value={team.maxDevices || 0}
									onChange={this.setMaxDevices}
									style={{ width: 100 }}
									disabled={
										!Auth.hasAccess(
											'team:edit',
											team._id
										) ||
										!Auth.hasPermission(
											'team:edit:setMaxDevices'
										)
									}
								/>
							</Form.Item>
							{Auth.hasPermission('team:read:deviceTtl') && (
								<>
									<Form.Item
										label={intl.formatMessage({
											id: 'teams.details.onlineInterval'
										})}
										{...formItemLayoutEqual}
									>
										<Select
											style={{ width: 200 }}
											onChange={this.setDeviceTTL}
											value={team.deviceTtl || 0}
											disabled={
												!Auth.hasAccess(
													'team:edit',
													team._id
												) ||
												!Auth.hasPermission(
													'team:edit:setDeviceTtl'
												)
											}
										>
											<Option value={1}>
												<FormattedMessage id="teams.details.onlineInterval.1day" />
											</Option>
											<Option value={7}>
												<FormattedMessage id="teams.details.onlineInterval.1week" />
											</Option>
											<Option value={14}>
												<FormattedMessage id="teams.details.onlineInterval.2weeks" />
											</Option>
											<Option value={31}>
												<FormattedMessage id="teams.details.onlineInterval.1month" />
											</Option>
											<Option value={92}>
												<FormattedMessage id="teams.details.onlineInterval.3months" />
											</Option>
											<Option value={184}>
												<FormattedMessage id="teams.details.onlineInterval.6months" />
											</Option>
											<Option value={365}>
												<FormattedMessage id="teams.details.onlineInterval.1year" />
											</Option>
											<Option value={0}>
												<FormattedMessage id="teams.details.onlineInterval.never" />
											</Option>
										</Select>
									</Form.Item>
									<Divider orientation="left"></Divider>
								</>
							)}
							{Auth.hasAccess('team:edit', team._id) &&
								Auth.hasPermission(
									'team:edit:setMessagesEnabled'
								) && (
									<Form>
										<Form.Item
											label={intl.formatMessage({
												id: 'teams.details.messagesEnabled'
											})}
											{...formItemLayoutEqual}
										>
											<Switch
												checked={
													team.messagesEnabled ||
													team.parentMessagesEnabled
												}
												onChange={async (value) => {
													this.setState({
														msgLoading: true
													})
													await this.setMessagesEnabled(
														value
													)
													this.setState({
														msgLoading: false
													})
												}}
												loading={this.state.msgLoading}
												disabled={
													team.parentMessagesEnabled
												}
												title={
													team.parentMessagesEnabled ?
														intl.formatMessage({
															id: 'teams.details.parentMessagesEnabled'
														})
													:	null
												}
											/>
										</Form.Item>
										<Form.Item
											label={intl.formatMessage({
												id: 'teams.details.thirdPartyDevicesEnabled'
											})}
											{...formItemLayoutEqual}
										>
											<Switch
												checked={
													team.thirdPartyDevicesEnabled ||
													team.parentThirdPartyDevicesEnabled
												}
												onChange={async (value) => {
													this.setState({
														msgLoading: true
													})
													await this.setThirdPartyDevicesEnabled(
														value
													)
													this.setState({
														msgLoading: false
													})
												}}
												loading={this.state.msgLoading}
												disabled={
													team.parentThirdPartyDevicesEnabled
												}
												title={
													(
														team.parentThirdPartyDevicesEnabled
													) ?
														intl.formatMessage({
															id: 'teams.details.parentThirdPartyDevicesEnabled'
														})
													:	null
												}
											/>
										</Form.Item>
									</Form>
								)}
							{Auth.hasPermission(
								'team:edit:setIpWhitelist',
								team._id
							) && (
								<>
									<Divider orientation="left">
										<FormattedMessage id="teams.details.settings.ipWhitelist" />
									</Divider>
									<Form>
										<Form.Item
											label={intl.formatMessage({
												id: 'teams.details.settings.ipWhitelistLabel'
											})}
											{...formItemLayoutEqual}
										>
											<Input
												placeholder={intl.formatMessage(
													{
														id: 'teams.details.settings.ipWhitelistPlaceholder'
													}
												)}
												style={{
													width: 'calc(100% - 42px)',
													marginRight: 8
												}}
												onChange={(event) => {
													this.setState({
														newIpToWhitelist:
															event.target.value
													})
												}}
												value={
													this.state.newIpToWhitelist
												}
											/>
											<Button
												icon={<PlusOutlined />}
												onClick={async () => {
													this.setState({
														msgLoading: true
													})
													await this.addIpWhitelist(
														this.state.newIpToWhitelist.trim()
													)
													this.setState({
														msgLoading: false
													})
													this.setState({
														newIpToWhitelist: ''
													})
												}}
												disabled={
													this.state.msgLoading ||
													!this.state
														.newIpToWhitelist ||
													this.state.newIpToWhitelist
														.length < 1
												}
											/>
										</Form.Item>
										{fullIpWhitelist &&
											fullIpWhitelist.length > 0 && (
												<Form.Item
													label={
														!mobileVersion &&
														intl.formatMessage({
															id: 'teams.details.settings.ipWhitelistDetailLabel'
														})
													}
													{...formItemLayoutEqual}
												>
													<List
														size="small"
														bordered
														dataSource={
															fullIpWhitelist
														}
														renderItem={(item) => (
															<List.Item>
																<div
																	style={{
																		// Truncate the text if too long
																		display:
																			'inline-block',
																		whiteSpace:
																			'nowrap',
																		overflow:
																			'hidden',
																		textOverflow:
																			'ellipsis',
																		width: 'calc(100% - 100px)',
																		verticalAlign:
																			'middle'
																	}}
																>
																	{item}
																</div>
																&nbsp;
																{(
																	team.parentIpWhitelist?.includes(
																		item
																	)
																) ?
																	<Button
																		style={{
																			float: 'right'
																		}}
																		size="small"
																		shape="round"
																		type="danger"
																		disabled
																	>
																		<FormattedMessage id="teams.details.settings.ipWhitelistInherited" />
																	</Button>
																:	<Button
																		style={{
																			float: 'right'
																		}}
																		size="small"
																		shape="round"
																		type="danger"
																		onClick={async () => {
																			this.setState(
																				{
																					msgLoading: true
																				}
																			)
																			await this.removeIpWhitelist(
																				item
																			)
																			this.setState(
																				{
																					msgLoading: false
																				}
																			)
																		}}
																	>
																		{intl.formatMessage(
																			{
																				id: 'actions.delete'
																			}
																		)}
																	</Button>
																}
															</List.Item>
														)}
													/>
												</Form.Item>
											)}
									</Form>
								</>
							)}
							{Auth.hasPermission('team:edit:setLogo') && (
								<React.Fragment>
									<Divider orientation="left">
										<FormattedMessage id="teams.details.settings.logo" />
									</Divider>
									<div
										style={{
											width: '100%',
											textAlign: 'center'
										}}
									>
										<Button
											style={{
												width: 414,
												height: 112,
												background: `url(${phoneBg}) 50% 50% / contain no-repeat`,
												border: 0,
												padding: 0
											}}
										>
											<div
												style={{
													position: 'absolute',
													left: 106,
													top: 63,
													width: 200,
													height: 44,
													background: `url(${team.logo ? Utils.martiniLogoImageUrl(team.logo) : emptyLogo}) 50% 50% / cover no-repeat`
												}}
											/>
											<input
												type="file"
												accept="image/*"
												ref={(e) =>
													(this.fileInput = e)
												}
												style={
													S.playlistDetails.fileInput
												}
												onChange={this.onSelectFile}
											/>
											{this.state.imageUploading && (
												<LoadingOutlined
													style={
														S.playlistDetails
															.spinner
													}
												/>
											)}
										</Button>
										<br />
										<span
											style={{
												margin: 10,
												color: Colors.disabled
											}}
										>
											<FormattedMessage id="teams.details.settings.logoRequirements" />
										</span>
										{team.logo && (
											<React.Fragment>
												<br />
												<Button
													onClick={async () => {
														this.setState({
															imageUploading: true
														})
														await this.editTeam(
															'logo',
															null,
															'String',
															true
														)
														this.setState({
															imageUploading: false
														})
													}}
												>
													<FormattedMessage id="actions.reset" />
												</Button>
											</React.Fragment>
										)}
									</div>
								</React.Fragment>
							)}
							{Auth.hasPermission('team:read:note') && (
								<React.Fragment>
									<Divider orientation="left">
										<FontAwesomeIcon icon={faClipboard} />{' '}
										<FormattedMessage id="spectreNotes" />
									</Divider>
									<Comments
										objectId={team._id}
										writePermission="team:edit:setNote"
									/>
								</React.Fragment>
							)}
						</TabPane>
					)}
				</Tabs>
			</div>
		)
	}
}

const TeamDetails = withRouter(withApollo(injectIntl(ITeamDetails)))

class TeamDetailsFetch extends Component {
	render() {
		const team = this.props.match.params.teamId
		return (
			<div>
				{team && (
					<Query query={TeamDetailsQuery} variables={{ id: team }}>
						{({ loading, error, data }) => {
							if ((!data || !data.teamById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							if (!data.teamById) {
								return <Redirect to="/teams" />
							}

							return <TeamDetails team={data.teamById} />
						}}
					</Query>
				)}
			</div>
		)
	}
}

export default withResponsive(injectIntl(TeamDetailsFetch))
