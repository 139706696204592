import moment from 'moment-timezone'
import { find } from 'lodash'

// Convert a timezone IANA format (e.g. "America/Los_Angeles") to UTC+offset format (e.g. "UTC-08:00")
// Used to display the timezone in the timezone switch button
export const convertTimezoneIANAToUTCFormat = (timezoneName: string) => {
	if (moment.tz.zone(timezoneName) != null) {
		const Z = moment().tz(timezoneName)
		const offset = parseInt(Z.format('Z'))
		if (offset > 0) {
			return `UTC+${offset}`
		} else if (offset < 0) {
			return `UTC${offset}`
		} else {
			return 'UTC'
		}
	} else {
		return 'Unknown'
	}
}

// WARNING: it is impossible to reliably guess a IANA TZ name (e.g. "America/Los_Angeles") from the UTC offset (e.g. "-7").
// Multiple TZs can share the same UTC offset at a specific point in time, and diverge later (e.g. because of DST).
// This function is a crutch to work with old Drax players which sometimes report an offset instead of a IANA timezone
const getIANATimezoneFromOffset = (timezoneOffset: number) => {
	const IANATimezone = find(moment.tz.names(), (timezoneName: string) => {
		return timezoneOffset === parseInt(moment.tz(timezoneName).format('Z'))
	})
	return IANATimezone
}

// WARNING: it is impossible to reliably guess a IANA TZ name (e.g. "America/Los_Angeles") from a TZ's abbreviated name (e.g. "PDT")
// Multiple TZs can share the same UTC offset at a specific point in time, and diverge later (e.g. because of DST).
// This function is a crutch to work with old Drax players which sometimes report an abbreviation instead of a IANA timezone
const getIANATimezoneFromAbbreviation = (abbreviation: string) => {
	return find(moment.tz.names(), (timezoneName: string) => {
		const abbrs = moment.tz.zone(timezoneName)?.abbrs
		return abbrs?.includes(abbreviation)
	})
}

// Take any timezone format (abbreviation, UTC, IANA)
// and return the IANA format
// The returned timezone is not reliable and SHOULD NOT be stored
export const normalizeTimezoneToIANAFormat = (timezone: string) => {
	// If the timezone is in the IANA format then return it as it is
	if (moment.tz.zone(timezone) != null) {
		return timezone
	} // If the timezone is in the UTC format then attempt to map
	else if (timezone.match(/^UTC((\+|-)\d{1,2}(:\d{1,2})?)?$/)) {
		const normalizedTimezone = getIANATimezoneFromOffset(
			parseInt(timezone.replace('UTC', ''))
		)
		if (normalizedTimezone) {
			return normalizedTimezone
		} else {
			return timezone
		}
	}
	// Then if the timezone is in an other format then convert it to IANA
	else {
		// Get the IANA format if it exists
		const normalizedTimezone = getIANATimezoneFromAbbreviation(timezone)
		if (normalizedTimezone) {
			return normalizedTimezone
		} else {
			return timezone
		}
	}
}

export const timeAgo = (input: string | Date) => {
	const date = input instanceof Date ? input : new Date(input)
	const ranges = [
		['years', 3600 * 24 * 365],
		['months', 3600 * 24 * 30],
		['weeks', 3600 * 24 * 7],
		['days', 3600 * 24],
		['hours', 3600],
		['minutes', 60],
		['seconds', 1]
	] as const
	const secondsElapsed = (date.getTime() - Date.now()) / 1000

	for (const [rangeType, rangeVal] of ranges) {
		if (rangeVal < Math.abs(secondsElapsed)) {
			const delta = Math.ceil(secondsElapsed / rangeVal)
			return { rangeType, delta }
		}
	}

	return {}
}
