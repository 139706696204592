const renderTeamChildren = (teams, node) => {
	if (node) {
		let result = { ...node, key: node._id }
		let children = teams
			.filter((t) => t.a === node._id)
			.map((t) => renderTeamChildren(teams, t))
		if (children && children.length > 0) {
			result.children = children
		}
		return result
	}
	return null
}

export const buildTeamTree = (teams, nodeId) => {
	if (!teams) {
		return null
	}
	return teams
		.filter((t) => {
			if (nodeId) {
				return t._id === nodeId
			}
			return !t.a
		})
		.map((t) => renderTeamChildren(teams, t))
}

export const pathDepth = (path) => {
	if (!path) {
		return 0
	}
	return path.split('/').length - 1
}

export const pathIndentation = (path) => {
	const depth = pathDepth(path)
	let str = ''
	for (let i = 0; i < depth; i++) {
		str += '-'
	}
	return str + ' '
}
