import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Modal, Button } from 'antd'

class CustomConfirmModal extends React.PureComponent {
	state = {
		visible: true
	}

	close = () => {
		this.setState({ visible: false })
	}

	onPrimaryAction = () => {
		this.close()
		this.props.onPrimaryAction()
	}

	onSecondaryAction = () => {
		this.close()
		this.props.onSecondaryAction()
	}

	onCancel = () => {
		this.close()
		this.props.onCancel && this.props.onCancel()
	}

	render() {
		const {
			title,
			content,
			primaryActionText,
			secondaryActionText,
			cancelText,
			afterClose
		} = this.props
		const { visible } = this.state
		return (
			<Modal
				destroyOnClose={true}
				visible={visible}
				loading={false}
				title={title}
				onOk={this.onPrimaryAction}
				onCancel={this.onCancel}
				afterClose={afterClose}
				footer={[
					<Button
						key="back"
						onClick={this.onCancel}
						data-test="button-cancel"
					>
						{cancelText}
					</Button>,
					<Button
						key="onSecondaryAction"
						type="primary"
						onClick={this.onSecondaryAction}
						data-test="button-secondary"
					>
						{secondaryActionText}
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={this.onPrimaryAction}
						data-test="button-primary"
					>
						{primaryActionText}
					</Button>
				]}
			>
				{content}
			</Modal>
		)
	}
}

export const openCustomConfirmModal = (props) => {
	const div = document.createElement('div')
	ReactDOM.render(
		<CustomConfirmModal
			{...props}
			afterClose={() => {
				ReactDOM.unmountComponentAtNode(div)
			}}
		/>,
		div
	)
}

export default CustomConfirmModal
