import React, { Component } from 'react'
import { Modal, Input } from 'antd'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import * as Utils from './Utils'

class TextInputModal extends Component {
	state = {
		loading: false,
		text: null
	}

	componentDidMount() {
		this.setState({ text: this.props.initialValue })
		setTimeout(() => {
			if (this.field) {
				this.field.focus()
			}
		}, 200)
	}

	handleCancel = (e) => {
		if (e.currentTarget.id === 'cancelButton') {
			// means the user clicked on the Cancel button
			this.props.onClose(true)
		} else {
			// means the user clicked on the X close button
			this.props.onClose(false)
		}
	}

	handleOK = async () => {
		this.setState({ loading: true })
		const { action, mutation, client, onClose } = this.props

		try {
			if (mutation) {
				const response = await client.mutate(mutation(this.state.text))
				Utils.parseMutationResponse(response)
			} else if (action) {
				await action(this.state.text)
			}
			this.setState({ loading: false })
			onClose(true)
		} catch (e) {
			Utils.displayError(e)
			this.setState({ loading: false })
		}
	}

	render() {
		const { title, actionLabel, placeholder, intl, textArea } = this.props
		return (
			<Modal
				visible={true}
				title={title}
				cancelText={intl.formatMessage({ id: 'actions.skip' })}
				cancelButtonProps={{
					id: 'cancelButton',
					hidden: this.state.text?.length > 0
				}}
				onCancel={this.handleCancel}
				okText={actionLabel}
				okButtonProps={{
					hidden: !(this.state.text?.length > 0)
				}}
				onOk={this.handleOK}
				confirmLoading={this.state.loading}
			>
				{textArea ?
					<Input.TextArea
						placeholder={placeholder}
						ref={(i) => (this.field = i)}
						value={this.state.text}
						onChange={(e) =>
							this.setState({ text: e.target.value })
						}
					/>
				:	<Input
						placeholder={placeholder}
						ref={(i) => (this.field = i)}
						onPressEnter={this.handleOK}
						value={this.state.text}
						onChange={(e) =>
							this.setState({ text: e.target.value })
						}
					/>
				}
			</Modal>
		)
	}
}

export default withApollo(injectIntl(TextInputModal))
