import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'

import AppLayout from './PageLayout'
import { Desktop, SubDesktop } from './Responsive'
import EmptySelection from './EmptySelection'
import S from './Styles'

// Bug in react-router-dom
// https://github.com/ReactTraining/react-router/issues/5866#issuecomment-385207863
Route.propTypes = {
	computedMatch: PropTypes.object,
	path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	exact: PropTypes.bool,
	strict: PropTypes.bool,
	sensitive: PropTypes.bool,
	component: PropTypes.func,
	render: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	location: PropTypes.object
}

export default class MasterDetail extends React.Component {
	state = {
		wideMode: false
	}

	setWideMode = (wideMode) => {
		this.setState({ wideMode })
	}

	render() {
		const {
			match,
			master: Master,
			detail: Detail,
			icon,
			itemVariable
		} = this.props
		const wm = {
			wideMode: this.state.wideMode,
			setWideMode: this.setWideMode
		}
		const fullSizeContent = (
			<div style={S.layout.scrollingContainer}>
				<Route
					exact
					path={`${match.url}`}
					render={(props) => (
						<Master {...props} match={match} {...wm} />
					)}
				/>
				<Route
					path={`${match.url}/:${itemVariable}`}
					render={(props) => <Detail {...props} {...wm} />}
				/>
			</div>
		)

		return (
			<AppLayout match={match}>
				<Desktop>
					{this.state.wideMode && match.url === '/devices' ?
						fullSizeContent
					:	<Row style={S.layout.fullHeight}>
							<Col span={12} style={S.layout.fullHeight}>
								<div
									style={{
										...S.layout.scrolling,
										marginRight: 24
									}}
								>
									<div style={S.layout.scrollingContainer}>
										<Route
											path={[
												`${match.url}/:${itemVariable}`,
												`${match.url}`
											]}
											render={(props) => (
												<Master {...props} {...wm} />
											)}
										/>
									</div>
								</div>
							</Col>
							<Col span={12} style={S.layout.fullHeight}>
								<div style={S.layout.scrolling}>
									<Switch>
										<Route
											path={`${match.url}/:${itemVariable}`}
											render={(p) => (
												<div
													style={
														S.layout
															.scrollingContainer
													}
												>
													<Detail {...p} {...wm} />
												</div>
											)}
										/>
										<Route
											exact
											path={`${match.url}`}
											render={(p) => (
												<EmptySelection icon={icon} />
											)}
										/>
									</Switch>
								</div>
							</Col>
						</Row>
					}
				</Desktop>
				<SubDesktop>{fullSizeContent}</SubDesktop>
			</AppLayout>
		)
	}
}
