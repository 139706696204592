import React, { Component } from 'react'
import {
	Modal,
	Alert,
	Button,
	Form,
	Tag,
	Tabs,
	Select,
	Input,
	Steps,
	Switch
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import ReactCodeInput from 'react-code-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUsers,
	faMusic,
	faCommentAlt
} from '@fortawesome/free-solid-svg-icons'

import { SelectTeamV2 } from '../teams/SelectTeam'
import { SelectProgram, SelectMessagePrograms } from '../tracks/SelectProgram'
import { SelectTimezone } from './SelectTimezone'
import { DevicesTableQuery } from './Queries'
import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'
import SelectSetupDevice from './SelectSetupDevice'
import { formItemLayout } from '../../components/Styles'

export const validateTeam = (team) => {
	if (team) {
		if (team.maxDevices && !Auth.hasPermission('team:edit:setMaxDevices')) {
			return team.devices.length < team.maxDevices
		} else {
			return true
		}
	}
	return false
}

class PairDevice extends Component {
	state = {
		activeTab: this.props.activeTab || '1',
		error: null,
		pin: '',
		team: this.props.team,
		program: null,
		accordionActive: null,
		messagePrograms: [],
		language: 'en',
		email: '',
		name: '',
		timezone: '',
		type: 'martini',
		sendEmail: false
	}

	sendCode = async () => {
		const { client, history, onClose } = this.props
		try {
			this.setState({ loading: true })
			let mut, r
			let variables = {
				teamId: this.state.team && this.state.team._id,
				programId: this.state.program,
				messageProgramIds: this.state.messagePrograms,
				timezone: this.state.timezone,
				nickname: this.state.name,
				type: this.state.type
			}
			if (this.state.activeTab === '2') {
				variables.email = this.state.email
				variables.language = this.state.language
				r = await client.mutate({
					variables,
					mutation: gql`
						mutation sendMagicLink(
							$teamId: String
							$programId: String
							$messageProgramIds: [String]
							$nickname: String
							$email: String
							$language: String
							$type: String
						) {
							sendMagicLink(
								teamId: $teamId
								programId: $programId
								messageProgramIds: $messageProgramIds
								nickname: $nickname
								email: $email
								language: $language
								type: $type
							) {
								success
								deviceId
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: DevicesTableQuery
						}
					]
				})
				mut = 'sendMagicLink'
			} else {
				variables.pin = this.state.pin
				r = await client.mutate({
					variables,
					mutation: gql`
						mutation setupDevice(
							$pin: String!
							$teamId: String
							$programId: String
							$messageProgramIds: [String]
							$timezone: String
							$nickname: String
						) {
							setupDevice(
								pin: $pin
								teamId: $teamId
								programId: $programId
								messageProgramIds: $messageProgramIds
								timeZone: $timezone
								nickname: $nickname
							) {
								success
								deviceId
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: DevicesTableQuery
						}
					]
				})
				mut = 'setupDevice'
			}

			Utils.parseMutationResponse(r)
			this.setState({ loading: false })
			const res = r.data[mut]
			if (res && res.success) {
				// redirect to device details
				onClose()
				history.push(`/devices/${res.deviceId}`)
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	handleCancel = () => {
		this.props.onClose()
	}

	render() {
		const { intl } = this.props

		return (
			<Modal
				visible={true}
				title={intl.formatMessage({ id: 'devices.pair.action' })}
				onCancel={this.handleCancel}
				footer={[
					<Button
						key="submit"
						ref={(b) => (this.submit = b)}
						type="primary"
						onClick={this.sendCode}
						loading={this.state.loading}
						disabled={
							(this.state.activeTab === '2' &&
								(this.state.sendEmail ||
									this.state.type === 'martini') &&
								(!this.state.email || !this.state.language)) ||
							(this.state.activeTab !== '2' &&
								!this.state.pin &&
								!this.state.timezone) ||
							!this.state.program ||
							!validateTeam(this.state.team)
						}
					>
						<FormattedMessage id="devices.pair.pairAction" />
					</Button>
				]}
			>
				<Form>
					<Tabs
						activeKey={this.state.activeTab}
						onChange={(t) => this.setState({ activeTab: t })}
					>
						<Tabs.TabPane
							tab={<FormattedMessage id="devices.pair.pin" />}
							key="1"
						>
							<div style={styles.body}>
								<h3>
									<FormattedMessage id="devices.pair.title" />
								</h3>
								<div style={{ marginBottom: 50 }}>
									<ReactCodeInput
										autoFocus
										type="text"
										fields={6}
										inputMode="tel"
										isValid={!this.state.error}
										value={this.state.pin}
										onChange={(e) => {
											this.setState({ pin: e })
											//if (e.length === 6) {
											// 		this.submit.focus()
											//}
										}}
									/>
								</div>
							</div>
						</Tabs.TabPane>

						{Auth.hasPermission('device:read:magicLink') && ( // for now only admins
							<Tabs.TabPane
								tab={
									<FormattedMessage id="devices.pair.email" />
								}
								key="2"
							>
								<Form.Item
									label={intl.formatMessage({
										id: 'teams.details.deviceType'
									})}
								>
									<Select
										value={this.state.type}
										onChange={(type) => {
											if (type === 'martini') {
												this.setState({
													sendEmail: true
												})
											}
											this.setState({ type: type })
										}}
									>
										<Select.Option value="martini">
											{intl.formatMessage({
												id: 'teams.details.deviceType.mobileApp'
											})}
										</Select.Option>
										<Select.Option value="largo">
											{intl.formatMessage({
												id: 'teams.details.deviceType.webApp'
											})}
										</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'teams.details.sendEmail'
									})}
									style={{ marginBottom: 0 }}
								>
									<Switch
										checked={this.state.sendEmail}
										size="small"
										onChange={(sendEmail) =>
											this.setState({
												sendEmail,
												email: ''
											})
										}
									/>
								</Form.Item>
								{this.state.sendEmail && (
									<div
										style={{
											marginLeft: 20,
											marginTop: 10
										}}
									>
										<p>
											<FormattedMessage id="devices.pair.info1" />
										</p>
										<Form.Item
											label={intl.formatMessage({
												id: 'teams.details.email'
											})}
											{...formItemLayout}
										>
											<Input
												autoCapitalize="none"
												autocompletetype="email"
												autoCorrect="false"
												keyboardtype="email-address"
												value={this.state.email}
												onChange={(v) =>
													this.setState({
														email: v.target.value
													})
												}
											/>
										</Form.Item>

										<Form.Item
											label={intl.formatMessage({
												id: 'teams.details.language'
											})}
											{...formItemLayout}
										>
											<Select
												value={this.state.language}
												onChange={(v) =>
													this.setState({
														language: v
													})
												}
											>
												{Utils.languages.map((l) => (
													<Select.Option
														key={l}
														value={l}
													>
														<FormattedMessage
															id={`lang.${l}`}
														/>
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										{this.state.type === 'martini' && (
											<>
												<p>
													<FormattedMessage id="devices.pair.info2" />
												</p>
												<Steps
													direction="vertical"
													size="small"
												>
													<Steps.Step
														status="process"
														description={
															<React.Fragment>
																<FormattedMessage id="devices.pair.info3" />{' '}
																<b>
																	<FormattedMessage id="devices.pair.info4" />
																</b>{' '}
																<FormattedMessage id="devices.pair.info5" />{' '}
																<a>
																	<FormattedMessage id="devices.pair.info6" />
																</a>
															</React.Fragment>
														}
													/>
													<Steps.Step
														status="process"
														description={intl.formatMessage(
															{
																id: 'devices.pair.info7'
															}
														)}
													/>
												</Steps>
											</>
										)}
									</div>
								)}
							</Tabs.TabPane>
						)}

						{Auth.hasPermission('device:read:setupDevices') && (
							<Tabs.TabPane
								tab={
									<FormattedMessage id="devices.pair.availableDevices" />
								}
								key="3"
							>
								<SelectSetupDevice
									pin={this.state.pin}
									onChange={(pin, type) => {
										this.setState({ pin, type })
									}}
								/>
							</Tabs.TabPane>
						)}
					</Tabs>

					<Form.Item
						label={intl.formatMessage({
							id: 'devices.pair.deviceName'
						})}
						style={{ marginTop: 24 }}
					>
						<Input
							value={this.state.name}
							onChange={(v) =>
								this.setState({ name: v.target.value })
							}
						/>
					</Form.Item>
					<Form.Item
						label={
							<React.Fragment>
								<FontAwesomeIcon icon={faUsers} fixedWidth />
								&nbsp;
								<FormattedMessage id="team" />
							</React.Fragment>
						}
					>
						<SelectTeamV2
							onChange={(team) => this.setState({ team })}
							selected={this.state.team && this.state.team._id}
							keyprops="_id"
							withDevices
						/>
						{this.state.team && !validateTeam(this.state.team) && (
							<Tag color="red">
								<FormattedMessage id="devices.pair.tooManyDevices" />
							</Tag>
						)}
					</Form.Item>
					<Form.Item
						label={
							<React.Fragment>
								<FontAwesomeIcon icon={faMusic} fixedWidth />
								&nbsp;
								<FormattedMessage id="program" />
							</React.Fragment>
						}
					>
						<SelectProgram
							deviceTeam={this.state.team}
							onSelect={(p) => this.setState({ program: p })}
							selectedId={this.state.program}
							showPlaylists
						/>
					</Form.Item>
					{this.state.pin &&
						parseInt(this.state.pin, 10) >= 500000 &&
						this.state.team &&
						(this.state.team.messagesEnabled ||
							this.state.team.parentMessagesEnabled) && (
							<Form.Item
								label={
									<React.Fragment>
										<FontAwesomeIcon
											icon={faCommentAlt}
											fixedWidth
										/>
										&nbsp;
										<FormattedMessage id="messagePrograms" />
									</React.Fragment>
								}
							>
								<SelectMessagePrograms
									selectedIds={this.state.messagePrograms}
									onSelect={(mps) =>
										this.setState({ messagePrograms: mps })
									}
								/>
							</Form.Item>
						)}
					{(this.state.activeTab === '1' ||
						this.state.activeTab === '3') && (
						<Form.Item
							label={
								<React.Fragment>
									<FontAwesomeIcon
										icon={faMusic}
										fixedWidth
									/>
									&nbsp;
									<FormattedMessage id="devices.pair.deviceTimezone" />
								</React.Fragment>
							}
						>
							<SelectTimezone
								currentDevicePin={this.state.pin}
								onSelect={(values) =>
									this.setState({
										timezone:
											values &&
											values.length > 1 &&
											values[values.length - 1]
									})
								}
								selectedValue={this.state.timezone}
							/>
						</Form.Item>
					)}
				</Form>

				{this.state.error && (
					<Alert
						message={intl.formatMessage({ id: 'error' })}
						description={this.state.error}
						style={{ width: '100%', marginTop: 20 }}
						type="error"
						showIcon
					/>
				)}
			</Modal>
		)
	}
}

const styles = {
	body: {
		paddingTop: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
}

export default withRouter(withApollo(injectIntl(PairDevice)))
