import React, { Component } from 'react'
import { render } from 'react-dom'
import { ApolloProvider, ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { createUploadLink } from 'apollo-upload-client'
import { concat } from '@apollo/client'
import { IntlProvider } from 'react-intl'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import 'antd/dist/antd.css'
import './styles/styles.css'
import 'moment/locale/fr'

import Routes from './router'
import config from './config'
import * as Utils from './components/Utils'
import * as Auth from './auth'
import { monitorAppUpdate } from './monitorUpdate'

monitorAppUpdate()

const env = Utils.environment()

if (env === 'production' || env === 'staging') {
	Sentry.init({
		dsn: 'https://1b897be82649486c92d65d62b34d3fbe@sentry.io/1220128',
		environment: env,
		release: import.meta.env.VITE_APP_GIT_SHA,
		beforeBreadcrumb: (breadcrumb, hint) => {
			if (
				breadcrumb.category === 'http' &&
				breadcrumb.data.url.startsWith('http://127.0.0.1')
			) {
				return null
			}
			return breadcrumb
		}
	})
}

const link = createUploadLink({
	uri: `${window.location.origin}/graphql`,
	credentials: 'include',
	headers: { 'Apollo-Require-Preflight': 'true' }
})

const errorLink = onError((req) => {
	if (Auth.cache.user) {
		if (req.graphQLErrors) {
			for (let e of req.graphQLErrors) {
				let error = new Error(e.message)
				error.graphQLErrors = JSON.stringify(e)
				error.operation = JSON.stringify(req.operation)
				error.response = JSON.stringify(req.response)
				error.user = Auth.cache.user.username

				if (Utils.userFacingErrors.indexOf(e.message) === -1) {
					Sentry.captureException(error)
				}
			}
		}
	}
})

const client = new ApolloClient({
	link: concat(errorLink, link),
	cache: new InMemoryCache()
})

Utils.setLanguage()

class ApolloApp extends Component {
	state = {
		language: Utils.language
	}

	setLanguage = (language) => {
		if (language) {
			Utils.setLanguage(language)
			this.setState({ language })
		}

		window.intercomSettings = {
			app_id: 'uul2iavk',
			alignment: 'right',
			custom_launcher_selector: '#help',
			hide_default_launcher: true,
			language_override: Auth.cache.user?.language
		}

		window.Intercom('boot', {
			app_id: config.intercom.appId
		})
	}

	render() {
		return (
			<IntlProvider
				locale={this.state.language}
				messages={Utils.messages[this.state.language]}
			>
				<ApolloProvider client={client}>
					<Routes
						graphqlClient={client}
						setLanguage={this.setLanguage}
					/>
				</ApolloProvider>
			</IntlProvider>
		)
	}
}

render(<ApolloApp />, document.getElementById('root'))
