import React, { Component } from 'react'
import { Alert, Layout, Button, Result } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { Redirect } from 'react-router-dom'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import { Mobile, Default } from '../../../components/Responsive'
import Loading from '../../../components/LoadingPage'
import * as Utils from '../../../components/Utils'
import Player from './Player'
import * as AudioPlayer from '../../../components/AudioPlayer'
import SubmitModal from './Submit'
import Logo from '../../../components/SpectreLogo'
import TrackList from './List'

import logoSpectre from '../../../img/logoSpectre.png'

import { publicGraphqlQueries } from '../../../shared/publicGraphqlQuery'

const SampleQuery = gql`
	${publicGraphqlQueries.queryFetchSampleToListen}
`

class IListenDisplay extends Component {
	state = {
		selectedTrack: null,
		selectedIndex: 0,
		feedback: {},
		hasChanges: false,
		playing: false,
		submitModal: false,
		submitted: false,
		showList: false
	}

	onBeforeUnload = (event) => {
		const { intl } = this.props
		// Ask confirmation if the user close the window after the fields have been edited
		if (
			!this.state.submitted &&
			Object.keys(this.state.feedback).length > 0
		) {
			event.returnValue = intl.formatMessage({
				id: 'samples.close.confirmation.message'
			})
		}
	}

	componentDidMount() {
		const fb = localStorage.getItem(`sample-${this.props.sample._id}`)
		if (fb) {
			this.setState({ feedback: JSON.parse(fb) })
		}
		this.preloadTracks()
		window.addEventListener('beforeunload', this.onBeforeUnload)
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.onBeforeUnload)
	}

	preloadTracks = () => {
		const { sample } = this.props
		sample.trackObjects.forEach((trackObject) => {
			AudioPlayer.preloadTrack({
				track: trackObject._id
			})
		})
	}

	selectTrack = async (index) => {
		const { sample } = this.props
		const track = sample.tracks[index]
		const trackObject = sample.trackObjects[index]

		AudioPlayer.startTrack({
			track: trackObject._id,
			crossfade: 2,
			startTime: track.start === -1 ? 0 : track.start,
			endTime: track.end,
			endCallback: () => {
				if (Utils.isClientIOS()) {
					// on iOS we can't play a track without user interaction
					this.setState({ playing: false })
				} else {
					this.selectTrack((index + 1) % sample.tracks.length)
				}
			}
		})

		this.setState({
			selectedTrack: {
				...track,
				...trackObject
			},
			selectedIndex: index,
			playing: true
		})
	}

	setFeedback = (rating, comment) => {
		let trackFb = this.state.feedback[this.state.selectedTrack._id] || {}
		if (rating) {
			if (trackFb.rating === rating) {
				trackFb.rating = null
			} else {
				trackFb.rating = rating
			}
		}
		if (comment || comment === '') {
			trackFb.comment = comment
		}
		let fb = { ...this.state.feedback }
		fb[this.state.selectedTrack._id] = trackFb
		this.setState({ feedback: fb, hasChanges: true })

		this.save()
	}

	playPause = () => {
		if (this.state.playing) {
			AudioPlayer.stop()
			this.setState({ playing: false })
		} else {
			this.selectTrack(this.state.selectedIndex)
		}
	}

	save = () => {
		localStorage.setItem(
			`sample-${this.props.sample._id}`,
			JSON.stringify(this.state.feedback)
		)
	}

	render() {
		const { sample, intl, review } = this.props

		if (this.state.submitted) {
			return (
				<Layout style={styles.main}>
					<Layout.Content style={styles.layout}>
						<Result
							status="success"
							title={
								<span style={{ color: colors.text }}>
									<FormattedMessage id="samples.listen.submitSuccess" />
								</span>
							}
						/>
					</Layout.Content>
				</Layout>
			)
		}

		Utils.setPageTitle(
			intl,
			null,
			intl.formatMessage(
				{ id: 'samples.listen.title' },
				{ name: sample.name }
			)
		)

		return (
			<React.Fragment>
				<Mobile>
					<Layout style={styles.main}>
						<Layout.Header
							style={{ ...styles.header, padding: '0 10px' }}
						>
							{!this.state.showList ?
								<div
									style={styles.mobileLogoContainer}
									onClick={() => {
										this.setState({ showList: false })
									}}
								>
									<Logo color="#FFF" />
								</div>
							:	<Button
									type="secondary"
									onClick={() =>
										this.setState({ showList: false })
									}
								>
									Back
								</Button>
							}
							<h1 style={styles.headerTitle}>{sample.name}</h1>
							{review ?
								<Button
									type="primary"
									onClick={() =>
										this.setState({ submitModal: true })
									}
								>
									<FormattedMessage id="actions.submit" />
								</Button>
							:	<div></div>}
						</Layout.Header>
						<Layout.Content style={styles.layout}>
							{this.state.showList ?
								<TrackList
									sample={sample}
									feedback={this.state.feedback}
									review={review}
									selectTrack={this.selectTrack}
									selectedIndex={this.state.selectedIndex}
									mobile
								/>
							:	<Player
									sample={sample}
									track={this.state.selectedTrack}
									next={() =>
										this.selectTrack(
											(this.state.selectedIndex + 1) %
												sample.tracks.length
										)
									}
									playPause={this.playPause}
									feedback={
										this.state.selectedTrack ?
											this.state.feedback[
												this.state.selectedTrack._id
											]
										:	null
									}
									setFeedback={this.setFeedback}
									playing={this.state.playing}
									review={review}
									mobile
									showList={() =>
										this.setState({ showList: true })
									}
								/>
							}
						</Layout.Content>
					</Layout>
				</Mobile>
				<Default>
					<Layout style={styles.main}>
						<Layout.Header style={styles.header}>
							<img
								src={logoSpectre}
								alt="Spectre"
								style={{ height: '40%' }}
							/>
							<h1 style={styles.headerTitle}>{sample.name}</h1>
							{review ?
								<Button
									type="primary"
									onClick={() =>
										this.setState({ submitModal: true })
									}
								>
									<FormattedMessage id="samples.submit" />
								</Button>
							:	<div></div>}
						</Layout.Header>
						<Layout.Content style={styles.layout}>
							<Player
								sample={sample}
								track={this.state.selectedTrack}
								next={() =>
									this.selectTrack(
										(this.state.selectedIndex + 1) %
											sample.tracks.length
									)
								}
								playPause={this.playPause}
								feedback={
									this.state.selectedTrack ?
										this.state.feedback[
											this.state.selectedTrack._id
										]
									:	null
								}
								setFeedback={this.setFeedback}
								playing={this.state.playing}
								review={review}
							/>
							<div style={styles.list}>
								<TrackList
									sample={sample}
									feedback={this.state.feedback}
									review={review}
									selectTrack={this.selectTrack}
									selectedIndex={this.state.selectedIndex}
								/>
							</div>
						</Layout.Content>
					</Layout>
				</Default>
				{this.state.submitModal && (
					<SubmitModal
						onClose={(didSubmit) => {
							this.setState({
								submitModal: false,
								submitted: didSubmit
							})
							if (this.state.playing) {
								this.playPause()
							}
						}}
						mutation={({ email, name, comment }) => ({
							variables: {
								email,
								name,
								comment,
								tracks: sample.tracks.map((t) => {
									const fb = this.state.feedback[t.trackId]
									return {
										trackId: t.trackId,
										...fb
									}
								}),
								sampleId: sample._id,
								date: new Date()
							},
							mutation: gql`
								${publicGraphqlQueries.mutationCreateSampleReview}
							`
						})}
					/>
				)}
			</React.Fragment>
		)
	}
}

const ListenDisplay = injectIntl(IListenDisplay)

class Listen extends Component {
	render() {
		const sample = this.props.match.params.sampleId
		return (
			<div style={styles.body}>
				{sample && (
					<Query query={SampleQuery} variables={{ id: sample }}>
						{({ loading, error, data }) => {
							if ((!data || !data.sampleById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={this.props.intl.formatMessage(
											{ id: 'error.message' }
										)}
										type="error"
										showIcon
										banner
									/>
								)
							}

							if (data.sampleById) {
								return (
									<ListenDisplay
										sample={data.sampleById}
										review={this.props.review}
									/>
								)
							} else {
								return <Redirect to="/error/404/NOT_FOUND" />
							}
						}}
					</Query>
				)}
			</div>
		)
	}
}

export default injectIntl(Listen)

const colors = {
	bg: '#181818',
	primary: '#1890ff',
	text: '#fff',
	subtitle: '#999',
	yes: '#52c41a',
	no: '#f5222d'
}

const styles = {
	body: {
		color: colors.text
	},
	main: {
		height: '100vh',
		width: '100vw',
		background: colors.bg
	},
	layout: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		paddingTop: 64
	},
	header: {
		position: 'fixed',
		zIndex: 10,
		width: '100%',
		background: colors.bg,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	headerTitle: {
		color: '#999',
		fontWeight: 700,
		textTransform: 'uppercase',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '3.2px',
		margin: 0
	},
	list: {
		position: 'fixed',
		top: 464,
		left: 0,
		right: 0,
		bottom: 0,
		overflowY: 'auto'
	},
	title: {
		color: colors.text,
		textAlign: 'center'
	},
	mobileLogoContainer: {
		marginLeft: 3,
		marginRight: 6,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'left',
		width: 50
	}
}
