import React from 'react'

export default (props) => (
	<svg
		width="15px"
		height="25px"
		viewBox="0 0 15 25"
		version="1.1"
		style={props.style}
	>
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="spectre" fill={props.color} fillRule="nonzero">
				<path
					d="M10.1073611,12.5 L2.89055556,25 L7.22388889,25 L14.4406944,12.5 L10.1073611,12.5 Z M11.5538889,0 L7.22055556,0 L0.00368055556,12.5 L4.33708333,12.5 L11.5538889,0 Z"
					id="Fill-3"
				></path>
			</g>
		</g>
	</svg>
)
