import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Form, Input, Divider, Button, Select } from 'antd'
import { formItemLayoutEqual } from '../../components/Styles'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'

const dayHours = 24
const weekHours = dayHours * 7

class NotificationSettings extends Component {
	state = {
		loading: false,
		notifyOffline: `${Auth.cache.user.notifyOfflineType || 0}`,
		notifyOfflineDelay: Auth.cache.user.notifyOfflineDelay || weekHours,
		notifyOfflineDelayInterval: 'hours',
		notifyActivation: `${Auth.cache.user.notifyActivationType || 0}`,
		notifyDisabled: `${Auth.cache.user.notifyDisabledType || 0}`
	}

	componentDidMount() {
		this.setState(
			this.calculateDelayInterval(
				Auth.cache.user.notifyOfflineDelay || 24
			)
		)
	}

	calculateDelayInterval = (d) => {
		if (d >= weekHours && d % weekHours === 0) {
			return {
				notifyOfflineDelay: Math.floor(d / weekHours),
				notifyOfflineDelayInterval: 'weeks'
			}
		} else if (d >= dayHours && d % dayHours === 0) {
			return {
				notifyOfflineDelay: Math.floor(d / dayHours),
				notifyOfflineDelayInterval: 'days'
			}
		}
		return {
			notifyOfflineDelay: d,
			notifyOfflineDelayInterval: 'hours'
		}
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		const { onClose, client } = this.props
		const { user } = Auth.cache
		this.setState({ loading: true })

		let delay = null
		if (this.state.notifyOfflineDelay) {
			let d = parseInt(this.state.notifyOfflineDelay, 10)
			switch (this.state.notifyOfflineDelayInterval) {
				case 'weeks':
					delay = d * weekHours
					break
				case 'days':
					delay = d * dayHours
					break
				default:
					delay = d
					break
			}
		}

		try {
			let params = {
				id: user._id,
				notifyOfflineType:
					this.state.notifyOffline ?
						parseInt(this.state.notifyOffline, 10)
					:	null,
				notifyOfflineDelay: delay,
				notifySince:
					this.state.notifyOfflineDevices ? new Date() : null,
				notifyActivationType:
					this.state.notifyActivation ?
						parseInt(this.state.notifyActivation, 10)
					:	null,
				notifyDisabledType:
					this.state.notifyDisabled ?
						parseInt(this.state.notifyDisabled, 10)
					:	null
			}
			let mutation = gql`
				mutation updateUserNotifications(
					$id: String!
					$notifyOfflineType: Float
					$notifyOfflineDelay: Float
					$notifySince: Date
					$notifyActivationType: Float
					$notifyDisabledType: Float
				) {
					userUpdateById(
						_id: $id
						record: {
							notifyOfflineType: $notifyOfflineType
							notifyOfflineDelay: $notifyOfflineDelay
							notifySince: $notifySince
							notifyActivationType: $notifyActivationType
							notifyDisabledType: $notifyDisabledType
						}
					) {
						record {
							_id
						}
					}
				}
			`

			const result = await client.mutate({
				variables: params,
				mutation: mutation
			})
			Utils.parseMutationResponse(result)
			await Auth.refreshUser(client)
			this.setState({ loading: false })
			onClose()
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, onClose } = this.props
		const { user } = Auth.cache
		return (
			<Modal
				visible={true}
				width="80vw"
				title={intl.formatMessage({
					id: 'account.notificationSettings.title'
				})}
				onCancel={onClose}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.handleSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage
							id={user ? 'actions.save' : 'teams.addUser.submit'}
						/>
					</Button>
				]}
			>
				<Form>
					<Form.Item
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'account.notificationSettings.offlineDevices'
						})}
					>
						<Select
							style={{ minWidth: 200 }}
							value={this.state.notifyOffline}
							onChange={(v) =>
								this.setState({ notifyOffline: v })
							}
						>
							<Select.Option key="offlineOff" value="0">
								<FormattedMessage id="account.notificationSettings.type.off" />
							</Select.Option>
							<Select.Option key="offlineTeam" value="1">
								<FormattedMessage id="account.notificationSettings.type.team" />
							</Select.Option>
							<Select.Option key="offlineTeamChildren" value="2">
								<FormattedMessage id="account.notificationSettings.type.teamAndChildren" />
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'account.notificationSettings.offlineDevices.for'
						})}
					>
						<Input
							onChange={(v) =>
								this.setState({
									notifyOfflineDelay: v.target.value
								})
							}
							value={this.state.notifyOfflineDelay}
							type="number"
							disabled={this.state.notifyOffline === '0'}
							style={{ width: 250 }}
							addonAfter={
								<Select
									disabled={this.state.notifyOffline === '0'}
									defaultValue={
										this.state.notifyOfflineDelayInterval
									}
									style={{ width: 120 }}
									onChange={(v) =>
										this.setState({
											notifyOfflineDelayInterval: v
										})
									}
								>
									{['hours', 'days', 'weeks'].map((i) => (
										<Select.Option
											key={`interval-${i}`}
											value={i}
										>
											<FormattedMessage
												id={`react-intl-formatted-duration.${i}Unit`}
												values={{
													value: this.state
														.notifyOfflineDelay
												}}
											/>
										</Select.Option>
									))}
								</Select>
							}
							// addonAfter={intl.formatMessage({ id: 'react-intl-formatted-duration.hoursUnit' }, { value: 2 })}
						/>
					</Form.Item>
					<Form.Item
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'account.notificationSettings.disabledDevices'
						})}
					>
						<Select
							style={{ minWidth: 200 }}
							value={this.state.notifyDisabled}
							onChange={(v) =>
								this.setState({ notifyDisabled: v })
							}
						>
							<Select.Option key="offlineOff" value="0">
								<FormattedMessage id="account.notificationSettings.type.off" />
							</Select.Option>
							<Select.Option key="offlineTeam" value="1">
								<FormattedMessage id="account.notificationSettings.type.team" />
							</Select.Option>
							<Select.Option key="offlineTeamChildren" value="2">
								<FormattedMessage id="account.notificationSettings.type.teamAndChildren" />
							</Select.Option>
						</Select>
					</Form.Item>
					<Divider />
					<Form.Item
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'account.notificationSettings.deviceActivated'
						})}
					>
						<Select
							style={{ minWidth: 200 }}
							value={this.state.notifyActivation}
							onChange={(v) =>
								this.setState({ notifyActivation: v })
							}
						>
							<Select.Option key="activationOff" value="0">
								<FormattedMessage id="account.notificationSettings.type.off" />
							</Select.Option>
							<Select.Option key="activationTeam" value="1">
								<FormattedMessage id="account.notificationSettings.type.team" />
							</Select.Option>
							<Select.Option
								key="activationTeamChildren"
								value="2"
							>
								<FormattedMessage id="account.notificationSettings.type.teamAndChildren" />
							</Select.Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(NotificationSettings))
