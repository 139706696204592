import React, { Component } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'

import Logo from '../../components/SpectreLogo'
import { changePassword } from '../../auth'
import S from '../../components/Styles'
import { setPageTitle, detectLocale } from '../../components/Utils'
import * as Auth from '../../auth'

const FormItem = Form.Item

class ResetPassword extends Component {
	formRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			goToLogin: false,
			errorCode: null
		}
	}

	handleSubmit = async (values) => {
		this.setState({ loading: true })
		const result = await changePassword({
			username: decodeURIComponent(this.props.match.params.username),
			token: decodeURIComponent(this.props.match.params.token),
			language: detectLocale(),
			...values
		})
		if (result.success) {
			// log in
			await Auth.login({
				username: decodeURIComponent(this.props.match.params.username),
				password: values.password
			})
			this.setState({ loading: false })
			this.props.history.push('/devices')
		} else if (result.error) {
			this.setState({
				errorCode: result.error.statusCode,
				loading: false
			})
		}
	}

	compareToFirstPassword = (rule, value, callback) => {
		if (value && value !== this.formRef.current.getFieldValue('password')) {
			callback(
				this.props.intl.formatMessage({
					id: 'auth.error.passwordsDontMatch'
				})
			)
		} else {
			callback()
		}
	}

	validateToNextPassword = async (rule, value, callback) => {
		if (value && this.state.confirmDirty) {
			await this.formRef.current.validateFields(['passwordConfirm'], {
				force: true
			})
		}
		callback()
	}

	render() {
		setPageTitle(this.props.intl, 'auth.resetPassword.title')
		const newAccount = this.props.match.params.new === 'true'
		const errorMessage =
			this.state.errorCode === 400 ?
				this.props.intl.formatMessage({
					id: 'server.INVALID_NEW_PASSWORD'
				})
			:	this.props.intl.formatMessage({
					id: 'server.RESET_PASSWORD_GENERIC_ERROR'
				})
		return (
			<div>
				<div style={S.login.bgImage}></div>
				<div style={S.login.bg}>
					<div style={S.login.container} className="login">
						<Form
							ref={this.formRef}
							onFinish={this.handleSubmit}
							style={S.login.form}
						>
							<div style={S.login.logoContainer}>
								<Logo color="#000" style={{ margin: '10px' }} />
							</div>
							{newAccount ?
								<div>
									<h3 style={{ textAlign: 'center' }}>
										<FormattedMessage id="auth.resetPassword.newAccount.1" />
									</h3>
									<p>
										<FormattedMessage id="auth.resetPassword.newAccount.2" />
										<br />
										<FormattedMessage id="auth.resetPassword.newAccount.3" />
									</p>
								</div>
							:	<p>
									<FormattedMessage id="auth.resetPassword.newPassword" />
								</p>
							}

							<FormItem
								name="password"
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: 'auth.error.noPassword'
										})
									},
									{
										validator: this.validateToNextPassword
									}
								]}
							>
								<Input
									prefix={
										<FontAwesomeIcon
											icon={faLock}
											style={S.login.fieldIcon}
										/>
									}
									type="password"
									autoComplete="new-password"
									placeholder={this.props.intl.formatMessage({
										id: 'auth.fields.password'
									})}
								/>
							</FormItem>
							<FormItem
								name="passwordConfirm"
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: 'auth.error.noPasswordConfirm'
										})
									},
									{
										validator: this.compareToFirstPassword
									}
								]}
							>
								<Input
									prefix={
										<FontAwesomeIcon
											icon={faLock}
											style={S.login.fieldIcon}
										/>
									}
									type="password"
									autoComplete="new-password"
									placeholder={this.props.intl.formatMessage({
										id: 'auth.fields.passwordConfirm'
									})}
								/>
							</FormItem>
							<FormItem>
								<Button
									type="primary"
									htmlType="submit"
									style={S.login.submit}
									loading={this.state.loading}
								>
									<FormattedMessage id="auth.resetPassword.save" />
								</Button>
								{this.state.errorCode && (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={errorMessage}
										style={{ width: '100%', marginTop: 20 }}
										type="error"
										showIcon
									/>
								)}
							</FormItem>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(injectIntl(ResetPassword))
