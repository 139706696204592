import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
	faPlay,
	faPause,
	faFastForward,
	faVolumeUp,
	faVolumeDown,
	faThumbsUp,
	faThumbsDown,
	faBars
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Slider, Input } from 'antd'

import * as Utils from '../../../components/Utils'
import * as AudioPlayer from '../../../components/AudioPlayer'
import TextInputModal from '../../../components/TextInputModal'
import SeekSlider from './SeekSlider'

import blankCover from '../../../img/blankCover.png'

class IPlayer extends Component {
	state = {
		mobileCommentModal: false
	}

	thumbAction = (rating) => {
		this.props.setFeedback(rating, null)
		if (this.props.mobile) {
			this.setState({ mobileCommentModal: true })
		}
	}

	render() {
		const {
			track,
			next,
			playPause,
			feedback,
			intl,
			setFeedback,
			playing,
			review,
			mobile,
			showList,
			sample
		} = this.props
		const styles = mobile ? mobileStyles : desktopStyles

		if (!track) {
			return (
				<div style={{ ...styles.container, ...styles.centerContainer }}>
					<div
						className="btn-initial-play"
						style={styles.control}
						onClick={playPause}
					>
						<FontAwesomeIcon icon={faPlay} size="2x" />
					</div>
				</div>
			)
		}

		const art = Utils.trackArtworkUrl(track)
		return (
			<div style={styles.container}>
				<div style={{ ...styles.bg, backgroundImage: `url(${art})` }} />
				<div style={styles.mask} />
				<div style={styles.trackInfoContainer}>
					<img style={styles.artwork} alt="" src={art} />
					<div style={styles.flex1}>
						<div style={styles.trackInfoText}>
							<h3 style={{ ...styles.info, ...styles.artist }}>
								{sample.showTrackNotes ?
									track.comment
								:	track.artist}
							</h3>
							<h2 style={{ ...styles.info, ...styles.title }}>
								{track.title}
							</h2>
						</div>
						<div style={styles.controls}>
							{mobile && (
								<div onClick={showList} style={styles.control}>
									<FontAwesomeIcon icon={faBars} size="2x" />
								</div>
							)}
							<div style={styles.control} onClick={playPause}>
								<FontAwesomeIcon
									icon={playing ? faPause : faPlay}
									size="2x"
								/>
							</div>
							<div style={styles.control} onClick={next}>
								<FontAwesomeIcon
									icon={faFastForward}
									size="2x"
								/>
							</div>
							{!mobile && (
								<div
									style={{
										...styles.slider,
										...styles.control
									}}
								>
									<FontAwesomeIcon icon={faVolumeDown} />
									<Slider
										min={0}
										max={100}
										defaultValue={
											AudioPlayer.targetVolume * 100
										}
										onChange={(v) =>
											AudioPlayer.setTargetVolume(v / 100)
										}
										style={{
											width: 60,
											margin: '0 15px 0 15px'
										}}
									/>
									<FontAwesomeIcon icon={faVolumeUp} />
								</div>
							)}
						</div>
						<div style={styles.seekBarContainer}>
							<SeekSlider
								style={styles.seekBar}
								playing={playing}
								track={track}
							/>
						</div>
					</div>
					{review && (
						<div style={{ ...styles.feedback, ...styles.flex1 }}>
							<h3 style={styles.ratingTitle}>
								<FormattedMessage id="samples.listen.trackFeedback" />
							</h3>
							<div style={styles.ratings}>
								<div
									style={{ marginRight: 20 }}
									onClick={() => this.thumbAction(2)}
								>
									<FontAwesomeIcon
										icon={faThumbsUp}
										size="2x"
										style={{
											color:
												(
													feedback &&
													feedback.rating === 2
												) ?
													'#52c41a'
												:	null,
											marginBottom: 10
										}}
									/>
								</div>
								<div onClick={() => this.thumbAction(1)}>
									<FontAwesomeIcon
										icon={faThumbsDown}
										size="2x"
										style={{
											color:
												(
													feedback &&
													feedback.rating === 1
												) ?
													'#f5222d'
												:	null,
											marginTop: 10
										}}
									/>
								</div>
							</div>
							{!mobile && (
								<div>
									<Input.TextArea
										placeholder={intl.formatMessage({
											id: 'samples.listen.comment'
										})}
										onChange={(e) =>
											setFeedback(null, e.target.value)
										}
										value={feedback && feedback.comment}
									/>
								</div>
							)}
						</div>
					)}
					{this.state.mobileCommentModal > 0 && (
						<TextInputModal
							textArea
							title={intl.formatMessage({
								id: 'samples.listen.feedback'
							})}
							actionLabel={intl.formatMessage({
								id: 'actions.submit'
							})}
							onClose={(shouldGoNext) => {
								this.setState({ mobileCommentModal: false })
								if (shouldGoNext) {
									next()
								}
							}}
							action={(text) => setFeedback(null, text)}
						/>
					)}
				</div>
			</div>
		)
	}
}

export default injectIntl(IPlayer)

const desktopStyles = {
	container: {
		width: '100vw',
		height: 400,
		overflow: 'hidden',
		position: 'fixed'
	},
	centerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bg: {
		backgroundPosition: 'left',
		backgroundRepeat: 'no-repeat',
		color: '#fff',
		paddingBottom: 16,
		paddingTop: 16,
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		filter: 'blur(40px)',
		transform: 'scale(1.5)'
	},
	mask: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: 'rgba(24, 24, 24, 0.3)'
	},
	trackInfoContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 3,
		display: 'flex',
		overflowX: 'auto'
	},
	artwork: {
		margin: 50,
		width: 300,
		height: 300,
		boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.5)',
		backgroundImage: `url(${blankCover})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		border: '1px solid rgba(255, 255, 255, 0.3)',
		objectFit: 'cover'
	},
	trackInfoText: {
		position: 'absolute',
		left: 0,
		right: 20,
		bottom: 150
	},
	flex1: {
		flex: 1,
		position: 'relative',
		minWidth: 280
	},
	info: {
		color: '#fff',
		fontWeight: 700,
		textAlign: 'left',
		textTransform: 'uppercase',
		flex: 1
	},
	artist: {
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '3.2px'
	},
	title: {
		fontSize: '26px',
		lineHeight: '32px',
		letterSpacing: '4.8px'
	},
	ratingTitle: {
		color: '#999',
		fontWeight: 700,
		textAlign: 'left',
		textTransform: 'uppercase',
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '3.2px'
	},
	controls: {
		position: 'absolute',
		left: 0,
		right: 20,
		bottom: 100,
		display: 'flex',
		alignItems: 'center'
	},
	seekBarContainer: {
		position: 'absolute',
		left: 0,
		right: 20,
		bottom: 50,
		paddingTop: 20
	},
	seekBar: {
		width: '100%'
	},
	slider: {
		display: 'flex',
		alignItems: 'center'
	},
	control: {
		marginRight: 40,
		cursor: 'pointer'
	},
	feedback: {
		padding: '0 50px',
		margin: 'auto 0 50px 0'
	},
	ratings: {
		display: 'flex',
		padding: '20px 0'
	}
}

const mobileStyles = {
	container: {
		width: '100vw',
		height: '100%',
		overflow: 'hidden',
		position: 'relative'
	},
	centerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	bg: {
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		color: '#fff',
		paddingBottom: 16,
		paddingTop: 16,
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		filter: 'blur(40px)',
		transform: 'scale(1.5)'
	},
	mask: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: 'rgba(24, 24, 24, 0.3)'
	},
	trackInfoContainer: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 3,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		overflowX: 'auto'
	},
	artwork: {
		margin: '30px auto',
		width: '30vh',
		height: '30vh',
		boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.5)',
		background: '#000',
		border: '1px solid rgba(255, 255, 255, 0.3)',
		objectFit: 'cover'
	},
	trackInfoText: {
		padding: '10px 20px'
	},
	flex1: {
		position: 'relative'
	},
	info: {
		color: '#fff',
		fontWeight: 700,
		textAlign: 'center',
		textTransform: 'uppercase',
		flex: 1
	},
	artist: {
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '3.2px'
	},
	title: {
		fontSize: '26px',
		lineHeight: '32px',
		letterSpacing: '4.8px'
	},
	ratingTitle: {
		display: 'none'
	},
	controls: {
		width: '100%',
		padding: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	seekBarContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '20px 30px'
	},
	seekBar: {
		width: '100%',
		margin: '0 20px 0 20px'
	},
	control: {
		margin: '0 20px',
		cursor: 'pointer'
	},
	feedback: {},
	ratings: {
		width: '100%',
		padding: '10px 30px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}
