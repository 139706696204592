import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
	Modal,
	Form,
	Input,
	Button,
	Switch,
	Radio,
	Select,
	message
} from 'antd'

import { forgotPassword } from '../../auth'
import { formItemLayout } from '../../components/Styles'
import { TeamDetailsQuery, TeamsTreeQuery } from '../teams/Queries'
import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'

const FormItem = Form.Item
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const roles = {
	setRole: ['manager', 'user'],
	setAdminRole: ['superadmin', 'admin', 'manager', 'user']
}

class NewUserModal extends Component {
	formRef = React.createRef()

	state = {
		confirmDirty: false,
		loading: false,
		role: this.props.user ? this.props.user.role : 'user',
		montyUser: this.props.user ? this.props.user.montyUser : null,
		language: this.props.user ? this.props.user.language || 'fr' : 'fr'
	}

	handleCancel = () => {
		this.props.onClose()
	}

	onClickSubmit = async (e) => {
		e.preventDefault()

		this.setState({ loading: true })
		const { onClose, client, team, user } = this.props
		const values = await this.formRef.current.validateFields()
		try {
			let params = {}
			let mutation = null
			if (user) {
				params = {
					...values,
					email: values.email.toLowerCase(),
					role: this.state.role,
					id: user._id,
					montyUser: this.state.montyUser,
					language: this.state.language
				}
				mutation = gql`
					mutation updateUser(
						$id: String!
						$firstName: String
						$lastName: String
						$role: String
						$email: String
						$montyUser: Boolean
						$language: String
					) {
						userUpdateById(
							_id: $id
							record: {
								firstName: $firstName
								lastName: $lastName
								role: $role
								username: $email
								montyUser: $montyUser
								language: $language
							}
						) {
							record {
								username
							}
						}
					}
				`
			} else {
				params = {
					...values,
					email: values.email.toLowerCase(),
					role: this.state.role,
					teamId: team._id,
					montyUser: this.state.montyUser,
					language: this.state.language,
					notifyOfflineType: 2,
					notifyOfflineDelay: 24 * 7,
					notifyActivationType: 2,
					notifyDisabledType: 2
				}
				mutation = gql`
					mutation createUser(
						$firstName: String!
						$lastName: String!
						$role: String
						$email: String!
						$teamId: String!
						$montyUser: Boolean
						$language: String
						$notifyOfflineType: Float
						$notifyOfflineDelay: Float
						$notifyActivationType: Float
						$notifyDisabledType: Float
					) {
						userCreate(
							record: {
								firstName: $firstName
								lastName: $lastName
								role: $role
								username: $email
								teamId: $teamId
								montyUser: $montyUser
								language: $language
								notifyOfflineType: $notifyOfflineType
								notifyOfflineDelay: $notifyOfflineDelay
								notifyActivationType: $notifyActivationType
								notifyDisabledType: $notifyDisabledType
							}
						) {
							record {
								username
							}
						}
					}
				`
			}

			const result = await client.mutate({
				variables: params,
				mutation: mutation,
				refetchQueries: [
					{
						query: TeamsTreeQuery
					},
					{
						query: TeamDetailsQuery,
						variables: { id: team._id }
					}
				]
			})
			Utils.parseMutationResponse(result)
			if (!user) {
				const newUser = result.data.userCreate.record
				if (newUser && newUser.username) {
					const inviteresult = await forgotPassword({
						username: newUser.username,
						invite: 'onInvite'
					})
					this.setState({ loading: false })
					if (inviteresult.success) {
						onClose()
					} else {
						console.log(inviteresult)
					}
				}
			} else {
				this.setState({ loading: false })
				onClose()
			}
		} catch (e) {
			this.setState({ loading: false })
			if (e.message.indexOf('duplicate key error') > -1) {
				message.error(
					this.props.intl.formatMessage(
						{ id: 'teams.addUser.alreadyExists' },
						{ email: values.email.toLowerCase() }
					)
				)
			} else {
				Utils.displayError(e)
			}
		}
	}

	handleConfirmBlur = (e) => {
		const value = e.target.value
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}

	render() {
		const { intl, team, user } = this.props

		let setRole = null
		if (Auth.hasAccess('user:edit', team._id)) {
			if (Auth.hasPermission('user:edit:setAdminRole')) {
				setRole = 'setAdminRole'
			} else if (Auth.hasPermission('user:edit:setRole')) {
				setRole = 'setRole'
			}
		}

		let canEdit =
			(Auth.hasAccess('user:edit', team._id) &&
				Auth.hasPermission('user:edit:setInfo')) ||
			user._id === Auth.cache.user._id

		return (
			<Modal
				visible={true}
				width="80vw"
				title={
					user ?
						intl.formatMessage(
							{ id: 'teams.editUser.title' },
							{ name: user.username }
						)
					:	intl.formatMessage(
							{ id: 'teams.addUser.title' },
							{ team: team.n }
						)
				}
				onCancel={this.handleCancel}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.onClickSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage
							id={user ? 'actions.save' : 'teams.addUser.submit'}
						/>
					</Button>
				]}
			>
				<Form ref={this.formRef}>
					{canEdit && (
						<FormItem
							{...formItemLayout}
							name="email"
							initialValue={user && user.username}
							label={intl.formatMessage({
								id: 'teams.details.email'
							})}
							rules={[
								{
									type: 'email',
									message: intl.formatMessage({
										id: 'teams.addUser.emailInvalid'
									})
								},
								{
									required: true,
									message: intl.formatMessage({
										id: 'teams.addUser.emailEmpty'
									})
								}
							]}
						>
							<Input
								autoCapitalize="none"
								autocompletetype="email"
								autoCorrect="false"
								keyboardtype="email-address"
							/>
						</FormItem>
					)}
					{canEdit && (
						<FormItem
							{...formItemLayout}
							name="firstName"
							initialValue={user && user.firstName}
							label={intl.formatMessage({
								id: 'teams.details.firstName'
							})}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'teams.addUser.firstNameEmpty'
									}),
									whitespace: true
								}
							]}
						>
							<Input />
						</FormItem>
					)}
					{canEdit && (
						<FormItem
							{...formItemLayout}
							name="lastName"
							initialValue={user && user.lastName}
							label={intl.formatMessage({
								id: 'teams.details.lastName'
							})}
							rules={[
								{
									required: true,
									message: intl.formatMessage({
										id: 'teams.addUser.lastNameEmpty'
									}),
									whitespace: true
								}
							]}
						>
							<Input />
						</FormItem>
					)}
					{canEdit && (
						<FormItem
							{...formItemLayout}
							label={intl.formatMessage({
								id: 'teams.details.language'
							})}
							data-test="user-language-select"
						>
							<Select
								value={this.state.language}
								onChange={(v) => this.setState({ language: v })}
							>
								{Utils.languages.map((l) => (
									<Select.Option key={l} value={l}>
										<FormattedMessage id={`lang.${l}`} />
									</Select.Option>
								))}
							</Select>
						</FormItem>
					)}
					{setRole && (
						<FormItem
							{...formItemLayout}
							label={intl.formatMessage({
								id: 'teams.addUser.role'
							})}
						>
							<RadioGroup
								value={this.state.role}
								onChange={(v) =>
									this.setState({ role: v.target.value })
								}
							>
								{roles[setRole].map((r, i) => (
									<RadioButton value={r} key={`role-${r}`}>
										{r}
									</RadioButton>
								))}
							</RadioGroup>
						</FormItem>
					)}
					{Auth.hasAccess('user:edit', team._id) &&
						Auth.hasPermission('user:edit:setMontyRole') && (
							<FormItem
								{...formItemLayout}
								label={intl.formatMessage({
									id: 'teams.addUser.montyRole'
								})}
							>
								<Switch
									onChange={(v) =>
										this.setState({ montyUser: v })
									}
									checked={this.state.montyUser}
								/>
							</FormItem>
						)}
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(NewUserModal))
