import React, { Component } from 'react'
import { Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import S from './Styles'

const styles = S.columnBrowser

export default class ColumnBrowser extends Component {
	state = {
		path: this.props.selectedPath || []
	}

	children = {}
	nodes = {}

	getChildren = (nodeId) => {
		let c = this.children[nodeId || 'root']
		if (!c) {
			c = this.props.getNodeChildren(nodeId)
			this.children[nodeId || 'root'] = c
			for (let n of c) {
				this.nodes[n._id] = n
			}
		}
		return c
	}

	selectItem = (level, nodeId) => {
		let node = this.nodes[nodeId]
		if (!node.isLeaf) {
			let { path } = this.state
			if (level === path.length) {
				path.push(nodeId)
			} else {
				path.splice(level)
				path.push(nodeId)
			}
			this.setState({ path })
		} else {
			this.props.onSelect(node)
		}
	}

	render() {
		let contents = []

		for (let i = 0; i <= this.state.path.length; i++) {
			let parent = null
			if (i > 0) {
				parent = this.state.path[i - 1]
			}
			contents.push(
				<Menu
					style={styles.column}
					mode="vertical"
					onClick={(n) => this.selectItem(i, n.key)}
					key={`menu-${parent}`}
				>
					{this.getChildren(parent).map((n) => {
						/**
						 * The line has to be select
						 *   if it is a node that is in the path but not a leaf
						 *   or if it is a currentPlaylist node and the isCurrent is true
						 *   or if it is a leaf that is in the path and the isCurrent is false
						 */
						const isSelected =
							(!n.isLeaf &&
								this.state.path.indexOf(n._id) > -1) ||
							(this.props.isCurrent && n.isCurrentPlaylist) ||
							(!this.props.isCurrent &&
								!n.isCurrentPlaylist &&
								this.state.path.indexOf(n._id) > -1)
						return (
							<Menu.Item
								ref={(e) => (this.lastNode = e)}
								key={n._id}
								className={
									isSelected ? 'ant-menu-item-selected' : ''
								}
							>
								<div style={styles.row}>
									<span>
										<FontAwesomeIcon
											icon={this.props.nodeIcon(n)}
										/>
										&nbsp;&nbsp;{n.n}
									</span>
									{!n.isLeaf && (
										<FontAwesomeIcon
											style={styles.expandIcon}
											icon={faAngleRight}
										/>
									)}
								</div>
							</Menu.Item>
						)
					})}
				</Menu>
			)
		}
		// TODO scroll to right when displaying children

		return (
			<div style={styles.scroll}>
				<div style={styles.container}>{contents}</div>
			</div>
		)
	}
}
