import React, { Component } from 'react'
import { List, Slider } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { injectIntl } from 'react-intl'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { withContentRect } from 'react-measure'
import { faPause } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../components/LoadingPage'
import * as Utils from '../../components/Utils'
import S, { Colors } from '../../components/Styles'
import * as AudioPlayer from '../../components/AudioPlayer'
import { SampleDetailsQuery } from './Queries'

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

class Listen extends Component {
	state = {
		loading: false,
		saving: false,
		items: [],
		playing: {}
	}

	componentDidMount() {
		this.loadTracks(this.props)
	}

	componentWillReceiveProps(props) {
		if (
			props._id !== this.props._id ||
			props.updated !== this.props.updated
		) {
			this.loadTracks(props)
		}
	}

	loadTracks = async (props) => {
		this.setState({ loading: true })

		let list = []
		for (let i in props.tracks) {
			let track = props.tracks[i]
			let trackObject = props.trackObjects[i]

			list.push({
				...track,
				...trackObject
			})
		}

		this.setState({ loading: false, items: list })
	}

	save = async () => {
		this.setState({ saving: true })
		const { client, _id } = this.props

		try {
			let tr = this.state.items.map((t) => ({
				trackId: t._id,
				start: t.start,
				end: t.end
			}))

			const r = await client.mutate({
				variables: {
					id: _id,
					tracks: JSON.stringify(tr)
				},
				mutation: gql`
					mutation updateSampleTracksList(
						$id: String!
						$tracks: String
					) {
						sampleUpdateById(_id: $id, tracks: $tracks) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: SampleDetailsQuery,
						variables: { id: _id }
					}
				]
			})

			Utils.parseMutationResponse(r)
			let res = r.data.sampleUpdateById

			if (res.message) {
				throw new Error(res.message)
			}
		} catch (e) {
			Utils.displayError(e)
		}
		this.setState({ saving: false })
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		)

		this.setState({ items }, this.save)
	}

	setTrackRange = (trackIndex, range) => {
		let items = [...this.state.items]
		items[trackIndex].start = range[0]
		items[trackIndex].end = range[1]
		AudioPlayer.startTrack({
			track: items[trackIndex]._id,
			crossfade: 0,
			startTime: range[0],
			endTime: range[1],
			stopAtEnd: true,
			progressCallback: (position) => {
				this.setState({
					playing: {
						track: items[trackIndex]._id,
						position
					}
				})
			},
			endCallback: () => {
				this.setState({ playing: {} })
			}
		})
		this.setState({ items }, this.save)
	}

	getItemStyle = (isDragging, draggableStyle) => ({
		userSelect: 'none',
		padding: 10,
		borderBottom: isDragging ? 0 : `1px solid ${Colors.lines}`,
		backgroundColor: '#fff',
		display: 'flex',
		...draggableStyle
	})

	render() {
		if (this.state.loading) {
			return <Loading />
		}

		let playBarWidth = 100
		if (
			this.props.contentRect &&
			this.props.contentRect.bounds &&
			this.props.contentRect.bounds.width
		) {
			playBarWidth = (this.props.contentRect.bounds.width - 30) / 2
		}

		return (
			<div ref={this.props.measureRef}>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={{
									borderTop: `1px solid ${Colors.lines}`,
									marginTop: 20
								}}
							>
								{this.state.items.map((t, index) => (
									<Draggable
										key={t._id}
										draggableId={t._id}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												style={this.getItemStyle(
													snapshot.isDragging,
													provided.draggableProps
														.style
												)}
											>
												<div
													style={{
														flex: 1,
														paddingRight: 10
													}}
													{...provided.dragHandleProps}
												>
													<List.Item.Meta
														avatar={
															<img
																src={Utils.trackArtworkThumbUrl(
																	t
																)}
																alt=""
																style={
																	S.trackCover
																}
															/>
														}
														title={t.title}
														description={
															(
																this.props
																	.showTrackNotes
															) ?
																t.comment
															:	t.artist
														}
													/>
												</div>
												<div
													style={{
														flex: 1,
														padding: 0,
														position: 'relative',
														height: 50,
														borderRadius: 3,
														border: `1px solid ${Colors.lines}`
													}}
												>
													{this.state.playing
														.track === t._id && (
														<div
															style={{
																position:
																	'absolute',
																top: 0,
																bottom: 0,
																left:
																	playBarWidth *
																	(this.state
																		.playing
																		.position /
																		(t.duration /
																			1000)),
																width: 1,
																background:
																	Colors.primary
															}}
														/>
													)}
													{this.state.playing
														.track === t._id && (
														<div
															style={{
																position:
																	'absolute',
																top: 0,
																bottom: 0,
																left: -25,
																color: Colors.primary,
																display: 'flex',
																alignItems:
																	'center'
															}}
															onClick={() => {
																AudioPlayer.stop(
																	0
																)
																this.setState({
																	playing: {}
																})
															}}
														>
															<FontAwesomeIcon
																icon={faPause}
															/>
														</div>
													)}
													<Slider
														range
														min={0}
														max={t.duration / 1000}
														defaultValue={[
															t.start === -1 ?
																0
															:	t.start,
															t.end === -1 ?
																t.duration /
																1000
															:	t.end
														]}
														style={{
															margin: '19px 0 0 0'
														}}
														tipFormatter={(v) =>
															Utils.formatMinutes(
																v
															)
														}
														onAfterChange={(
															range
														) =>
															this.setTrackRange(
																index,
																range
															)
														}
													/>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		)
	}
}

export default withContentRect('bounds')(withApollo(injectIntl(Listen)))
