import React, { Component } from 'react'
import { Input, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faPlay } from '@fortawesome/free-solid-svg-icons'
import S from './Styles'
import DetailsProperty from './DetailsProperty'
import config from '../config'
import * as Utils from './Utils'
import FormattedTimeSince from '../utils/FormattedTimeSince'

const styles = S.columnBrowser

export default class ColumnBrowser extends Component {
	state = {
		renaming: null,
		renameLoading: false
	}

	componentWillReceiveProps(newProps) {
		if (
			newProps.itemToRename &&
			newProps.itemToRename !== this.props.itemToRename
		) {
			this.setState({ renaming: newProps.itemToRename.join('/') })
		}
	}

	nodePath = (level, node) => {
		return node ?
				[
					...this.props.path.slice(0, level),
					node._id || node.uuid
				].join('/')
			:	null
	}

	selectItem = (level, node) => {
		let nodePath = this.nodePath(level, node)
		if (
			this.clickedNode &&
			nodePath &&
			this.clickedNode === nodePath &&
			this.props.canEdit &&
			this.props.canEdit(node)
		) {
			this.setState({ renaming: nodePath })
			this.clickedNode = null
		} else {
			this.clickedNode = nodePath
			this.props.selectItem(node, level)
			this.setState({ renaming: null })
		}
	}

	rename = async (e) => {
		this.setState({ renameLoading: true })
		await this.props.rename(e.target.value)
		this.setState({ renameLoading: false, renaming: null })
	}

	playAudio = () => {
		try {
			if (this.audio) {
				this.audio.pause()
			}
			const url =
				config.cdn ?
					`${config.cdn.baseUrl}${config.cdn.paths.messagesOptimized}no-meta/stereo/${this.props.selectedItem._id}`
				:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.messagesOptimized}/no-meta/stereo/${this.props.selectedItem._id}`
			this.audio = new Audio(url)
			this.audio.play()
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		let contents = []
		let currentNode = this.props.tree

		if (!currentNode) {
			return (
				<div style={S.empty}>
					<FormattedMessage id="media.table.noMedia" />
				</div>
			)
		}

		for (let i = 0; i <= this.props.path.length; i++) {
			if (currentNode) {
				if (this.props.isNodeFolder(currentNode)) {
					const children = this.props.nodeChildren(currentNode)
					if (children && children.length) {
						contents.push(
							<ul
								className="ant-menu ant-menu-light ant-menu-root ant-menu-vertical"
								style={styles.column}
								onClick={(e) => this.selectItem(i)}
								key={`menu-${i}`}
							>
								{children.map((n) => {
									const nodePath = this.nodePath(i, n)
									const renaming =
										this.state.renaming === nodePath
									return (
										<li
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
												if (!renaming) {
													this.selectItem(i, n)
												}
											}}
											key={n._id || n.uuid}
											className={`ant-menu-item ${(i < this.props.path.length && n.uuid && this.props.path[i] === n.uuid) || (this.props.selectedItem && this.props.selectedItem._id === n._id) ? ' ant-menu-item-selected' : ''}`}
										>
											<div style={styles.row}>
												<span style={styles.rowName}>
													{(
														renaming &&
														this.state.renameLoading
													) ?
														<LoadingOutlined />
													:	<FontAwesomeIcon
															icon={this.props.nodeIcon(
																n
															)}
														/>
													}
													&nbsp;&nbsp;
													{renaming ?
														<Input
															size="small"
															defaultValue={this.props.nodeLabel(
																n
															)}
															onFocus={(e) =>
																e.target.select()
															}
															autoFocus
															onPressEnter={
																this.rename
															}
														/>
													:	<div
															style={{
																display: 'flex',
																flexDirection:
																	'column'
															}}
														>
															<div
																style={{
																	lineHeight:
																		'18px'
																}}
															>
																{this.props.nodeLabel(
																	n
																)}
															</div>
															{this.props
																.nodeSubtitle &&
																this.props.nodeSubtitle(
																	n
																) && (
																	<div className="browser-subtitle">
																		{this.props.nodeSubtitle(
																			n
																		)}
																	</div>
																)}
														</div>
													}
												</span>
												{this.props.isNodeFolder(n) && (
													<FontAwesomeIcon
														style={
															styles.expandIcon
														}
														icon={faAngleRight}
													/>
												)}
											</div>
										</li>
									)
								})}
							</ul>
						)
					} else {
						contents.push(
							<div
								style={styles.column}
								className="ant-menu-vertical"
								key={`empty-${i}`}
							>
								<div style={{ ...S.empty, width: 200 }}>
									<FormattedMessage id="media.table.emptyFolder" />
								</div>
							</div>
						)
					}
				}

				if (i < this.props.path.length) {
					currentNode = currentNode.folders[this.props.path[i]]
					if (!currentNode) {
						currentNode = this.props.nodeDetails(this.props.path[i])
					}
				}
			}
		}

		if (this.props.selectedItem) {
			// file details
			contents.push(
				<div
					style={styles.column}
					className="ant-menu-vertical"
					key="details"
				>
					<div style={styles.details}>
						<h3 style={{ marginBottom: 20 }}>
							{this.props.selectedItem.name}
						</h3>
						{this.props.selectedItem.duration && (
							<DetailsProperty title="media.table.details.duration">
								{this.props.selectedItem.duration}
							</DetailsProperty>
						)}
						{this.props.selectedItem.created && (
							<DetailsProperty title="media.table.details.uploaded">
								<FormattedDate
									value={this.props.selectedItem.created}
								/>{' '}
								(
								<FormattedTimeSince
									date={this.props.selectedItem.created}
								/>
								)
							</DetailsProperty>
						)}
						{this.props.selectedItem.isAudio && (
							<Button
								onClick={this.playAudio}
								style={{
									width: 100,
									margin: '30px auto 0 auto'
								}}
							>
								<FontAwesomeIcon icon={faPlay} />
							</Button>
						)}
					</div>
				</div>
			)
		}

		return (
			<div style={styles.scroll}>
				<div style={styles.container}>{contents}</div>
			</div>
		)
	}
}
