/**
 * Configuration loader.
 */
import * as Utils from '../components/Utils'
import devConfig from './development.config.js'
import prodConfig from './production.config.js'
import stagingConfig from './staging.config.js'

const env = Utils.environment()

const getConfig = () => {
	if (env === 'production') {
		return prodConfig
	} else if (env === 'staging') {
		return stagingConfig
	}
	return devConfig
}

export default getConfig()
