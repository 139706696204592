import React from 'react'
import { Tooltip } from 'antd'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { Colors } from '../../components/Styles'
import { isThirdPartyDeviceOnline } from '../../components/utils/thirdPartyDeviceUtils'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, injectIntl } from 'react-intl'
import FormattedTimeSince from '../../utils/FormattedTimeSince'

// TODO: create a common component for the Device and the ThirdPartyDevice pages
const ThirdPartyDeviceStatus = ({ onlineDate, ...props }) => {
	const isOnline = isThirdPartyDeviceOnline({ onlineDate })
	return (
		<Tooltip
			title={
				isOnline ?
					<FormattedMessage id="devices.table.online" />
				:	<FormattedMessage
						id="devices.table.offline"
						values={{
							time: <FormattedTimeSince date={onlineDate || ''} />
						}}
					/>
			}
		>
			<Icon
				size="lg"
				fixedWidth
				style={{
					color:
						isOnline ?
							Colors.status.green.color
						:	Colors.status.red.color
				}}
				icon={faCircle}
				{...props}
			/>
		</Tooltip>
	)
}

export default injectIntl(ThirdPartyDeviceStatus)
