const deviceOnlineExpiration = 1000 * 60 * 60 * 24 // 1 day

// onlineDate is the last date the thirdPartyDevice sent something to VX
export const isThirdPartyDeviceOnline = (device) => {
	const now = new Date()
	const old = new Date(device.onlineDate)
	if (old) {
		return now.getTime() - old.getTime() < deviceOnlineExpiration
	} else {
		return false
	}
}

export const thirdPartyDeviceStatus = (onlineDate) => {
	isThirdPartyDeviceOnline({ onlineDate }) ?
		`online ${new Date(onlineDate).getTime()}`
	:	`offline ${new Date(onlineDate).getTime()}`
}
