import React, { Component } from 'react'
import { Alert } from 'antd'
import { injectIntl } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { faFolder, faListAlt } from '@fortawesome/free-regular-svg-icons'
import { faLink, faMusic } from '@fortawesome/free-solid-svg-icons'
import { uniqueId } from 'lodash'
import * as Sentry from '@sentry/browser'

import Loading from '../../components/LoadingPage'
import ColumnBrowser from '../../components/ColumnBrowser'
import * as Utils from '../../components/Utils'

class IPlaylistsTree extends Component {
	getChildren = (nodeId) => {
		// if the parent node is a pool and this pool has a currentPlaylist
		// then add the "Current Playlist" item as children
		const isAPoolNode = this.props.playlists.some(
			(playlist) =>
				playlist._id === nodeId &&
				playlist.y === 1 &&
				playlist.currentPlaylist
		)
		const children = this.props.playlists
			.filter((t) => t.a === nodeId)
			.sort((a, b) => {
				let aa = a.n || `${a.created}`
				let bb = b.n || `${b.created}`
				return aa.localeCompare(bb)
			})
			.map((n) => {
				let pl = n
				if (!pl.n) {
					pl.n = this.props.intl.formatDate(n.created, {
						day: 'numeric',
						month: 'long',
						year: 'numeric'
					})
				}
				return {
					...n,
					playlistOrPoolId: n._id,
					isLeaf: n.y === 2
				}
			})
		if (this.props.isCurrentPlaylistEnable && isAPoolNode) {
			const poolNode = this.props.playlists.find(
				(playlist) => playlist._id === nodeId
			)
			const currentPlaylistNodeId = poolNode.currentPlaylist
			const currentPlaylist = this.props.playlists.find(
				(playlist) => playlist._id === currentPlaylistNodeId
			)
			if (currentPlaylist) {
				children.unshift({
					_id: uniqueId(),
					playlistOrPoolId: nodeId,
					a: nodeId,
					n: `Current playlist (${currentPlaylist.n})`,
					y: 3,
					isCurrentPlaylist: true,
					isLeaf: true
				})
			} else {
				const errorMessage = `The current playlist selected for the pool ${nodeId} does not exist`
				console.error(errorMessage)
				Sentry.captureException(new Error(errorMessage))
			}
		}
		return children
	}

	getPlaylistPath = (node) => {
		let path = []
		if (node) {
			path = [node._id]
			let parentId = node.a
			while (parentId) {
				path = [parentId, ...path]
				const playlist = this.props.playlists.find(
					(playlist) => playlist._id === parentId
				)
				if (playlist) {
					parentId = playlist.a
				} else {
					parentId = null
				}
			}
		}
		return path
	}

	render() {
		return (
			<div style={this.props.style}>
				<ColumnBrowser
					getNodeChildren={this.getChildren}
					nodeIcon={(n) =>
						[faFolder, faListAlt, faMusic, faLink][n.y || 0]
					} // folder, pool, playlist
					onSelect={this.props.onSelect}
					selectedPath={this.getPlaylistPath(this.props.selected)}
					isCurrent={this.props.isCurrent}
				/>
			</div>
		)
	}
}

const PlaylistsTree = injectIntl(IPlaylistsTree)

class AssignPlaylist extends Component {
	state = {
		error: null
	}

	render() {
		const { playlist, intl } = this.props
		return (
			<Query
				fetchPolicy="no-cache"
				query={gql`
					query playlistsForAssignQuery {
						playlists {
							_id
							n
							a
							y
							created
							currentPlaylist
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					if (loading) {
						return <Loading />
					}
					if (error) {
						return (
							<Alert
								message={intl.formatMessage({ id: 'error' })}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					return (
						<PlaylistsTree
							style={this.props.style}
							playlists={data.playlists}
							onSelect={this.props.onChange}
							selected={playlist ? playlist.playlist : null}
							isCurrent={playlist?.isCurrent}
							isCurrentPlaylistEnable={
								this.props.isCurrentPlaylistEnable
							}
						/>
					)
				}}
			</Query>
		)
	}
}

export default withApollo(injectIntl(AssignPlaylist))
