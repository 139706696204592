import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Select, Button, Input, Row, Col, AutoComplete } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'

const { Option } = Select

const suggestions = [
	'Programmation Playlist Thématique Automate de Diffusion. Mise à jour trimestrielle 30% du contenu musical. Le prix inclut la programmation musicale adaptée selon les heures de la journée',
	'Normalisation et Harmonisation Audio',
	'Licence Logicielle avec Assistance Niveau 2'
].map((value) => ({ value, label: value }))

class PlanItems extends Component {
	add = (_) => {
		this.props.valueChanged({
			items: [
				...this.props.items,
				{
					description: '',
					quantity: 1
				}
			]
		})
	}

	remove = (line) => {
		let items = this.props.items
		items.splice(line, 1)
		this.props.valueChanged({ items: items })
	}

	setLineProperty = (v, line, prop) => {
		let items = this.props.items
		let item = items[line]
		item[prop] = v
		this.props.valueChanged({ items: items })
	}

	total = () => {
		let t = 0
		for (let i of this.props.items) {
			if (i.price && i.quantity) {
				t = t + i.price * i.quantity
			}
		}
		return t
	}

	render() {
		const { intl, currency, items } = this.props

		return (
			<React.Fragment>
				<Row gutter={16} style={{ fontWeight: 600, marginBottom: 5 }}>
					<Col xs={24} md={14}>
						<FormattedMessage id="billing.plan.item" />
					</Col>
					<Col xs={18} md={3}>
						<FormattedMessage id="billing.plan.quantity" />
					</Col>
					<Col xs={18} md={3}>
						<FormattedMessage id="billing.plan.unitPrice" />
					</Col>
					<Col xs={18} md={3}>
						<FormattedMessage id="billing.plan.totalPrice" />
					</Col>
					<Col xs={6} md={1}></Col>
				</Row>
				{items.map((item, i) => (
					<Row
						gutter={16}
						key={`item-${i}`}
						style={{ marginBottom: 20 }}
					>
						<Col xs={24} md={14}>
							<AutoComplete
								placeholder={intl.formatMessage({
									id: 'billing.plan.item'
								})}
								options={suggestions}
								style={{ width: '100%' }}
								onChange={(v) =>
									this.setLineProperty(v, i, 'description')
								}
								value={item.description}
							/>
						</Col>
						<Col xs={6} md={3}>
							<Input
								placeholder="1000"
								type="number"
								value={item.quantity}
								onChange={(e) =>
									this.setLineProperty(
										parseInt(e.target.value, 10),
										i,
										'quantity'
									)
								}
							/>
						</Col>
						<Col xs={6} md={3}>
							<Input
								placeholder="1"
								type="number"
								value={item.price}
								onChange={(e) =>
									this.setLineProperty(
										parseFloat(e.target.value),
										i,
										'price'
									)
								}
							/>
						</Col>
						<Col xs={6} md={3}>
							<Input
								placeholder="1000"
								type="number"
								value={
									item.price ? item.price * item.quantity : 0
								}
								onChange={(e) =>
									this.setLineProperty(
										e.target.value / item.quantity,
										i,
										'price'
									)
								}
							/>
						</Col>
						<Col xs={6} md={1}>
							<Button
								shape="circle"
								onClick={(e) => this.remove(i)}
							>
								<FontAwesomeIcon icon={faTrashAlt} />
							</Button>
						</Col>
					</Row>
				))}
				<Row gutter={16} style={{ fontWeight: 600, marginBottom: 5 }}>
					<Col xs={24} md={10}>
						<Button type="dashed" onClick={this.add}>
							<FontAwesomeIcon icon={faPlus} />
							&nbsp;&nbsp;
							<FormattedMessage id="billing.plan.items.add" />
						</Button>
					</Col>
					<Col
						xs={24}
						md={8}
						style={{ textAlign: 'right', paddingTop: 5 }}
					>
						<FormattedMessage id="billing.plan.totalPriceBeforeTax" />
					</Col>
					<Col xs={24} md={6}>
						<Input
							placeholder={intl.formatMessage({
								id: 'billing.plan.totalPrice'
							})}
							type="number"
							value={this.total()}
							addonAfter={
								<Select
									defaultValue={currency}
									onChange={(c) =>
										this.props.valueChanged({ currency: c })
									}
									style={{ width: 80 }}
								>
									<Option value="eur">EUR</Option>
									<Option value="usd">USD</Option>
									<Option value="hkd">HKD</Option>
									<Option value="cny">CNY</Option>
								</Select>
							}
						/>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(PlanItems))
