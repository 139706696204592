import React, { Component } from 'react'
import {
	Modal,
	Form,
	Button,
	Select,
	DatePicker,
	Checkbox,
	InputNumber,
	Tooltip
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { RRule, rrulestr } from 'rrule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { formItemLayout } from '../../components/Styles'
import RRuleGenerator from '../../components/RRuleGenerator'
import * as Utils from '../../components/Utils'
import { isSameOrNextDay } from '../../components/utils/dateUtils'

const FormItem = Form.Item
const Option = Select.Option

class EditEvent extends Component {
	state = {
		playlistId:
			this.props.event.playlist ? this.props.event.playlist._id : null,
		weight: this.props.event.weight,

		dtstart: null,
		recurrent: false,
		interval: 1,
		freq: RRule.DAILY,
		until: null,
		byweekday: [],
		bymonthday: [],
		dtend: null
	}

	componentDidMount() {
		if (this.props.event) {
			let rule = rrulestr(this.props.event.rule)

			// for rule sets we need to get the internal rule
			if (rule._exdate) {
				rule = rule._rrule[0]
			}

			let localTime = Utils.utcToLocalDate(rule.options.dtstart)
			let localTimeEnd = Utils.utcToLocalDate(
				moment(rule.options.dtstart).add(
					this.props.event.duration || 60,
					'minutes'
				)
			)
			let localTimeUntil = null
			if (rule.options.until) {
				localTimeUntil = Utils.utcToLocalDate(rule.options.until)
			}

			this.setState({
				dtstart: localTime.toDate(),
				dtend: localTimeEnd.toDate(),
				interval: rule.options.interval,
				freq: rule.options.freq || RRule.DAILY,
				until: localTimeUntil ? localTimeUntil.toDate() : null,
				recurrent: rule.options.count !== 1,
				bymonthday: rule.options.bymonthday || [],
				byweekday: rule.options.byweekday || []
			})
		}
	}

	handleSubmit = (e) => {
		e.preventDefault()

		let {
			dtstart,
			until,
			freq,
			interval,
			recurrent,
			byweekday,
			bymonthday,
			playlistId
		} = this.state

		if (!playlistId) {
			this.props.onClose(this.props.event.id)
			return
		}

		let localTime = Utils.localToUtcDate(dtstart)
		let localTimeUntil = null
		if (until) {
			localTimeUntil = Utils.localToUtcDate(until)
		}
		interval = parseInt(interval, 10) || 1

		let rule = new RRule({
			dtstart: localTime.toDate(),
			until: localTimeUntil ? localTimeUntil.toDate() : null,
			freq,
			interval,
			count: recurrent ? null : 1,
			byweekday: freq === RRule.WEEKLY ? byweekday : null,
			bymonthday: freq === RRule.MONTHLY ? bymonthday : null
		})
		let oldRule = rrulestr(this.props.event.rule)

		// keep old rule exceptions
		if (oldRule && oldRule._exdate) {
			rule = Utils.updatedRuleSetWithRule(oldRule, rule)
		}

		console.log(rule.toString())

		this.props.onClose(
			this.props.event.id,
			rule.toString(),
			Utils.duration(this.state.dtstart, this.state.dtend),
			this.state.weight,
			this.selectedPlaylist()
		)
	}

	selectedPlaylist = () => {
		if (this.state.playlistId) {
			for (let pl of this.props.playlists) {
				if (this.state.playlistId === pl._id) {
					return pl
				}
			}
		}
		return null
	}

	handleDelete = (e) => {
		e.preventDefault()
		if (this.state.recurrent) {
			const { intl } = this.props
			Modal.confirm({
				title: intl.formatMessage({
					id: 'messagePrograms.details.delete.confirm.title'
				}),
				content: intl.formatMessage(
					{ id: 'messagePrograms.details.delete.confirm.message' },
					{
						date: intl.formatDate(this.props.event.start, {
							day: 'numeric',
							month: 'long',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						})
					}
				),
				okText: intl.formatMessage({
					id: 'messagePrograms.details.confirm.all'
				}),
				okType: 'danger',
				cancelText: intl.formatMessage({
					id: 'messagePrograms.details.confirm.instanceOnly'
				}),
				onOk: () => {
					this.props.onClose(this.props.event.id)
				},
				onCancel: () => {
					// Build rule set
					let rruleSet = rrulestr(this.props.event.rule, {
						forceset: true
					})
					rruleSet.exdate(
						Utils.localToUtcDate(this.props.event.start).toDate()
					)

					this.props.onClose(
						this.props.event.id,
						rruleSet.toString(),
						Utils.duration(this.state.dtstart, this.state.dtend),
						this.state.weight,
						this.selectedPlaylist()
					)
				}
			})
		} else {
			this.props.onClose(this.props.event.id)
		}
	}

	handleCancel = () => {
		this.props.onClose()
	}

	isValid = () => {
		const { dtstart, until, dtend, playlistId } = this.state
		if (!playlistId) {
			return false
		}
		if (until) {
			if (until < dtstart) {
				return false
			}
		}
		if (dtend) {
			if (dtend < dtstart) {
				return false
			}
		}
		return true
	}

	render() {
		const { intl } = this.props
		let footer = [
			<Button
				type="primary"
				htmlType="submit"
				key="submit"
				onClick={this.handleSubmit}
				disabled={!this.isValid()}
			>
				<FormattedMessage id="actions.save" />
			</Button>
		]

		if (this.props.event.id) {
			footer.splice(
				0,
				0,
				<Button
					type="danger"
					htmlType="submit"
					key="delete"
					onClick={this.handleDelete}
				>
					<FormattedMessage id="actions.delete" />
				</Button>
			)
		}

		return (
			<Modal
				visible={true}
				title={this.props.intl.formatMessage({
					id:
						this.props.event.id ?
							'messagePrograms.details.editSlot'
						:	'messagePrograms.details.newSlot'
				})}
				onCancel={this.handleCancel}
				footer={footer}
			>
				<Form>
					<FormItem
						label={intl.formatMessage({ id: 'playlist' })}
						{...formItemLayout}
					>
						<Select
							value={this.state.playlistId}
							onChange={(v) => this.setState({ playlistId: v })}
						>
							{this.props.playlists.map((p) => (
								<Option value={p._id} key={p._id}>
									{p.name}
								</Option>
							))}
						</Select>
					</FormItem>
					<FormItem
						label={
							<span>
								<Tooltip
									title={intl.formatMessage({
										id: 'programs.scheduler.weight.info'
									})}
								>
									<FontAwesomeIcon icon={faInfoCircle} />
								</Tooltip>
								&nbsp;
								{intl.formatMessage({
									id: 'programs.scheduler.weight'
								})}
							</span>
						}
						{...formItemLayout}
					>
						<InputNumber
							value={this.state.weight}
							onChange={(v) => this.setState({ weight: v })}
						/>
					</FormItem>

					<FormItem>
						<Checkbox
							checked={this.state.recurrent}
							onChange={(r) =>
								this.setState({ recurrent: r.target.checked })
							}
						>
							<FormattedMessage id="messagePrograms.details.edit.recurrent" />
						</Checkbox>
					</FormItem>

					{this.state.recurrent ?
						<RRuleGenerator
							event={this.state}
							onChange={(s) => this.setState(s)}
							dtend
						/>
					:	<React.Fragment>
							<FormItem
								label={intl.formatMessage({
									id: 'programs.scheduler.from'
								})}
								{...formItemLayout}
							>
								<DatePicker
									showTime
									format="DD-MM-YYYY HH:mm"
									placeholder={intl.formatMessage({
										id: 'programs.scheduler.from'
									})}
									value={
										this.state.dtstart ?
											moment(this.state.dtstart)
										:	null
									}
									onChange={(v) => {
										if (v) {
											this.setState({
												dtstart: v.toDate()
											})
										}
									}}
								/>
							</FormItem>
							<FormItem
								label={intl.formatMessage({
									id: 'programs.scheduler.to'
								})}
								{...formItemLayout}
							>
								<DatePicker
									showTime
									format="DD-MM-YYYY HH:mm"
									placeholder={intl.formatMessage({
										id: 'programs.scheduler.to'
									})}
									value={
										this.state.dtend ?
											moment(this.state.dtend)
										:	null
									}
									disabledDate={(dt) =>
										!isSameOrNextDay(dt, this.state.dtstart)
									}
									onChange={(v) => {
										if (v) {
											this.setState({ dtend: v.toDate() })
										}
									}}
								/>
							</FormItem>
						</React.Fragment>
					}
				</Form>
			</Modal>
		)
	}
}

export default injectIntl(EditEvent)
