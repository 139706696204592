import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { Table, Input } from 'antd'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { Colors } from './Styles'

class TableDisplay extends Component {
	state = {
		searchText: '',
		searchVisible: false,
		teamFilters: null,
		teamFilterVisible: false
	}

	didSelect(record, e) {
		const { onSelect, redirect, path, history, checkboxes } = this.props

		if (checkboxes) {
			// done elsewhere
		} else if (onSelect) {
			onSelect(record)
		} else if (redirect) {
			history.push(`${path}/${record._id}`)
		}
	}

	tableChanged = (pagination, filters, sorter) => {
		if (sorter.columnKey && sorter.order) {
			localStorage.setItem(
				this.props.sortKey,
				JSON.stringify({
					column: sorter.columnKey,
					order: sorter.order
				})
			)
		} else {
			localStorage.removeItem(this.props.sortKey)
		}
	}

	render() {
		const {
			link,
			path,
			intl,
			renderName,
			nameProp,
			searchProps,
			searchNamePlaceholder,
			columns,
			dataSource,
			selectedId,
			sortKey,
			collapsed
		} = this.props

		let savedSort, savedSortColumn
		const storedSort = localStorage.getItem(sortKey)
		if (storedSort) {
			savedSort = JSON.parse(storedSort)
			savedSortColumn = savedSort ? savedSort.column : null
		}

		let cols = []

		if (nameProp) {
			cols.push({
				title: intl.formatMessage({ id: 'name' }),
				key: 'name',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'name' ?
						savedSort.order
					:	'ascend',
				render: (text, record, index) => {
					if (renderName) {
						return renderName(record)
					}
					if (link) {
						return (
							<Link to={`${path}/${record._id}`}>
								{nameProp(record)}
							</Link>
						)
					}
					return nameProp(record)
				},
				sorter: (a, b) => {
					let aa = nameProp(a) || ''
					let bb = nameProp(b) || ''
					return aa.localeCompare(bb)
				},
				filterIcon: (filtered) => (
					<SearchOutlined
						style={{
							color:
								!!this.state.searchText ?
									Colors.primary
								:	Colors.disabled.icon
						}}
					/>
				),
				filterDropdown: (props) => (
					<Input
						ref={(e) => (this.searchInput = e)}
						placeholder={intl.formatMessage({
							id: searchNamePlaceholder || 'actions.searchName'
						})}
						value={this.state.searchText}
						onChange={(e) => {
							this.setState({ searchText: e.target.value })
						}}
						onPressEnter={() =>
							this.setState({ searchVisible: false })
						}
						prefix={<SearchOutlined />}
						suffix={
							!!this.state.searchText ?
								<CloseCircleOutlined
									onClick={() =>
										this.setState({ searchText: '' })
									}
								/>
							:	null
						}
					/>
				),
				filterDropdownVisible: this.state.searchVisible,
				filteredValue: [this.state.searchText],
				filtered: !!this.state.searchText,
				onFilter: (value, record) => {
					if (value.length < 3) {
						return true
					}
					let aa = ['']
					if (searchProps) {
						aa = searchProps(record)
					} else if (nameProp) {
						aa = [nameProp(record)]
					}
					for (let prop of aa) {
						if (
							prop &&
							prop.toLowerCase().indexOf(value.toLowerCase()) > -1
						) {
							return true
						}
					}
					return false
				},
				onFilterDropdownVisibleChange: (visible) => {
					this.setState({ searchVisible: visible })
					if (visible) {
						setTimeout(() => {
							this.searchInput.focus()
						}, 200)
					}
				}
			})
		}

		for (let col of columns) {
			cols.push({
				defaultSortOrder:
					savedSortColumn && savedSortColumn === col.key ?
						savedSort.order
					:	null,
				...col
			})
		}

		return (
			<Table
				rowKey={this.props.rowKey || '_id'}
				columns={cols}
				dataSource={dataSource}
				pagination={false}
				onRow={(record) => ({
					onClick: (e) => this.didSelect(record, e)
				})}
				rowClassName={(record) =>
					record[this.props.rowKey] === selectedId ?
						'table-row-selected'
					:	''
				}
				size="small"
				onChange={this.tableChanged}
				defaultExpandAllRows={!collapsed}
				locale={{
					emptyText: intl.formatMessage({
						id: this.props.emptyText || 'noResults'
					})
				}}
				rowSelection={
					this.props.checkboxes ?
						{
							type: 'checkbox',
							onSelect: (
								record,
								selected,
								selectedRows,
								nativeEvent
							) => this.props.onSelect(selectedRows),
							selectedRowKeys: this.props.selectedIds
						}
					:	null
				}
			/>
		)
	}
}

export default withRouter(injectIntl(TableDisplay))
