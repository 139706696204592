import React, { Component } from 'react'
import { Card, Row, Col, Input, Tag, Button, Modal } from 'antd'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { FormattedMessage, injectIntl } from 'react-intl'
import FormattedDuration from 'react-intl-formatted-duration'
import Color from 'color'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

import S, { Colors } from '../../components/Styles'
import * as Utils from '../../components/Utils'
import { ProgramDetailsQuery } from './Queries'
import * as Auth from '../../auth'
import EditPlaylistDetails from './EditPlaylistDetails'
import ActionsMenu from '../../components/ActionsMenu'
import { SelectMultiplePrograms } from '../tracks/SelectProgram'

export class PlaylistCardDisplay extends Component {
	render() {
		const {
			playlist,
			title,
			controls,
			genres,
			playlistStats,
			children,
			color
		} = this.props

		let chartLabels = []
		let chartDataset = []
		for (let genre in genres) {
			chartLabels.push(genre)
			chartDataset.push(genres[genre][playlist._id] || 0)
		}
		const numberOfElements = chartLabels.length

		const img = Utils.playlistImageUrl(playlist)
		const showStats =
			playlistStats && Auth.hasPermission('program:read:numberOfTracks')

		return (
			<Card
				title={title || playlist.name}
				bodyStyle={{ ...S.card.body, paddingBottom: 10 }}
				extra={controls && <div style={S.card.header}>{controls}</div>}
				style={S.card.container}
			>
				<Row gutter={10}>
					<Col lg={showStats ? 8 : 12} sm={24}>
						<div style={S.playlistDetails.imageContainer}>
							<img
								src={img}
								style={S.playlistDetails.image}
								alt={playlist.name}
							/>
						</div>
					</Col>
					{showStats && (
						<Col lg={8} sm={24}>
							<div style={S.card.trackInfo.textContainer}>
								<h3>
									<FormattedMessage id="playlists.details.stats" />
								</h3>
								<div>
									<FormattedMessage
										id="playlists.details.tracks"
										values={{ count: playlistStats.tracks }}
									/>
								</div>
								<div>
									{Utils.formatBytes(playlistStats.size, 1)}
								</div>
								<div>
									<FormattedDuration
										seconds={Math.floor(
											playlistStats.duration / 1000
										)}
										textComponent="span"
										format="{hours} {minutes} {seconds}"
									/>
								</div>
							</div>
						</Col>
					)}
				</Row>
				{children}
			</Card>
		)
	}
}

class PlaylistDetails extends Component {
	state = {
		editPlaylistModal: false,
		duplicatePlaylistModal: false
	}

	onCrossfadeChange = async (e) => {
		const { playlist, program, client } = this.props
		try {
			const value = parseInt(e.target.value, 10)
			const r = await client.mutate({
				variables: { id: playlist._id, xf: value },
				mutation: gql`
					mutation updateProgramPlaylistCrossfade(
						$id: MongoID!
						$xf: Float
					) {
						programPlaylistUpdateById(
							_id: $id
							record: { crossfade: $xf }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: ProgramDetailsQuery,
						variables: { id: program._id }
					}
				]
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { playlist, intl, program, color, playlistStats, genres } =
			this.props
		let headerControls = []
		headerControls.push(
			<Input
				key={`xfade-${playlist._id}`}
				addonBefore={intl.formatMessage({
					id: 'playlists.details.crossfade'
				})}
				addonAfter={intl.formatMessage({
					id: 'playlists.details.crossfade.seconds'
				})}
				placeholder="3"
				className="crossfadeInput"
				style={S.card.headerItem}
				onPressEnter={this.onCrossfadeChange}
				onChange={this.onCrossfadeChange}
				defaultValue={playlist.crossfade}
				disabled={
					!Auth.hasPermission('programPlaylist:edit:setCrossfade') ||
					!Auth.hasAccess('programPlaylist:edit', program.teamId)
				}
			/>
		)

		if (
			Auth.hasPermission('programPlaylist:edit:setName') &&
			Auth.hasAccess('programPlaylist:edit', program.teamId)
		) {
			let actions = [
				{
					title: intl.formatMessage({ id: 'actions.edit' }),
					key: 'editPlaylist',
					icon: faEdit,
					dataTest: 'edit-playlist-button',
					action: () => this.setState({ editPlaylistModal: true })
				}
			]

			if (Auth.hasAccess('programPlaylist:add', program.teamId)) {
				actions.push({
					title: intl.formatMessage({
						id: 'playlists.actions.duplicate'
					}),
					key: 'duplicatePlaylist',
					icon: faCopy,
					action: () =>
						this.setState({ duplicatePlaylistModal: true })
				})
			}

			if (Auth.hasAccess('programPlaylist:delete', program.teamId)) {
				actions.push({
					title: intl.formatMessage({ id: 'actions.delete' }),
					key: 'deletePlaylist',
					icon: faTrash,
					confirm: intl.formatMessage(
						{ id: 'playlists.actions.delete.confirm' },
						{ name: playlist.name }
					),
					mutation: {
						variables: { id: playlist._id },
						mutation: gql`
							mutation programPlaylist($id: String!) {
								programPlaylistRemoveById(id: $id) {
									success
									message
								}
							}
						`,
						refetchQueries: [
							{
								query: ProgramDetailsQuery,
								variables: { id: program._id }
							}
						],
						awaitRefetchQueries: true
					}
				})
			}

			headerControls.push(
				<ActionsMenu
					key="setName"
					dataTest="playlist-option-menu"
					style={S.card.headerItem}
					menuItems={actions}
				/>
			)
		}

		return (
			<PlaylistCardDisplay
				title={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div
							style={{
								...S.playlistDetails.legend,
								backgroundColor: Color(color).fade(0.5),
								border: `1px solid ${color}`
							}}
						></div>
						<span style={{ float: 'left' }} title={playlist._id}>
							{playlist.name}
						</span>
						{Auth.hasPermission(
							'programPlaylist:read:montyPlaylist'
						) && (
							<React.Fragment>
								{playlist.playlist ?
									<span
										style={{
											float: 'left',
											color: Colors.disabled.text,
											marginLeft: 5
										}}
									>
										{' '}
										(
										{playlist.playlist.n ||
											intl.formatDate(
												playlist.playlist.created,
												{
													day: 'numeric',
													month: 'long',
													year: 'numeric'
												}
											)}
										)
									</span>
								:	<span style={{ float: 'left', marginLeft: 5 }}>
										<Tag color="red">
											<FormattedMessage id="playlists.details.noMontyPlaylistAssigned" />
										</Tag>
									</span>
								}
								{playlist.isCurrent && (
									<span
										style={{ float: 'left', marginLeft: 5 }}
									>
										<Tag color="gold">
											<FormattedMessage id="playlists.details.currentPlaylist" />
										</Tag>
									</span>
								)}
							</React.Fragment>
						)}
					</div>
				}
				controls={headerControls}
				playlist={playlist}
				playlistStats={playlistStats}
				genres={genres}
				color={color}
			>
				{this.state.editPlaylistModal && (
					<EditPlaylistDetails
						playlist={playlist}
						program={program}
						onClose={() =>
							this.setState({ editPlaylistModal: false })
						}
					/>
				)}
				{this.state.duplicatePlaylistModal && (
					<DuplicatePlaylist
						playlist={playlist}
						program={program}
						onClose={() =>
							this.setState({ duplicatePlaylistModal: false })
						}
					/>
				)}
			</PlaylistCardDisplay>
		)
	}
}

export default withApollo(injectIntl(PlaylistDetails))

class IDuplicatePlaylist extends Component {
	state = {
		programs: []
	}

	submit = async () => {
		const { playlist, program, client, onClose } = this.props
		try {
			const r = await client.mutate({
				variables: { id: playlist._id, programs: this.state.programs },
				mutation: gql`
					mutation duplicateProgramPlaylist(
						$id: String!
						$programs: [String]
					) {
						programPlaylistDuplicate(id: $id, programs: $programs) {
							success
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: ProgramDetailsQuery,
						variables: { id: program._id }
					}
				]
			})
			Utils.parseMutationResponse(r)
			onClose()
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, onClose } = this.props
		return (
			<Modal
				title={intl.formatMessage({
					id: 'playlists.actions.duplicate'
				})}
				visible={true}
				onCancel={onClose}
				footer={[
					<Button type="primary" onClick={this.submit}>
						<FormattedMessage id="actions.duplicate" />
					</Button>
				]}
			>
				<SelectMultiplePrograms
					selectedIds={this.state.programs}
					onSelect={(p) => this.setState({ programs: p })}
				/>
			</Modal>
		)
	}
}

const DuplicatePlaylist = withApollo(injectIntl(IDuplicatePlaylist))
