import React, { Component } from 'react'
import S from './Styles'
import { Mobile, Default } from './Responsive'

export default class ResponsiveCard extends Component {
	render() {
		return (
			<React.Fragment>
				<Mobile>
					<div
						className={`ant-card-head-wrapper ${this.props.className}`}
					>
						{!this.props.hideTitleOnMobile && (
							<div className="ant-card-head-title">
								{this.props.title}
							</div>
						)}
						<div className="ant-card-extra">
							<div style={S.card.header}>
								{this.props.actionsButton}
							</div>
						</div>
					</div>
					<div
						className={`ant-card-head-wrapper ${this.props.className}`}
					>
						<div style={S.card.headerMobile}>
							{this.props.children}
						</div>
					</div>
				</Mobile>
				<Default>
					<div
						className={`ant-card-head-wrapper ${this.props.className}`}
					>
						<div className="ant-card-head-title">
							{this.props.title}
						</div>
						<div className="ant-card-extra">
							<div style={S.card.header}>
								{this.props.children}
								{this.props.actionsButton}
							</div>
						</div>
					</div>
				</Default>
			</React.Fragment>
		)
	}
}
