import gql from 'graphql-tag'

export const MessageProgramsTableQuery = gql`
	query messageProgramsTableQuery {
		messagePrograms {
			_id
			name
			team {
				_id
				path
				n
			}
			zones {
				_id
				device {
					_id
				}
			}
		}
	}
`

export const MessageProgramDetailsQuery = gql`
	query fetchMessageProgram($id: String!) {
		messageProgramById(_id: $id) {
			_id
			name
			teamId
			team {
				_id
				path
				n
			}
			updated
			crossfade
			musicVolume
			mediaIds
			medias {
				_id
				name
				duration
				created
			}
			schedule
		}
	}
`

export const MessageProgramDetailsDevicesQuery = gql`
	query fetchMessageProgramWithDevice($id: String!) {
		messageProgramById(_id: $id) {
			_id
			teamId
			zones {
				_id
				name
				device {
					_id
					name
					nickname
					OS
					onlineDate
					onlineState
					lastConnection
					dlState
					contentUpdated
					zones {
						_id
						name
						playState
						program {
							_id
							updatedAt
						}
						messagePrograms {
							_id
							updated
						}
					}
				}
			}
		}
	}
`

export const MediaQuery = gql`
	query fetchTeamMediaTree($teamId: String!) {
		teamMediaTree(teamId: $teamId) {
			tree
		}
	}
`
