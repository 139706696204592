import React, { Component } from 'react'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Utils from '../../components/Utils'

class PlayStatus extends Component {
	render() {
		const { device, zone } = this.props
		const status = Utils.playStateDisplay(device, zone)
		return (
			<Button
				style={{
					...this.props.style,
					...status.color,
					cursor: 'default'
				}}
				className={this.props.className}
			>
				<FontAwesomeIcon icon={status.icon} />
				&nbsp;{status.title}
			</Button>
		)
	}
}

export default PlayStatus
