import React, { Component } from 'react'
import { Modal, Alert, Button, Form, Select } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import removeAccents from 'remove-accents'

import { DeviceDetailsQuery } from '../devices/Queries'
import * as Utils from '../../components/Utils'

const checkIfDeviceHasTheProgramRights = (program, team) => {
	// Check that the device's team has the rights on the program
	// That means that the device's team or one of its children is the selected program's team
	return (
		program?.teamId &&
		(program.teamId === team?._id ||
			team?.children?.includes(program.teamId))
	)
}

class ISelectProgram extends Component {
	render() {
		return (
			<Query
				query={gql`
					query selectProgramsQuery {
						programs {
							_id
							name
							teamId
							playlists {
								_id
							}
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					const deviceTeam = this.props.deviceTeam
					const selectedProgram = data?.programs?.find(
						(program) => program._id === this.props.selectedId
					)
					const isDeviceTeamHasProgramRights =
						checkIfDeviceHasTheProgramRights(
							selectedProgram,
							deviceTeam
						)

					if (error) {
						return (
							<Alert
								message={this.props.intl.formatMessage({
									id: 'error'
								})}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					return (
						<Form.Item
							help={
								deviceTeam &&
								selectedProgram &&
								!isDeviceTeamHasProgramRights &&
								this.props.intl.formatMessage(
									{
										id: 'devices.player.changeProgram.deviceTeamHasNoRightsOnProgram'
									},
									{
										teamName:
											deviceTeam.n || deviceTeam.name
									}
								)
							}
							validateStatus={
								deviceTeam &&
								selectedProgram &&
								!isDeviceTeamHasProgramRights &&
								'warning'
							}
						>
							<Select
								style={{ width: '100%' }}
								placeholder={this.props.intl.formatMessage({
									id: 'program'
								})}
								value={
									data && data.programs ?
										this.props.selectedId
									:	null
								}
								onChange={this.props.onSelect}
								loading={loading}
								showSearch
								filterOption={(input, option) =>
									removeAccents(option.props.label)
										.toLowerCase()
										.indexOf(
											removeAccents(input.toLowerCase())
										) > -1
								}
							>
								{data &&
									data.programs &&
									[...data.programs]
										.sort((a, b) =>
											a.name.localeCompare(b.name)
										)
										.map((p) => (
											<Select.Option
												key={p._id}
												value={p._id}
												label={p.name}
											>
												{p.name}
											</Select.Option>
										))}
							</Select>
						</Form.Item>
					)
				}}
			</Query>
		)
	}
}

export const SelectProgram = withApollo(injectIntl(ISelectProgram))

class ISelectMultiplePrograms extends Component {
	render() {
		return (
			<Query
				query={gql`
					query selectMultipleProgramsQuery {
						programs {
							_id
							name
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					if (error) {
						return (
							<Alert
								message={this.props.intl.formatMessage({
									id: 'error'
								})}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					return (
						<Select
							style={{ width: '100%' }}
							placeholder={this.props.intl.formatMessage({
								id: 'programs'
							})}
							value={
								data && data.programs ?
									this.props.selectedIds
								:	null
							}
							mode="multiple"
							onChange={this.props.onSelect}
							loading={loading}
							showSearch
							filterOption={(input, option) =>
								removeAccents(option.props.label)
									.toLowerCase()
									.indexOf(
										removeAccents(input.toLowerCase())
									) > -1
							}
						>
							{data &&
								data.programs &&
								[...data.programs]
									.sort((a, b) =>
										a.name.localeCompare(b.name)
									)
									.map((p) => (
										<Select.Option
											key={p._id}
											value={p._id}
											label={p.name}
										>
											{p.name}
										</Select.Option>
									))}
						</Select>
					)
				}}
			</Query>
		)
	}
}

export const SelectMultiplePrograms = withApollo(
	injectIntl(ISelectMultiplePrograms)
)

class ISelectMessagePrograms extends Component {
	render() {
		return (
			<Query
				query={gql`
					query selectMessageProgramsQuery {
						messagePrograms {
							_id
							name
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					if (error) {
						return (
							<Alert
								message={this.props.intl.formatMessage({
									id: 'error'
								})}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					return (
						<Select
							style={{ width: '100%' }}
							placeholder={this.props.intl.formatMessage({
								id: 'messagePrograms'
							})}
							value={
								data && data.messagePrograms ?
									this.props.selectedIds
								:	null
							}
							mode={this.props.single ? 'default' : 'multiple'}
							onChange={this.props.onSelect}
							loading={loading}
							showSearch
							filterOption={(input, option) =>
								removeAccents(option.props.label)
									.toLowerCase()
									.indexOf(
										removeAccents(input.toLowerCase())
									) > -1
							}
						>
							{data &&
								data.messagePrograms &&
								[...data.messagePrograms]
									.sort((a, b) =>
										a.name.localeCompare(b.name)
									)
									.map((p) => (
										<Select.Option
											key={p._id}
											value={p._id}
											label={p.name}
										>
											{p.name}
										</Select.Option>
									))}
						</Select>
					)
				}}
			</Query>
		)
	}
}

export const SelectMessagePrograms = withApollo(
	injectIntl(ISelectMessagePrograms)
)

class ISelectProgramsModal extends Component {
	state = {
		error: null,
		program: this.props.zone.program ? this.props.zone.program._id : null,
		messagePrograms:
			this.props.zone.messagePrograms ?
				this.props.zone.messagePrograms.map((mp) => mp._id)
			:	[],
		loading: false,
		loadingSync: false
	}

	handleCancel = () => {
		this.props.onClose()
	}

	save = async () => {
		try {
			this.setState({ loading: true })
			await this.saveAction()
			this.props.onClose()
		} catch (e) {
			Utils.displayError(e)
			this.setState({ loading: false })
		}
	}

	saveSync = async () => {
		try {
			this.setState({ loadingSync: true })
			await this.saveAction()

			// sync
			const r = await this.props.client.mutate({
				variables: { id: this.props.device._id, command: 'sync' },
				mutation: gql`
					mutation syncDevice($id: String!, $command: String!) {
						sendDeviceCommand(_id: $id, command: $command) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(r)

			this.props.onClose()
		} catch (e) {
			Utils.displayError(e)
			this.setState({ loadingSync: false })
		}
	}

	saveAction = async () => {
		const r = await this.props.client.mutate({
			variables: {
				id: this.props.zone._id,
				programId: this.state.program,
				messageProgramIds: this.state.messagePrograms
			},
			mutation: gql`
				mutation updateZoneMessageProgram(
					$id: String!
					$programId: String
					$messageProgramIds: [String]
				) {
					zoneUpdateById(
						_id: $id
						record: {
							programId: $programId
							messageProgramIds: $messageProgramIds
						}
					) {
						recordId
					}
				}
			`
		})
		Utils.parseMutationResponse(r)

		const dr = await this.props.client.mutate({
			variables: { id: this.props.device._id },
			mutation: gql`
				mutation updateDeviceContentUpdated($id: MongoID!) {
					deviceUpdateById(
						_id: $id
						record: { contentUpdated: null }
					) {
						recordId
					}
				}
			`,
			refetchQueries: [
				{
					query: DeviceDetailsQuery,
					variables: { id: this.props.device._id }
				}
			]
		})

		Utils.parseMutationResponse(dr)
	}

	render() {
		const { device, title } = this.props

		return (
			<Modal
				visible={true}
				title={
					title ? title : (
						this.props.intl.formatMessage(
							{ id: 'devices.player.changeProgram.modalTitle' },
							{ device: device.nickname || device.name || '' }
						)
					)
				}
				onCancel={this.handleCancel}
				width="60vw"
				footer={[
					<Button
						key="save"
						type="default"
						onClick={this.save}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.save" />
					</Button>,
					<Button
						key="saveSync"
						type="primary"
						onClick={this.saveSync}
						loading={this.state.loadingSync}
					>
						<FormattedMessage id="devices.player.changePrograms.saveAndSync" />
					</Button>
				]}
			>
				<Form>
					<Form.Item
						label={
							<React.Fragment>
								<FontAwesomeIcon icon={faMusic} fixedWidth />
								&nbsp;
								<FormattedMessage id="program" />
							</React.Fragment>
						}
					>
						<SelectProgram
							deviceTeam={device.team}
							onSelect={(p) =>
								this.setState({
									program: p,
									accordionActive: null
								})
							}
							selectedId={this.state.program}
							showPlaylists
						/>
					</Form.Item>
					{Utils.isDeviceDrax(device) &&
						(device.team.messagesEnabled ||
							device.team.parentMessagesEnabled) && (
							<Form.Item
								label={
									<React.Fragment>
										<FontAwesomeIcon
											icon={faCommentAlt}
											fixedWidth
										/>
										&nbsp;
										<FormattedMessage id="messagePrograms" />
									</React.Fragment>
								}
							>
								<SelectMessagePrograms
									selectedIds={this.state.messagePrograms}
									onSelect={(mps) =>
										this.setState({ messagePrograms: mps })
									}
								/>
							</Form.Item>
						)}
				</Form>
			</Modal>
		)
	}
}

export const SelectProgramsModal = withApollo(injectIntl(ISelectProgramsModal))
