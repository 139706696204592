import React, { Component } from 'react'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import { Tooltip } from 'antd'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
	faExpandArrowsAlt,
	faCompressArrowsAlt
} from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { withRouter } from 'react-router-dom'

import * as Utils from '../../components/Utils'
import Table from '../../components/TableV2'
import * as Constants from '../../components/Constants'
import { withResponsive } from '../../components/Responsive'
import { thirdPartyDeviceStatus } from '../../components/utils/thirdPartyDeviceUtils'
import DeviceStatus from './ThirdPartyDeviceStatus'

class IThirdPartyDeviceListDisplay extends Component {
	columns = {
		status: {
			name: this.props.intl.formatMessage({
				id: 'thirdPartyDevices.info.online'
			}),
			accessor: (v) => thirdPartyDeviceStatus(v.onlineDate),
			queryProps: ['onlineDate'],
			title: <Icon icon={faCircle} fixedWidth />,
			render: (props) => {
				return <DeviceStatus onlineDate={props.onlineDate} size="1x" />
			},
			filters: [
				{
					text: this.props.intl.formatMessage({
						id: 'thirdPartyDevices.table.online'
					}),
					value: 1
				},
				{
					text: this.props.intl.formatMessage({
						id: 'thirdPartyDevices.table.offline.title'
					}),
					value: 0
				}
			],
			onFilter: (value, record) => {
				return value === record.onlineDate
			}
		},
		timeZone: {
			title: this.props.intl.formatMessage({
				id: 'thirdPartyDevices.info.timeZone'
			}),
			queryProps: ['timeZone'],
			name: this.props.intl.formatMessage({
				id: 'thirdPartyDevices.info.timeZone'
			}),
			accessor: (device) => device.timeZone
		}
	}

	render() {
		const {
			redirect,
			link,
			match,
			selectedId,
			onSelect,
			title,
			actions,
			defaultColumns,
			defaultMobileColumns,
			storageKey,
			data,
			dontGreyOutPreactivated
		} = this.props

		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (match && match.params && match.params.thirdPartyDeviceId) {
			sel = match.params.thirdPartyDeviceId
		}

		return (
			<Table
				rowKey="_id"
				columns={this.columns}
				defaultColumns={defaultColumns}
				defaultMobileColumns={defaultMobileColumns}
				link={link}
				path="/thirdpartydevices"
				teamProp={(record) => record.team}
				nameProp={(record) => record.nickname || record.name}
				nameQueryProps={['nickname', 'name']}
				searchProps={(record) => [
					record.nickname,
					record.name,
					record.note,
					Utils.deviceMac(record)
				]}
				selectedId={sel}
				redirect={redirect}
				onSelect={onSelect}
				storageKey={storageKey}
				match={match}
				queryType="thirdPartyDevices"
				title={title}
				actions={actions}
				data={data}
				rowDisabled={(d) =>
					(!dontGreyOutPreactivated && d.activationToken) ||
					d.disabled
				}
			/>
		)
	}
}

export const ThirdPartyDeviceListDisplay = injectIntl(
	IThirdPartyDeviceListDisplay
)

class ThirdPartyDeviceTable extends Component {
	state = {
		pairModal: false
	}

	render() {
		const {
			match,
			intl,
			tabletVersion,
			mobileVersion,
			wideMode,
			setWideMode,
			history
		} = this.props
		if (!match.params || !match.params.thirdPartyDeviceId) {
			Utils.setPageTitle(intl, 'thirdPartyDevices')
		}

		let actions = []

		if (!mobileVersion && !tabletVersion) {
			actions.push({
				action: () => {
					setWideMode(!wideMode)
					if (!wideMode) {
						history.push('/thirdPartyDevices')
					}
				},
				title: (
					<FormattedMessage
						id={
							wideMode ? 'tables.wideModeOff' : (
								'tables.wideModeOn'
							)
						}
					/>
				),
				icon: wideMode ? faCompressArrowsAlt : faExpandArrowsAlt,
				key: 'wideMode'
			})
		}
		return (
			<ThirdPartyDeviceListDisplay
				actions={actions}
				title={intl.formatMessage({
					id: 'thirdPartyDevices.table.title'
				})}
				match={match}
				redirect
				defaultColumns={['name', 'team', 'status']}
				defaultMobileColumns={['name', 'status']}
				storageKey="thirdPartyDevicesList"
			/>
		)
	}
}

export default withRouter(injectIntl(withResponsive(ThirdPartyDeviceTable)))
