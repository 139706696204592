import React from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Tag, message } from 'antd'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHdd,
	faFastForward,
	faExclamationTriangle,
	faExclamationCircle,
	faStop,
	faCloudDownloadAlt,
	faPowerOff,
	faPlay,
	faThumbsDown,
	faVolumeOff,
	faPause,
	faHeadphonesAlt,
	faWifi,
	faVolumeUp,
	faCog,
	faCircle,
	faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'
import {
	faHdd as faWhiteHdd,
	faCommentAlt
} from '@fortawesome/free-regular-svg-icons'
import {
	faApple,
	faAndroid,
	faBluetooth,
	faChrome
} from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'
import semver from 'semver'
import * as Sentry from '@sentry/browser'
import { RRuleSet } from 'rrule'
import md5 from 'md5'

import * as Constants from './Constants'
import {
	TrackInfoDisplay,
	PlaylistInfoDisplay,
	MessageInfoDisplay
} from '../views/tracks/CompactTrackInfoDisplay'
import FormattedTimeSince from '../utils/FormattedTimeSince'
import config from '../config'
import { Colors } from './Styles'
import messagesEn from '../translations/en'
import messagesFr from '../translations/fr'
import messagesZh from '../translations/zh'

import blankCover from '../img/blankCover.png'

export const languages = ['fr', 'en', 'zh']

export const messages = {
	en: messagesEn,
	fr: messagesFr,
	zh: messagesZh
}

export let language = null

export const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

const deviceOnlineExpiration = 1000 * 60 * 60 * 24 // 1 day

export const setLanguage = (lang) => {
	if (!lang) {
		lang = detectLocale()
	}
	language = lang
	updateMomentLocale(lang)
}

export const environment = () => {
	const hostname = window && window.location && window.location.hostname
	if (hostname === 'localhost' || hostname.includes('192.168')) {
		return 'development'
	} else if (hostname === 'dashboard.staging.spectre-music.com') {
		return 'staging'
	}
	return 'production'
}

export const isClientIOS = () => {
	return /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream
}

export const setPageTitle = (intl, page, object) => {
	if (page) {
		document.title = intl.formatMessage(
			{ id: 'pageTitle' },
			{ title: intl.formatMessage({ id: page }) }
		)
	} else if (object) {
		document.title = intl.formatMessage(
			{ id: 'pageTitle' },
			{ title: object }
		)
	} else {
		document.title = intl.formatMessage({ id: 'emptyPageTitle' })
	}
}

export const detectLocale = () => {
	let locale =
		navigator.languages && navigator.languages.length ?
			navigator.languages[0]
		:	navigator.language
	let language = locale.split(/[-_]/)[0]

	if (messages[language]) {
		return language
	}
	return 'en'
}

export const updateMomentLocale = (language) => {
	moment.updateLocale(language, {
		week: {
			dow: 1
		}
	})
	moment.locale(language)
}

export const osLogo = (os) => {
	switch (os) {
		case 'iOS':
		case 'iPhone OS':
		case 'iPadOS':
			return faApple
		case 'Android':
			return faAndroid
		case Constants.DRAX_OS:
		case Constants.DRAX_OS_NEW:
			return faHdd
		case 'Chromium':
			return faChrome
		default:
			return faWhiteHdd
	}
}

export const deviceTypeIcon = (os) => {
	switch (os) {
		case 'iOS':
		case 'iPhone OS':
		case 'iPadOS':
			return faApple
		case 'Android':
			return faAndroid
		case 'Chromium':
			return faChrome
		default:
			return faHdd
	}
}

export const deviceMac = (device) => {
	if (device.network) {
		for (let iface in device.network) {
			if (iface !== 'lo') {
				if (device.network[iface] && device.network[iface].mac) {
					let mac = device.network[iface].mac
					if (mac !== '02:00:00:00:00:00') {
						return mac.toLowerCase()
					}
				}
			}
		}
	}
	return ''
}

export const formatBytes = (bytes, decimals) => {
	if (bytes === null || bytes === undefined || bytes < 0) return '-'
	if (bytes === 0) return '0Bytes'
	let k = 1024
	let dm = decimals || 2
	let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let i = Math.floor(Math.log(bytes) / Math.log(k))
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

export const percent = (value, total) => {
	if (total > 0 && value > 0 && value <= total) {
		return value / total
	}
	return 0
}

export const formatMinutes = (minutes) => {
	const h = Math.floor(minutes / 60)
	const m = parseInt(minutes, 10) % 60
	return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m
}

export const deviceStatus = (device) => {
	if (device.safeMode) {
		return {
			icon: faExclamationTriangle,
			color: Colors.status.red.color,
			title: <FormattedMessage id="devices.table.safeMode" />,
			value: 'safeMode'
		}
	} else if (isDeviceOnline(device)) {
		return {
			icon: faCircle,
			color: Colors.status.green.color,
			title: <FormattedMessage id="devices.table.online" />,
			value: `online ${new Date(device.lastConnection).getTime()}`
		}
	}
	return {
		icon: faCircle,
		color: Colors.status.red.color,
		title: (
			<FormattedMessage
				id="devices.table.offline"
				values={{
					time:
						device.lastConnection ?
							<FormattedTimeSince date={device.lastConnection} />
						:	undefined
				}}
			/>
		),
		value: `offline ${new Date(device.lastConnection).getTime()}`
	}
}

// onlineDate is the last date the device sent something to VX (excluding pings)
export const isDeviceOnline = (device) => {
	if (
		device.onlineState === Constants.ONLINE_STATE_ONLINE &&
		device.onlineDate
	) {
		const now = new Date()
		const old = new Date(device.onlineDate)
		if (old) {
			return now.getTime() - old.getTime() < deviceOnlineExpiration
		}
	}
	return false
}

const formatDate = (date) => {
	return moment(date).format('D MMM YYYY HH:mm')
}

export const deviceSync = (device, showDates) => {
	if (!device || !device.zones) {
		return {
			state: Constants.SYNC_STATE_NEVERSYNCED,
			color: Colors.status.red.color,
			case: 'No device or zones',
			title: <FormattedMessage id="devices.info.neverSynced" />,
			value: 'neverSynced'
		}
	}

	let isOnline = isDeviceOnline(device)
	if (isOnline && device.dlState === Constants.DL_STATE_DOWNLOADING) {
		if (
			device.downloading &&
			device.downloading.totalDownloadingTracks &&
			device.downloading.downloadingTrackNumber
		) {
			// device downloading updates
			return {
				state: Constants.SYNC_STATE_SYNCING,
				color: Colors.status.green.color,
				case: 'Downloading with state',
				spin: true,
				title: <FormattedMessage id="devices.info.syncInProgress" />,
				downloadingTrack: device.downloading.downloadingTrackNumber,
				totalTracks: device.downloading.totalDownloadingTracks || 1,
				progress: percent(
					device.downloading.downloadingTrackNumber,
					device.downloading.totalDownloadingTracks || 1
				),
				value: 'syncInProgress'
			}
		} else {
			return {
				state: Constants.SYNC_STATE_SYNCING,
				color: Colors.status.green.color,
				case: 'Downloading without state',
				spin: true,
				title: <FormattedMessage id="devices.info.syncInProgress" />,
				value: 'syncInProgress'
			}
		}
	}

	const deviceContentUpdated = device.contentUpdated

	if (!deviceContentUpdated) {
		return {
			state: Constants.SYNC_STATE_NEVERSYNCED,
			color: Colors.status.red.color,
			case: 'Device never completed an update successfully',
			title: <FormattedMessage id="devices.info.neverSynced" />,
			value: 'neverSynced'
		}
	}
	for (let zone of device.zones) {
		if (zone.program || zone.programUpdated) {
			const programUpdated =
				zone.program && zone.program.lastProgramModification ?
					zone.program.lastProgramModification
				:	zone.programUpdated
			if (deviceContentUpdated < programUpdated) {
				return {
					state: Constants.SYNC_STATE_OUTDATED,
					color: Colors.status.orange.color,
					case: `Program ${
						zone.program ? zone.program.name : ''
					} for zone ${zone.name} updated ${formatDate(
						programUpdated
					)}, device last synced ${formatDate(deviceContentUpdated)}`,
					title:
						showDates ?
							<FormattedMessage
								id="devices.info.outOfDate"
								values={{
									lastSync: (
										<FormattedTimeSince
											date={deviceContentUpdated}
										/>
									)
								}}
							/>
						:	<FormattedMessage id="devices.info.outOfDate.title" />,
					value: 'outOfDate',
					contentUpdated: programUpdated
				}
			}

			if (zone.messageProgramsUpdated) {
				const msgProgramUpdated = zone.messageProgramsUpdated
				if (deviceContentUpdated < msgProgramUpdated) {
					return {
						state: Constants.SYNC_STATE_OUTDATED,
						color: Colors.status.orange.color,
						case: `Message programs for zone ${
							zone.name
						} updated ${formatDate(
							msgProgramUpdated
						)}, device last synced ${formatDate(
							deviceContentUpdated
						)}`,
						title:
							showDates ?
								<FormattedMessage
									id="devices.info.outOfDate"
									values={{
										lastSync: (
											<FormattedTimeSince
												date={deviceContentUpdated}
											/>
										)
									}}
								/>
							:	<FormattedMessage id="devices.info.outOfDate.title" />,
						value: 'outOfDate',
						contentUpdated: msgProgramUpdated
					}
				}
			}
		} else {
			return {
				state: Constants.SYNC_STATE_NEVERSYNCED,
				color: Colors.status.red.color,
				case: `Zone ${zone.name} has no program`,
				title: <FormattedMessage id="devices.info.neverSynced" />,
				value: 'neverSynced'
			}
		}
	}

	return {
		state: Constants.SYNC_STATE_UPTODATE,
		color: Colors.status.green.color,
		title: <FormattedMessage id="devices.info.upToDate" />,
		value: 'upToDate'
	}
}

export const programStats = (program) => {
	if (!program || !program.playlists) {
		return null
	}
	let stats = {
		genres: {}
	}
	for (let playlist of program.playlists) {
		if (!playlist.playlist || !playlist.playlist.tracks) {
			continue
		}
		let duration = 0
		let size = 0
		for (let track of playlist.playlist.tracks.filter(
			(track) => track !== null
		)) {
			duration += track.duration
			size += track.size
			if (!!track.genre && track.genre !== 'null') {
				let g = stats.genres[track.genre] || {}
				let playlistGenreCount = g[playlist._id] || 0
				g[playlist._id] = playlistGenreCount + 1
				stats.genres[track.genre] = g
			}
		}
		stats[playlist._id] = {
			duration,
			size,
			tracks: playlist.playlist.tracks.length
		}
	}
	return stats
}

export const playlistImageUrl = (playlist, size = 160, format = 'png') => {
	if (
		playlist &&
		!!playlist.artwork &&
		playlist.artwork[`${size}${format}`]
	) {
		let hash = playlist.artwork[`${size}${format}`]
		let path = `programPlaylists/${playlist._id}_${size}_${hash}.${format}`
		return config.cdn ?
				`${config.cdn.baseUrl}${config.cdn.paths.artworksOptimized}${path}`
			:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.artworksOptimized}/${path}`
	}
	return blankCover
}

export const playlistOriginalImageUrl = (path) => {
	if (path) {
		return config.cdn ?
				`${config.cdn.baseUrl}${config.cdn.paths.artworks}${path}`
			:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.artworks}/${path}`
	}
	return blankCover
}

export const martiniLogoImageUrl = (fileName) => {
	if (fileName) {
		return config.cdn ?
				`${config.cdn.baseUrl}${config.cdn.paths.userAssets}${fileName}`
			:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.userAssets}/${fileName}`
	}
	return null
}

export const trackArtworkThumbUrl = (track) => {
	return trackArtUrl(track, 160, 'png')
}

export const trackArtworkUrl = (track) => {
	return trackArtUrl(track, 800, 'png')
}

export const trackArtUrl = (track, size, format) => {
	if (!!track.artwork && track.artwork[`${size}${format}`]) {
		let hash = track.artwork[`${size}${format}`]
		let path = `tracks/${track._id}_${size}_${hash}.${format}`
		return config.cdn ?
				`${config.cdn.baseUrl}${config.cdn.paths.artworksOptimized}${path}`
			:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.artworksOptimized}/${path}`
	}
	return null
}

export const historyDisplay = (intl, h, device) => {
	if (h.event) {
		let contents = intl.formatMessage({ id: `history.events.${h.event}` })
		switch (h.event) {
			case Constants.EVENT_COME_ONLINE:
				return { icon: faPowerOff, contents }
			case Constants.EVENT_GO_OFFLINE:
				return {
					icon: faPowerOff,
					color: Colors.status.red.color,
					contents: `${contents} (${
						h.message ||
						intl.formatMessage({
							id: 'history.events.disconnected'
						})
					})`
				}
			case Constants.EVENT_GO_INACTIVE:
				return {
					icon: faPowerOff,
					color: Colors.status.orange.color,
					contents
				}

			case Constants.EVENT_PLAY_TRACK:
				return {
					icon: faPlay,
					contents,
					details: (
						<TrackInfoDisplay
							trackId={h.trackId}
							playlistId={h.playlistId}
							manualPlaylist={h.manualPlaylist}
							device={device}
						/>
					)
				}
			case Constants.EVENT_SKIP:
				return { icon: faFastForward, contents }
			case Constants.EVENT_BLACKLIST:
				return {
					icon: faThumbsDown,
					color: Colors.status.red.color,
					contents
				}
			case Constants.EVENT_STOP:
				return {
					icon: faStop,
					color: Colors.status.red.color,
					contents: `${contents}${h.message ? ` (${h.message})` : ''}`
				}
			case Constants.EVENT_PAUSE:
				return {
					icon: faPause,
					color: Colors.status.red.color,
					contents
				}
			case Constants.EVENT_SELECT_PLAYLIST:
				if (!h.manualPlaylist && !h.playlistId) {
					return {
						contents: intl.formatMessage({
							id: `history.events.scheduledPlaylist`
						})
					}
				}
				return {
					contents,
					details: (
						<PlaylistInfoDisplay
							playlistId={h.playlistId}
							manual={h.manualPlaylist}
						/>
					)
				}
			case Constants.EVENT_RAN_OUT:
				return { icon: faVolumeOff, contents }
			case Constants.EVENT_PLAY_MESSAGE_SCHEDULED:
			case Constants.EVENT_PLAY_MESSAGE_MANUAL:
				return {
					icon: faPlay,
					contents,
					details: (
						<MessageInfoDisplay
							mediaId={h.messageId}
							manual={
								h.event === Constants.EVENT_PLAY_MESSAGE_MANUAL
							}
							username={h.username}
						/>
					)
				}

			case Constants.EVENT_DOWNLOAD:
				return { icon: faCloudDownloadAlt, contents }
			case Constants.EVENT_STOP_DOWNLOAD:
				if (h.message) {
					return {
						icon: faCloudDownloadAlt,
						color: Colors.status.red.color,
						contents: intl.formatMessage(
							{ id: `history.events.10.error` },
							{ message: h.message }
						)
					}
				}
				return { icon: faCloudDownloadAlt, contents }
			case Constants.EVENT_LOG:
				return { contents: h.message }
			case Constants.EVENT_WARNING:
				return {
					icon: faExclamationTriangle,
					color: Colors.status.orange.color,
					contents: h.message
				}
			case Constants.EVENT_ERROR:
				if (h.message === 'Under-voltage detected') {
					return null
				} else {
					return {
						icon: faExclamationCircle,
						color: Colors.status.red.color,
						contents: h.message
					}
				}
			case Constants.EVENT_LOGOUT:
				return {
					icon: faSignOutAlt,
					color: Colors.status.red.color,
					contents: h.message
				}
			default:
				return null
		}
	}
	return null
}

export const userFacingErrors = [
	'ACCESS_DENIED',
	'Access denied',
	'PERMISSION_DENIED',
	'DEVICE_NOT_FOUND',
	'MISSING_COMMAND',
	'MISSING_VOLUME',
	'INVALID_PIN',
	'TEAM_NOT_FOUND',
	'INVALID_PATH',
	'PROGRAM_NOT_FOUND',
	'PLAYLIST_NOT_FOUND',
	'ZONE_NOT_FOUND',
	'USER_NOT_FOUND',
	'WRONG_PASSWORD',
	'DEVICE_OFFLINE',
	'INCORRECT_PARAMS',
	'MAX_DEVICES_REACHED',
	'NOT_LOGGED_IN',
	'DUPLICATE_API_NAME',
	'EVENT_NOT_SAVED',
	'INVALID_IP',
	'DUPLICATE_IP'
]

export const displayGraphQLErrors = (error) => {
	const msg = error.message
		.replace('GraphQL error: ', '')
		.replace('Error: ', '')
	if (msg === 'NOT_LOGGED_IN') {
		return <Redirect to="/login" />
	} else if (userFacingErrors.indexOf(msg) > -1) {
		return <FormattedMessage id={`server.${msg}`} />
	}
	return <FormattedMessage id="error.message" />
}

export const displayError = (e) => {
	console.error(e)

	if (!e.errorFields) {
		const msg = e.message
			.replace('GraphQL error: ', '')
			.replace('Error: ', '')

		if (userFacingErrors.indexOf(msg) === -1) {
			Sentry.captureException(e)
		}

		let display = 'error.message'
		if (userFacingErrors.indexOf(msg) > -1) {
			display = `server.${msg}`
		}
		message.error(
			<IntlProvider locale={language} messages={messages[language]}>
				<FormattedMessage id={display} />
			</IntlProvider>
		)
	}
}

export const parseMutationResponse = (res) => {
	if (res.data) {
		for (let key in res.data) {
			let obj = res.data[key]
			if (obj && obj.success === false) {
				if (obj.message) {
					throw new Error(obj.message)
				} else {
					throw new Error(`${key} failed`)
				}
			}
		}
	}
}

export const displayLoad = (load) => {
	return `${load['1']} (1min) ${load['5']} (5min) ${load['15']} (15min)`
}

export const displayIO = (io) => {
	return (
		<React.Fragment>
			<div>{io.tps} reqs/s</div>
			<div>Reads: {io.readDelta} kB</div>
			<div>Writes: {io.writeDelta} kB</div>
		</React.Fragment>
	)
}

export const iconForOutputType = (type) => {
	if (type.indexOf('Bluetooth') > -1) {
		return faBluetooth
	}
	if (type.toLowerCase().indexOf('airplay') > -1) {
		return faWifi
	}
	if (type.indexOf('Headphones') > -1) {
		return faHeadphonesAlt
	}
	return faVolumeUp
}

export const nameForOutputType = (zone) => {
	if (zone.stateOutputType.indexOf('Bluetooth') > -1) {
		if (zone.stateOutputName) {
			return zone.stateOutputName
		} else {
			return <FormattedMessage id="devices.player.output.bluetooth" />
		}
	} else if (zone.stateOutputType.toLowerCase().indexOf('airplay') > -1) {
		if (zone.stateOutputName) {
			return zone.stateOutputName
		} else {
			return <FormattedMessage id="devices.player.output.airplay" />
		}
	} else if (zone.stateOutputType === 'Headphones') {
		return <FormattedMessage id="devices.player.output.lineout" />
	} else if (zone.stateOutputType === 'Speaker') {
		return <FormattedMessage id="devices.player.output.speaker" />
	}
	return zone.stateOutputType
}

export const playStateDisplay = (device, zone) => {
	if (device && zone) {
		if (isDeviceOnline(device)) {
			switch (
				zone.playState // eslint-disable-line
			) {
				case Constants.PLAY_STATE_PLAYING_MESSAGE:
					return {
						color: Colors.status.green,
						icon: faCommentAlt,
						title: (
							<FormattedMessage id="devices.player.status.playingMessage" />
						),
						value: 1.2
					}
				case Constants.PLAY_STATE_PLAYING:
					if (zone.stateManualPlaylist) {
						return {
							color: Colors.status.orange,
							icon: faPlay,
							title: (
								<FormattedMessage id="devices.player.status.forced" />
							),
							value: 1.1
						}
					}
					return {
						color: Colors.status.green,
						icon: faPlay,
						title: (
							<FormattedMessage id="devices.player.status.playing" />
						),
						value: Constants.PLAY_STATE_PLAYING
					}
				case Constants.PLAY_STATE_SILENCE:
					return {
						color: Colors.status.green,
						icon: faVolumeOff,
						title: (
							<FormattedMessage id="devices.player.status.ranOut" />
						),
						value: Constants.PLAY_STATE_SILENCE
					}
				case Constants.PLAY_STATE_PAUSED:
					return {
						color: Colors.status.red,
						icon: faPause,
						title: (
							<FormattedMessage id="devices.player.status.paused" />
						),
						value: Constants.PLAY_STATE_PAUSED
					}
				case Constants.PLAY_STATE_STOPPED:
					return {
						color: Colors.status.red,
						icon: faStop,
						title: (
							<FormattedMessage id="devices.player.status.stopped" />
						),
						value: Constants.PLAY_STATE_STOPPED
					}
			}
		}
		if (
			!device.contentUpdated ||
			device.dlState === Constants.DL_STATE_DOWNLOADING
		) {
			return {
				color: Colors.status.orange,
				icon: faCloudDownloadAlt,
				title: (
					<FormattedMessage id="devices.player.status.awaitingContent" />
				),
				value: -2
			}
		}
		if (!zone.program && !zone.programId && !zone.stateProgramId) {
			return {
				color: Colors.status.orange,
				icon: faCog,
				title: (
					<FormattedMessage id="devices.player.status.awaitingSetup" />
				),
				value: -3
			}
		}
	}
	return {
		color: Colors.status.red,
		icon: faPowerOff,
		title: <FormattedMessage id="devices.player.status.offline" />,
		value: -1
	}
}

export const isDeviceDrax = (device) => {
	return device.type && device.type.indexOf('drax') > -1
}

export const isDeviceVersionAbove = (device, targetVersion) => {
	if (device.appVersion) {
		const components = device.appVersion.split(' ')
		const version = components[0]
		const versionString = semver.valid(semver.coerce(version))
		return semver.gte(versionString, targetVersion)
	}
	return false
}

export const updateBadge = (device) => {
	if (device.appVersion && device.latestVersion) {
		const components = device.appVersion.split(' ')
		const version = components[0]
		const versionString = semver.valid(semver.coerce(version))
		let badgeColor = null

		if (semver.lt(versionString, device.latestVersion)) {
			badgeColor =
				semver.diff(versionString, device.latestVersion) === 'major' ?
					'red'
				:	'orange'
		}

		if (badgeColor) {
			return (
				<Tag color={badgeColor} style={{ marginLeft: 10 }}>
					<FontAwesomeIcon icon={faCloudDownloadAlt} />
					&nbsp;
					<FormattedMessage id="devices.info.appVersion.updateAvailable" />
				</Tag>
			)
		}
	}
	return null
}

export const indexById = (ar) => {
	let obj = {}
	for (let i of ar) {
		obj[i._id] = i
	}
	return obj
}

export const utcToLocalDate = (date, offsetDate) => {
	return moment(date).subtract(
		moment(offsetDate || date).utcOffset(),
		'minutes'
	)
}

export const localToUtcDate = (date, offsetDate) => {
	return moment(date).add(moment(offsetDate || date).utcOffset(), 'minutes')
}

export const duration = (start, end) => {
	let localTime = localToUtcDate(start)
	let localTimeEnd = localToUtcDate(end)
	return Math.floor(
		(localTimeEnd.toDate().getTime() - localTime.toDate().getTime()) /
			(60 * 1000)
	)
}

export const stripeIntervals = {
	YEARLY: 'year',
	MONTHLY: 'month',
	WEEKLY: 'week',
	DAILY: 'day'
}

export const rruleIntervalFromStripe = (interval) => {
	for (let key in stripeIntervals) {
		if (stripeIntervals[key] === interval) {
			return key
		}
	}
	return null
}

export const invoiceStatusColor = (status) => {
	switch (status) {
		case 'open':
			return 'gold'
		case 'paid':
			return 'green'
		case 'uncollectible':
			return 'red'
		case 'void':
		case 'draft':
		default:
			return null
	}
}

export const updatedRuleSetWithRule = (oldRuleSet, newRule) => {
	let rruleSet = new RRuleSet()
	rruleSet.rrule(newRule)
	let localTime = moment(newRule.options.dtstart)

	// save exceptions
	for (let date of oldRuleSet._exdate) {
		// for frequencies daily+, match the exception time
		if (
			newRule.options.freq !== 'HOURLY' &&
			newRule.options.freq !== 'MINUTELY'
		) {
			let d = moment(date)
			d.hour(localTime.hour())
			d.minute(localTime.minute())
			date = d.toDate()
		}
		rruleSet.exdate(date)
	}

	return rruleSet
}

export const displayUpdateTime = (device) => {
	if (device.autoUpdate) {
		return (
			<FormattedMessage
				id="devices.info.actions.updates.scheduled.at"
				values={{ time: `${device.autoUpdateTime}:00` }}
			/>
		)
	} else {
		return <FormattedMessage id="devices.info.actions.updates.instantly" />
	}
}

export const updateTimeValue = (device) => {
	return device.autoUpdate ? parseInt(device.autoUpdateTime, 10) : -1
}

export const displayRebootTime = (device) => {
	if (device.autoReboot) {
		return (
			<FormattedMessage
				id="devices.info.actions.updates.scheduled.at"
				values={{ time: `${device.autoRebootTime}:00` }}
			/>
		)
	} else {
		return <FormattedMessage id="no" />
	}
}

export const rebootTimeValue = (device) => {
	return device.autoReboot ? parseInt(device.autoRebootTime, 10) : -1
}

export const cardNumberStars = (pm) => {
	if (pm.type === 'card') {
		switch (pm.card.brand) {
			case 'Diners Club':
				return '****-******-**'
			case 'American Express':
				return '****-******-*'
			case 'Visa':
			case 'Mastercard':
			case 'Discover':
			case 'UnionPay':
			case 'JCB':
			default:
				return '****-****-****-'
		}
	} else {
		return '****-****-********-****-****-'
	}
}

export const displayPaymentMethodType = (pm) => {
	if (pm.card) {
		return (
			<React.Fragment>
				<div>{pm.card.brand}</div>
				<div style={{ fontSize: 11 }}>
					{cardNumberStars(pm)}
					{pm.card.last4}
				</div>
				{pm.owner && pm.owner.name && (
					<div style={{ fontSize: 11 }}>{pm.owner.name}</div>
				)}
			</React.Fragment>
		)
	}
	if (pm.sepa_debit) {
		return (
			<React.Fragment>
				<div>
					<FormattedMessage id="billing.paymentMethods.sepaDebit" />
				</div>
				<div style={{ fontSize: 11 }}>
					{pm.sepa_debit.country}*********************
					{pm.sepa_debit.last4}
				</div>
				{pm.owner && pm.owner.name && (
					<div style={{ fontSize: 11 }}>{pm.owner.name}</div>
				)}
			</React.Fragment>
		)
	}
	if (pm.sepa_credit_transfer) {
		return (
			<React.Fragment>
				<div>
					<FormattedMessage id="billing.paymentMethods.sepaCredit" />
				</div>
				<div style={{ fontSize: 11 }}>
					<FormattedMessage id="billing.credit.bank" />:{' '}
					{pm.sepa_credit_transfer.bank_name}
				</div>
				<div style={{ fontSize: 11 }}>
					<FormattedMessage id="billing.credit.bic" />:{' '}
					{pm.sepa_credit_transfer.bic}
				</div>
				<div style={{ fontSize: 11 }}>
					<FormattedMessage id="billing.credit.iban" />:{' '}
					{pm.sepa_credit_transfer.iban}
				</div>
			</React.Fragment>
		)
	}
	return ''
}

export const displayPaymentMethodStatus = (pm, isDefault) => {
	let result = []
	if (pm.status === 'chargeable') {
		if (pm.type === 'card') {
			const year = moment().year()
			const month = moment().month() + 1
			const dateVal = {
				date: `${pm.card.exp_month < 10 ? '0' : ''}${
					pm.card.exp_month
				}/${pm.card.exp_year}`
			}
			if (
				year > pm.card.exp_year ||
				(year === pm.card.exp_year && month > pm.card.exp_month)
			) {
				// expired
				result.push(
					<Tag key="status" color="red">
						<FormattedMessage
							id="billing.paymentMethods.expired"
							values={dateVal}
						/>
					</Tag>
				)
			} else if (
				year === pm.card.exp_year &&
				month >= pm.card.exp_month - 1
			) {
				// expires in less than 2 months
				result.push(
					<Tag key="status" color="gold">
						<FormattedMessage
							id="billing.paymentMethods.expires"
							values={dateVal}
						/>
					</Tag>
				)
			} else {
				// valid
				result.push(
					<Tag color="green" key="status">
						<FormattedMessage
							id="billing.paymentMethods.expires"
							values={dateVal}
						/>
					</Tag>
				)
			}
		} else if (pm.type === 'sepa_credit_transfer') {
			// valid
			result.push(
				<Tag color="green" key="status">
					<FormattedMessage id="billing.paymentMethods.valid" />
				</Tag>
			)
		}
	} else if (pm.status === 'pending') {
		if (pm.type !== 'sepa_credit_transfer') {
			result.push(
				<Tag color="gold" key="status">
					<FormattedMessage id="billing.paymentMethods.pending" />
				</Tag>
			)
		}
	} else {
		result.push(
			<Tag color="red" key="status">
				<FormattedMessage id="billing.paymentMethods.invalid" />
			</Tag>
		)
	}

	// sepa
	if (pm.mandate && pm.mandate.acceptance) {
		switch (pm.mandate.acceptance.status) {
			case 'accepted':
				result.push(
					<Tag color="green" key="mandate">
						<FormattedMessage id="billing.mandate.accepted" />
					</Tag>
				)
				break
			case 'refused':
				result.push(
					<Tag color="red" key="mandate">
						<FormattedMessage id="billing.mandate.refused" />
					</Tag>
				)
				break
			case 'pending':
				result.push(
					<Tag color="gold" key="mandate">
						<FormattedMessage id="billing.mandate.pending" />
					</Tag>
				)
				break
			default:
				break
		}
	}

	if (isDefault) {
		result.push(
			<Tag color="blue" key="default">
				<FormattedMessage id="billing.paymentMethods.default" />
			</Tag>
		)
	}
	return <React.Fragment>{result}</React.Fragment>
}

export const userName = (user) => {
	if (!user) {
		return '<DELETED>'
	} else if (user.firstName && user.lastName) {
		return `${user.firstName} ${user.lastName}`
	}
	return user.username
}

export const userAvatar = (user) => {
	if (!user) {
		return null
	}
	return `https://www.gravatar.com/avatar/${md5(
		user.username.trim().toLowerCase()
	)}?d=retro`
}

export const netmaskToCidr = (mask) => {
	let cidr = ''
	for (let m of mask.split('.')) {
		if (
			parseInt(m, 10) > 255 ||
			(parseInt(m, 10) > 0 && parseInt(m, 10) < 128)
		) {
			throw new Error('Invalid Netmask')
		}

		cidr += (m >>> 0).toString(2)
	}

	if (cidr.substring(cidr.search('0'), 32).search('1') !== -1) {
		throw new Error('Invalid Netmask')
	}
	return cidr.split('1').length - 1
}

export const isString = (x) => {
	return Object.prototype.toString.call(x) === '[object String]'
}
