import React, { Component } from 'react'
import { Modal, Select, Form, TimePicker } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import moment from 'moment'

import { formItemLayoutEqual } from '../../components/Styles'
import { DeviceDetailsQuery, DeviceActionMutation } from './Queries'
import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'

const FormItem = Form.Item
const Option = Select.Option

class Updates extends Component {
	state = {
		error: null
	}

	handleCancel = () => {
		this.props.onClose()
	}

	handleEnabledChange = async (v) => {
		let value = v === 'scheduled'
		const { device, client } = this.props

		try {
			const r = await client.mutate({
				variables: {
					device: device._id,
					autoUpdate: value,
					autoUpdateTime: device.autoUpdateTime || '03'
				},
				mutation: gql`
					mutation updateDeviceAutoUpdate(
						$device: MongoID!
						$autoUpdate: Boolean!
						$autoUpdateTime: String!
					) {
						deviceUpdateById(
							_id: $device
							record: {
								autoUpdate: $autoUpdate
								autoUpdateTime: $autoUpdateTime
							}
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				]
			})
			Utils.parseMutationResponse(r)

			const dr = await client.mutate(
				DeviceActionMutation(device, null, 'options')
			)
			Utils.parseMutationResponse(dr)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	handleTimeChange = async (v) => {
		const value = v ? v.format('HH') : '03'

		const { device, client } = this.props

		try {
			const r = await client.mutate({
				variables: {
					device: device._id,
					autoUpdateTime: value
				},
				mutation: gql`
					mutation updateDeviceAutoUpdateTime(
						$device: MongoID!
						$autoUpdateTime: String!
					) {
						deviceUpdateById(
							_id: $device
							record: { autoUpdateTime: $autoUpdateTime }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				]
			})
			Utils.parseMutationResponse(r)

			if (Utils.isDeviceOnline(device)) {
				const dr = await client.mutate(
					DeviceActionMutation(device, null, 'options')
				)
				Utils.parseMutationResponse(dr)
			}
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, device } = this.props
		const canEdit = Auth.hasPermission('device:edit:setAutoUpdates')

		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id: 'devices.info.actions.updates.title'
				})}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<Form>
					<FormItem
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'devices.info.actions.updates.apply'
						})}
					>
						<Select
							style={{ width: 128 }}
							value={
								device.autoUpdate ? 'scheduled' : 'instantly'
							}
							onChange={this.handleEnabledChange}
							disabled={!canEdit}
						>
							<Option value="instantly">
								<FormattedMessage id="devices.info.actions.updates.instantly" />
							</Option>
							<Option value="scheduled">
								<FormattedMessage id="devices.info.actions.updates.scheduled" />
							</Option>
						</Select>
					</FormItem>
					<FormItem
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'devices.info.actions.updates.time'
						})}
					>
						<TimePicker
							disabled={!device.autoUpdate || !canEdit}
							minuteStep={60}
							secondStep={60}
							defaultValue={moment(`03:00:00`, 'HH:mm:ss')}
							value={moment(
								`${device.autoUpdateTime || '03'}:00:00`,
								'HH:mm:ss'
							)}
							onChange={this.handleTimeChange}
						/>
					</FormItem>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(Updates))
