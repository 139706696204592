import React, { Component } from 'react'
import gql from 'graphql-tag'
import {
	StripeProvider,
	injectStripe,
	Elements,
	CardElement,
	IbanElement
} from 'react-stripe-elements'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Button, Tabs, message, Form, Input, Collapse } from 'antd'
import { withApollo } from '@apollo/client/react/hoc'
import { TeamBillingQuery } from '../Queries'
import { AddressForm, AddressDisplay } from './AddressDisplay'

import config from '../../../config'
import * as Utils from '../../../components/Utils'
import S from '../../../components/Styles'

const TabPane = Tabs.TabPane

class IAddPaymentMethod extends Component {
	state = {
		loading: false,
		type: 'card',
		showAddress: false,
		label: null
	}

	componentDidMount() {
		const { source, subscription } = this.props
		if (source) {
			this.setState({
				label: source.metadata.name,
				name: source.owner.name,
				email: source.owner.email,
				...source.owner.address
			})
		} else {
			if (subscription.customerInfo) {
				const customerInfo = JSON.parse(subscription.customerInfo)
				this.setState({
					name: customerInfo.shipping.name,
					email: customerInfo.email,
					...customerInfo.shipping.address
				})
			}
		}
	}

	handleSubmit = async () => {
		try {
			const { client, team, subscription, stripe, onClose } = this.props
			this.setState({ loading: true })
			const now = new Date()
			const nowTs = Math.floor(now.getTime() / 1000)

			let src = {
				type: this.state.type,
				metadata: {
					name: this.state.label
				},
				owner: {
					name: this.state.name,
					address: {
						line1: this.state.line1,
						line2: this.state.line2,
						postal_code: this.state.postal_code,
						city: this.state.city,
						state: this.state.state,
						country: this.state.country
					},
					email: this.state.email
				}
			}
			if (this.state.type === 'sepa_debit') {
				src.mandate = {
					notification_method: 'email',
					acceptance: {
						status: 'accepted',
						date: nowTs
					}
				}
				src.currency = 'eur'
			}

			let result
			if (this.state.type === 'sepa_credit_transfer') {
				// credit is added on the backend
				src.currency = 'eur'
				const r = await client.mutate({
					variables: {
						id: subscription._id,
						source: JSON.stringify(src)
					},
					mutation: gql`
						mutation createSubscriptionSepaCredit(
							$id: String!
							$source: String!
						) {
							subscriptionCreateSepaCredit(
								_id: $id
								source: $source
							) {
								success
								result
								message
							}
						}
					`
				})
				console.log(r)
				Utils.parseMutationResponse(r)
				if (
					r.data.subscriptionCreateSepaCredit &&
					r.data.subscriptionCreateSepaCredit.result
				) {
					result = {
						source: JSON.parse(
							r.data.subscriptionCreateSepaCredit.result
						)
					}
				}
			} else {
				// card and debit can be added on the frontend
				result = await stripe.createSource(src)
			}

			if (result && result.source) {
				const r = await client.mutate({
					variables: {
						id: subscription._id,
						source: result.source.id
					},
					mutation: gql`
						mutation addSubscriptionPaymentMethod(
							$id: String!
							$source: String!
						) {
							subscriptionAddPaymentMethod(
								_id: $id
								source: $source
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: team._id }
						}
					],
					awaitRefetchQueries: true
				})

				Utils.parseMutationResponse(r)
				this.setState({ loading: false })
				onClose()
			} else if (result.error) {
				this.setState({ loading: false })
				message.error(result.error.message)
			} else {
				throw new Error('Invalid Stripe response')
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, onClose, source, subscription } = this.props
		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id:
						source ?
							'billing.paymentMethods.edit'
						:	'billing.paymentMethods.add'
				})}
				onCancel={onClose}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.handleSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.save" />
					</Button>
				]}
			>
				<Form>
					<Form.Item
						label={intl.formatMessage({
							id: 'billing.paymentMethods.add.name'
						})}
					>
						<Input
							value={this.state.label}
							onChange={(e) =>
								this.setState({ label: e.target.value })
							}
							// placeholder={intl.formatMessage({id: 'billing.paymentMethods.add.name.placeholder'})}
						/>
					</Form.Item>

					<Collapse
						style={{ width: '100%' }}
						onChange={(v) =>
							this.setState({ showAddress: v.length > 0 })
						}
						activeKey={this.state.showAddress ? '1' : null}
					>
						<Collapse.Panel
							key="1"
							header={
								<React.Fragment>
									<div>
										<h3>
											<FormattedMessage id="billing.paymentMethods.add.cardholder" />
										</h3>
										{!this.state.showAddress && (
											<AddressDisplay {...this.state} />
										)}
									</div>
								</React.Fragment>
							}
						>
							<AddressForm
								onChange={(v) => this.setState(v)}
								{...this.state}
							/>
						</Collapse.Panel>
					</Collapse>

					<Tabs
						onChange={(k) => this.setState({ type: k })}
						defaultActiveKey={this.state.type}
						style={{ marginTop: 20, marginBottom: 20 }}
					>
						<TabPane
							tab={
								<FormattedMessage id="billing.paymentMethods.card" />
							}
							key="card"
						>
							<div style={styles.stripeInputContainer}>
								<CardElement />
							</div>
						</TabPane>
						{subscription.subsidiary === 'global' && (
							<TabPane
								tab={
									<FormattedMessage id="billing.paymentMethods.sepaDebit" />
								}
								key="sepa_debit"
							>
								<div style={styles.stripeInputContainer}>
									<IbanElement
										supportedCountries={['SEPA']}
									/>
								</div>
								<p
									style={{
										...S.emptyText,
										fontSize: 11,
										marginTop: 10
									}}
								>
									<FormattedMessage id="billing.mandate" />
								</p>
							</TabPane>
						)}
						{/*
						subscription.subsidiary === 'global' &&
						<TabPane tab={<FormattedMessage id='billing.paymentMethods.sepaCredit' />} key='sepa_credit_transfer'></TabPane>
					*/}
					</Tabs>
				</Form>
			</Modal>
		)
	}
}

const styles = {
	stripeInputContainer: {
		border: '1px solid #d9d9d9',
		borderRadius: 4,
		width: '100%',
		height: 32,
		padding: '6px 11px'
	}
}

const AddPaymentMethod = withApollo(injectStripe(injectIntl(IAddPaymentMethod)))

class StripeContext extends Component {
	state = {
		stripe: null
	}

	// Stripe is loaded async to speed up page loading time
	componentDidMount() {
		const subsidiary = this.props.subscription.subsidiary || 'global'
		const pk = config.stripe[subsidiary].pk

		if (window.Stripe) {
			this.setState({ stripe: window.Stripe(pk) })
		} else {
			let el = document.querySelector('#stripe-js')
			if (el) {
				el.addEventListener('load', () => {
					// Create Stripe instance once Stripe.js loads
					this.setState({ stripe: window.Stripe(pk) })
				})
			}
		}
	}

	render() {
		return (
			<StripeProvider stripe={this.state.stripe}>
				<Elements>
					<AddPaymentMethod {...this.props} />
				</Elements>
			</StripeProvider>
		)
	}
}

export default StripeContext
