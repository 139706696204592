import gql from 'graphql-tag'

export const TeamsTreeQuery = gql`
	query teamsTreeQuery {
		teams {
			_id
			n
			a
			maxDevices
			messagesEnabled
			users {
				_id
			}
			devices {
				_id
			}
			programs {
				_id
			}
			messagePrograms {
				_id
			}
		}
	}
`

export const TeamsFilterQuery = gql`
	query teamsFilterQuery {
		teams {
			_id
			n
			a
			path
		}
	}
`

export const TeamDetailsQuery = gql`
	query fetchTeamDetails($id: String!) {
		teamById(_id: $id) {
			_id
			n
			a
			path
			children
			maxDevices
			deviceTtl
			messagesEnabled
			parentMessagesEnabled
			thirdPartyDevicesEnabled
			parentThirdPartyDevicesEnabled
			logo
			ipWhitelist
			parentIpWhitelist
			childrenUsers {
				_id
				teamId
			}
			devices {
				_id
				name
				nickname
				OS
				onlineDate
				onlineState
				lastConnection
				contentUpdated
				activationToken
				activationLink
				zones {
					_id
					name
					playState
					program {
						_id
					}
					messagePrograms {
						_id
						updated
					}
				}
				teamId
			}
			programs {
				_id
				name
				playlists {
					_id
				}
				teamId
				programPlaylistIds
			}
			messagePrograms {
				_id
				name
				teamId
			}
			users {
				_id
				username
				firstName
				lastName
				teamId
				role
				montyUser
				language
			}
		}
	}
`

export const TeamBillingQuery = gql`
	query fetchTeamBilling($id: String!) {
		teamById(_id: $id) {
			_id
			subscription {
				_id
				teamId
				team {
					_id
					n
				}
				customerInfo
				currency
				interval
				intervalCount
				collectionMethod
				daysUntilDue
				taxRate {
					id
					percentage
					display_name
				}
				startDate
				endDate
				invoiceFooter
				subsidiary
				items {
					price
					quantity
					description
				}
			}
		}
	}
`
