import blankCover from '../img/blankCover.png'
import bg from '../img/bg.jpg'

export const Colors = {
	primary: '#1890ff',
	emptyIcons: '#e8ebee',
	status: {
		green: {
			color: '#52c41a',
			backgroundColor: '#f6ffed',
			borderColor: '#b7eb8f'
		},
		orange: {
			color: '#fa8c16',
			backgroundColor: '#fff7e6',
			borderColor: '#ffd591'
		},
		red: {
			color: '#f5222d',
			backgroundColor: '#fff1f0',
			borderColor: '#ffa39e'
		},
		blue: {
			color: '#fff',
			backgroundColor: '#1890ff',
			borderColor: '#1890ff'
		},
		lightBlue: {
			color: '#1890ff',
			backgroundColor: '#e6f7ff',
			borderColor: '#91d5ff'
		}
	},
	playlistColors: [
		'#F15A5A',
		'#4EBA6F',
		'#2D95BF',
		'#955BA5',
		'#F0C419',
		'#111930',
		'#FF6B03',
		'#7B1323',
		'#00676E',
		'#D9AE4C',
		'#434544',
		'#963933'
	],
	lines: '#eee',
	disabled: {
		icon: '#aaa',
		text: '#999'
	},
	lightBackground: 'transparent',
	lightBackgroundBorder: 'transparent',
	switchBackground: '#1890ff'
}

export default {
	login: {
		bg: {
			width: '100%',
			height: '100%',
			position: 'fixed'
		},
		bgImage: {
			position: 'fixed',
			zIndex: -1,
			width: '100%',
			height: '100%',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundImage: `url(${bg})`,
			filter: 'blur(4px)',
			transform: 'scale(1.1)'
		},
		logoContainer: {
			width: '100%',
			textAlign: 'center'
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%'
		},
		form: {
			width: '300px',
			margin: '25px',
			backgroundColor: '#FFF',
			padding: '30px 30px 10px 30px',
			boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.3)'
		},
		fieldIcon: {
			color: 'rgba(0,0,0,.25)'
		},
		forgotPass: {
			float: 'right',
			marginBottom: '16px'
		},
		submit: {
			width: '100%'
		}
	},
	layout: {
		fullHeight: {
			height: '100%',
			position: 'relative'
		},
		pageMiddle: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0
		},
		scrolling: {
			overflow: 'auto',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0
		},
		scrollingContainer: {
			background: '#fff',
			marginTop: 16,
			marginBottom: 16,
			padding: 24
		}
	},
	menuListItem: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},
	itemDetails: {
		titleSection: {
			position: 'relative',
			background: Colors.lightBackground,
			borderBottom: `1px solid ${Colors.lightBackgroundBorder}`,
			margin: '-24px -24px 10px -24px',
			padding: '24px 24px 5px 24px'
		},
		title: {
			textAlign: 'center',
			margin: '0 70px'
		},
		titleMobile: {
			textAlign: 'center',
			marginTop: 10
		},
		titleLeft: {
			marginRight: 5
		},
		titleActions: {
			position: 'absolute',
			right: 24,
			top: 29
		},
		status: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: 10
		},
		property: {
			lineHeight: 2
		},
		propertyTitle: {
			textTransform: 'uppercase',
			fontSize: '10px',
			lineHeight: 1,
			color: Colors.disabled.text
		},
		propertyValue: {
			marginBottom: 10
		},
		teamPath: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			flexWrap: 'wrap',
			padding: 10
		}
	},
	note: {
		whiteSpace: 'pre-line'
	},
	card: {
		fullWidthContainer: {
			width: '100%'
		},
		body: {
			padding: '10px 10px 0 10px'
		},
		container: {
			marginBottom: 16
		},
		header: {
			display: 'flex'
		},
		headerMobile: {
			display: 'flex',
			flexWrap: 'wrap',
			marginTop: 10,
			marginLeft: -10
		},
		headerItem: {
			marginLeft: 10
		},
		trackInfo: {
			header: {
				display: 'flex',
				flexWrap: 'nowrap',
				alignItems: 'baseline',
				marginBottom: 3
			},
			title: {
				marginRight: 10,
				fontSize: 15
			},
			body: {
				display: 'flex',
				alignItems: 'flex-start'
			},
			image: {
				width: 80,
				height: 80,
				borderRadius: 5,
				border: '1px solid #000',
				backgroundImage: `url(${blankCover})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				marginRight: 10,
				objectFit: 'cover'
			},
			textContainer: {
				flex: 1,
				paddingRight: 10
			},
			card: {
				margin: '5px 0'
			},
			cardBody: {
				padding: 10,
				display: 'flex',
				alignItems: 'center'
			},
			compactImage: {
				width: 50,
				height: 50,
				borderRadius: 5,
				border: '1px solid #000',
				backgroundImage: `url(${blankCover})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				marginRight: 10,
				objectFit: 'cover'
			}
		}
	},
	empty: {
		width: '100%',
		height: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: 14,
		color: 'rgba(0, 0, 0, 0.45)'
	},
	emptyText: {
		color: 'rgba(0, 0, 0, 0.45)'
	},
	mobileCol: {
		marginBottom: 16
	},
	horizontal: {
		display: 'flex',
		alignItems: 'center'
	},
	centered: {
		display: 'flex',
		justifyContent: 'center'
	},
	playlistDetails: {
		legend: {
			width: 30,
			height: 20,
			borderRadius: 5,
			float: 'left',
			marginRight: 5
		},
		imageContainer: {
			position: 'relative',
			width: 100,
			height: 100,
			marginLeft: 10,
			marginRight: 20
		},
		detailsContainer: {
			flexGrow: 1
		},
		image: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			width: 100,
			height: 100,
			position: 'absolute',
			backgroundColor: '#000',
			borderRadius: 5,
			border: '1px solid #000',
			objectFit: 'cover'
		},
		spinner: {
			position: 'absolute',
			fontSize: 30,
			top: 'calc(50% - 15px)',
			left: 0,
			right: 0,
			color: Colors.primary
		},
		fileInput: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			opacity: 0,
			cursor: 'pointer'
		}
	},
	filterPopup: {
		maxWidth: 300,
		backgroundColor: 'white',
		padding: '8px 0',
		borderRadius: 6,
		boxShadow: '0 1px 6px rgba(0, 0, 0, .2)'
	},
	noBulletList: {
		listStyle: 'none',
		margin: 0,
		padding: 0
	},
	columnBrowser: {
		scroll: {
			height: '100%',
			width: '100%',
			overflowX: 'auto',
			overflowY: 'hidden'
		},
		container: {
			display: 'block',
			whiteSpace: 'nowrap',
			height: '100%'
		},
		column: {
			height: '100%',
			display: 'inline-block',
			verticalAlign: 'top',
			overflowX: 'visible',
			overflowY: 'scroll',
			minWidth: 150
		},
		row: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'baseline'
		},
		rowName: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'baseline'
		},
		expandIcon: {
			marginLeft: 10,
			fontSize: 10,
			fontWeight: 800
		},
		details: {
			padding: 20,
			display: 'flex',
			flexDirection: 'column'
		}
	},
	formItemSubtitle: {
		position: 'absolute',
		right: 5,
		bottom: -30,
		color: Colors.disabled.text
	},
	trackCover: {
		width: 50,
		height: 50,
		backgroundImage: `url(${blankCover})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	switchOneColor: {
		backgroundImage: 'none',
		backgroundColor: Colors.switchBackground
	}
}

export const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
}

export const formItemLayoutNarrow = {
	labelCol: {
		xs: { span: 4 },
		sm: { span: 24 }
	},
	wrapperCol: {
		xs: { span: 20 },
		sm: { span: 24 }
	}
}

export const formItemLayoutEqual = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	}
}

export const formItemLayoutWide = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	}
}

export const formItemLayoutXWide = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	}
}

export const formItemLayoutSwitch = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 20 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 4 }
	}
}

export const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 16,
			offset: 8
		}
	}
}
