import React, { Component } from 'react'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import { Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faLink,
	faSyncAlt,
	faExpandArrowsAlt,
	faCompressArrowsAlt
} from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { withRouter } from 'react-router-dom'

import * as Utils from '../../components/Utils'
import Table from '../../components/TableV2'
import PairDevice from './PairDevice'
import * as Auth from '../../auth'
import * as Constants from '../../components/Constants'
import { withResponsive } from '../../components/Responsive'

class IDeviceListDisplay extends Component {
	columns = {
		status: {
			name: this.props.intl.formatMessage({ id: 'devices.info.online' }),
			accessor: (v) => Utils.deviceStatus(v).value,
			queryProps: [
				'lastConnection',
				'onlineDate',
				'onlineState',
				'safeMode'
			],
			title: <FontAwesomeIcon icon={faCircle} fixedWidth />,
			render: (props) => {
				const s = Utils.deviceStatus(props)
				return (
					<Tooltip title={s.title}>
						<FontAwesomeIcon
							icon={s.icon}
							style={{ color: s.color }}
							fixedWidth
						/>
					</Tooltip>
				)
			},
			filters: [
				{
					text: this.props.intl.formatMessage({
						id: 'devices.table.online'
					}),
					value: 1
				},
				{
					text: this.props.intl.formatMessage({
						id: 'devices.table.offline.title'
					}),
					value: 0
				}
			],
			onFilter: (value, record) => {
				return value === (Utils.isDeviceOnline(record) ? 1 : 0)
			}
		},
		sync: {
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.syncStatus'
			}),
			accessor: (v) => Utils.deviceSync(v).value,
			queryProps: [
				'dlState',
				'contentUpdated',
				{
					zones: [
						'_id',
						'name',
						'playState',
						'programUpdated',
						'messageProgramsUpdated'
					]
				}
			],
			title: <FontAwesomeIcon icon={faSyncAlt} fixedWidth />,
			render: (props) => {
				const s = Utils.deviceSync(
					props,
					Auth.hasPermission('device:read:syncStatusDate')
				)
				return (
					<Tooltip title={s.title}>
						<FontAwesomeIcon
							icon={faSyncAlt}
							spin={s.spin}
							style={{ color: s.color }}
							fixedWidth
						/>
					</Tooltip>
				)
			},
			filters: [
				{
					text: this.props.intl.formatMessage({
						id: 'devices.info.upToDate'
					}),
					value: Constants.SYNC_STATE_UPTODATE
				},
				{
					text: this.props.intl.formatMessage({
						id: 'devices.info.syncInProgress'
					}),
					value: Constants.SYNC_STATE_SYNCING
				},
				{
					text: this.props.intl.formatMessage({
						id: 'devices.info.outOfDate.title'
					}),
					value: Constants.SYNC_STATE_OUTDATED
				},
				{
					text: this.props.intl.formatMessage({
						id: 'devices.info.neverSynced'
					}),
					value: Constants.SYNC_STATE_NEVERSYNCED
				}
			],
			onFilter: (value, record) => {
				const val = parseInt(value, 10)
				const s = Utils.deviceSync(record)
				return s.state === val
			}
		},
		os: {
			accessor: (d) => d.OS,
			name: this.props.intl.formatMessage({ id: 'devices.info.OS' }),
			queryProps: ['OS'],
			render: (props) => {
				const icon = Utils.osLogo(props.OS)
				return <FontAwesomeIcon icon={icon} fixedWidth />
			},
			onFilter: (value, record) => {
				if (record.OS) return value.indexOf(record.OS) > -1
				return false
			},
			filters: [
				{ text: 'Largo', value: 'Chromium' },
				{ text: 'iOS', value: 'iOS iPhone OS' },
				{ text: 'Android', value: 'Android' },
				{
					text: 'DraxOS',
					value: `${Constants.DRAX_OS} ${Constants.DRAX_OS_NEW}`
				}
			]
		},
		model: {
			accessor: (device) =>
				`${!!device.brand ? `${device.brand} ` : ''}${device.model}`,
			queryProps: ['brand', 'model', 'simulator'],
			name: this.props.intl.formatMessage({ id: 'devices.info.model' }),
			render: (device) => (
				<span>
					{!!device.brand ? `${device.brand} ` : ''}
					{device.model}{' '}
					{device.simulator && (
						<FormattedMessage id="devices.info.simulator" />
					)}
				</span>
			)
		},
		osVersion: {
			accessor: (device) => device.OSVersion,
			queryProps: ['OSVersion'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.OSVersion'
			}),
			type: 'Version'
		},
		appVersion: {
			accessor: (device) => device.appVersion,
			queryProps: ['appVersion', 'OS'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.appVersion'
			}),
			type: 'Version'
		},
		buildDate: {
			accessor: (device) => device.created,
			queryProps: ['created'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.buildDate'
			}),
			render: (device) =>
				!!device.created ?
					<FormattedDate
						value={device.created}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				:	'',
			type: 'Date'
		},
		installDate: {
			accessor: (device) => device.installationDate,
			queryProps: ['installationDate'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.installDate'
			}),
			render: (device) =>
				!!device.installationDate ?
					<FormattedDate
						value={device.installationDate}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				:	'',
			type: 'Date'
		},
		timeZone: {
			title: this.props.intl.formatMessage({
				id: 'devices.info.timeZone'
			}),
			queryProps: ['timeZone'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.timeZone'
			}),
			accessor: (device) => device.timeZone
		},
		uptime: {
			accessor: (d) =>
				d.onlineState === Constants.ONLINE_STATE_ONLINE ?
					d.uptime
				:	null,
			queryProps: ['uptime', 'onlineState'],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.uptime'
			}),
			render: (device) =>
				(
					device.onlineState === Constants.ONLINE_STATE_ONLINE &&
					!!device.uptime
				) ?
					<FormattedDate
						value={device.uptime}
						year="numeric"
						month="short"
						day="2-digit"
						hour="2-digit"
						minute="2-digit"
					/>
				:	'',
			type: 'Date'
		},
		temperature: {
			queryProps: ['temperature'],
			name: this.props.intl.formatMessage({
				id: 'devices.info.temperature'
			}),
			accessor: (device) => device.temperature,
			render: (device) =>
				device.temperature ? `${device.temperature}ºC` : '',
			type: 'Number'
		},
		numberOfZones: {
			title: this.props.intl.formatMessage({ id: 'zones' }),
			queryProps: ['zoneIds'],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.numberOfZones'
			}),
			accessor: (device) => (device.zoneIds ? device.zoneIds.length : 0),
			type: 'Number'
		},
		playState: {
			queryProps: [
				'onlineState',
				'onlineDate',
				'contentUpdated',
				'dlState',
				{
					zones: [
						'_id',
						'playState',
						'stateManualPlaylist',
						'programId',
						'stateProgramId'
					]
				}
			],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.playState'
			}),
			render: (device) => {
				let contents = []
				if (device && device.zones) {
					for (let z of device.zones) {
						const zps = Utils.playStateDisplay(device, z)
						contents = [
							...contents,
							<div key={z._id}>
								{z.name}:{' '}
								<span style={{ color: zps.color.color }}>
									{zps.title}
								</span>
							</div>
						]
					}
				}
				return <div>{contents}</div>
			},
			accessor: (device) => {
				// Use first zone for now TODO find something better
				if (device && device.zones && device.zones.length > 0) {
					const zps = Utils.playStateDisplay(device, device.zones[0])
					return zps.value
				}
				return null
			}
		},
		freeSpace: {
			queryProps: ['freeSpace'],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.freeStorage'
			}),
			render: (device) =>
				device.freeSpace ? Utils.formatBytes(device.freeSpace, 1) : '',
			accessor: (device) => device.freeSpace,
			type: 'Number'
		},
		totalSpace: {
			queryProps: ['totalSpace'],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.totalStorage'
			}),
			render: (device) =>
				device.totalSpace ?
					Utils.formatBytes(device.totalSpace, 1)
				:	'',
			accessor: (device) => device.totalSpace,
			type: 'Number'
		},
		usedSpace: {
			queryProps: ['totalSpace', 'freeSpace'],
			name: this.props.intl.formatMessage({
				id: 'devices.table.columns.usedStorage'
			}),
			render: (device) =>
				device.totalSpace && device.freeSpace ?
					Utils.formatBytes(device.totalSpace - device.freeSpace, 1)
				:	'',
			accessor: (device) => device.totalSpace - device.freeSpace,
			type: 'Number'
		},
		mac: {
			queryProps: ['network', 'type'], // network is an object, comparing it doesnt trigger rendering, so we request a dummy property
			name: this.props.intl.formatMessage({ id: 'devices.info.mac' }),
			accessor: (device) => Utils.deviceMac(device),
			noFilter: true
		},
		updates: {
			name: this.props.intl.formatMessage({
				id: 'devices.info.actions.updates.title'
			}),
			queryProps: ['autoUpdateTime', 'autoUpdate'],
			accessor: (device) => Utils.updateTimeValue(device),
			render: (device) => Utils.displayUpdateTime(device),
			acl: 'device:read:autoUpdates',
			type: 'Number'
		},
		reboot: {
			name: this.props.intl.formatMessage({
				id: 'devices.info.actions.autoReboot.title'
			}),
			queryProps: ['autoRebootTime', 'autoReboot'],
			accessor: (device) => Utils.rebootTimeValue(device),
			render: (device) => Utils.displayRebootTime(device),
			acl: 'device:read:autoReboot',
			type: 'Number'
		},
		zone: {
			name: this.props.intl.formatMessage({ id: 'zone' }),
			accessor: (props) => props.zone.name,
			hiddenFromHeader: true
		},
		activationLink: {
			name: this.props.intl.formatMessage({
				id: 'teams.details.devices.preauth.column'
			}),
			queryProps: ['activationLink'],
			render: (device) => typeof device.activationLink === 'string'
		}
	}

	render() {
		const {
			redirect,
			link,
			match,
			selectedId,
			onSelect,
			title,
			actions,
			defaultColumns,
			defaultMobileColumns,
			storageKey,
			data,
			dontGreyOutPreactivated
		} = this.props
		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (match && match.params && match.params.deviceId) {
			sel = match.params.deviceId
		}

		return (
			<Table
				rowKey="_id"
				columns={this.columns}
				defaultColumns={defaultColumns}
				defaultMobileColumns={defaultMobileColumns}
				link={link}
				path="/devices"
				teamProp={(record) => record.team}
				nameProp={(record) => record.nickname || record.name}
				nameQueryProps={[
					'nickname',
					'name',
					'activationLink',
					'disabled'
				]}
				searchProps={(record) => [
					record.nickname,
					record.name,
					record.note,
					Utils.deviceMac(record)
				]}
				selectedId={sel}
				redirect={redirect}
				onSelect={onSelect}
				storageKey={storageKey}
				match={match}
				queryType="devices"
				title={title}
				actions={actions}
				data={data}
				rowDisabled={(d) =>
					(!dontGreyOutPreactivated && d.activationLink) || d.disabled
				}
			/>
		)
	}
}

export const DeviceListDisplay = injectIntl(IDeviceListDisplay)

class DeviceTable extends Component {
	state = {
		pairModal: false
	}

	render() {
		const {
			match,
			intl,
			tabletVersion,
			mobileVersion,
			wideMode,
			setWideMode,
			history
		} = this.props
		if (!match.params || !match.params.deviceId) {
			Utils.setPageTitle(intl, 'devices')
		}

		let actions = []
		if (Auth.hasAccess('device:add', Auth.cache.user.teamId)) {
			actions = [
				{
					action: () => this.setState({ pairModal: true }),
					title: <FormattedMessage id="devices.pair.action" />,
					icon: faLink,
					key: 'pairDevice'
				}
			]
		}
		if (!mobileVersion && !tabletVersion) {
			actions.push({
				action: () => {
					setWideMode(!wideMode)
					if (!wideMode) {
						history.push('/devices')
					}
				},
				title: (
					<FormattedMessage
						id={
							wideMode ? 'tables.wideModeOff' : (
								'tables.wideModeOn'
							)
						}
					/>
				),
				icon: wideMode ? faCompressArrowsAlt : faExpandArrowsAlt,
				key: 'wideMode'
			})
		}
		return (
			<React.Fragment>
				<DeviceListDisplay
					actions={actions}
					title={intl.formatMessage({ id: 'devices.table.title' })}
					match={match}
					redirect
					defaultColumns={['name', 'team', 'status', 'sync', 'os']}
					defaultMobileColumns={['name', 'status', 'sync', 'os']}
					extraQueryProps={['network']}
					storageKey="devicesList"
				/>
				{this.state.pairModal && (
					<PairDevice
						team={Auth.cache.team}
						onClose={(e) => this.setState({ pairModal: false })}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default withRouter(injectIntl(withResponsive(DeviceTable)))
