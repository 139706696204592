export default {
	pollInterval: 2000,
	longPollInterval: 10000,
	aws: {
		region: 'eu-west-3',
		s3: {
			buckets: {
				artworks: 'spectre.prod.artworks',
				artworksOptimized: 'spectre.prod.artworksOptimized',
				userAssets: 'spectre.prod.user-assets',
				messages: 'spectre.prod.messages.originals'
			},
			baseUrl: 'https://s3-eu-west-3.amazonaws.com/'
		}
	},
	cdn: {
		paths: {
			music: 'music.originals/',
			musicOptimized: 'music.optimized/',
			assets: 'app-assets/',
			artworks: 'artworks/',
			artworksOptimized: 'artworks.optimized/',
			userAssets: 'user-assets/',
			messages: 'messages.originals/',
			messagesOptimized: 'messages.optimized/'
		},
		baseUrl: 'https://cdn.spectre-music.com/'
	},
	intercom: {
		docsUrl: 'https://intercom.help/spectre-music',
		appId: 'uul2iavk'
	},
	stripe: {
		global: {
			pk: 'pk_live_iPAXctiexRs6WFoXuo8TZdYv'
		},
		apac: {
			pk: 'pk_live_51HJh8CCtFjdBaYEeKWL9oI2k4iLLArr1JI0Og3v2THWQGBjukq6xXTBOipxKHdYWt1cfINnEEtvcDQEhfPtCHZMr00QuyfkCPN'
		}
	},
	sonos: {
		baseUrl: 'https://api.sonos.com/',
		clientId: 'ca4f76c8-c8a9-41c1-8cd5-87b05a64d51f'
	},
	vauxhall: {
		baseUrl: 'https://vauxhall.spectre-music.com/'
	}
}
