import React, { Component } from 'react'
import { Alert, List, ConfigProvider, Radio } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

import Loading from '../../components/LoadingPage'
import * as Utils from '../../components/Utils'
import FormattedTimeSince from '../../utils/FormattedTimeSince'
import config from '../../config'

const SetupDevicesQuery = gql`
	query setupDevicesQuery {
		setupDevices {
			_id
			name
			type
			pin
			brand
			model
			OS
			OSVersion
			appVersion
			locale
			country
			timeZone
			simulator
			lastConnection
			network
			created
		}
	}
`

class SelectSetupDevice extends Component {
	delete = async (deviceId) => {
		try {
			const r = await this.props.client.mutate({
				variables: {
					deviceId
				},
				mutation: gql`
					mutation deleteSetupDevice($deviceId: MongoID!) {
						deleteSetupDevice(_id: $deviceId) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: SetupDevicesQuery
					}
				]
			})

			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl } = this.props

		return (
			<Query query={SetupDevicesQuery} pollInterval={config.pollInterval}>
				{({ loading, error, data }) => {
					if (data && data.setupDevices) {
						return (
							<ConfigProvider
								renderEmpty={() => (
									<div
										style={{
											width: '100%',
											height: 200,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'column'
										}}
									>
										<LoadingOutlined />
										<FormattedMessage id="devices.pair.awaitingDevices" />
									</div>
								)}
							>
								<Radio.Group
									name="devices"
									style={{ width: '100%' }}
									value={this.props.pin}
									onChange={this.props.onChange}
								>
									<List
										bordered
										dataSource={data.setupDevices}
										renderItem={(device) => {
											let actions = [
												<a
													onClick={() =>
														this.delete(device._id)
													}
													key="delete"
													title={intl.formatMessage({
														id: 'actions.delete'
													})}
												>
													<FontAwesomeIcon
														icon={faTrashAlt}
													/>
												</a>
											]
											return (
												<List.Item
													key={device._id}
													actions={actions}
													onClick={() =>
														this.props.onChange(
															device.pin,
															device.type
														)
													}
												>
													<List.Item.Meta
														title={
															<Radio
																value={
																	device.pin
																}
															>
																{device.name} (
																{device.pin})
															</Radio>
														}
														description={
															<div>
																<div>
																	<FontAwesomeIcon
																		icon={Utils.deviceTypeIcon(
																			device.OS
																		)}
																		style={{
																			width: 16
																		}}
																	/>
																	&nbsp;
																	{(
																		!!device.brand
																	) ?
																		`${device.brand} `
																	:	''}
																	{
																		device.model
																	}{' '}
																	{device.simulator && (
																		<FormattedMessage id="devices.info.simulator" />
																	)}
																</div>
																{device.timeZone && (
																	<div>
																		<FontAwesomeIcon
																			icon={
																				faGlobe
																			}
																			style={{
																				width: 16
																			}}
																		/>
																		&nbsp;
																		{
																			device.timeZone
																		}
																	</div>
																)}
																{device.lastConnection && (
																	<div>
																		<FontAwesomeIcon
																			icon={
																				faClock
																			}
																			style={{
																				width: 16
																			}}
																		/>
																		&nbsp;
																		<FormattedMessage
																			id="devices.pair.connectedOn"
																			values={{
																				date: (
																					<FormattedDate
																						value={
																							device.lastConnection
																						}
																					/>
																				),
																				relativeDate:
																					(
																						<FormattedTimeSince
																							date={
																								device.lastConnection
																							}
																						/>
																					)
																			}}
																		/>
																	</div>
																)}
															</div>
														}
													/>
												</List.Item>
											)
										}}
									/>
								</Radio.Group>
							</ConfigProvider>
						)
					}
					if (loading) {
						return (
							<Loading style={{ height: 200, minHeight: 200 }} />
						)
					}
					if (error) {
						return (
							<Alert
								message={intl.formatMessage({ id: 'error' })}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}
				}}
			</Query>
		)
	}
}

export default withApollo(injectIntl(SelectSetupDevice))
