import React, { Component } from 'react'
import { FormattedMessage, FormattedDate } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'

export default class Blame extends Component {
	render() {
		const { eventFilters } = this.props
		return (
			<Query
				query={gql`
					query fetchBlameHistories(
						$filter: FilterFindManyHistoryInput
					) {
						blameHistories(filter: $filter) {
							_id
							date
							username
						}
					}
				`}
				variables={{ filter: eventFilters }}
			>
				{({ data, error }) => {
					if (error) {
						console.log(error)
					}
					if (
						data &&
						data.blameHistories &&
						data.blameHistories.length > 0
					) {
						const h = data.blameHistories[0]
						if (h.username) {
							return (
								<FormattedMessage
									id={this.props.dateUserTitle}
									values={{
										username: h.username,
										date: (
											<FormattedDate
												value={h.date}
												year="numeric"
												month="short"
												day="2-digit"
												hour="2-digit"
												minute="2-digit"
											/>
										)
									}}
								/>
							)
						}
						return (
							<FormattedMessage
								id={this.props.dateTitle}
								values={{
									date: (
										<FormattedDate
											value={h.date}
											year="numeric"
											month="short"
											day="2-digit"
											hour="2-digit"
											minute="2-digit"
										/>
									)
								}}
							/>
						)
					}
					return null
				}}
			</Query>
		)
	}
}
