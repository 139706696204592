import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Input, Divider, Select } from 'antd'

import { Colors } from '../../../components/Styles'
import * as Utils from '../../../components/Utils'

class IAddressForm extends Component {
	render() {
		const { intl, onChange } = this.props

		return (
			<div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({ id: 'address.name' })}
						value={this.props.name}
						onChange={(e) => onChange({ name: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'address.line1'
						})}
						value={this.props.line1}
						onChange={(e) => onChange({ line1: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'address.line2'
						})}
						value={this.props.line2}
						onChange={(e) => onChange({ line2: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'address.postal_code'
						})}
						value={this.props.postal_code}
						onChange={(e) =>
							onChange({ postal_code: e.target.value })
						}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({ id: 'address.city' })}
						value={this.props.city}
						onChange={(e) => onChange({ city: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'address.state'
						})}
						value={this.props.state}
						onChange={(e) => onChange({ state: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'address.country'
						})}
						value={this.props.country}
						onChange={(e) => onChange({ country: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'teams.details.email'
						})}
						value={this.props.email}
						onChange={(e) => onChange({ email: e.target.value })}
					/>
				</div>
				<div style={styles.addressFormLine}>
					<Input
						placeholder={intl.formatMessage({
							id: 'billing.vat.taxId'
						})}
						value={this.props.taxId}
						onChange={(e) => onChange({ taxId: e.target.value })}
					/>
				</div>
				<div>
					<Select
						placeholder={intl.formatMessage({
							id: 'teams.details.language'
						})}
						value={this.props.language}
						onChange={(v) => onChange({ language: v })}
						style={{ width: '150px' }}
					>
						{Utils.languages.map((l) => (
							<Select.Option key={l} value={l}>
								<FormattedMessage id={`lang.${l}`} />
							</Select.Option>
						))}
					</Select>
				</div>
			</div>
		)
	}
}

export const AddressForm = injectIntl(IAddressForm)

const styles = {
	addressFormLine: {
		marginBottom: 5
	}
}

export class AddressDisplay extends Component {
	render() {
		const {
			name,
			line1,
			line2,
			state,
			postal_code,
			city,
			country,
			email,
			taxId,
			language
		} = this.props
		return (
			<div>
				<div>
					<b>{name}</b>
				</div>
				<div>{line1}</div>
				{line2 && <div>{line2}</div>}
				{state ?
					<div>
						{city}, {state} {postal_code}
					</div>
				:	<div>
						{postal_code} {city}
					</div>
				}
				<div>{country}</div>
				<div>{email}</div>
				<Divider style={{ marginTop: 10, marginBottom: 10 }} />
				<div>
					<FormattedMessage id="billing.vat.taxId" />:{' '}
					{taxId || (
						<span style={{ color: Colors.disabled.text }}>
							<FormattedMessage id="none" />
						</span>
					)}
				</div>
				<div>
					<FormattedMessage id="teams.details.language" />:{' '}
					<FormattedMessage id={`lang.${language || 'en'}`} />
				</div>
			</div>
		)
	}
}
