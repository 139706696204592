import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { Row, Col, Card, Button } from 'antd'
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faHistory, faAlignJustify } from '@fortawesome/free-solid-svg-icons'

import { deviceTypeIcon } from '../../components/Utils'
import DetailsProperty from '../../components/DetailsProperty'
import ActionsMenu from '../../components/ActionsMenu'
import History from '../tracks/PlayHistory'
import * as Auth from '../../auth'
import { isThirdPartyDeviceOnline } from '../../components/utils/thirdPartyDeviceUtils'
import S from '../../components/Styles'
import FormattedTimeSince from '../../utils/FormattedTimeSince'
import config from '../../config'

const vauxhallUrl = config.vauxhall.baseUrl
	.replace('https://', '')
	.replace('/', '')

class DeviceInfo extends Component {
	state = {
		historyModal: false,
		sshLoading: false,
		filePathModal: false,
		installDateLoading: false,
		locationPopup: false
	}

	render() {
		const { device, intl } = this.props
		let systemActions = []

		if (Auth.hasPermission('thirdPartyDevice:read:logs')) {
			systemActions.push({
				title: intl.formatMessage({
					id: 'thirdPartyDevices.info.actions.viewLogs'
				}),
				key: 'showLogs',
				icon: faAlignJustify,
				action: () =>
					window.open(
						`https://app.datadoghq.eu/logs?live=true&query_a=host%3A${vauxhallUrl}+%40thirdPartyDeviceId%3A${device._id}+OR+%40deviceUuid%3A${device.uuid}`,
						'_blank'
					)
			})
		}

		return (
			<div>
				<Row gutter={16}>
					<Col xs={24} md={12} style={S.mobileCol}>
						<Card
							title={intl.formatMessage({
								id: 'thirdPartyDevices.info.system'
							})}
							bodyStyle={S.card.body}
							extra={
								systemActions.length > 0 && (
									<ActionsMenu menuItems={systemActions} />
								)
							}
						>
							{Auth.hasPermission(
								'thirdPartyDevice:read:hostname'
							) && (
								<DetailsProperty title="thirdPartyDevices.info.hostname">
									{device.name}
								</DetailsProperty>
							)}
							{Auth.hasPermission(
								'thirdPartyDevice:read:model'
							) && (
								<DetailsProperty title="thirdPartyDevices.info.model">
									<Icon
										icon={deviceTypeIcon(device.OS)}
										style={styles.icon}
									/>
									&nbsp;
									{!!device.brand ? `${device.brand} ` : ''}
									{device.model}{' '}
									{device.simulator && (
										<FormattedMessage id="thirdPartyDevices.info.simulator" />
									)}
								</DetailsProperty>
							)}
							{Auth.hasPermission(
								'thirdPartyDevice:read:timeZone'
							) &&
								!!device.timeZone && (
									<DetailsProperty title="thirdPartyDevices.info.timeZone">
										{device.timeZone}
									</DetailsProperty>
								)}
							{Auth.hasPermission(
								'thirdPartyDevice:read:status'
							) &&
								isThirdPartyDeviceOnline(device) && (
									<DetailsProperty title="thirdPartyDevices.info.online">
										<FormattedMessage
											id="thirdPartyDevices.info.online.value"
											values={{
												date: (
													<FormattedDate
														value={
															device.onlineDate
														}
														year="numeric"
														month="short"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
													/>
												),
												relativeDate: (
													<FormattedTimeSince
														date={device.onlineDate}
													/>
												)
											}}
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission(
								'thirdPartyDevice:read:status'
							) &&
								!isThirdPartyDeviceOnline(device) && (
									<DetailsProperty title="thirdPartyDevices.info.online">
										<FormattedMessage
											id="thirdPartyDevices.info.offline.value"
											values={{
												date: (
													<FormattedDate
														value={
															device.onlineDate
														}
														year="numeric"
														month="short"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
													/>
												),
												relativeDate: (
													<FormattedTimeSince
														date={device.onlineDate}
													/>
												)
											}}
										/>
									</DetailsProperty>
								)}
						</Card>
					</Col>
					<Col xs={24} md={12} style={S.mobileCol}>
						<Card
							title={intl.formatMessage({
								id: 'thirdPartyDevices.info.content'
							})}
							bodyStyle={{ ...S.card.body, paddingBottom: 10 }}
						>
							{Auth.hasPermission(
								'thirdPartyDevice:read:history'
							) && (
								<Button
									onClick={() =>
										this.setState({ historyModal: true })
									}
								>
									<Icon icon={faHistory} />
									&nbsp;
									<FormattedMessage id="thirdPartyDevices.info.actions.logs" />
								</Button>
							)}
						</Card>
					</Col>
				</Row>
				{this.state.historyModal && (
					<History
						device={device}
						onClose={() => this.setState({ historyModal: false })}
					/>
				)}
			</div>
		)
	}
}

const styles = {
	icon: {
		width: 16
	},
	networkTitle: {
		width: '100%',
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 10
	},
	lineItem: {
		marginBottom: 10
	}
}

export default withApollo(injectIntl(DeviceInfo))
