import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import { Alert, Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import ActionsMenu from '../../components/ActionsMenu'
import Loading from '../../components/LoadingPage'
import S from '../../components/Styles'
import * as Constants from '../../components/Constants'
import * as Utils from '../../components/Utils'

class ITrackInfoDisplay extends Component {
	render() {
		const { title, actions, image, texts, loading, error, edit } =
			this.props
		const style = S.card.trackInfo
		return (
			<div>
				<div style={style.header}>
					<h2 style={style.title}>{title}</h2>
					{actions && (
						<ActionsMenu size="small" menuItems={actions} />
					)}
					{edit && (
						<Button size="small" onClick={edit}>
							<FontAwesomeIcon icon={faEdit} />
						</Button>
					)}
				</div>
				<div style={style.body}>
					{loading ?
						<Loading style={{ minHeight: 50 }} />
					: error ?
						<Alert
							message={this.props.intl.formatMessage({
								id: 'error'
							})}
							description={Utils.displayGraphQLErrors(error)}
							type="error"
							showIcon
						/>
					:	<React.Fragment>
							{image && (
								<img src={image} style={style.image} alt="" />
							)}
							{texts && (
								<div style={style.textContainer}>
									{texts.map((t, i) => (
										<div key={`${title}-${i}`}>{t}</div>
									))}
								</div>
							)}
						</React.Fragment>
					}
				</div>
			</div>
		)
	}
}

const TrackInfoDisplay = injectIntl(ITrackInfoDisplay)
export default TrackInfoDisplay

class ITrackQuery extends Component {
	render() {
		const { intl, playState, trackId, trackActions } = this.props
		if (trackId === 'silence') {
			return (
				<TrackInfoDisplay
					title={
						(
							playState === 'PLAYBACK_STATE_PLAYING' ||
							playState === Constants.PLAY_STATE_PLAYING ||
							playState === Constants.PLAY_STATE_PLAYING_MESSAGE
						) ?
							intl.formatMessage({
								id: 'devices.player.currentTrack'
							})
						:	intl.formatMessage({ id: 'devices.player.lastTrack' })
					}
					actions={[]}
					texts={[
						intl.formatMessage({
							id: 'devices.player.trackSilence'
						})
					]}
					loading={false}
				/>
			)
		} else if (trackId) {
			return (
				<Query
					variables={{ id: trackId }}
					query={gql`
						query fetchTrack($id: String!) {
							trackById(_id: $id) {
								_id
								title
								artist
								artwork
							}
						}
					`}
				>
					{({ loading, error, data }) => {
						return (
							<TrackInfoDisplay
								title={
									(
										playState ===
											'PLAYBACK_STATE_PlAYING' ||
										playState ===
											Constants.PLAY_STATE_PLAYING ||
										playState ===
											Constants.PLAY_STATE_PLAYING_MESSAGE
									) ?
										intl.formatMessage({
											id: 'devices.player.currentTrack'
										})
									:	intl.formatMessage({
											id: 'devices.player.lastTrack'
										})
								}
								actions={trackActions}
								image={
									data && data.trackById ?
										Utils.trackArtworkThumbUrl(
											data.trackById
										)
									:	null
								}
								texts={
									data && data.trackById ?
										[
											data.trackById.title,
											data.trackById.artist
										]
									:	[]
								}
								loading={loading}
								error={error}
							/>
						)
					}}
				</Query>
			)
		}
		return null
	}
}

export const TrackQuery = injectIntl(ITrackQuery)

class IPlaylistQuery extends Component {
	render() {
		const { intl, playlistId, playlistActions, playlistSubtitle } =
			this.props
		// TODO: understand why playlistId is equal to "undefined" (as a string)
		if (playlistId && playlistId !== 'undefined') {
			return (
				<Query
					variables={{ id: playlistId }}
					query={gql`
						query fetchProgramPlaylistForTrackInfo($id: MongoID!) {
							programPlaylistById(_id: $id) {
								_id
								name
								artwork
								crossfade
							}
						}
					`}
				>
					{({ loading, error, data }) => {
						return (
							<TrackInfoDisplay
								title={intl.formatMessage({
									id: 'devices.player.currentPlaylist'
								})}
								actions={playlistActions}
								image={
									(
										playlistId &&
										data &&
										data.programPlaylistById
									) ?
										Utils.playlistImageUrl(
											data.programPlaylistById
										)
									:	null
								}
								texts={
									playlistId ?
										data && data.programPlaylistById ?
											[
												data.programPlaylistById.name,
												playlistSubtitle
											]
										:	null
									:	[
											intl.formatMessage({
												id: 'devices.player.status.ranOut'
											})
										]
								}
								loading={loading}
								error={error}
							/>
						)
					}}
				</Query>
			)
		}
		return (
			<TrackInfoDisplay
				title={intl.formatMessage({ id: 'playlist' })}
				actions={playlistActions}
				texts={[]}
			/>
		)
	}
}

export const PlaylistQuery = injectIntl(IPlaylistQuery)
