import config from '../config'
import * as Utils from './Utils'

let preloadedPlayers = {}
let gTrack = null
let gPlayer = null
let extractTimeout = null
const extractDuration = 12

export let targetVolume = 1.0

export const setTargetVolume = (vol) => {
	targetVolume = vol
	if (gPlayer) {
		gPlayer.volume = vol
	}
}

export const playExtract = (newTrack) => {
	if (extractTimeout) {
		clearTimeout(extractTimeout)
	}

	const url =
		config.cdn ?
			`${config.cdn.baseUrl}${config.cdn.paths.musicOptimized}no-meta/stereo/${newTrack}`
		:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.musicOptimized}/no-meta/stereo/${newTrack}`
	let newPlayer = new Audio(url)
	newPlayer.addEventListener('loadeddata', async () => {
		let duration = newPlayer.duration
		newPlayer.currentTime = Math.max(0.0, (duration - extractDuration) / 2)

		if (gPlayer) {
			fadeOut(gPlayer, 3)
		}
		gTrack = newTrack
		gPlayer = newPlayer

		extractTimeout = setTimeout(async () => {
			if (gTrack === newTrack) {
				await fadeOut(gPlayer, 3)
				gTrack = null
				gPlayer = null
			}
		}, extractDuration * 1000)

		await fadeIn(newPlayer, 3)
	})
}

const getPlayerUrl = (track, message) => {
	let url
	if (config.cdn) {
		url =
			message ?
				`${config.cdn.baseUrl}${config.cdn.paths.messagesOptimized}no-meta/stereo/${track}`
			:	`${config.cdn.baseUrl}${config.cdn.paths.musicOptimized}no-meta/stereo/${track}`
	} else {
		url =
			message ?
				`${config.aws.s3.baseUrl}${config.aws.s3.buckets.messagesOptimized}/no-meta/stereo/${track}`
			:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.musicOptimized}/no-meta/stereo/${track}`
	}

	return url
}

export const preloadTrack = ({ track }) => {
	let url = getPlayerUrl(track)
	preloadedPlayers[track] = new Audio(url)
}

const getPreloadedTrack = (track, message) => {
	if (!message && preloadedPlayers[track]) {
		return preloadedPlayers[track]
	} else {
		const url = getPlayerUrl(track, message)
		return new Audio(url)
	}
}

export const startTrack = ({
	track,
	crossfade = 3,
	startTime = 0,
	endTime,
	message = false,
	endCallback,
	stopAtEnd = false,
	progressCallback
}) => {
	const onTimeUpdate = (event) => {
		// callback at the end of the track (either end of file or specified end time)
		if (event.target === gPlayer) {
			let end = endTime
			if (end === -1) {
				end = event.target.duration
			}
			end = end - crossfade

			if (event.target.currentTime >= end) {
				if (stopAtEnd || isiOS) {
					stop(crossfade)
				}
				if (endCallback) {
					endCallback()
				}
				event.target.removeEventListener('timeupdate', onTimeUpdate)
			} else if (progressCallback) {
				progressCallback(
					event.target.currentTime,
					event.target.duration
				)
			}
		}
	}

	const newPlayer = getPreloadedTrack(track, message)
	const isiOS = Utils.isClientIOS()
	if (isiOS) {
		crossfade = 0
	}

	if (gPlayer) {
		gPlayer.removeEventListener('timeupdate', onTimeUpdate)
		fadeOut(gPlayer, crossfade)
	}
	gTrack = track
	gPlayer = newPlayer

	newPlayer.currentTime = startTime

	newPlayer.addEventListener('timeupdate', onTimeUpdate)

	fadeIn(newPlayer, crossfade)
}

export const setVolumeSlider = (didSeek) => {
	if (gPlayer && didSeek) {
		gPlayer.addEventListener('timeupdate', (event) => {
			didSeek(event.target.currentTime, event.target)
		})
		return gPlayer
	}
	return null
}

export const seek = (time, player) => {
	if (gPlayer && gPlayer === player) {
		gPlayer.currentTime = time
	}
}

export const stop = async (fade = 1) => {
	const isiOS = Utils.isClientIOS()
	if (isiOS) {
		fade = 0
	}

	if (gPlayer) {
		let pl = gPlayer
		gPlayer = null
		gTrack = null
		await fadeOut(pl, fade)
	}
}

const fadeIn = (player, duration) => {
	return new Promise(async (resolve, reject) => {
		if (!duration) {
			player.volume = targetVolume
			try {
				player.play()
				resolve()
			} catch (e) {
				reject(e)
			}
		} else {
			setTimeout(resolve, duration * 1000)
			let vol = 0.0
			player.volume = vol
			try {
				player.play()
				let increment = 1.0 / (duration * 5)
				let fadeInAudio = setInterval(() => {
					if (vol >= targetVolume) {
						player.volume = targetVolume
						clearInterval(fadeInAudio)
					} else {
						vol = Math.min(vol + increment, targetVolume)
						player.volume = vol
					}
				}, 200)
				resolve()
			} catch (e) {
				reject(e)
			}
		}
	})
}

const fadeOut = (player, duration) => {
	return new Promise(async (resolve, _) => {
		if (!duration) {
			player.pause()
			resolve()
		} else {
			setTimeout(() => {
				player.pause()
				resolve()
			}, duration * 1000)
			let vol = targetVolume
			player.volume = vol

			let increment = 1.0 / (duration * 5)
			let fadeOutAudio = setInterval(() => {
				if (vol <= 0.0) {
					player.volume = 0.0
					clearInterval(fadeOutAudio)
				} else {
					vol = Math.max(vol - increment, 0.0)
					player.volume = vol
				}
			}, 200)
		}
	})
}
