import gql from 'graphql-tag'

export const DevicesTableQuery = gql`
	query devicesTableQuery {
		devices {
			_id
			name
			nickname
			OS
			type
			lastConnection
			onlineDate
			onlineState
			dlState
			disabled
			contentUpdated
			activationLink
			network
			team {
				_id
				n
				path
			}
			zones {
				_id
				name
				playState
				programUpdated
				messageProgramsUpdated
			}
		}
	}
`

export const DeviceDetailsQuery = gql`
	query fetchDeviceDetail($id: MongoID!) {
		deviceById(_id: $id) {
			_id
			uuid
			name
			nickname
			blacklist
			brand
			model
			OS
			type
			OSVersion
			appVersion
			latestVersion
			timeZone
			lastConnection
			contentUpdated
			totalSpace
			freeSpace
			hasSDCard
			forceUpdate
			simulator
			uptime
			teamId
			onlineDate
			onlineState
			dlState
			disabled
			activationLink
			downloading {
				downloadingTrackNumber
				totalDownloadingTracks
			}
			zoneIds
			autoUpdate
			autoUpdateTime
			autoReboot
			autoRebootTime
			network
			ssh
			temperature
			missingTracks
			load
			io
			safeMode
			location
			created
			installationDate
			zones {
				_id
				name
				index
				output
				playState
				programId
				programUpdated
				messageProgramsUpdated
				messageProgramIds
				stateProgramId
				statePlaylistId
				stateTrackId
				stateTrackStartTime
				stateMessageId
				stateManualPlaylist
				stateVolume
				stateOutputName
				stateOutputType
				stateGain
				stereo
				volumes
			}
			outputs {
				type
				channel
				name
			}
			maxZones
		}
	}
`

export const DeviceActionMutation = (device, zone, action, param) => ({
	variables: {
		id: device._id,
		zoneId: zone ? zone._id : null,
		command: action,
		param: param ? `${param}` : null
	},
	mutation: gql`
		mutation sendDeviceActionCommand(
			$id: String!
			$command: String
			$zoneId: String
			$param: String
		) {
			sendDeviceCommand(
				_id: $id
				zone: $zoneId
				command: $command
				param: $param
			) {
				success
				message
			}
		}
	`
})
