export const publicGraphqlQueries = {
	queryFetchSampleToListen: `
		query fetchSampleToListen($id: String!) {
			sampleById(_id: $id) {
				_id
				name
				tracks {
					trackId
					start
					end
				}
				trackObjects {
					_id
					title
					artist
					duration
					artwork
					comment
				}
				showTrackNotes
			}
		}
	`,
	mutationCreateSampleReview: `
		mutation createSampleReview($email: String, $tracks: [SampleReviewTracksInput], $sampleId: String, $date: Date, $comment: String, $name: String) {
			sampleReviewCreate(record: {
				email: $email,
				tracks: $tracks,
				sampleId: $sampleId,
				updated: $date,
				comment: $comment,
				name: $name
			}) {
				recordId
			}
		}
	`
}
