import React from 'react'
import { Button } from 'antd'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUserLock,
	faExclamationCircle,
	faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl'
import { Colors } from './Styles'
import AppLayout from './PageLayout'
import * as Auth from '../auth'

class Error extends React.Component {
	logout = (_) => {
		const match = this.props.match
		Auth.logout()
		this.props.client.resetStore()
		if (match && match.params && match.params.code && match.params.error) {
			this.props.history.push(`/login/${match.params.error}`)
		} else {
			this.props.history.push(`/login`)
		}
	}

	render() {
		const { match, hideMenu } = this.props
		let code, error
		if (match) {
			code = match.params.code
			error = match.params.error
		}

		let content
		console.log(match, code, error)
		if (code === '401') {
			content = (
				<div style={styles.container}>
					<FontAwesomeIcon
						icon={faUserLock}
						style={{
							fontSize: 150,
							color: Colors.emptyIcons,
							marginBottom: 20
						}}
					/>
					<h3>
						<FormattedMessage id="Error" />:{' '}
						<FormattedMessage id="401" />
					</h3>
					<Button onClick={this.logout}>
						<FormattedMessage id="account.logout" />
					</Button>
				</div>
			)
		} else if (code === '200') {
			content = (
				<div style={styles.container}>
					<FontAwesomeIcon
						icon={faCheckCircle}
						style={{
							fontSize: 150,
							color: Colors.emptyIcons,
							marginBottom: 20
						}}
					/>
					<h3 style={{ textAlign: 'center' }}>
						{error && <FormattedMessage id={error} />}
					</h3>
				</div>
			)
		} else {
			content = (
				<div style={styles.container}>
					<FontAwesomeIcon
						icon={faExclamationCircle}
						style={{
							fontSize: 150,
							color: Colors.emptyIcons,
							marginBottom: 20
						}}
					/>
					<h3 style={{ textAlign: 'center' }}>
						<FormattedMessage id="error" />
						&nbsp;
						{error && (
							<React.Fragment>
								<br />
								<FormattedMessage id={error} />
							</React.Fragment>
						)}
					</h3>
				</div>
			)
		}

		if (hideMenu) {
			return content
		}
		return <AppLayout match={match}>{content}</AppLayout>
	}
}

export default withApollo(withRouter(Error))

const styles = {
	container: {
		width: '100%',
		minHeight: 400,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}
