import React, { Component } from 'react'
import { Form, Input, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import Logo from '../../components/SpectreLogo'
import { forgotPassword } from '../../auth'
import S from '../../components/Styles'
import { setPageTitle } from '../../components/Utils'

const FormItem = Form.Item

class ForgotPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			message: false
		}
	}

	handleSubmit = async (values) => {
		this.setState({ loading: true })
		const result = await forgotPassword({
			...values,
			invite: 'onResetPassword'
		})
		this.setState({ loading: false })
		if (result.success) {
			this.setState({ message: true })
		}
	}

	render() {
		setPageTitle(this.props.intl, 'auth.forgotPassword.title')
		return (
			<div>
				<div style={S.login.bgImage}></div>
				<div style={S.login.bg}>
					<div style={S.login.container} className="login">
						<Form onFinish={this.handleSubmit} style={S.login.form}>
							<div style={S.login.logoContainer}>
								<Logo color="#000" style={{ margin: '10px' }} />
							</div>
							<p>
								<FormattedMessage id="auth.resetPassword.info" />
							</p>
							<FormItem
								name="username"
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: 'auth.error.noUsername'
										})
									}
								]}
							>
								<Input
									prefix={
										<FontAwesomeIcon
											icon={faUser}
											style={S.login.fieldIcon}
										/>
									}
									placeholder={this.props.intl.formatMessage({
										id: 'auth.fields.username'
									})}
									autoCorrect="off"
									autoCapitalize="none"
									type="email"
								/>
							</FormItem>
							<FormItem>
								<Button
									type="primary"
									htmlType="submit"
									style={S.login.submit}
									loading={this.state.loading}
								>
									<FormattedMessage id="auth.resetPassword.send" />
								</Button>
							</FormItem>
							{this.state.message && (
								<p>
									<FormattedMessage id="auth.resetPassword.confirmation" />
								</p>
							)}
						</Form>
					</div>
				</div>
				<div style={{ width: '100%', height: '100%' }}>
					<FontAwesomeIcon
						size="2x"
						icon={faQuestionCircle}
						id="help"
						className="absoluteHelp"
					/>
				</div>
			</div>
		)
	}
}

export default injectIntl(ForgotPassword)
