import moment from 'moment'

// Check if date1 is the dame day or the day following date2
export const isSameOrNextDay = (date1, date2) => {
	const d1 = moment(date1)
	const d2 = moment(date2)
	return (
		d1.isSameOrAfter(d2.startOf('day')) &&
		d1.isSameOrBefore(d2.add(1, 'day').endOf('day'))
	)
}
