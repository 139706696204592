import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Alert, Divider, Modal, Tag } from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
	faUsers,
	faEdit,
	faTrash,
	faClipboard,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons'

import Loading from '../../components/LoadingPage'
import config from '../../config'
import Title from '../../components/Title'
import TextInputModal from '../../components/TextInputModal'
import { SelectTeamModal } from '../teams/SelectTeam'
import { setPageTitle } from '../../components/Utils'
import {
	ThirdPartyDeviceDetailsQuery,
	ThirdPartyDevicesTableQuery
} from './Queries'
import S from '../../components/Styles'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'
import ThirdPartyDeviceStatus from './ThirdPartyDeviceStatus'
import Comments from '../../components/CommentsPage'
import ThirdPartyDevicePlayer from './ThirdPartyDevicePlayer'
import ThirdPartyDeviceInfo from './ThirdPartyDeviceInfo'

const partialErrorsTranslations = {
	HOUSEHOLD_ID_NOT_FOUND: 'thirdPartyDevices.error.houseOldIdNotFound'
}

class IThirdPartyDeviceDetails extends Component {
	state = {
		renameModal: false,
		selectTeamModal: false
	}

	render() {
		const { thirdPartyDevice, intl, client } = this.props
		setPageTitle(
			intl,
			null,
			thirdPartyDevice.nickname || thirdPartyDevice.name
		)

		let actions = []
		if (
			Auth.hasPermission('thirdPartyDevice:edit:setName') &&
			Auth.hasAccess('thirdPartyDevice:edit', thirdPartyDevice.teamId)
		) {
			actions.push({
				title: intl.formatMessage({ id: 'actions.rename' }),
				key: 'renameDevice',
				icon: faEdit,
				action: () => this.setState({ renameModal: true })
			})
		}
		if (
			Auth.hasPermission('thirdPartyDevice:edit:setTeam') &&
			Auth.hasAccess('thirdPartyDevice:edit', thirdPartyDevice.teamId)
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'actions.assignToTeam'
				}),
				key: 'assignDevice',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: true })
			})
		}
		if (
			Auth.hasAccess('thirdPartyDevice:delete', thirdPartyDevice.teamId)
		) {
			actions.push({
				title: this.props.intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteDevice',
				dataTest: 'delete-thirdPartyDevice',
				icon: faTrash,
				action: () => {
					Modal.confirm({
						title: intl.formatMessage(
							{ id: 'devices.actions.delete.confirm' },
							{
								name:
									thirdPartyDevice.nickname ||
									thirdPartyDevice.name ||
									''
							}
						),
						okText: intl.formatMessage({ id: 'yes' }),
						okType: 'danger',
						cancelText: intl.formatMessage({ id: 'no' }),
						onOk: async () => {
							try {
								// remove thirdPartyDevice
								const dr = await client.mutate({
									variables: { id: thirdPartyDevice._id },
									mutation: gql`
										mutation removeThirdPartyDevice(
											$id: MongoID!
										) {
											thirdPartyDeviceRemoveById(
												_id: $id
											) {
												recordId
											}
										}
									`,
									refetchQueries: [
										{
											query: ThirdPartyDevicesTableQuery
										},
										{
											query: ThirdPartyDeviceDetailsQuery,
											variables: {
												id: thirdPartyDevice._id
											}
										}
									]
								})
								Utils.parseMutationResponse(dr)
							} catch (e) {
								Utils.displayError(e)
							}
						}
					})
				}
			})
		}

		return (
			<div>
				<Title
					title={thirdPartyDevice.nickname || thirdPartyDevice.name}
					actions={actions}
					backUrl="/thirdpartydevices"
					backTitle="thirdPartyDevices"
					wideMode={this.props.wideMode}
					dataTest="main-player-menu"
				>
					<div style={S.itemDetails.status}>
						<ThirdPartyDeviceStatus
							onlineDate={thirdPartyDevice.onlineDate}
						/>
					</div>
					{thirdPartyDevice.team && (
						<div style={S.itemDetails.teamPath}>
							<Link to={`/teams/${thirdPartyDevice.team._id}`}>
								<Tag color="blue" style={{ margin: 0 }}>
									{thirdPartyDevice.team.path}
								</Tag>
							</Link>
						</div>
					)}
				</Title>
				<ThirdPartyDevicePlayer thirdPartyDevice={thirdPartyDevice} />
				{this.state.renameModal && (
					<TextInputModal
						initialValue={
							thirdPartyDevice.nickname || thirdPartyDevice.name
						}
						title={this.props.intl.formatMessage({
							id: 'devices.actions.renameDevice'
						})}
						actionLabel={this.props.intl.formatMessage({
							id: 'actions.rename'
						})}
						placeholder={this.props.intl.formatMessage({
							id: 'thirdPartyDevice'
						})}
						onClose={() => this.setState({ renameModal: false })}
						mutation={(name) => ({
							variables: { id: thirdPartyDevice._id, name },
							mutation: gql`
								mutation updateThirdPartyDeviceNickname(
									$id: MongoID!
									$name: String!
								) {
									thirdPartyDeviceUpdateById(
										_id: $id
										record: { nickname: $name }
									) {
										recordId
									}
								}
							`
						})}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={this.props.intl.formatMessage({
							id: 'devices.actions.assignDeviceToTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={thirdPartyDevice.teamId}
						onSelect={async (t) => {
							try {
								if (t && t !== thirdPartyDevice.teamId) {
									const r = await client.mutate({
										variables: {
											id: thirdPartyDevice._id,
											parent: t
										},
										mutation: gql`
											mutation updateThirdPartyDeviceTeam(
												$id: MongoID!
												$parent: String!
											) {
												thirdPartyDeviceUpdateById(
													_id: $id
													record: { teamId: $parent }
												) {
													recordId
												}
											}
										`,
										refetchQueries: [
											{
												query: ThirdPartyDevicesTableQuery
											}
										]
									})
									Utils.parseMutationResponse(r)
								}
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{
					<React.Fragment>
						<Divider orientation="left">
							<Icon icon={faInfoCircle} />{' '}
							<FormattedMessage id="thirdPartyDevices.details.deviceInfo" />
						</Divider>
						<ThirdPartyDeviceInfo device={thirdPartyDevice} />
					</React.Fragment>
				}
				{Auth.hasPermission('device:read:note') && (
					<React.Fragment>
						<Divider orientation="left">
							<Icon icon={faClipboard} />{' '}
							<FormattedMessage id="spectreNotes" />
						</Divider>
						<Comments
							objectId={thirdPartyDevice._id}
							writePermission="thirdPartyDevices:edit:setNote"
						/>
					</React.Fragment>
				)}
			</div>
		)
	}
}

export const DeviceDetails = withApollo(injectIntl(IThirdPartyDeviceDetails))

// Default exported class
class ThirdPartyDeviceDetailsFetch extends Component {
	render() {
		const thirdPartyDeviceId = this.props.match.params.thirdPartyDeviceId
		return (
			<React.Fragment>
				{thirdPartyDeviceId && (
					<Query
						pollInterval={config.pollInterval}
						query={ThirdPartyDeviceDetailsQuery}
						variables={{ id: thirdPartyDeviceId }}
						errorPolicy="all"
					>
						{({ loading, error, data }) => {
							if (
								(!data || !data.thirdPartyDeviceById) &&
								loading
							) {
								return <Loading />
							}
							const partialError =
								error?.graphQLErrors?.length > 0 &&
								error.graphQLErrors[0].message
							if (
								error &&
								!partialErrorsTranslations[partialError]
							) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							} else if (!data.thirdPartyDeviceById) {
								return <Redirect to="/thirdpartydevices" />
							}

							return (
								<DeviceAdditionalDetailsFetch
									thirdPartyDevice={data.thirdPartyDeviceById}
									wideMode={this.props.wideMode}
									partialError={partialError}
								/>
							)
						}}
					</Query>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(ThirdPartyDeviceDetailsFetch)

const DeviceAdditionalDetailsFetch = injectIntl(
	({ intl, wideMode, thirdPartyDevice, partialError }) => (
		<>
			{partialError && (
				<>
					<Alert
						message={intl.formatMessage({
							id: 'error'
						})}
						description={intl.formatMessage({
							id: partialErrorsTranslations[partialError]
						})}
						type="error"
					/>
					<Divider />
				</>
			)}
			<Query
				query={gql`
					query fetchThirdPartyDeviceAdditionalDetails(
						$team: String!
						$programs: [String!]!
					) {
						teamById(_id: $team) {
							_id
							path
							messagesEnabled
							parentMessagesEnabled
							thirdPartyDevicesEnabled
							parentThirdPartyDevicesEnabled
							deviceTtl
						}
						programs: programByIds(_ids: $programs) {
							_id
							name
							updatedAt
							scheduleReset
							playlists {
								_id
								name
								canForceTrack
							}
						}
					}
				`}
				variables={{
					team: thirdPartyDevice.teamId.toString(),
					programs: thirdPartyDevice.programIds
				}}
			>
				{({ loading, error, data }) => {
					if (!data || loading) {
						return <Loading />
					}
					if (error) {
						return (
							<Alert
								message={intl.formatMessage({
									id: 'error'
								})}
								description={Utils.displayGraphQLErrors(error)}
								type="error"
								showIcon
							/>
						)
					}

					const extendedThirdPartyDevice = {
						...thirdPartyDevice,
						team: data.teamById || thirdPartyDevice.team,
						programs: data.programs || thirdPartyDevice.programs
					}

					return (
						<DeviceDetails
							thirdPartyDevice={extendedThirdPartyDevice}
							wideMode={wideMode}
						/>
					)
				}}
			</Query>
		</>
	)
)
