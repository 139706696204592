import React, { Component } from 'react'
import { Modal, Form, Collapse, Switch } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import VolumeSlider from './VolumeSlider'
import PlayerOutput from './PlayerOutput'
import * as Auth from '../../auth'
import { DeviceActionMutation, DeviceDetailsQuery } from '../devices/Queries'
import { formItemLayout } from '../../components/Styles'
import * as Utils from '../../components/Utils'

const Panel = Collapse.Panel

class OutputSettings extends Component {
	state = {
		volumes: this.props.zone.volumes || {},
		stereoLoading: false
	}

	volumeChanged = async (v, type) => {
		const { zone, device, client } = this.props
		try {
			let volumes = {}
			if (type === 'messages') {
				// overwrite all message volumes
				volumes = {
					messages: v,
					music: this.state.volumes.music || 2,
					gain: this.state.volumes.gain || 2
				}
			} else {
				volumes = { ...this.state.volumes }
				volumes[type] = v
			}

			this.setState({ volumes })
			const response = await client.mutate({
				variables: {
					id: device._id,
					zoneId: zone._id,
					param: JSON.stringify(volumes)
				},
				mutation: gql`
					mutation sendDeviceVolume(
						$id: String!
						$zoneId: String
						$param: String
					) {
						sendDeviceVolume(
							_id: $id
							zone: $zoneId
							param: $param
						) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(response)
		} catch (e) {
			this.setState({ volumes: zone.volumes })
			Utils.displayError(e)
		}
	}

	stereoChanged = async (newValue) => {
		const { device, zone, client } = this.props
		try {
			this.setState({ stereoLoading: true })
			const response = await client.mutate({
				variables: { id: zone._id, stereo: newValue },
				mutation: gql`
					mutation updateZoneStereo($id: String!, $stereo: Boolean) {
						zoneUpdateById(_id: $id, record: { stereo: $stereo }) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(response)
			this.setState({ stereoLoading: false })
		} catch (e) {
			Utils.displayError(e)
			this.setState({ stereoLoading: false })
		}
	}

	martiniVolumeChanged = async (v) => {
		const { zone, device, client } = this.props
		try {
			const response = await client.mutate(
				DeviceActionMutation(device, zone, 'setVolume', `${v - 1}`)
			)
			Utils.parseMutationResponse(response)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	largoVolumeChanged = async (newVolume, type) => {
		const { zone, device, client } = this.props
		const volumes = {
			music: newVolume
		}
		try {
			const response = await client.mutate({
				variables: {
					id: device._id,
					zoneId: zone._id,
					param: JSON.stringify(volumes)
				},
				mutation: gql`
					mutation sendDeviceVolume(
						$id: String!
						$zoneId: String
						$param: String
					) {
						sendDeviceVolume(
							_id: $id
							zone: $zoneId
							param: $param
						) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(response)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	renderMessagesVolumes = () => {
		const { zone, device } = this.props
		const { volumes } = this.state
		return (
			<React.Fragment>
				{zone.messagePrograms.map((mp) => {
					if (mp && mp.medias && mp.medias.length > 0) {
						return (
							<React.Fragment key={mp._id}>
								{mp.medias
									.filter((media) => media !== null)
									.map((m) => (
										<Form.Item
											label={
												m.name ?
													m.name.replace('.m4a', '')
												:	''
											}
											key={`volume-${m._id}`}
											{...formItemLayout}
										>
											<VolumeSlider
												style={{ width: '45%' }}
												volume={
													volumes ?
														volumes[m._id] ||
														volumes.messages ||
														2
													:	2
												}
												disabled={
													!Auth.hasPermission(
														'device:edit:setZoneVolume'
													) ||
													!Auth.hasAccess(
														'device:edit',
														device.teamId
													)
												}
												volumeChanged={(v) =>
													this.volumeChanged(v, m._id)
												}
											/>
										</Form.Item>
									))}
							</React.Fragment>
						)
					}
					return null
				})}
			</React.Fragment>
		)
	}

	render() {
		const { intl, onClose, device, zone } = this.props
		const { volumes } = this.state

		const isOnline = Utils.isDeviceOnline(device)

		return (
			<Modal
				visible={true}
				title={intl.formatMessage(
					{ id: 'devices.player.zone.outputSettings.title' },
					{ zone: zone.name, device: device.nickname || device.name }
				)}
				onCancel={onClose}
				footer={[]}
				width="80vw"
			>
				<Form>
					<Form.Item
						label={intl.formatMessage({
							id: 'devices.player.zone.output'
						})}
						{...formItemLayout}
					>
						<PlayerOutput
							disabled={
								!Auth.hasPermission('zone:edit:setOutput') ||
								!Auth.hasAccess('zone:edit', device.teamId)
							}
							device={device}
							zone={zone}
						/>
						{Auth.hasPermission('zone:edit:setStereo') &&
							Auth.hasAccess('device:edit', device.teamId) && (
								<span style={{ marginLeft: 20 }}>
									<Switch
										checked={zone.stereo}
										onChange={this.stereoChanged}
										loading={this.state.stereoLoading}
									/>
									&nbsp;&nbsp;
									<FormattedMessage id="devices.player.stereo" />
								</span>
							)}
					</Form.Item>
					{
						// If Drax
						Utils.isDeviceDrax(device) &&
							Utils.isDeviceVersionAbove(device, '2.3.0') && (
								<React.Fragment>
									<Form.Item
										label={intl.formatMessage({
											id: 'devices.player.volume.master'
										})}
										{...formItemLayout}
									>
										<VolumeSlider
											style={{ width: '90%' }}
											disabled={
												!Auth.hasPermission(
													'device:edit:setZoneVolume'
												) ||
												!Auth.hasAccess(
													'device:edit',
													device.teamId
												)
											}
											volumeChanged={(v) =>
												this.volumeChanged(v, 'gain')
											}
											volume={
												volumes ?
													volumes.gain || 2
												:	zone.stateGain / 100 || 2
											}
											canHaveGain
											showWarnings
										/>
									</Form.Item>
									<Form.Item
										label={
											<span>
												<FontAwesomeIcon
													icon={faMusic}
												/>
												&nbsp;&nbsp;
												<FormattedMessage id="music" />
											</span>
										}
										{...formItemLayout}
									>
										<VolumeSlider
											style={{ width: '45%' }}
											disabled={
												!Auth.hasPermission(
													'device:edit:setZoneVolume'
												) ||
												!Auth.hasAccess(
													'device:edit',
													device.teamId
												)
											}
											volumeChanged={(v) =>
												this.volumeChanged(v, 'music')
											}
											volume={
												volumes ?
													volumes.music || 2
												:	zone.stateVolume || 2
											}
										/>
									</Form.Item>
									{device.type !== 'martini' &&
										zone.messagePrograms &&
										zone.messagePrograms.length > 0 && (
											<React.Fragment>
												{(
													Utils.isDeviceVersionAbove(
														device,
														'2.6.5'
													)
												) ?
													<React.Fragment>
														<Form.Item
															label={
																<span>
																	<FontAwesomeIcon
																		icon={
																			faCommentAlt
																		}
																	/>
																	&nbsp;&nbsp;
																	<FormattedMessage id="messages" />
																</span>
															}
															key="volume-messages"
															{...formItemLayout}
														>
															<VolumeSlider
																style={{
																	width: '45%'
																}}
																volume={
																	(
																		volumes &&
																		volumes.messages
																	) ?
																		volumes.messages
																	:	2
																}
																disabled={
																	!Auth.hasPermission(
																		'device:edit:setZoneVolume'
																	) ||
																	!Auth.hasAccess(
																		'device:edit',
																		device.teamId
																	)
																}
																volumeChanged={(
																	v
																) =>
																	this.volumeChanged(
																		v,
																		'messages'
																	)
																}
															/>
														</Form.Item>
														<Collapse>
															<Panel
																header={intl.formatMessage(
																	{
																		id: 'messages'
																	}
																)}
																key="msgs"
															>
																{this.renderMessagesVolumes()}
															</Panel>
														</Collapse>
													</React.Fragment>
												:	this.renderMessagesVolumes()}
											</React.Fragment>
										)}
								</React.Fragment>
							)
					}
					{
						// if Martini
						device.type === 'martini' && (
							<Form.Item
								label={intl.formatMessage({
									id: 'devices.player.volume'
								})}
								{...formItemLayout}
							>
								<VolumeSlider
									volume={zone.stateVolume}
									disabled={
										!Auth.hasPermission(
											'device:edit:setZoneVolume'
										) ||
										!Auth.hasAccess(
											'device:edit',
											device.teamId
										) ||
										!isOnline
									}
									volumeChanged={this.martiniVolumeChanged}
								/>
							</Form.Item>
						)
					}
					{
						// if Largo
						device.type === 'largo' && (
							<Form.Item
								label={intl.formatMessage({
									id: 'devices.player.volume'
								})}
								{...formItemLayout}
							>
								<VolumeSlider
									volume={zone.volumes?.music}
									disabled={
										!Auth.hasPermission(
											'device:edit:setZoneVolume'
										) ||
										!Auth.hasAccess(
											'device:edit',
											device.teamId
										) ||
										!isOnline
									}
									volumeChanged={this.largoVolumeChanged}
								/>
							</Form.Item>
						)
					}
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(OutputSettings))
