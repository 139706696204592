import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Form, Input, Button, Collapse, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

import * as Utils from '../../components/Utils'
import AssignPlaylist from '../programs/AssignPlaylist'
import { SelectTeamV2 } from '../teams/SelectTeam'
import { SamplesListQuery, SampleDetailsQuery } from './Queries'

class EditSampleModal extends Component {
	formRef = React.createRef()

	state = {
		loading: false,
		team: null,
		playlist: null
	}

	componentDidMount() {
		if (this.props.sample) {
			this.setState({
				team: this.props.sample.team,
				playlist: this.props.sample.playlist
			})
		}
	}

	save = async () => {
		const { client, history, onClose, sample, duplicate } = this.props
		if (!this.state.team) {
			message.error(
				this.props.intl.formatMessage({ id: 'server.TEAM_NOT_FOUND' })
			)
			return
		}
		if (!duplicate && !this.state.playlist) {
			return
		}

		this.setState({ loading: true })

		const values = await this.formRef.current.validateFields()
		try {
			let res
			if (sample && !duplicate) {
				const r = await client.mutate({
					variables: {
						id: sample._id,
						name: values.name,
						teamId: this.state.team._id,
						playlistId:
							this.state.playlist.playlistOrPoolId ||
							this.state.playlist._id
					},
					mutation: gql`
						mutation updateSample(
							$id: String!
							$name: String!
							$teamId: String
							$playlistId: String!
						) {
							sampleUpdateById(
								_id: $id
								name: $name
								teamId: $teamId
								playlistId: $playlistId
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: SamplesListQuery
						},
						{
							query: SampleDetailsQuery,
							variables: { id: sample._id }
						}
					],
					awaitRefetchQueries: true
				})

				Utils.parseMutationResponse(r)
				res = r.data.sampleUpdateById
			} else if (duplicate) {
				const r = await client.mutate({
					variables: {
						name: values.name,
						teamId: this.state.team._id,
						duplicateId: sample._id
					},
					mutation: gql`
						mutation duplicateSample(
							$name: String!
							$teamId: String
							$duplicateId: String!
						) {
							sampleDuplicate(
								name: $name
								teamId: $teamId
								duplicateId: $duplicateId
							) {
								recordId
							}
						}
					`,
					refetchQueries: [{ query: SamplesListQuery }]
				})

				Utils.parseMutationResponse(r)
				res = r.data.sampleDuplicate
			} else {
				const r = await client.mutate({
					variables: {
						name: values.name,
						teamId: this.state.team._id,
						playlistId: this.state.playlist.playlistOrPoolId
					},
					mutation: gql`
						mutation createSample(
							$name: String!
							$teamId: String
							$playlistId: String!
						) {
							sampleCreate(
								name: $name
								teamId: $teamId
								playlistId: $playlistId
							) {
								recordId
							}
						}
					`,
					refetchQueries: [{ query: SamplesListQuery }]
				})

				Utils.parseMutationResponse(r)
				res = r.data.sampleCreate
			}

			this.setState({ loading: false })
			if (res) {
				if (res.recordId) {
					// redirect to sample details
					onClose()
					history.push(`/samples/${res.recordId}`)
				} else if (res.message) {
					throw new Error(res.message)
				}
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, sample, duplicate } = this.props

		let footer = [
			<Button
				type="primary"
				htmlType="submit"
				key="submit"
				onClick={this.save}
				loading={this.state.loading}
			>
				<FormattedMessage
					id={sample ? 'actions.save' : 'actions.create'}
				/>
			</Button>
		]

		return (
			<Modal
				visible={true}
				width="80vw"
				title={
					duplicate ?
						intl.formatMessage({
							id: 'samples.actions.duplicateSample'
						})
					: sample ?
						intl.formatMessage(
							{ id: 'samples.actions.editSample' },
							{ name: sample.name }
						)
					:	intl.formatMessage({ id: 'samples.actions.newSample' })
				}
				onCancel={this.props.onClose}
				footer={footer}
			>
				<Form ref={this.formRef}>
					<Form.Item
						name="name"
						initialValue={
							sample &&
							(duplicate ? sample.name + ' copy' : sample.name)
						}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'playlists.edit.noName'
								})
							}
						]}
						label={intl.formatMessage({ id: 'name' })}
					>
						<Input
							onChange={() => this.setState({ edited: true })}
						/>
					</Form.Item>

					<Form.Item
						label={
							<React.Fragment>
								<FontAwesomeIcon icon={faUsers} />
								&nbsp;&nbsp;
								<FormattedMessage id="team" />
							</React.Fragment>
						}
					>
						<div data-test="edit-sample-select-team">
							<SelectTeamV2
								onChange={(team) => this.setState({ team })}
								selected={
									this.state.team ? this.state.team._id : null
								}
								keyprops="_id"
								hideNoParents
							/>
						</div>
					</Form.Item>

					{!duplicate && (
						<Collapse
							className="column-browser"
							accordion
							activeKey="1"
							style={{ width: '100%' }}
						>
							<Collapse.Panel
								header={
									<span>
										<b>
											<FormattedMessage id="playlists.edit.assignPlaylist" />
											:
										</b>
									</span>
								}
								key="1"
							>
								<AssignPlaylist
									playlist={{ playlist: this.state.playlist }}
									style={{ height: 400 }}
									onChange={(v) => {
										if (v) {
											this.setState({
												playlist: v,
												edited: true
											})
										} else {
											this.setState({
												playlist: null,
												edited: true
											})
										}
									}}
								/>
							</Collapse.Panel>
						</Collapse>
					)}
				</Form>
			</Modal>
		)
	}
}

export default withRouter(withApollo(injectIntl(EditSampleModal)))
