import React, { Component } from 'react'
import uniq from 'lodash/uniq'
import { Modal, Button, Alert, Table, Checkbox, Input } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import difference from 'lodash/difference'
import { SearchOutlined } from '@ant-design/icons'

import Loading from '../../components/LoadingPage'
import * as Utils from '../../components/Utils'

class SelectZones extends Component {
	state = {
		loading: false,
		loadingSync: false,
		selected: this.props.selected || [],
		zoneFilter: ''
	}

	handleSubmit = async () => {
		this.setState({ loading: true })
		await this.props.onSave(
			this.state.selected.filter((d) => d.indexOf('device-') === -1),
			difference(this.props.selected, this.state.selected).filter(
				(d) => d.indexOf('device-') === -1
			),
			false
		)
		this.setState({ loading: false })
		this.props.onClose()
	}

	handleSubmitSync = async () => {
		this.setState({ loadingSync: true })
		await this.props.onSave(
			this.state.selected.filter((d) => d.indexOf('device-') === -1),
			difference(this.props.selected, this.state.selected).filter(
				(d) => d.indexOf('device-') === -1
			),
			true
		)
		this.setState({ loadingSync: false })
		this.props.onClose()
	}

	formatData = (data) => {
		let devices = []
		let deviceIds = []

		for (let team of data) {
			for (let d of team.devices) {
				if (deviceIds.indexOf(d._id) === -1) {
					let device
					if (d.zones.length === 1) {
						let zone = d.zones[0]
						device = {
							key: zone._id,
							name: d.nickname || d.name,
							messageProgramsEnabled:
								team.messagesEnabled ||
								team.parentMessagesEnabled,
							type: d.type
						}
						if (zone.program) {
							device.program = zone.program.name
						}
						if (zone.messagePrograms) {
							device.messagePrograms = (
								<ul>
									{zone.messagePrograms.map((mp) => (
										<li key={mp._id}>{mp.name}</li>
									))}
								</ul>
							)
						}
					} else {
						device = {
							key: `device-${d._id}`,
							name: d.nickname || d.name,
							messageProgramsEnabled:
								team.messagesEnabled ||
								team.parentMessagesEnabled,
							type: d.type,
							children: d.zones.map((z) => ({
								key: z._id,
								name: z.name,
								program: z.program ? z.program.name : null,
								messagePrograms:
									z.messagePrograms ?
										<ul>
											{z.messagePrograms.map((mp) => (
												<li key={mp._id}>{mp.name}</li>
											))}
										</ul>
									:	null
							}))
						}
					}
					devices.push(device)
					deviceIds.push(d._id)
				}
			}
		}
		return devices
	}

	render() {
		const { intl, title, onClose, teams, property } = this.props
		const rowSelection = {
			onChange: (selectedRowKeys) => {
				this.setState({ selected: selectedRowKeys })
			},
			selectedRowKeys: this.state.selected,
			getCheckboxProps: (record) => {
				if (this.props.isRowDisabled) {
					const disabled = this.props.isRowDisabled(record)
					if (disabled) {
						return { disabled: true }
					}
				}
				if (record.key.indexOf('device-') > -1) {
					return { disabled: true }
				}
				return null
			}
		}

		const columns = [
			{
				title: intl.formatMessage({ id: 'name' }),
				dataIndex: 'name',
				key: 'name',
				filteredValue: [this.state.zoneFilter],
				filterDropdown: ({
					setSelectedKeys,
					selectedKeys,
					confirm
				}) => (
					<div
						style={{ padding: 8 }}
						onKeyDown={(e) => {
							e.stopPropagation()
							// if the user presses escape then clear and close the dropdown
							if (e.key === 'Escape') {
								this.setState({
									zoneFilter: ''
								})
								confirm()
							}
						}}
					>
						<Input
							ref={(node) => {
								this.searchInput = node
							}}
							placeholder={intl.formatMessage({
								id: 'tables.filters.filterByDevice'
							})}
							style={{
								display: 'block'
							}}
							onChange={(e) => {
								this.setState({
									zoneFilter: e.target.value
								})
							}}
							onPressEnter={() => {
								// close the dropdown on pressing enter
								confirm()
							}}
						/>
					</div>
				),
				filterIcon: (filtered) => (
					<div
						style={{
							display: 'inline-flex',
							alignItems: 'center'
						}}
					>
						{this.state.zoneFilter && (
							<div style={{ marginRight: 8 }}>
								<FormattedMessage
									id="tables.filters.showingResults"
									values={{
										filter: this.state.zoneFilter,
										i: (chunks) => <i>{chunks}</i>
									}}
								/>
							</div>
						)}
						<SearchOutlined
							style={{ color: filtered ? '#1677ff' : undefined }}
						/>
					</div>
				),
				onFilter: (value, record) => {
					if (!this.state.zoneFilter) {
						return true
					} else {
						return record.name
							.toLowerCase()
							.includes(this.state.zoneFilter.toLowerCase())
					}
				},
				onFilterDropdownVisibleChange: (visible) => {
					if (visible) {
						// focus on the input after the dropdown opens
						setTimeout(() => {
							this.searchInput.focus()
						}, 0)
					}
				}
			},
			{
				title: intl.formatMessage({ id: property }),
				dataIndex: property,
				key: property
			}
		]

		return (
			<Modal
				visible={true}
				title={title}
				onCancel={onClose}
				width="80vw"
				bodyStyle={{ padding: 0 }}
				footer={[
					<Button
						key="save"
						type="default"
						onClick={this.handleSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.save" />
					</Button>,
					<Button
						key="saveSync"
						type="primary"
						onClick={this.handleSubmitSync}
						loading={this.state.loadingSync}
					>
						<FormattedMessage id="devices.player.changePrograms.saveAndSync" />
					</Button>
				]}
			>
				<Query query={this.props.query} variables={{ teams }}>
					{({ loading, error, data }) => {
						if (data && data.teams) {
							return (
								<Table
									columns={columns}
									pagination={false}
									rowSelection={rowSelection}
									dataSource={this.formatData(data.teams)}
									defaultExpandAllRows={true}
									onFilterZones={(zoneFilter) => {
										this.setState({ zoneFilter })
									}}
								/>
							)
						}
						if (loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={Utils.displayGraphQLErrors(
										error
									)}
									type="error"
									showIcon
								/>
							)
						}
					}}
				</Query>
			</Modal>
		)
	}
}

export default injectIntl(SelectZones)
