import React from 'react'
import { FormattedRelativeTime } from 'react-intl'
import { timeAgo } from './date'

export default function FormattedTimeSince({ date }) {
	// some values like onlineDate can be null
	if (!date) {
		return null
	}

	const { delta, rangeType } = timeAgo(date)

	return <FormattedRelativeTime value={delta} unit={rangeType} />
}
