import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

export default (props) => (
	<div style={{ ...styles.container, ...props.style }}>
		<LoadingOutlined />
	</div>
)

const styles = {
	container: {
		width: '100%',
		minHeight: 400,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}
