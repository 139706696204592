import React, { Component } from 'react'
import { Layout } from 'antd'
import { Mobile, Default } from '../Responsive'

class ResponsiveNav extends Component {
	state = {
		menuVisible: false
	}

	handleMenuVisibility = (menuVisible) => {
		this.setState({ menuVisible })
	}

	render() {
		const MenuMarkup = this.props.menuMarkup

		return (
			<div>
				<Default>
					<Layout.Sider
						width={60}
						style={{
							overflow: 'auto',
							height: '100vh',
							position: 'fixed',
							left: 0
						}}
					>
						<MenuMarkup match={this.props.match} />
					</Layout.Sider>
				</Default>

				<Mobile>
					<Layout.Header
						style={{ padding: 0 }}
						className="main-menu-mobile"
					>
						<MenuMarkup match={this.props.match} mobileVersion />
					</Layout.Header>
				</Mobile>
			</div>
		)
	}
}

ResponsiveNav.defaultProps = {
	placement: 'bottom'
}

export default ResponsiveNav
