import React, { Component } from 'react'
import { Modal, Collapse, List } from 'antd'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'

import { DeviceDetailsQuery, DeviceActionMutation } from './Queries'
import TZ from '../../resources/timezones'
import * as Utils from '../../components/Utils'

const Panel = Collapse.Panel

class TimeZone extends Component {
	state = {
		error: null,
		zone: this.props.device.timeZone,
		restart: false
	}

	handleCancel = () => {
		this.props.onClose()
	}

	handleTimeChange = async (value) => {
		const { device, client, onClose, intl } = this.props
		this.setState({ zone: value })
		try {
			const r = await client.mutate({
				variables: {
					device: device._id,
					timeZone: value
				},
				mutation: gql`
					mutation updateDeviceTimezone(
						$device: MongoID!
						$timeZone: String!
					) {
						deviceUpdateById(
							_id: $device
							record: { timeZone: $timeZone }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				]
			})
			Utils.parseMutationResponse(r)

			if (Utils.isDeviceOnline(device)) {
				const dr = await client.mutate(
					DeviceActionMutation(device, null, 'options')
				)
				Utils.parseMutationResponse(dr)
				Modal.confirm({
					title: intl.formatMessage({
						id: 'devices.info.actions.setTimeZone'
					}),
					content: intl.formatMessage({
						id: 'devices.info.timeZone.restart'
					}),
					okText: intl.formatMessage({
						id: 'devices.info.timeZone.restart.now'
					}),
					cancelText: intl.formatMessage({
						id: 'devices.info.timeZone.restart.later'
					}),
					onOk: this.restart,
					onCancel: onClose
				})
			} else {
				onClose()
			}
		} catch (e) {
			Utils.displayError(e)
		}
	}

	restart = async () => {
		try {
			const { device, client, onClose } = this.props
			const r = await client.mutate({
				variables: { id: device._id, command: 'restart' },
				mutation: gql`
					mutation sendDeviceCommandRestart(
						$id: String!
						$command: String!
					) {
						sendDeviceCommand(_id: $id, command: $command) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(r)
			onClose()
		} catch (e) {
			Utils.displayError(e)
		}
	}

	zoneRegion = (z) => {
		for (let group of TZ) {
			for (let zone of group.zones) {
				if (zone.value === z) {
					return group.group
				}
			}
		}
		return null
	}

	render() {
		const { intl } = this.props
		const region = this.zoneRegion(this.state.zone)

		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id: 'devices.info.actions.setTimeZone'
				})}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<Collapse
					className="collapse-no-padding"
					accordion
					defaultActiveKey={region}
					style={{ width: '100%' }}
				>
					{TZ.map((region) => (
						<Panel
							header={region.group}
							key={region.group}
							style={{ padding: 0 }}
						>
							<List
								size="small"
								dataSource={region.zones}
								renderItem={(item) => (
									<List.Item
										className={
											this.state.zone === item.value ?
												'table-row-selected'
											:	''
										}
										style={{
											paddingLeft: 15,
											paddingRight: 15,
											cursor: 'pointer'
										}}
										onClick={() =>
											this.handleTimeChange(item.value)
										}
									>
										{item.name}
									</List.Item>
								)}
							/>
						</Panel>
					))}
				</Collapse>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(TimeZone))
