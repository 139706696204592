import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Form, Input, Button, Collapse, Switch, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as Utils from '../../components/Utils'
import AssignPlaylist from './AssignPlaylist'
import S from '../../components/Styles'
import { ProgramDetailsQuery } from './Queries'

class EditPlaylistModal extends Component {
	formRef = React.createRef()

	state = {
		loading: false,
		canForceTracks: false,
		image: null,
		playlistId: null,
		edited: false
	}

	handleCancel = () => {
		const { intl, playlist, onClose } = this.props
		if (
			playlist &&
			(this.formRef.current.isFieldsTouched() ||
				this.state.image ||
				this.state.canForceTracks !== playlist.canForceTrack) &&
			this.state.edited
		) {
			Modal.confirm({
				title: intl.formatMessage(
					{ id: 'playlists.edit.confirm' },
					{ playlist: playlist.name }
				),
				okText: intl.formatMessage({ id: 'actions.save' }),
				cancelText: intl.formatMessage({ id: 'actions.cancel' }),
				onOk: async () => {
					await this.save()
					onClose()
				},
				onCancel: onClose
			})
		} else {
			onClose()
		}
	}

	componentDidMount() {
		if (this.props.playlist) {
			this.setState({
				canForceTracks: this.props.playlist.canForceTrack,
				playlistId:
					this.props.playlist.playlist ?
						this.props.playlist.playlist._id
					:	null
			})
		}
	}

	onClickSubmit = async (e) => {
		e.preventDefault()
		await this.save()
		this.props.onClose()
	}

	save = async () => {
		this.setState({ loading: true })
		const { client, playlist, program } = this.props
		try {
			const values = await this.formRef.current.validateFields()
			if (playlist) {
				const r = await client.mutate({
					variables: {
						id: playlist._id,
						name: values.name,
						playlistId: this.state.playlistId,
						canForceTrack: this.state.canForceTracks
					},
					mutation: gql`
						mutation updateProgramPlaylistDetail(
							$id: MongoID!
							$name: String!
							$playlistId: String
							$canForceTrack: Boolean
						) {
							programPlaylistUpdateById(
								_id: $id
								record: {
									name: $name
									playlistId: $playlistId
									canForceTrack: $canForceTrack
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: ProgramDetailsQuery,
							variables: { id: program._id }
						}
					],
					awaitRefetchQueries: true
				})
				Utils.parseMutationResponse(r)
				this.setState({ loading: false })
			} else {
				const r = await client.mutate({
					variables: {
						program: program._id,
						name: values.name,
						playlistId: this.state.playlistId,
						canForceTrack: this.state.canForceTracks
					},
					mutation: gql`
						mutation createProgramPlaylist(
							$program: String!
							$name: String!
							$playlistId: String
							$canForceTrack: Boolean
						) {
							programPlaylistCreate(
								record: {
									programId: $program
									name: $name
									crossfade: 3
									playlistId: $playlistId
									canForceTrack: $canForceTrack
								}
							) {
								recordId
							}
						}
					`
				})
				Utils.parseMutationResponse(r)

				if (
					r.data &&
					r.data.programPlaylistCreate &&
					r.data.programPlaylistCreate.recordId
				) {
					if (this.tempFile) {
						// we have a playlist id so now we can upload the image
						await this.uploadImage(
							this.tempFile,
							r.data.programPlaylistCreate.recordId
						)
					}

					const dr = await client.mutate({
						variables: {
							program: program._id,
							ids: [
								...program.programPlaylistIds,
								r.data.programPlaylistCreate.recordId
							]
						},
						mutation: gql`
							mutation updateProgramPlaylistsList(
								$program: String!
								$ids: [String]!
							) {
								programUpdateById(
									_id: $program
									record: { programPlaylistIds: $ids }
								) {
									recordId
								}
							}
						`,
						refetchQueries: [
							{
								query: ProgramDetailsQuery,
								variables: { id: program._id }
							}
						],
						awaitRefetchQueries: true
					})
					Utils.parseMutationResponse(dr)
					this.setState({ loading: false })
				}
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
			throw e
		}
	}

	onSelectFile = async (e) => {
		const file = e.target.files[0]
		if (file && this.props.playlist) {
			await this.uploadImage(file, this.props.playlist._id)
		} else {
			this.tempFile = file
			this.setState({ image: URL.createObjectURL(file) })
		}
	}

	uploadImage = async (file, playlistId) => {
		this.setState({ imageUploading: true })

		try {
			const result = await this.props.client.mutate({
				variables: { file, id: playlistId },
				mutation: gql`
					mutation uploadProgramPlaylistImage(
						$file: Upload!
						$id: String
					) {
						programPlaylistUploadImage(file: $file, id: $id) {
							success
							message
							url
						}
					}
				`
			})
			Utils.parseMutationResponse(result)
			this.setState({
				imageUploading: false,
				edited: true,
				image: Utils.playlistOriginalImageUrl(
					result.data.programPlaylistUploadImage.url
				)
			})
		} catch (e) {
			this.setState({ imageUploading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, playlist } = this.props

		let footer = [
			<Button
				type="primary"
				htmlType="submit"
				key="submit"
				onClick={this.onClickSubmit}
				loading={this.state.loading}
			>
				<FormattedMessage
					id={playlist ? 'actions.save' : 'actions.create'}
				/>
			</Button>
		]

		return (
			<Modal
				visible={true}
				width="80vw"
				title={
					playlist ?
						intl.formatMessage(
							{ id: 'playlists.actions.edit' },
							{ name: playlist.name }
						)
					:	intl.formatMessage({ id: 'playlists.actions.add' })
				}
				onCancel={this.handleCancel}
				footer={footer}
			>
				<Form ref={this.formRef}>
					<Row gutter={20}>
						<Col md={20} xs={24}>
							<Form.Item
								name="name"
								initialValue={playlist && playlist.name}
								rules={[
									{
										required: true,
										message: intl.formatMessage({
											id: 'playlists.edit.noName'
										})
									}
								]}
								label={intl.formatMessage({ id: 'name' })}
							>
								<Input
									onChange={() =>
										this.setState({ edited: true })
									}
								/>
							</Form.Item>
							<Form.Item>
								<Switch
									checked={this.state.canForceTracks}
									onChange={(v) =>
										this.setState({
											canForceTracks: v,
											edited: true
										})
									}
								/>
								&nbsp;&nbsp;
								<FormattedMessage id="playlists.edit.canForceTrack" />
							</Form.Item>
						</Col>
						<Col md={4} xs={24}>
							<Form.Item
								label={intl.formatMessage({
									id: 'playlists.edit.image'
								})}
							>
								<Button style={{ width: 100, height: 100 }}>
									<img
										src={
											this.state.image ?
												this.state.image
											:	Utils.playlistImageUrl(
													this.props.playlist
												)
										}
										style={S.playlistDetails.image}
										alt=""
									/>
									<input
										type="file"
										accept="image/*"
										ref={(e) => (this.fileInput = e)}
										style={S.playlistDetails.fileInput}
										onChange={this.onSelectFile}
									/>
									{this.state.imageUploading && (
										<LoadingOutlined
											style={S.playlistDetails.spinner}
										/>
									)}
								</Button>
							</Form.Item>
						</Col>
					</Row>

					<Collapse
						className="column-browser"
						accordion
						activeKey="1"
						style={{ width: '100%' }}
					>
						<Collapse.Panel
							header={
								<span>
									<b>
										<FormattedMessage id="playlists.edit.assignPlaylist" />
										:
									</b>{' '}
									{playlist && playlist.playlist ?
										playlist.playlist.n
									:	''}
								</span>
							}
							key="1"
						>
							<AssignPlaylist
								playlist={playlist}
								isCurrentPlaylistEnable
								style={{ height: 400 }}
								onChange={(v) => {
									if (v) {
										this.setState({
											playlistId: v.playlistOrPoolId,
											edited: true
										})
									} else {
										this.setState({
											playlistId: null,
											edited: true
										})
									}
								}}
							/>
						</Collapse.Panel>
					</Collapse>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(EditPlaylistModal))
