import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { Row, Col, Card, Button, Alert, Statistic } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import * as Utils from '../../../components/Utils'
import S, { Colors } from '../../../components/Styles'
import Loading from '../../../components/LoadingPage'
import * as Auth from '../../../auth'
import Plan from './Plan'
import NewPlan from './NewPlan'
import { TeamBillingQuery } from '../Queries'
import Address from './Address'
import Invoices from './Invoices'
import PaymentMethods from './PaymentMethods'

class IBillingDisplay extends Component {
	state = {
		planModal: false,
		newPlanModal: false
	}

	render() {
		const { team, subscription, intl } = this.props
		let content

		if (!subscription) {
			content = (
				<div
					style={{
						...S.empty,
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div>
						<FormattedMessage id="billing.empty" />
					</div>
					{Auth.hasPermission('subscription:edit:setBillingPlan') &&
						Auth.hasAccess('team:edit', team._id) && (
							<Button
								onClick={() =>
									this.setState({ newPlanModal: true })
								}
								style={{ marginTop: 20 }}
							>
								<FontAwesomeIcon icon={faEdit} />
								&nbsp;
								<FormattedMessage id="billing.setup" />
							</Button>
						)}
				</div>
			)
		} else if (subscription.teamId !== team._id) {
			content = (
				<div
					style={{
						...S.empty,
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div>
						<p>
							<b>
								<FormattedMessage id="billing.disabled" />
							</b>
						</p>
						<p>
							<FormattedMessage id={`server.BILLING_PARENT`} />{' '}
							<b>{subscription.team.n}</b>
						</p>
					</div>
				</div>
			)
		} else {
			let total = 0
			if (subscription.items) {
				for (let p of subscription.items) {
					total += p.quantity * p.price
				}
			}

			content = (
				<React.Fragment>
					<Row gutter={16}>
						{Auth.hasPermission('team:read:billingPlan') && (
							<Col xs={24} md={12} style={S.mobileCol}>
								<Card
									title={intl.formatMessage({
										id: 'billing.plan'
									})}
									bodyStyle={{
										...S.card.body,
										paddingBottom: 10
									}}
									extra={
										Auth.hasPermission(
											'subscription:edit:setBillingPlan'
										) && (
											<Button
												onClick={() =>
													this.setState({
														planModal: true
													})
												}
											>
												<FontAwesomeIcon
													icon={faEdit}
												/>
											</Button>
										)
									}
								>
									<Statistic
										value={`${intl.formatNumber(total, { style: 'currency', currency: subscription.currency })} ${intl.formatMessage({ id: 'billing.excludingTax' })} / ${subscription.intervalCount > 1 ? `${subscription.intervalCount} ` : ``}${intl.formatMessage({ id: `RRule.${Utils.rruleIntervalFromStripe(subscription.interval)}` }, { count: subscription.intervalCount })}`}
									/>
									<div>
										<FormattedMessage
											id="billing.plan.netInvoicing"
											values={{
												days: subscription.daysUntilDue
											}}
										/>
									</div>
									<div>
										<FormattedMessage id="billing.vat.applicableVat" />
										:{' '}
										{subscription.taxRate ?
											subscription.taxRate.percentage
										:	0}
										%
									</div>
									{Auth.hasPermission(
										'subscription:edit:setBillingPlan'
									) && (
										<React.Fragment>
											<div>
												<FormattedMessage id="billing.vat.invoiceFooter" />
												:{' '}
												{subscription.invoiceFooter || (
													<span
														style={{
															color: Colors
																.disabled.text
														}}
													>
														<FormattedMessage id="nonef" />
													</span>
												)}
											</div>
											<div>
												<FormattedMessage id="billing.subsidiary" />
												:{' '}
												<FormattedMessage
													id={`billing.subsidiary.${subscription.subsidiary || 'global'}`}
												/>
											</div>
										</React.Fragment>
									)}
								</Card>
							</Col>
						)}
						{Auth.hasPermission(
							'subscription:read:billingAddress'
						) && (
							<Col xs={24} md={12} style={S.mobileCol}>
								<Address
									subscription={subscription}
									team={team}
								/>
							</Col>
						)}
					</Row>
					{Auth.hasPermission('subscription:read:paymentMethods') && (
						<PaymentMethods
							team={team}
							subscription={subscription}
						/>
					)}
					{Auth.hasPermission('subscription:read:invoices') && (
						<Invoices team={team} subscription={subscription} />
					)}
				</React.Fragment>
			)
		}

		return (
			<React.Fragment>
				{content}
				{this.state.planModal && (
					<Plan
						team={team}
						subscription={subscription}
						onClose={() => this.setState({ planModal: false })}
					/>
				)}
				{this.state.newPlanModal && (
					<NewPlan
						team={team}
						onClose={() => this.setState({ newPlanModal: false })}
					/>
				)}
			</React.Fragment>
		)
	}
}

const BillingDisplay = withApollo(injectIntl(IBillingDisplay))

class BillingQuery extends Component {
	render() {
		const { team, intl } = this.props

		return (
			<React.Fragment>
				{team && (
					<Query
						query={TeamBillingQuery}
						variables={{ id: team._id }}
					>
						{({ loading, error, data }) => {
							if ((!data || !data.teamById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							return (
								<BillingDisplay
									team={team}
									subscription={data.teamById.subscription}
								/>
							)
						}}
					</Query>
				)}
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(BillingQuery))
