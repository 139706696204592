import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Card, Button, Alert } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import S from '../../../components/Styles'
import * as Utils from '../../../components/Utils'
import { TeamBillingQuery } from '../Queries'
import * as Auth from '../../../auth'
import { AddressForm, AddressDisplay } from './AddressDisplay'

class Address extends Component {
	state = {
		loading: false,
		editing: false
	}

	edit = () => {
		const { subscription, team } = this.props
		const customerInfo = JSON.parse(subscription.customerInfo)
		let address, name, email, taxId, language
		if (customerInfo) {
			if (customerInfo.shipping?.address) {
				address = customerInfo.shipping.address
				if (address.line1 === ' ') {
					address.line1 = null
				}
				name = customerInfo.shipping.name || team.n
			}
			email = customerInfo.email
			if (customerInfo.tax_ids.total_count > 0) {
				taxId = customerInfo.tax_ids.data[0].value
			}
			if (
				customerInfo.preferred_locales &&
				customerInfo.preferred_locales.length
			) {
				language = customerInfo.preferred_locales[0]
			}

			this.setState({
				name,
				email,
				...address,
				taxId,
				language,
				editing: true
			})
		}
	}

	handleSubmit = async (e) => {
		try {
			this.setState({ loading: true })

			const r = await this.props.client.mutate({
				variables: {
					id: this.props.subscription._id,
					address: JSON.stringify({
						shipping: {
							name: this.state.name,
							address: {
								line1: this.state.line1,
								line2: this.state.line2,
								postal_code: this.state.postal_code,
								city: this.state.city,
								state: this.state.state,
								country: this.state.country
							}
						},
						email: this.state.email,
						preferred_locales: [this.state.language]
					}),
					taxId: this.state.taxId
				},
				mutation: gql`
					mutation updateSubscriptionAddress(
						$id: String!
						$address: String
						$taxId: String
					) {
						subscriptionUpdateAddress(
							_id: $id
							address: $address
							taxId: $taxId
						) {
							success
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: TeamBillingQuery,
						variables: { id: this.props.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)

			this.setState({ loading: false, editing: false })
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, subscription } = this.props
		const customerInfo = JSON.parse(subscription.customerInfo)

		return (
			<Card
				title={intl.formatMessage({ id: 'billing.address' })}
				bodyStyle={{ ...S.card.body, paddingBottom: 10 }}
				extra={
					Auth.hasPermission('team:edit:setBillingAddress') &&
					(this.state.editing ?
						<Button
							loading={this.state.loading}
							type="primary"
							onClick={this.handleSubmit}
						>
							<FormattedMessage id="actions.save" />
						</Button>
					:	<Button onClick={this.edit}>
							<FontAwesomeIcon icon={faEdit} />
						</Button>)
				}
			>
				{this.state.editing ?
					<AddressForm
						{...this.state}
						onChange={(s) => this.setState(s)}
					/>
				:	<React.Fragment>
						{(
							customerInfo &&
							customerInfo.shipping &&
							customerInfo.shipping.address &&
							customerInfo.shipping.address.line1 !== ' '
						) ?
							<AddressDisplay
								name={customerInfo.shipping.name}
								{...customerInfo.shipping.address}
								email={customerInfo.email}
								taxId={
									customerInfo.tax_ids.total_count > 0 ?
										customerInfo.tax_ids.data[0].value
									:	null
								}
								language={
									(
										customerInfo.preferred_locales &&
										customerInfo.preferred_locales.length
									) ?
										customerInfo.preferred_locales[0]
									:	null
								}
							/>
						:	<Alert
								description={intl.formatMessage({
									id: 'billing.address.empty'
								})}
								type="warning"
								showIcon
							/>
						}
					</React.Fragment>
				}
			</Card>
		)
	}
}

export default withApollo(injectIntl(Address))
