import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import { Alert } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import {
	faPlus,
	faUser,
	faHdd,
	faMusic,
	faSearch
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../components/LoadingPage'
import Table from '../../components/Table'
import TextInputModal from '../../components/TextInputModal'
import Title from '../../components/Title'
import config from '../../config'
import { setPageTitle, displayGraphQLErrors } from '../../components/Utils'
import { TeamsTreeQuery } from './Queries'
import * as Auth from '../../auth'
import { Mobile, Default } from '../../components/Responsive'
import SearchUser from '../users/SearchUser'
import * as TeamUtils from './TeamUtils'

const countPrograms = (team) => {
	let programCount = 0
	if (team.programs) {
		programCount += team.programs.length
	}
	if (team.messagesEnabled && team.messagePrograms) {
		programCount += team.messagePrograms.length
	}
	return programCount
}

export class TeamsListDisplay extends Component {
	render() {
		const {
			teams,
			showDevices,
			showPrograms,
			showUsers,
			redirect,
			link,
			match,
			selectedId,
			ignoreUrl,
			onSelect,
			searchKey,
			keyboardShortcuts
		} = this.props
		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (!ignoreUrl && match && match.params && match.params.teamId) {
			sel = match.params.teamId
		}

		let rootNode = null
		let data
		if (teams) {
			if (!Auth.hasAccess('team:browse', 'all')) {
				rootNode = Auth.cache.team._id
			}
			data = TeamUtils.buildTeamTree(teams, rootNode)
		}

		let columns = []

		if (showUsers) {
			columns.push({
				title: <FontAwesomeIcon icon={faUser} />,
				align: 'center',
				key: 'users',
				render: (r) => (r.users ? r.users.length : 0),
				sorter: (a, b) => {
					const aa = a.users ? a.users.length : 0
					const bb = b.users ? b.users.length : 0
					return aa - bb
				}
			})
		}
		if (showDevices) {
			columns.push({
				title: <FontAwesomeIcon icon={faHdd} />,
				align: 'center',
				key: 'devices',
				render: (r) =>
					`${r.devices ? r.devices.length : 0}${r.maxDevices ? `/${r.maxDevices}` : ''}`,
				sorter: (a, b) => {
					const aa = a.devices ? a.devices.length : 0
					const bb = b.devices ? b.devices.length : 0
					return aa - bb
				}
			})
		}
		if (showPrograms) {
			columns.push({
				title: (
					<React.Fragment>
						<FontAwesomeIcon icon={faMusic} />
						&nbsp;&nbsp;
						<FontAwesomeIcon icon={faCommentAlt} />
					</React.Fragment>
				),
				align: 'center',
				key: 'programs',
				render: (team) => countPrograms(team),
				sorter: (a, b) => {
					const aa = countPrograms(a)
					const bb = countPrograms(b)
					return aa - bb
				}
			})
		}

		return (
			<React.Fragment>
				<Mobile>
					<Table
						rowKey="_id"
						columns={[]}
						dataSource={data}
						link={link}
						path="/teams"
						nameProp={(record) => record.n}
						renderName={(r) => {
							if (Auth.cache.team._id === r._id) {
								return (
									<strong>
										{r.n}{' '}
										<FormattedMessage id="teams.tree.myTeam" />
									</strong>
								)
							}
							return r.n
						}}
						selectedId={sel}
						redirect={redirect}
						sortKey="teamsTableSort"
						onSelect={onSelect}
						searchKey={searchKey}
					/>
				</Mobile>
				<Default>
					<Table
						rowKey="_id"
						columns={columns}
						dataSource={data}
						link={link}
						path="/teams"
						nameProp={(record) => record.n}
						renderName={(r) => {
							if (Auth.cache.team._id === r._id) {
								return (
									<Link
										className="greyLink"
										to={`/teams/${r._id}`}
									>
										<strong>
											{r.n}{' '}
											<FormattedMessage id="teams.tree.myTeam" />
										</strong>
									</Link>
								)
							}
							return (
								<Link
									className="greyLink"
									to={`/teams/${r._id}`}
								>
									{r.n}
								</Link>
							)
						}}
						selectedId={sel}
						redirect={redirect}
						sortKey="teamsTableSort"
						onSelect={onSelect}
						searchKey={searchKey}
						keyboardShortcuts={keyboardShortcuts}
					/>
				</Default>
			</React.Fragment>
		)
	}
}

class TeamsListFetch extends Component {
	state = {
		addTeamModal: false,
		searchUserModal: false
	}

	render() {
		const { match, mobileVersion, intl } = this.props
		if (!match.params || !match.params.teamId) {
			setPageTitle(intl, 'teams')
		}

		let actions = []
		if (Auth.hasAccess('team:add', 'all')) {
			actions.push({
				title: intl.formatMessage({ id: 'teams.tree.addTeam' }),
				key: 'addTeam',
				icon: faPlus,
				action: () => this.setState({ addTeamModal: true })
			})
		}
		if (Auth.hasAccess('user:read:search')) {
			actions.push({
				title: intl.formatMessage({ id: 'teams.tree.searchUsers' }),
				key: 'searchUser',
				icon: faSearch,
				action: () => this.setState({ searchUserModal: true })
			})
		}

		return (
			<div>
				<Title
					title={intl.formatMessage({ id: 'teams.tree.title' })}
					actions={actions}
					left={true}
					dataTest="teams-title-section"
				/>
				{this.state.addTeamModal && (
					<TextInputModal
						initialValue=""
						title={intl.formatMessage({
							id: 'teams.actions.newTeamName'
						})}
						actionLabel={intl.formatMessage({
							id: 'actions.create'
						})}
						placeholder={intl.formatMessage({ id: 'team' })}
						onClose={() => this.setState({ addTeamModal: false })}
						mutation={(name) => ({
							variables: { name },
							mutation: gql`
								mutation createTeamInTree($name: String!) {
									teamCreate(
										record: {
											n: $name
											maxDevices: 1
											deviceTtl: 184
										}
									) {
										recordId
									}
								}
							`,
							refetchQueries: [
								{
									query: TeamsTreeQuery
								}
							]
						})}
					/>
				)}
				{this.state.searchUserModal && (
					<SearchUser
						onClose={() =>
							this.setState({ searchUserModal: false })
						}
					/>
				)}
				<Query
					query={TeamsTreeQuery}
					pollInterval={config.longPollInterval}
				>
					{({ loading, error, data }) => {
						if ((!data || !data.teams) && loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={displayGraphQLErrors(error)}
									type="error"
									showIcon
								/>
							)
						}

						return (
							<TeamsListDisplay
								teams={data.teams}
								match={match}
								mobileVersion={mobileVersion}
								showUsers
								showDevices
								showPrograms
								redirect
								searchKey="TeamsTree"
								keyboardShortcuts
							/>
						)
					}}
				</Query>
			</div>
		)
	}
}

export default injectIntl(TeamsListFetch)
