import React, { Component } from 'react'
import { injectIntl, FormattedDate } from 'react-intl'
import { faPlus, faFolder, faHdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'

import Table from '../../components/TableV2'
import { setPageTitle } from '../../components/Utils'
import * as Auth from '../../auth'
import BrowseMedia from './BrowseMedia'
import NewMessageProgram from './NewMessageProgram'
import ImportRamadanEvents from './ImportRamadanEvents'

class IMessageProgramsListDisplay extends Component {
	columns = {
		devices: {
			name: this.props.intl.formatMessage({ id: 'devices' }),
			title: <FontAwesomeIcon icon={faHdd} />,
			queryProps: [{ zones: ['_id'] }],
			accessor: (record) => {
				if (!record.zones) {
					return 0
				}
				return record.zones.length
			},
			type: 'Number'
		},
		media: {
			name: this.props.intl.formatMessage({ id: 'messages' }),
			title: <FontAwesomeIcon icon={faCommentAlt} />,
			queryProps: [{ medias: ['_id'] }],
			accessor: (record) => {
				if (!record.medias) {
					return 0
				}
				return record.medias.length
			},
			type: 'Number'
		},
		updated: {
			accessor: (sample) => sample.updated,
			queryProps: ['updated'],
			name: this.props.intl.formatMessage({ id: 'samples.updateDate' }),
			render: (sample) =>
				!!sample.updated ?
					<FormattedDate
						value={sample.updated}
						year="numeric"
						month="2-digit"
						day="2-digit"
					/>
				:	'',
			type: 'Date'
		}
	}

	render() {
		const {
			redirect,
			link,
			match,
			selectedId,
			onSelect,
			defaultColumns,
			defaultMobileColumns,
			storageKey,
			title,
			actions,
			data
		} = this.props
		let sel = null
		if (selectedId) {
			sel = selectedId
		} else if (match && match.params && match.params.messageProgramId) {
			sel = match.params.messageProgramId
		}

		return (
			<Table
				rowKey="_id"
				columns={this.columns}
				defaultColumns={defaultColumns}
				defaultMobileColumns={defaultMobileColumns}
				link={link}
				path="/messages"
				teamProp={(record) => record.team}
				nameProp={(record) => record.name}
				nameQueryProps={['name']}
				searchProps={(record) => [record.name]}
				selectedId={sel}
				redirect={redirect}
				onSelect={onSelect}
				storageKey={storageKey}
				match={match}
				queryType="messagePrograms"
				title={title}
				actions={actions}
				data={data}
			/>
		)
	}
}

export const MessageProgramsListDisplay = injectIntl(
	IMessageProgramsListDisplay
)

class MessageProgramsListFetch extends Component {
	state = {
		newProgramModal: false,
		manageMedia: false
	}

	render() {
		const { match, intl } = this.props
		if (!match.params || !match.params.messageProgramId) {
			setPageTitle(intl, 'messagePrograms')
		}

		let actions = []
		if (Auth.hasAccess('messageProgram:add', Auth.cache.user.teamId)) {
			actions.push({
				title: intl.formatMessage({
					id: 'messagePrograms.actions.newProgram'
				}),
				key: 'newProgram',
				icon: faPlus,
				action: () => this.setState({ newProgramModal: true })
			})
		}
		if (Auth.hasAccess('media:browse', Auth.cache.user.teamId)) {
			actions.push({
				title: intl.formatMessage({
					id: 'messagePrograms.actions.browseMedia'
				}),
				key: 'manageMedia',
				icon: faFolder,
				action: () => this.setState({ manageMedia: true })
			})
		}
		if (Auth.hasPermission('messageProgram:edit:importRamadanEvents')) {
			actions.push({
				title: intl.formatMessage({
					id: 'messagePrograms.actions.importRamadanEvents'
				}),
				key: 'importRamadanEvents',
				icon: faFolder,
				action: () => this.setState({ importRamadanEvents: true })
			})
		}

		return (
			<React.Fragment>
				<MessageProgramsListDisplay
					actions={actions}
					title={intl.formatMessage({
						id: 'messagePrograms.table.title'
					})}
					match={match}
					redirect
					defaultColumns={['name', 'team']}
					defaultMobileColumns={['name']}
					extraQueryProps={[]}
					storageKey="messageProgramsList"
				/>
				{this.state.newProgramModal && (
					<NewMessageProgram
						team={Auth.cache.team}
						onClose={(e) =>
							this.setState({ newProgramModal: false })
						}
					/>
				)}
				{this.state.manageMedia && (
					<BrowseMedia
						onClose={() => this.setState({ manageMedia: false })}
					/>
				)}
				{this.state.importRamadanEvents && (
					<ImportRamadanEvents
						onClose={() =>
							this.setState({ importRamadanEvents: false })
						}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(MessageProgramsListFetch)
