import React, { Component } from 'react'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { Alert, Divider, Tag } from 'antd'
import {
	injectIntl,
	FormattedMessage,
	FormattedDate,
	FormattedNumber
} from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFileInvoiceDollar,
	faCloudDownloadAlt,
	faMoneyCheckAlt,
	faTrash,
	faHandshake
} from '@fortawesome/free-solid-svg-icons'

import * as Utils from '../../../components/Utils'
import Loading from '../../../components/LoadingPage'
import Table from '../../../components/Table'
import ActionsMenu from '../../../components/ActionsMenu'
import * as Auth from '../../../auth'
import { TeamBillingQuery } from '../Queries'

const SORTKEY = 'invoicesTableSort'

class IInvoicesDisplay extends Component {
	state = {}

	invoiceMenu = (invoice) => {
		let actions = []

		if (invoice.hosted_invoice_url && invoice.status === 'open') {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'billing.invoices.actions.payNow'
				}),
				key: 'payNow',
				icon: faMoneyCheckAlt,
				action: () => window.open(invoice.hosted_invoice_url, '_blank')
			})
		}
		if (invoice.invoice_pdf) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'billing.invoices.actions.download'
				}),
				key: 'download',
				icon: faCloudDownloadAlt,
				action: () => window.open(invoice.invoice_pdf, '_blank')
			})
		}
		if (
			invoice.status === 'open' &&
			Auth.hasPermission('team:edit:setBillingPlan')
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'billing.invoices.actions.markAsPaid'
				}),
				key: 'markPaid',
				icon: faHandshake,
				mutation: {
					variables: {
						id: this.props.subscription._id,
						invoice: invoice.id
					},
					mutation: gql`
						mutation updateSubscriptionInvoiceAsPaid(
							$id: String!
							$invoice: String
						) {
							subscriptionMarkInvoiceAsPaid(
								_id: $id
								invoice: $invoice
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: this.props.team._id }
						}
					],
					awaitRefetchQueries: true
				}
			})
		}
		if (
			invoice.status === 'open' &&
			Auth.hasPermission('team:edit:setBillingPlan')
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'billing.invoices.actions.void'
				}),
				key: 'void',
				icon: faTrash,
				confirm: this.props.intl.formatMessage({
					id: 'billing.invoices.actions.void.confirm'
				}),
				mutation: {
					variables: {
						id: this.props.subscription._id,
						invoice: invoice.id
					},
					mutation: gql`
						mutation voidSubscriptionInvoice(
							$id: String!
							$invoice: String
						) {
							subscriptionVoidInvoice(
								_id: $id
								invoice: $invoice
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: this.props.team._id }
						}
					],
					awaitRefetchQueries: true
				}
			})
		}

		return actions
	}

	render() {
		const { invoices, intl } = this.props

		let savedSort, savedSortColumn
		const storedSort = localStorage.getItem(SORTKEY)
		if (storedSort) {
			savedSort = JSON.parse(storedSort)
			savedSortColumn = savedSort ? savedSort.column : null
		}

		let columns = [
			{
				title: intl.formatMessage({ id: 'billing.invoices.date' }),
				key: 'date',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'date' ?
						savedSort.order
					:	null,
				render: (text, record, index) => {
					return (
						<FormattedDate
							value={new Date(record.created * 1000)}
							day="numeric"
							month="long"
							year="numeric"
						/>
					)
				},
				sorter: (a, b) => {
					return a.created - b.created
				}
			},
			{
				title: intl.formatMessage({ id: 'billing.invoices.amount' }),
				key: 'amount',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'amount' ?
						savedSort.order
					:	null,
				render: (_, record) => {
					return (
						<FormattedNumber
							value={record.total / 100}
							style="currency"
							currency={record.currency}
						/>
					) // eslint-disable-line
				},
				sorter: (a, b) => {
					return a.total - b.total
				}
			},
			{
				title: intl.formatMessage({ id: 'billing.invoices.status' }),
				key: 'status',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'status' ?
						savedSort.order
					:	null,
				render: (_, record) => {
					return (
						<Tag
							key={`${record.id}-status`}
							color={Utils.invoiceStatusColor(record.status)}
						>
							<FormattedMessage
								id={`billing.invoices.status.${record.status}`}
							/>
						</Tag>
					)
				},
				sorter: (a, b) => {
					return a.status.localeCompare(b.status)
				}
			},
			{
				key: 'action',
				align: 'right',
				render: (_, record) => (
					<ActionsMenu
						size="small"
						menuItems={this.invoiceMenu(record)}
					/>
				)
			}
		]

		return (
			<Table
				rowKey="id"
				columns={columns}
				dataSource={invoices}
				sortKey={SORTKEY}
				emptyText="billing.invoices.empty"
			/>
		)
	}
}

const InvoicesDisplay = withApollo(injectIntl(IInvoicesDisplay))

class InvoicesQuery extends Component {
	render() {
		const { team, intl, subscription } = this.props

		return (
			<React.Fragment>
				<Divider orientation="left">
					<FontAwesomeIcon icon={faFileInvoiceDollar} />
					&nbsp;&nbsp;
					<FormattedMessage id="billing.invoices" />
				</Divider>
				{team && (
					<Query
						query={gql`
							query fetchSubscriptionInvoices($id: String!) {
								subscriptionInvoices(_id: $id) {
									invoices
								}
							}
						`}
						variables={{ id: subscription._id }}
					>
						{({ loading, error, data }) => {
							if (
								(!data || !data.subscriptionInvoices) &&
								loading
							) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={intl.formatMessage({
											id: 'error'
										})}
										description={Utils.displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							const response = JSON.parse(
								data.subscriptionInvoices.invoices
							)
							return (
								<InvoicesDisplay
									team={team}
									invoices={response.data}
									subscription={subscription}
									hasMore={response.has_more} // TODO show more
								/>
							)
						}}
					</Query>
				)}
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(InvoicesQuery))
