import React, { Component } from 'react'
import {
	Modal,
	Form,
	Button,
	Select,
	DatePicker,
	Checkbox,
	InputNumber,
	Alert
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { RRule, rrulestr } from 'rrule'
import uuid from 'uuid/v4'

import { formItemLayout } from '../../components/Styles'
import * as Utils from '../../components/Utils'

class BatchActions extends Component {
	state = {
		range: [], // moments [start, end]
		allOccurrences: false,
		action: 'setWeight',
		eventCount: null,
		loading: false,

		weight: 1
	}

	// actions

	handleSubmit = async () => {
		this.setState({ loading: true })
		try {
			let events
			switch (this.state.action) {
				case 'setWeight':
					events = this.setWeight()
					break
				case 'delete':
					events = this.delete()
					break
				default:
					break
			}
			await this.props.setEvents(events)
			this.props.onClose()
		} catch (e) {
			Utils.displayError(e)
			this.setState({ loading: false })
		}
	}

	setWeight = () => {
		let { events, rangeEvents } = this.extractRangeEvents()

		for (let id of rangeEvents) {
			events[id].weight = this.state.weight
		}

		return events
	}

	delete = () => {
		let { events, rangeEvents } = this.extractRangeEvents()

		for (let id of rangeEvents) {
			delete events[id]
		}

		return events
	}

	// helpers

	duplicateEvent = (event, oldRule, { start, end }) => {
		// for rule sets we need to get the internal rule
		let oldRuleSet
		if (oldRule._exdate) {
			oldRuleSet = oldRule
			oldRule = oldRule._rrule[0]
		}

		let dtstart = oldRule.options.dtstart
		if (start) {
			// keep event time but make it after specified start date
			let oldStart = moment(dtstart)
			if (oldStart.isSameOrBefore(start)) {
				// Adjust time offset for oldstart in case it's in a different DST period
				oldStart = Utils.utcToLocalDate(dtstart)
				oldStart = Utils.localToUtcDate(oldStart, start.toDate())

				// event start date is before range
				let newStart = moment(start)
					.hour(oldStart.hour())
					.minute(oldStart.minute())
					.second(0)
					.millisecond(0)
				if (newStart.isBefore(start)) {
					newStart.add(1, 'day')
				}
				dtstart = newStart.toDate()
			}
		}
		if (end) {
			end = end.toDate()
		}

		let newRule = new RRule({
			dtstart,
			freq: oldRule.options.freq,
			until: end || oldRule.options.until,
			interval: oldRule.options.interval,
			count: oldRule.options.count,
			byweekday: oldRule.options.byweekday,
			bymonthday: oldRule.options.bymonthday
		})

		// If old rule was a set recreate it
		if (oldRuleSet) {
			newRule = Utils.updatedRuleSetWithRule(oldRuleSet, newRule)
		}

		const rule = newRule.toString()

		return {
			...event,
			id: uuid(),
			rule
		}
	}

	extractRangeEvents = () => {
		const { events } = this.props
		const { range, allOccurrences } = this.state
		let newEvents = {}
		let rangeEvents = []
		if (range.length === 2) {
			const rangeStart = Utils.localToUtcDate(range[0]).subtract(
				1,
				'second'
			)
			const rangeEnd = Utils.localToUtcDate(range[1]).subtract(
				1,
				'second'
			)

			for (let id in events) {
				const evt = events[id]

				// check for events in range
				const eventRule = rrulestr(evt.rule)
				const eventTimes = eventRule.between(
					rangeStart.toDate(),
					rangeEnd.toDate()
				)
				if (eventTimes.length > 0) {
					if (allOccurrences) {
						// keep event as is but mark it for modification
						newEvents[id] = evt
						rangeEvents.push(id)
					} else {
						// split event into up to 3 events: before, during, after (if necessary)

						// before
						if (
							moment(eventRule.options.dtstart).isBefore(
								rangeStart
							)
						) {
							const newBeforeEvent = this.duplicateEvent(
								evt,
								eventRule,
								{ end: rangeStart }
							)
							newEvents[newBeforeEvent.id] = newBeforeEvent
						}

						// during
						const newDuringEvent = this.duplicateEvent(
							evt,
							eventRule,
							{ start: rangeStart, end: rangeEnd }
						)
						newEvents[newDuringEvent.id] = newDuringEvent
						rangeEvents.push(newDuringEvent.id)

						// after
						if (
							eventRule.options.count !== 1 &&
							(!eventRule.options.until ||
								moment(eventRule.options.until).isAfter(
									rangeEnd
								))
						) {
							const newAfterEvent = this.duplicateEvent(
								evt,
								eventRule,
								{ start: rangeEnd }
							)
							newEvents[newAfterEvent.id] = newAfterEvent
						}
					}
				} else {
					// no events in range, keep as is
					newEvents[id] = evt
				}
			}
			return { events: newEvents, rangeEvents }
		}
		return null
	}

	countRangeEvents = () => {
		const { events } = this.props
		const { range, allOccurrences } = this.state
		if (range.length === 2) {
			let count = 0
			const localFrom = Utils.localToUtcDate(range[0]).subtract(
				1,
				'second'
			)
			const localUntil = Utils.localToUtcDate(range[1]).subtract(
				1,
				'second'
			)

			for (let id in events) {
				const evt = events[id]

				const eventRule = rrulestr(evt.rule)
				const eventTimes = eventRule.between(
					localFrom.toDate(),
					localUntil.toDate()
				)
				if (allOccurrences) {
					// count each event only once
					if (eventTimes.length > 0) {
						count += 1
					}
				} else {
					// count each occurrence
					count += eventTimes.length
				}
			}
			return count
		}
		return 0
	}

	render() {
		const { intl } = this.props
		let footer = [
			<Button
				type="primary"
				htmlType="submit"
				key="submit"
				onClick={this.handleSubmit}
				loading={this.state.loading}
			>
				<FormattedMessage id="actions.save" />
			</Button>
		]

		return (
			<Modal
				width="70vw"
				visible={true}
				title={this.props.intl.formatMessage({
					id: 'programs.scheduler.batch'
				})}
				onCancel={() => this.props.onClose()}
				footer={footer}
			>
				<Form>
					<Form.Item
						label={intl.formatMessage({
							id: 'programs.scheduler.batch.between'
						})}
						{...formItemLayout}
					>
						<DatePicker.RangePicker
							showTime
							format="DD-MM-YYYY HH:mm"
							placeholder={[
								intl.formatMessage({
									id: 'programs.scheduler.from'
								}),
								intl.formatMessage({
									id: 'programs.scheduler.to'
								})
							]}
							value={this.state.range}
							onChange={(range) => this.setState({ range })}
						/>
						<br />
						<Checkbox
							checked={this.state.allOccurrences}
							onChange={(r) =>
								this.setState({
									allOccurrences: r.target.checked
								})
							}
						>
							<FormattedMessage id="programs.scheduler.batch.allOccurences" />
						</Checkbox>
						<br />
						{this.state.range.length === 2 && (
							<Alert
								message={this.props.intl.formatMessage(
									{
										id:
											this.state.allOccurrences ?
												'programs.scheduler.batch.eventsModifiedRepeat'
											:	'programs.scheduler.batch.eventsModified'
									},
									{ count: this.countRangeEvents() }
								)}
								type="info"
							/>
						)}
					</Form.Item>

					<Form.Item
						label={intl.formatMessage({
							id: 'programs.scheduler.batch.action'
						})}
						{...formItemLayout}
					>
						<Select
							style={{ width: 250 }}
							value={this.state.action}
							onChange={(action) => this.setState({ action })}
						>
							{['setWeight', 'delete'].map((a) => (
								<Select.Option value={a} key={a}>
									<FormattedMessage
										id={`programs.scheduler.batch.action.${a}`}
									/>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					{this.state.action === 'setWeight' && (
						<Form.Item
							label={intl.formatMessage({
								id: 'programs.scheduler.weight'
							})}
							{...formItemLayout}
						>
							<InputNumber
								value={this.state.weight}
								onChange={(v) => this.setState({ weight: v })}
							/>
						</Form.Item>
					)}
				</Form>
			</Modal>
		)
	}
}

export default injectIntl(BatchActions)
