import React, { Component } from 'react'
import keys from 'lodash/keys'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Checkbox, Row, Input, Button, Tag, Select } from 'antd'
import {
	faEllipsisH,
	faTable,
	faFilter,
	faPlus
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hotkeys } from 'react-keyboard-shortcuts'
import removeAccents from 'remove-accents'

import S from '../Styles'
import ActionsMenu from '../ActionsMenu'
import { Default } from '../Responsive'
import { SelectTeamV2 } from '../../views/teams/SelectTeam'
import * as Auth from '../../auth'

class TableV2Header extends Component {
	state = {
		columnsModal: false,
		newFilterOpen: false
	}

	hot_keys = {
		'meta+f': {
			priority: 1,
			handler: (event) => {
				event.preventDefault()
				if (this.searchInput) {
					setTimeout(() => this.searchInput.focus(), 200)
				}
			}
		},
		esc: {
			priority: 1,
			handler: (event) => {
				event.preventDefault()
				if (this.props.searchChanged && this.searchInput) {
					this.props.searchChanged(null)
					this.searchInput.blur()
				}
			}
		}
	}

	handleFilters = () => {
		if (this.state.newFilterOpen) {
			this.setState({ newFilterOpen: false })
		} else if (this.props.filters && this.props.filters.length > 0) {
			this.setState({ newFilterOpen: false })
			this.props.filtersChanged([])
		} else {
			this.setState({ newFilterOpen: true })
		}
	}

	render() {
		const {
			intl,
			title,
			selectedColumns,
			selectedColumnsChanged,
			columns,
			actions,
			searchChanged,
			search,
			filters,
			filtersChanged,
			data
		} = this.props
		if (!columns) {
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap'
					}}
				>
					{title && (
						<h1 style={S.itemDetails.titleLeft}>
							{title || '...'}
						</h1>
					)}
				</div>
			)
		}

		let sortedColumns = keys(columns).sort((a, b) =>
			columns[a].name.localeCompare(columns[b].name)
		)

		// select columns
		let menuActions = [
			{
				title: intl.formatMessage({ id: 'tables.columns' }),
				key: 'columns',
				icon: faTable,
				action: () => this.setState({ columnsModal: true })
			}
		]

		if (actions) {
			menuActions = [...menuActions, ...actions]
		}

		return (
			<React.Fragment>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexWrap: 'wrap'
					}}
				>
					{title && (
						<h1 style={S.itemDetails.titleLeft}>
							{title || '...'}
						</h1>
					)}
					{title ?
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								flexWrap: 'wrap',
								marginRight: -5,
								marginTop: -5
							}}
						>
							<Input.Search
								placeholder={intl.formatMessage({
									id: 'actions.search'
								})}
								onChange={(e) => searchChanged(e.target.value)}
								style={{
									flexShrink: 2,
									maxWidth: '50%',
									...styles.spaced
								}}
								value={search}
								ref={(r) => (this.searchInput = r)}
							/>
							<Button
								style={styles.spaced}
								onClick={this.handleFilters}
								type={
									filters.length > 0 ? 'primary' : 'default'
								}
							>
								<FontAwesomeIcon icon={faFilter} />
								<Default>
									<React.Fragment>
										&nbsp;
										<FormattedMessage id="tables.filters" />
									</React.Fragment>
								</Default>
							</Button>
							<ActionsMenu
								icon={faEllipsisH}
								menuItems={menuActions}
								style={styles.spaced}
							/>
						</div>
					:	<Input.Search
							placeholder={intl.formatMessage({
								id: 'actions.search'
							})}
							onChange={(e) => searchChanged(e.target.value)}
							style={{ width: '100%' }}
							value={search}
							ref={(r) => (this.searchInput = r)}
						/>
					}
				</div>
				<Filters
					filters={filters}
					filtersChanged={(v, newOpen) => {
						if (newOpen !== this.state.newFilterOpen) {
							this.setState({ newFilterOpen: newOpen })
						}
						filtersChanged(v)
					}}
					newFilterOpen={this.state.newFilterOpen}
					setNewFilterOpen={(open) =>
						this.setState({ newFilterOpen: open })
					}
					columns={columns}
					displayedColumns={selectedColumns}
					data={data}
				/>
				{this.state.columnsModal && (
					<Modal
						visible={true}
						title={intl.formatMessage({ id: 'tables.columns' })}
						onCancel={() => this.setState({ columnsModal: false })}
						footer={[]}
					>
						<Checkbox.Group
							value={selectedColumns}
							onChange={selectedColumnsChanged}
						>
							{sortedColumns.map((k) => {
								const col = columns[k]
								if (col.hiddenFromHeader) {
									return null
								}
								if (col.acl) {
									if (!Auth.hasPermission(col.acl)) {
										return null
									}
								}
								return (
									<Row key={k}>
										<Checkbox value={k}>
											{col.name}
										</Checkbox>
									</Row>
								)
							})}
						</Checkbox.Group>
					</Modal>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(hotkeys(TableV2Header))

class IFilters extends Component {
	state = {
		newFilterColumn: null,
		newFilterValues: [],
		newFilterNames: {}
	}

	selectColumn = (c) => {
		let names = {}
		const selectedColumn = this.props.columns[c]
		if (selectedColumn.filters) {
			for (let f of selectedColumn.filters) {
				names[`${f.value}`] = f.text
			}
		}
		this.setState({
			newFilterColumn: c,
			newFilterValues: [],
			newFilterNames: names
		})
	}

	filterValueChanged = (v) => {
		this.setState({ newFilterValues: v })
	}

	deleteFilter = (index) => {
		this.delete = true // clicking the delete button also triggers edit...
		let f = [...this.props.filters]
		f.splice(index, 1)
		this.props.filtersChanged(f, false)
	}

	editFilter = (index) => {
		if (!this.delete) {
			let filter = this.props.filters[index]
			let f = [...this.props.filters]
			f.splice(index, 1)
			this.props.filtersChanged(f, true)
			this.setState({
				newFilterColumn: filter.column,
				newFilterValues: filter.values,
				newFilterNames: filter.names
			})
		}
		this.delete = false
	}

	doneEditing = () => {
		if (
			this.state.newFilterValues &&
			this.state.newFilterValues.length > 0
		) {
			const newFilter = {
				column: this.state.newFilterColumn,
				values: this.state.newFilterValues,
				names: this.state.newFilterNames
			}
			this.props.filtersChanged([...this.props.filters, newFilter], false)
			this.setState({
				newFilterColumn: null,
				newFilterValues: [],
				newFilterNames: {}
			})
		}
	}

	cancelEditing = () => {
		this.props.setNewFilterOpen(false)
		this.setState({
			newFilterColumn: null,
			newFilterValues: [],
			newFilterNames: {}
		})
	}

	renderFilterValueSelect = () => {
		if (this.state.newFilterColumn) {
			let selectedColumn = this.props.columns[this.state.newFilterColumn]
			if (selectedColumn.type) {
				// Type can be Date, Number, Version, Team
				if (selectedColumn.type === 'Team') {
					return (
						<SelectTeamV2
							size="small"
							style={{ minWidth: 200, ...styles.spaced }}
							multiple
							selected={this.state.newFilterValues}
							keyprops="path"
							onFetch={(teams) => {
								let names = {}
								for (let t of teams) {
									names[t.path] = t.n
								}
								this.setState({ newFilterNames: names })
							}}
							onChange={(teams) =>
								this.filterValueChanged(
									teams.map((t) => t.path)
								)
							}
						/>
					)
				} else {
					// le TODO
				}
			} else {
				let content
				let extra = {}
				if (selectedColumn.filters) {
					extra.filterOption = (input, option) =>
						removeAccents(option.props.label.toLowerCase()).indexOf(
							removeAccents(input.toLowerCase())
						) > -1
					content = selectedColumn.filters.map((c) => (
						<Select.Option
							key={c.value}
							label={c.text}
							value={c.value}
						>
							{c.text}
						</Select.Option>
					))
				} else {
					// String
					let values = []
					for (let r of this.props.data) {
						const val = selectedColumn.accessor(r)
						if (val && values.indexOf(val) === -1) {
							values.push(val)
						}
					}
					values.sort()
					content = values.map((c) => (
						<Select.Option key={c} value={c}>
							{c}
						</Select.Option>
					))
				}
				return (
					<React.Fragment>
						{' '}
						={' '}
						<Select
							style={{ minWidth: 200, ...styles.spaced }}
							placeholder={selectedColumn.name}
							size="small"
							mode="multiple"
							value={this.state.newFilterValues}
							onChange={this.filterValueChanged}
							{...extra}
						>
							{content}
						</Select>
					</React.Fragment>
				)
			}
		}
		return null
	}

	renderFilterValue = (filter) => {
		if (filter.column) {
			// Column
			const selectedColumn = this.props.columns[filter.column]

			// Operator
			let operator = '='

			// Values
			let values = filter.values
			if (Object.keys(this.state.newFilterNames).length > 0) {
				values = filter.values.map((v) => filter.names[`${v}`] || v)
			}

			return `${selectedColumn.name} ${operator} ${values.join(', ')}`
		}
		return null
	}

	render() {
		const { intl, filters, newFilterOpen, columns, displayedColumns } =
			this.props
		let allColumns = displayedColumns.map((k) => ({
			...columns[k],
			key: k
		}))
		return (
			<div>
				{filters &&
					filters.map((f, i) => (
						<Tag
							key={`filter-${i}-${f.column}`}
							style={{ marginBottom: 5, cursor: 'pointer' }}
							closable
							onClose={() => this.deleteFilter(i)}
							onClick={() => this.editFilter(i)}
						>
							{this.renderFilterValue(f)}
						</Tag>
					))}
				{filters && filters.length > 0 && !newFilterOpen && (
					<Tag
						onClick={() => this.props.setNewFilterOpen(true)}
						style={{
							borderStyle: 'dashed',
							cursor: 'pointer',
							marginBottom: 5
						}}
					>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp;
						<FormattedMessage id="tables.addFilter" />
					</Tag>
				)}
				{newFilterOpen && (
					<div>
						<FormattedMessage id="tables.addFilter" />:
						<Select
							data-test="filter-column"
							showSearch
							style={{ minWidth: 200, ...styles.spaced }}
							placeholder={intl.formatMessage({
								id: 'tables.column'
							})}
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.props.children
									.toLowerCase()
									.indexOf(input.toLowerCase()) > -1
							}
							size="small"
							value={this.state.newFilterColumn}
							onChange={this.selectColumn}
						>
							{allColumns.map((c) =>
								c.noFilter || (c.type && c.type !== 'Team') ?
									null
								:	<Select.Option key={c.key} value={c.key}>
										{c.name}
									</Select.Option>
							)}
						</Select>
						{this.renderFilterValueSelect()}
						<Button
							onClick={this.cancelEditing}
							size="small"
							style={styles.spaced}
						>
							<FormattedMessage id="actions.cancel" />
						</Button>
						<Button
							onClick={this.doneEditing}
							size="small"
							type="primary"
							style={styles.spaced}
							disabled={
								!this.state.newFilterValues ||
								!this.state.newFilterValues.length
							}
						>
							<FormattedMessage id="actions.done" />
						</Button>
					</div>
				)}
			</div>
		)
	}
}

const Filters = injectIntl(IFilters)

const styles = {
	spaced: {
		margin: 5
	}
}
