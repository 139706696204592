import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import {
	Alert,
	Tag,
	Breadcrumb,
	Descriptions,
	Tabs,
	Collapse,
	Switch,
	Divider,
	Button,
	Modal
} from 'antd'
import { Link, Redirect } from 'react-router-dom'
import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl'
import {
	faTrash,
	faEdit,
	faCopy,
	faList,
	faCommentDots,
	faQuoteLeft,
	faQuoteRight,
	faThumbsUp,
	faThumbsDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Loading from '../../components/LoadingPage'
import config from '../../config'
import Title from '../../components/Title'
import S from '../../components/Styles'
import { setPageTitle, displayGraphQLErrors } from '../../components/Utils'
import { SamplesListQuery, SampleDetailsQuery } from './Queries'
import EditSample from './EditSample'
import OrderedTrackList from './OrderedTrackList'
import Review from './Reviews'
import * as Utils from '../../components/Utils'
import { SelectTeamModal } from '../teams/SelectTeam'

class ISampleDetails extends Component {
	state = {
		editModal: false,
		duplicateModal: false,
		selectTeamModal: false,
		allowReview: true
	}

	onShowTrackNotesChanged = async (v) => {
		const { client, sample } = this.props

		try {
			const r = await client.mutate({
				variables: {
					id: sample._id,
					showTrackNotes: v
				},
				mutation: gql`
					mutation updateSampleNotes(
						$id: String!
						$showTrackNotes: Boolean
					) {
						sampleUpdateById(
							_id: $id
							showTrackNotes: $showTrackNotes
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: SampleDetailsQuery,
						variables: { id: sample._id }
					}
				]
			})

			Utils.parseMutationResponse(r)
			let res = r.data.sampleUpdateById

			if (res.message) {
				throw new Error(res.message)
			}
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { sample, intl, client } = this.props
		setPageTitle(intl, null, sample.name)

		let actions = [
			{
				title: intl.formatMessage({ id: 'actions.edit' }),
				key: 'editSample',
				icon: faEdit,
				dataTest: 'edit-sample',
				action: () => this.setState({ editModal: true })
			},
			{
				title: intl.formatMessage({
					id: 'samples.actions.duplicateSample'
				}),
				key: 'duplicateSample',
				icon: faCopy,
				dataTest: 'duplicate-sample',
				action: () => this.setState({ duplicateModal: true })
			},
			{
				title: intl.formatMessage({
					id: 'samples.actions.assignSampleToTeam'
				}),
				key: 'reassignSample',
				icon: faEdit,
				dataTest: 'reassign-sample',
				action: () => this.setState({ selectTeamModal: true })
			},
			{
				title: intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteSample',
				icon: faTrash,
				confirm: intl.formatMessage(
					{ id: 'samples.actions.delete.confirm' },
					{ name: sample.name }
				),
				mutation: {
					variables: { id: sample._id },
					mutation: gql`
						mutation removeSample($id: String!) {
							sampleRemoveById(_id: $id) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: SamplesListQuery
						},
						{
							query: SampleDetailsQuery,
							variables: { id: sample._id }
						}
					],
					awaitRefetchQueries: true
				}
			}
		]

		const listenLink = `${window.location.protocol}//${window.location.host}/listen/${sample._id}`
		const reviewLink = `${window.location.protocol}//${window.location.host}/review/${sample._id}`

		return (
			<div>
				<Title
					title={sample.name}
					actions={actions}
					backUrl="/samples"
					backTitle="samples"
					dataTest="main-sample-menu"
				>
					<div
						style={S.itemDetails.teamPath}
						data-test="sample-selected-team"
					>
						{sample.team && (
							<Link to={`/teams/${sample.team._id}`}>
								<Tag color="blue">{sample.team.path}</Tag>
							</Link>
						)}
					</div>
				</Title>

				<Tabs>
					<Tabs.TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faList} />{' '}
								<FormattedMessage id="sample" />
							</span>
						}
						key="1"
					>
						<Descriptions column={1} bordered>
							<Descriptions.Item
								label={intl.formatMessage({ id: 'playlist' })}
							>
								<Breadcrumb
									separator=">"
									style={{ marginBottom: 10 }}
								>
									{sample.playlist &&
										sample.playlist.path &&
										sample.playlist.path.map((t) => (
											<Breadcrumb.Item key={t}>
												{t}
											</Breadcrumb.Item>
										))}
									{sample.playlist ?
										<Breadcrumb.Item
											onClick={() =>
												this.setState({
													editModal: true
												})
											}
										>
											{sample.playlist.n ||
												intl.formatDate(
													sample.playlist.created,
													{
														day: 'numeric',
														month: 'long',
														year: 'numeric'
													}
												)}
										</Breadcrumb.Item>
									:	<p style={S.emptyText}>
											<FormattedMessage id="nonef" />
										</p>
									}
								</Breadcrumb>
								<Switch
									checked={sample.showTrackNotes}
									onChange={this.onShowTrackNotesChanged}
								/>
								&nbsp;&nbsp;
								<FormattedMessage id="samples.showTrackNotes" />
							</Descriptions.Item>
							<Descriptions.Item
								label={intl.formatMessage({
									id: 'samples.updateDate'
								})}
							>
								<FormattedDate value={sample.updated} />
							</Descriptions.Item>
							<Descriptions.Item
								label={intl.formatMessage({
									id: 'samples.url'
								})}
							>
								<div
									className="sample-preview"
									style={{ marginBottom: 10 }}
								>
									<a
										href={
											this.state.allowReview ?
												reviewLink
											:	listenLink
										}
										target="_blank"
									>
										{this.state.allowReview ?
											reviewLink
										:	listenLink}
									</a>
									&nbsp;&nbsp;
									{document.queryCommandSupported('copy') && (
										<CopyToClipboard
											text={
												this.state.allowReview ?
													reviewLink
												:	listenLink
											}
										>
											<Button>
												<FormattedMessage id="devices.info.actions.ssh.copy" />
											</Button>
										</CopyToClipboard>
									)}
								</div>
								<Switch
									checked={this.state.allowReview}
									onChange={(v) =>
										this.setState({ allowReview: v })
									}
								/>
								&nbsp;&nbsp;
								<FormattedMessage id="samples.url.allowFeedback" />
							</Descriptions.Item>
						</Descriptions>

						{this.state.editModal && (
							<EditSample
								sample={sample}
								onClose={() =>
									this.setState({ editModal: false })
								}
							/>
						)}
						{this.state.duplicateModal && (
							<EditSample
								duplicate
								sample={sample}
								onClose={() =>
									this.setState({ duplicateModal: false })
								}
							/>
						)}
						{this.state.selectTeamModal && (
							<SelectTeamModal
								title={this.props.intl.formatMessage({
									id: 'samples.actions.assignSampleToTeam'
								})}
								onClose={() =>
									this.setState({ selectTeamModal: false })
								}
								selected={sample.teamId}
								hideNoParents
								onSelect={async (t) => {
									try {
										if (t && t !== sample.teamId) {
											const r = await client.mutate({
												variables: {
													id: sample._id,
													parent: t
												},
												mutation: gql`
													mutation updateSampleTeam(
														$id: String!
														$parent: String!
													) {
														sampleUpdateById(
															_id: $id
															teamId: $parent
														) {
															recordId
														}
													}
												`,
												refetchQueries: [
													{
														query: SampleDetailsQuery,
														variables: {
															id: sample._id
														}
													}
												]
											})
											Utils.parseMutationResponse(r)
										}
									} catch (e) {
										Utils.displayError(e)
									}
								}}
							/>
						)}

						<OrderedTrackList {...sample} onChange={() => {}} />
					</Tabs.TabPane>
					<Tabs.TabPane
						tab={
							<span>
								<FontAwesomeIcon icon={faCommentDots} />{' '}
								<FormattedMessage id="samples.feedback" />
							</span>
						}
						key="2"
					>
						{sample.reviews.length ?
							<Collapse
								style={{ width: '100%' }}
								className="column-browser"
							>
								{sample.reviews.map((r) => {
									let likes = 0
									let dislikes = 0
									let comments = 0
									for (let t of r.tracks) {
										if (t.rating === 1) {
											dislikes++
										} else if (t.rating === 2) {
											likes++
										}
										if (t.comment) {
											comments++
										}
									}

									return (
										<Collapse.Panel
											key={r._id}
											header={
												<div>
													<div>
														<span
															style={{
																fontWeight: 600
															}}
														>
															{r.name}
														</span>
														<Divider type="vertical" />
														<span
															style={{
																color: '#999'
															}}
														>
															{r.email}
														</span>
														<Divider type="vertical" />
														<span
															style={{
																color: '#999'
															}}
														>
															<FormattedDate
																value={
																	r.updated
																}
																day="numeric"
																month="long"
																year="numeric"
															/>
														</span>
														<Divider type="vertical" />
														<span
															style={{
																color: '#999'
															}}
														>
															<FontAwesomeIcon
																icon={
																	faThumbsUp
																}
															/>
															&nbsp;{likes}
															&nbsp;&nbsp;&nbsp;&nbsp;
															<FontAwesomeIcon
																icon={
																	faThumbsDown
																}
															/>
															&nbsp;{dislikes}
															&nbsp;&nbsp;&nbsp;&nbsp;
															<FontAwesomeIcon
																icon={
																	faCommentDots
																}
															/>
															&nbsp;{comments}
														</span>
													</div>
													<div>
														{r.comment && (
															<div
																style={{
																	display:
																		'flex',
																	alignItems:
																		'center'
																}}
															>
																<FontAwesomeIcon
																	icon={
																		faQuoteLeft
																	}
																/>
																<p
																	style={{
																		margin: '0 10px'
																	}}
																>
																	{r.comment}
																</p>
																<FontAwesomeIcon
																	icon={
																		faQuoteRight
																	}
																/>
															</div>
														)}
													</div>
												</div>
											}
										>
											<Review
												sample={sample}
												review={r}
											/>
										</Collapse.Panel>
									)
								})}
							</Collapse>
						:	<div style={S.empty}>
								<FormattedMessage id="samples.noReviews" />
							</div>
						}
					</Tabs.TabPane>
				</Tabs>
			</div>
		)
	}
}

const SampleDetails = withApollo(injectIntl(ISampleDetails))

class SampleDetailsFetch extends Component {
	render() {
		const sample = this.props.match.params.sampleId
		return (
			<div>
				{sample && (
					<Query
						pollInterval={config.longPollInterval}
						query={SampleDetailsQuery}
						variables={{ id: sample }}
					>
						{({ loading, error, data }) => {
							if ((!data || !data.sampleById) && loading) {
								return <Loading />
							}
							if (error) {
								return (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={displayGraphQLErrors(
											error
										)}
										type="error"
										showIcon
									/>
								)
							}

							if (!data.sampleById) {
								return <Redirect to="/samples" />
							}

							return <SampleDetails sample={data.sampleById} />
						}}
					</Query>
				)}
			</div>
		)
	}
}

export default injectIntl(SampleDetailsFetch)
