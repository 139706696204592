import React from 'react'
import { Layout } from 'antd'

import MenuMarkup from './nav/MenuMarkup'
import ResponsiveNav from './nav/ResponsiveNav'
import { Mobile, Default } from './Responsive'

const { Content } = Layout

export default ({ match, children, hideNav }) => (
	<Layout className="layout" style={{ minHeight: '100vh', height: '100%' }}>
		{!hideNav && (
			<ResponsiveNav
				menuMarkup={MenuMarkup}
				placement="bottomLeft"
				match={match}
			/>
		)}
		<Default>
			<Content
				style={{
					paddingLeft: 16,
					paddingRight: 16,
					paddingTop: 0,
					paddingBottom: 0,
					marginLeft: 60
				}}
			>
				{children}
			</Content>
		</Default>
		<Mobile>
			<Content style={{ padding: 0 }}>{children}</Content>
		</Mobile>
	</Layout>
)
