import React, { Component } from 'react'
import { Table, List } from 'antd'
import {
	faThumbsUp,
	faThumbsDown,
	faQuoteLeft,
	faQuoteRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Utils from '../../../components/Utils'
import S from '../../../components/Styles'

class TrackList extends Component {
	render() {
		const { sample, feedback, selectedIndex, review, selectTrack, mobile } =
			this.props

		return (
			<Table
				className={mobile ? 'playerTableMobile' : 'playerTable'}
				showHeader={false}
				pagination={false}
				dataSource={sample.tracks.map((t, i) => ({
					...t,
					...sample.trackObjects[i]
				}))}
				onRow={(_, index) => ({
					onClick: () => selectTrack(index)
				})}
				rowKey="_id"
				style={{ borderTop: '1px solid #222' }}
			>
				<Table.Column
					key="track"
					render={(_, t, i) => (
						<List.Item.Meta
							avatar={
								<img
									src={Utils.trackArtworkThumbUrl(t)}
									alt=""
									style={S.trackCover}
								/>
							}
							title={
								<span
									style={{
										color:
											selectedIndex === i ?
												colors.primary
											:	colors.text
									}}
								>
									{t.title}
								</span>
							}
							description={
								<span
									style={{
										color:
											selectedIndex === i ?
												colors.primary
											:	colors.subtitle
									}}
								>
									{sample.showTrackNotes ?
										t.comment
									:	t.artist}
								</span>
							}
						/>
					)}
				/>
				{review && (
					<Table.Column
						key="rating"
						width={mobile ? 40 : 80}
						render={(_, t, i) => {
							const fb = feedback[t._id]
							if (fb && fb.rating) {
								return (
									<FontAwesomeIcon
										style={{
											color:
												fb.rating === 2 ?
													colors.yes
												:	colors.no
										}}
										icon={
											fb.rating === 2 ?
												faThumbsUp
											:	faThumbsDown
										}
									/>
								)
							}
							return null
						}}
					/>
				)}
				{review && !mobile && (
					<Table.Column
						key="comment"
						width="40%"
						render={(_, t, i) => {
							const fb = feedback[t._id]
							if (fb && fb.comment) {
								return (
									<div
										style={{
											color: colors.subtitle,
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<FontAwesomeIcon icon={faQuoteLeft} />
										<p style={{ margin: '0 10px' }}>
											{fb.comment}
										</p>
										<FontAwesomeIcon icon={faQuoteRight} />
									</div>
								)
							}
							return ''
						}}
					/>
				)}
			</Table>
		)
	}
}

export default TrackList

const colors = {
	bg: '#181818',
	primary: '#1890ff',
	text: '#fff',
	subtitle: '#999',
	yes: '#52c41a',
	no: '#f5222d'
}
