import React, { Component } from 'react'
import { Button, Dropdown, Menu, Modal } from 'antd'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import * as Utils from './Utils'

class ActionsMenu extends Component {
	buildMenu = (items) => {
		return items.map((item, i) => {
			if (item.divider) {
				return <Menu.Divider key={item.key || `divider-${i}`} />
			}

			if (item.submenu) {
				return (
					<Menu.SubMenu
						key={item.key || `action-${i}`}
						title={
							<React.Fragment>
								{item.icon && (
									<FontAwesomeIcon
										icon={item.icon}
										style={{ marginRight: 5 }}
										fixedWidth
									/>
								)}
								{item.title}
							</React.Fragment>
						}
					>
						{this.buildMenu(item.submenu)}
					</Menu.SubMenu>
				)
			}

			let action = item.action
			if (item.mutation) {
				action = async () => {
					try {
						let mut = item.mutation
						if (item.variableMethod) {
							mut.variables = item.variableMethod()
						}
						const r = await this.props.client.mutate(mut)
						Utils.parseMutationResponse(r)
						if (item.callback) {
							item.callback(r)
						}
					} catch (e) {
						Utils.displayError(e)
					}
				}
			}
			let onclick = action
			if (item.confirm) {
				onclick = () =>
					Modal.confirm({
						title: item.confirm,
						okText: this.props.intl.formatMessage({ id: 'yes' }),
						okType: 'danger',
						cancelText: this.props.intl.formatMessage({ id: 'no' }),
						onOk: action
					})
			}

			let s = { position: 'relative' }
			if (item.disabled && !action) {
				s.cursor = 'text'
				s.color = 'rgba(0, 0, 0, 0.40)'
			}
			switch (item.size) {
				case 'small':
					s.lineHeight = '16px'
					s.padding = '2px 12px'
					break
				case 'smallTop':
					s.lineHeight = '16px'
					s.padding = '5px 12px 2px 12px'
					break
				case 'smallBottom':
					s.lineHeight = '16px'
					s.padding = '2px 12px 5px 12px'
					break
				default:
					break
			}

			return (
				<Menu.Item
					key={item.key || `action-${i}`}
					disabled={item.disabled}
					onClick={onclick}
					style={s}
					id={item.id}
					data-test={item.dataTest}
				>
					{item.icon && (
						<FontAwesomeIcon
							icon={item.icon}
							style={{ marginRight: 5 }}
							fixedWidth
						/>
					)}
					{item.title}
					{item.children}
				</Menu.Item>
			)
		})
	}

	render() {
		let menu
		if (this.props.menuItems && this.props.menuItems.length > 0) {
			menu = (
				<Menu style={{ minWidth: 200 }}>
					{this.buildMenu(this.props.menuItems)}
				</Menu>
			)
		} else if (this.props.customMenu) {
			menu = this.props.customMenu
		} else {
			return null
		}

		return (
			<Dropdown
				overlay={menu}
				trigger={['click']}
				placement="bottomRight"
			>
				<Button
					style={{
						...this.props.style,
						marginTop: 0,
						marginBottom: 0
					}}
					size={this.props.size || 'default'}
					data-test={this.props.dataTest}
				>
					<FontAwesomeIcon icon={this.props.icon || faEllipsisH} />
				</Button>
			</Dropdown>
		)
	}
}

export default withApollo(injectIntl(ActionsMenu))
