import React, { Component } from 'react'
import { Slider, Alert } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'

class VolumeSlider extends Component {
	state = {
		volume:
			parseFloat(this.props.volume) >= 1 ?
				(parseFloat(this.props.volume) - 1) * 100
			:	null
	}

	componentWillReceiveProps(nextProps) {
		if (parseFloat(nextProps.volume) >= 1) {
			this.setState({
				volume: (parseFloat(nextProps.volume) - 1) * 100
			})
		}
	}

	volumeChanged = (v) => {
		this.setState({
			volume: v
		})
		// Debounce the volume change for 100ms to avoid spamming the server in case the user move the slider too slowly
		clearTimeout(this.timeout)
		this.timeout = setTimeout(() => {
			this.props.volumeChanged(v / 100 + 1)
		}, 100)
	}

	color(volume) {
		if (volume > 150) {
			return 'red'
		} else if (volume > 100) {
			return 'orange'
		} else if (volume <= 5) {
			return 'red'
		} else if (volume <= 30) {
			return 'orange'
		}
		return 'green'
	}

	render() {
		if (this.state.volume === null) {
			return <FormattedMessage id="devices.player.volume.unknown" />
		}

		let marks = {
			0: '0%',
			50: '50%',
			100: '100%'
		}

		if (this.props.canHaveGain) {
			marks[150] = '150%'
			marks[200] = '200%'
		}

		const color = this.color(this.state.volume)

		return (
			<React.Fragment>
				<Slider
					className={`slider-${color}`}
					onChange={this.volumeChanged}
					value={this.state.volume}
					min={0}
					max={this.props.canHaveGain ? 200 : 100}
					marks={marks}
					disabled={this.props.disabled}
					tipFormatter={(value) => `${value}%`}
					style={this.props.style}
				/>
				{this.state.volume > 100 && this.props.showWarnings && (
					<Alert
						message={this.props.intl.formatMessage({
							id: 'devices.player.volume.highGainWarning'
						})}
						type="warning"
						showIcon
					/>
				)}
				{this.state.volume < 100 && this.props.showWarnings && (
					<Alert
						message={this.props.intl.formatMessage({
							id: 'devices.player.volume.lowGainWarning'
						})}
						type="warning"
						showIcon
					/>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(VolumeSlider)
