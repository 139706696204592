import React, { Component } from 'react'
import { Modal, Alert, Button } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faFile } from '@fortawesome/free-regular-svg-icons'
import {
	faMusic,
	faFolderPlus,
	faFileUpload,
	faTrash,
	faUsers
} from '@fortawesome/free-solid-svg-icons'

import * as Auth from '../../auth'
import Loading from '../../components/LoadingPage'
import ColumnBrowser from '../../components/ColumnBrowserTree'
import { displayGraphQLErrors } from '../../components/Utils'
import { MediaQuery } from './Queries'
import S from '../../components/Styles'
import { SelectTeamV2 } from '../teams/SelectTeam'
import * as Utils from '../../components/Utils'

const TEAM_KEY = 'mediaTeam'

class BrowseMedia extends Component {
	state = {
		error: null,
		uploading: false,
		deleting: false,
		creatingFolder: false,
		path: [],
		selectedItem: null,
		itemToRename: null,
		team:
			(
				Auth.hasPermission('messageProgram:read:mediaTeamSelector') &&
				localStorage.getItem(TEAM_KEY)
			) ?
				JSON.parse(localStorage.getItem(TEAM_KEY))
			:	Auth.cache.team
	}

	handleCancel = () => {
		this.props.onClose()
	}

	selectItem = (item, level = this.state.path.length, rename = false) => {
		let { path } = this.state
		let selectedItem = null

		if (level < path.length) {
			path.splice(level)
		}
		if (item) {
			if (item._id) {
				selectedItem = item
			} else {
				path.push(item.uuid)
			}
		}

		this.setState({
			selectedItem,
			path,
			itemToRename: rename ? path : null
		})
	}

	uploadFile = async (e) => {
		e.preventDefault()
		e.stopPropagation()

		const file = e.target.files[0]

		if (file && file.name) {
			try {
				this.setState({ uploading: true })
				const result = await this.props.client.mutate({
					variables: {
						name: file.name,
						file,
						teamId: this.enclosingFolderTeamId(),
						path: `/${this.state.path.join('/')}`
					},
					mutation: gql`
						mutation mediaUpload(
							$name: String!
							$file: Upload!
							$teamId: String!
							$path: String!
						) {
							mediaUpload(
								name: $name
								file: $file
								teamId: $teamId
								path: $path
							) {
								success
								message
								id
							}
						}
					`,
					refetchQueries: [
						{
							query: MediaQuery,
							variables: { teamId: this.state.team._id }
						}
					],
					awaitRefetchQueries: true
				})
				if (result.data.mediaUpload.id) {
					this.selectItem({ _id: result.data.mediaUpload.id })
				} else if (result.data.mediaUpload.message) {
					console.log(result.data.mediaUpload.message)
				}
				this.setState({ uploading: false })
			} catch (e) {
				this.setState({ uploading: false })
				Utils.displayError(e)
			}
		}
	}

	createFolder = async () => {
		try {
			this.setState({ creatingFolder: true })

			const result = await this.props.client.mutate({
				variables: {
					teamId: this.enclosingFolderTeamId(),
					newPath: `/${this.state.path.join('/')}`
				},
				mutation: gql`
					mutation createFolder($teamId: String!, $newPath: String!) {
						editFolder(teamId: $teamId, newPath: $newPath) {
							success
							message
							name
						}
					}
				`,
				refetchQueries: [
					{
						query: MediaQuery,
						variables: { teamId: this.state.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			if (result.data.editFolder.success && result.data.editFolder.name) {
				this.selectItem(
					{ name: result.data.editFolder.name },
					this.state.path.length,
					true
				) /// TODO
			} else if (result.data.editFolder.message) {
				console.log(result.data.editFolder.message)
			}
			this.setState({ creatingFolder: false })
		} catch (e) {
			this.setState({ creatingFolder: false })
			Utils.displayError(e)
		}
	}

	renameItem = async (newName) => {
		try {
			let variables = {
				teamId: this.state.team._id,
				newName
			}
			let mutation
			if (this.state.selectedItem) {
				// rename media
				variables.id = this.state.selectedItem._id
				variables.path = `/${this.state.path.join('/')}`
				variables.teamId =
					this.state.selectedItem.teamId || this.state.team._id
				mutation = gql`
					mutation renameMedia(
						$teamId: String!
						$id: String!
						$path: String!
						$newName: String!
					) {
						editMedia(
							teamId: $teamId
							id: $id
							oldPath: $path
							newPath: $path
							newName: $newName
						) {
							success
							message
						}
					}
				`
			} else {
				// rename folder
				let path = [...this.state.path]
				const uuid = path.splice(-1, 1)
				variables.path = `/${path.join('/')}`
				variables.uuid = uuid[0]
				variables.teamId = this.enclosingFolderTeamId()
				mutation = gql`
					mutation renameFolder(
						$teamId: String!
						$path: String!
						$uuid: String!
						$newName: String!
					) {
						editFolder(
							teamId: $teamId
							oldPath: $path
							newPath: $path
							uuid: $uuid
							newName: $newName
						) {
							success
							message
						}
					}
				`
			}
			const result = await this.props.client.mutate({
				variables,
				mutation,
				refetchQueries: [
					{
						query: MediaQuery,
						variables: { teamId: this.state.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			if (result.data.editMedia && result.data.editMedia.success) {
				this.selectItem(null, this.state.path.length)
			} else if (
				result.data.editFolder &&
				result.data.editFolder.success
			) {
				this.selectItem(null, this.state.path.length - 1)
			} else if (result.data.editMedia && result.data.editMedia.message) {
				console.log(result.data.editMedia.message)
			} else if (
				result.data.editFolder &&
				result.data.editFolder.message
			) {
				console.log(result.data.editFolder.message)
			}
		} catch (e) {
			Utils.displayError(e)
		}
	}

	deleteItem = async () => {
		Modal.confirm({
			title: this.props.intl.formatMessage({
				id: 'media.table.actions.delete.confirm'
			}),
			okText: this.props.intl.formatMessage({ id: 'yes' }),
			okType: 'danger',
			cancelText: this.props.intl.formatMessage({ id: 'no' }),
			onOk: async () => {
				try {
					this.setState({ deleting: true })
					const result = await this.props.client.mutate({
						variables: {
							teamId:
								this.state.selectedItem ?
									this.state.selectedItem.teamId ||
									this.state.team._id
								:	this.enclosingFolderTeamId(),
							path: `/${this.state.path.join('/')}`,
							mediaId:
								this.state.selectedItem ?
									this.state.selectedItem._id
								:	null
						},
						mutation: gql`
							mutation deleteMedia(
								$teamId: String!
								$path: String!
								$mediaId: String
							) {
								deleteMedia(
									teamId: $teamId
									path: $path
									mediaId: $mediaId
								) {
									success
									message
								}
							}
						`,
						refetchQueries: [
							{
								query: MediaQuery,
								variables: { teamId: this.state.team._id }
							}
						],
						awaitRefetchQueries: true
					})
					if (result.data.deleteMedia.success) {
						this.selectItem(
							null,
							this.state.path.length +
								(this.state.selectedItem ? 0 : -1)
						)
					} else if (result.data.deleteMedia.message) {
						console.log(result.data.deleteMedia.message)
					}
					this.setState({ deleting: false })
				} catch (e) {
					this.setState({ deleting: false })
					Utils.displayError(e)
				}
			}
		})
	}

	canEditSelectedItem = () => {
		// A file is selected
		if (this.state.selectedItem) {
			if (this.state.selectedItem.teamId) {
				return Auth.hasAccess(
					'media:edit',
					this.state.selectedItem.teamId
				)
			}
			// File is part of user's team
			return Auth.hasAccess('media:edit', this.state.team._id)
		}

		// A folder is selected
		return this.canEditEnclosingFolder()
	}

	enclosingFolderTeamId = () => {
		const { path } = this.state

		if (path.length > 0) {
			let folder = this.tree.folders[path[0]]
			if (folder && folder.teamId) {
				return folder.teamId
			}
		}

		return this.state.team._id
	}

	canEditEnclosingFolder = () => {
		const teamId = this.enclosingFolderTeamId()
		return Auth.hasAccess('media:edit', teamId)
	}

	render() {
		const { intl } = this.props
		return (
			<Modal
				width="80vw"
				bodyStyle={{ height: '70vh', padding: 0 }}
				visible={true}
				title={intl.formatMessage({
					id:
						this.props.selectMedia ?
							'messagePrograms.details.selectFile'
						:	'media.table.title'
				})}
				onCancel={this.handleCancel}
				footer={[
					<div style={styles.panelFooter} key="footer">
						<div style={styles.panelLeft}>
							{Auth.hasAccess(
								'media:add',
								this.state.team._id
							) && (
								<Button
									disabled={!this.canEditEnclosingFolder()}
									onClick={this.createFolder}
									loading={this.state.creatingFolder}
								>
									{!this.state.creatingFolder && (
										<FontAwesomeIcon icon={faFolderPlus} />
									)}
									&nbsp;&nbsp;
									<FormattedMessage id="media.table.actions.newFolder" />
								</Button>
							)}
							{Auth.hasAccess(
								'media:add',
								this.state.team._id
							) && (
								<Button
									loading={this.state.uploading}
									disabled={!this.canEditEnclosingFolder()}
								>
									{!this.state.uploading && (
										<FontAwesomeIcon icon={faFileUpload} />
									)}
									&nbsp;&nbsp;
									<FormattedMessage id="media.table.actions.uploadFile" />
									<input
										type="file"
										ref={(e) => (this.fileInput = e)}
										style={S.playlistDetails.fileInput}
										onChange={this.uploadFile}
									/>
								</Button>
							)}
							{Auth.hasAccess(
								'media:delete',
								this.state.team._id
							) &&
								(this.state.path.length > 0 ||
									this.state.selectedItem) && (
									<Button
										onClick={this.deleteItem}
										loading={this.state.deleting}
										disabled={!this.canEditSelectedItem()}
									>
										{!this.state.deleting && (
											<FontAwesomeIcon icon={faTrash} />
										)}
										&nbsp;&nbsp;
										<FormattedMessage id="media.table.actions.delete" />
									</Button>
								)}
						</div>
						<div style={styles.panelRight}>
							{Auth.hasPermission(
								'messageProgram:read:mediaTeamSelector'
							) && (
								<div style={{ marginRight: 10 }}>
									<FontAwesomeIcon icon={faUsers} />
									<SelectTeamV2
										style={{
											minWidth: 200,
											marginLeft: 10
										}}
										onChange={(t) => {
											localStorage.setItem(
												TEAM_KEY,
												JSON.stringify({
													_id: t._id,
													n: t.n
												})
											)
											this.setState({
												team: t,
												path: [],
												selectedItem: null
											})
										}}
										selected={
											this.state.team &&
											this.state.team._id
										}
										keyprops="_id"
										hideNoParents
									/>
								</div>
							)}
							{this.props.selectMedia && (
								<Button
									type="primary"
									onClick={(e) =>
										this.props.onClose(
											this.state.selectedItem
										)
									}
									disabled={
										!this.state.selectedItem ||
										!this.props.selectMedia(
											this.state.selectedItem
										)
									}
								>
									<FormattedMessage id="actions.select" />
								</Button>
							)}
						</div>
					</div>
				]}
			>
				<Query
					query={MediaQuery}
					variables={{
						teamId: this.state.team._id
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loading />
						}
						if (error || !data.teamMediaTree.tree) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={displayGraphQLErrors(error)}
									type="error"
									showIcon
								/>
							)
						}

						let result = JSON.parse(data.teamMediaTree.tree)
						this.mediasById = result.medias
						this.tree = result.tree

						return (
							<ColumnBrowser
								tree={this.tree}
								nodeLabel={(n) => (n && n.name ? n.name : null)}
								nodeSubtitle={(n) =>
									n && n.teamName ? n.teamName : null
								}
								isNodeFolder={(n) => n && n._id === undefined}
								nodeIcon={(n) => {
									if (n && n._id) {
										return n.isAudio ? faMusic : faFile
									}
									return faFolder
								}}
								nodeChildren={(n) => {
									if (!n || !n.media || !n.folders) {
										return null
									}

									let folders = []
									for (let f in n.folders) {
										folders.push(n.folders[f])
									}

									return [
										...folders,
										...n.media.map(
											(m) => this.mediasById[m]
										)
									].sort((a, b) =>
										a.name.localeCompare(b.name)
									)
								}}
								nodeDetails={(n) => this.mediasById[n]}
								path={this.state.path}
								selectItem={this.selectItem}
								selectedItem={this.state.selectedItem}
								rename={this.renameItem}
								canEdit={(n) =>
									Auth.hasAccess(
										'media:edit',
										n.teamId || this.state.team._id
									)
								}
								itemToRename={this.state.itemToRename}
							/>
						)
					}}
				</Query>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(BrowseMedia))

const styles = {
	panelFooter: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	panelLeft: {
		textAlign: 'left'
	},
	panelRight: {
		textAlign: 'right',
		display: 'flex'
	}
}
