import React, { Component } from 'react'
import { Modal, Form, Button, Card, DatePicker, Checkbox } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { RRule, rrulestr } from 'rrule'

import BrowseMedia from './BrowseMedia'
import S, { formItemLayout } from '../../components/Styles'
import config from '../../config'
import RRuleGenerator from '../../components/RRuleGenerator'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'

const FormItem = Form.Item

class EditEvent extends Component {
	state = {
		selectMedia: false,
		media: this.props.event.media,

		dtstart: null,
		recurrent: false,
		interval: 1,
		freq: RRule.DAILY,
		until: null,
		byweekday: [],
		bymonthday: [],
		limitHours: false,
		byHourStart: null,
		byHourEnd: null
	}

	componentDidMount() {
		if (this.props.event) {
			let rule = rrulestr(this.props.event.rule)

			// for rule sets we need to get the internal rule
			if (rule._exdate) {
				rule = rule._rrule[0]
			}

			let localTime = Utils.utcToLocalDate(rule.options.dtstart)
			let localTimeUntil = null
			if (rule.options.until) {
				localTimeUntil = Utils.utcToLocalDate(rule.options.until)
			}

			let byHourStart, byHourEnd
			let limitHours = false
			if (rule.options.byhour && rule.options.byhour.length > 1) {
				limitHours = true
				byHourStart = rule.options.byhour[0]
				byHourEnd =
					rule.options.byhour[rule.options.byhour.length - 1] + 1
			}

			this.setState({
				dtstart: localTime.toDate(),
				interval: rule.options.interval,
				freq: rule.options.freq || RRule.DAILY,
				until: localTimeUntil ? localTimeUntil.toDate() : null,
				recurrent: rule.options.count !== 1,
				bymonthday: rule.options.bymonthday || [],
				byweekday: rule.options.byweekday || [],
				byHourStart,
				byHourEnd,
				limitHours
			})
		}
	}

	handleSubmit = (e) => {
		e.preventDefault()

		let {
			dtstart,
			until,
			freq,
			interval,
			recurrent,
			byweekday,
			bymonthday,
			limitHours
		} = this.state

		let localTime = Utils.localToUtcDate(dtstart)
		let localTimeUntil = null
		if (until) {
			localTimeUntil = Utils.localToUtcDate(until)
		}
		interval = parseInt(interval, 10) || 1

		let byhour
		if (limitHours) {
			let byHourStart = parseInt(this.state.byHourStart, 10)
			let byHourEnd = parseInt(this.state.byHourEnd, 10)

			if (
				byHourStart >= 0 &&
				byHourStart < 23 &&
				byHourEnd > 0 &&
				byHourEnd <= 23
			) {
				if (byHourStart > byHourEnd) {
					byHourEnd += 24
				}
				let bh = []
				for (let i = byHourStart; i < byHourEnd; i++) {
					bh.push(`${i % 24}`)
				}
				byhour = bh.join(',')
			}
		}

		let rule = new RRule({
			dtstart: localTime.toDate(),
			until: localTimeUntil ? localTimeUntil.toDate() : null,
			freq,
			interval,
			count: recurrent ? null : 1,
			byweekday: freq === RRule.WEEKLY ? byweekday : null,
			bymonthday: freq === RRule.MONTHLY ? bymonthday : null,
			byhour
		})
		let oldRule = rrulestr(this.props.event.rule)

		// keep old rule exceptions
		if (oldRule && oldRule._exdate) {
			rule = Utils.updatedRuleSetWithRule(oldRule, rule)
		}

		console.log(rule.toString())

		this.props.onClose(
			this.props.event.id,
			rule.toString(),
			this.state.media
		)
	}

	handleDelete = (e) => {
		e.preventDefault()
		if (this.state.recurrent) {
			const { intl } = this.props
			Modal.confirm({
				title: intl.formatMessage({
					id: 'messagePrograms.details.delete.confirm.title'
				}),
				content: intl.formatMessage(
					{ id: 'messagePrograms.details.delete.confirm.message' },
					{
						date: intl.formatDate(this.props.event.start, {
							day: 'numeric',
							month: 'long',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit'
						})
					}
				),
				okText: intl.formatMessage({
					id: 'messagePrograms.details.confirm.all'
				}),
				okType: 'danger',
				cancelText: intl.formatMessage({
					id: 'messagePrograms.details.confirm.instanceOnly'
				}),
				onOk: () => {
					this.props.onClose(this.props.event.id)
				},
				onCancel: () => {
					// Build rule set
					let rruleSet = rrulestr(this.props.event.rule, {
						forceset: true
					})
					rruleSet.exdate(
						Utils.localToUtcDate(this.props.event.start).toDate()
					)

					this.props.onClose(
						this.props.event.id,
						rruleSet.toString(),
						this.state.media
					)
				}
			})
		} else {
			this.props.onClose(this.props.event.id)
		}
	}

	handleCancel = () => {
		this.props.onClose()
	}

	playAudio = () => {
		try {
			if (this.audio) {
				this.audio.pause()
			}
			const url =
				config.cdn ?
					`${config.cdn.baseUrl}${config.cdn.paths.messagesOptimized}no-meta/stereo/${this.state.media._id}`
				:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.messagesOptimized}/no-meta/stereo/${this.state.media._id}`
			this.audio = new Audio(url)
			this.audio.play()
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl } = this.props
		let footer = [
			<Button
				type="primary"
				htmlType="submit"
				key="submit"
				onClick={this.handleSubmit}
			>
				<FormattedMessage id="actions.save" />
			</Button>
		]

		if (this.props.event.id) {
			footer.splice(
				0,
				0,
				<Button
					type="danger"
					htmlType="submit"
					key="delete"
					onClick={this.handleDelete}
				>
					<FormattedMessage id="actions.delete" />
				</Button>
			)
		}

		return (
			<Modal
				visible={true}
				title={this.props.intl.formatMessage({
					id:
						this.props.event.id ?
							'messagePrograms.details.editSlot'
						:	'messagePrograms.details.newSlot'
				})}
				onCancel={this.handleCancel}
				footer={footer}
			>
				<Form>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.details.audioFile'
						})}
					>
						{this.state.media ?
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<div
									style={{
										...S.card.trackInfo.textContainer,
										textAlign: 'center'
									}}
								>
									<div>{this.state.media.name}</div>
									{Auth.hasPermission('*') && (
										<div
											style={{
												...S.emptyText,
												fontSize: 10
											}}
										>
											{this.state.media._id}
										</div>
									)}
									<div>{this.state.media.duration}</div>
									<Button
										onClick={this.playAudio}
										style={{ width: 100, marginTop: 10 }}
									>
										<FontAwesomeIcon icon={faPlay} />
									</Button>
									<Button
										onClick={() =>
											this.setState({ selectMedia: true })
										}
										style={{
											width: 100,
											marginTop: 10,
											marginLeft: 10
										}}
									>
										<FormattedMessage id="actions.change" />
									</Button>
								</div>
							</Card>
						:	<Button
								style={{ width: '100%', height: 50 }}
								onClick={() =>
									this.setState({ selectMedia: true })
								}
							>
								<FormattedMessage id="messagePrograms.details.selectFile" />
							</Button>
						}
						{this.state.selectMedia && (
							<BrowseMedia
								selectMedia={(media) => media.isAudio}
								onClose={(media) =>
									this.setState({
										selectMedia: false,
										media: media ? media : this.props.media
									})
								}
							/>
						)}
					</FormItem>

					<FormItem>
						<Checkbox
							checked={this.state.recurrent}
							onChange={(r) =>
								this.setState({ recurrent: r.target.checked })
							}
						>
							<FormattedMessage id="messagePrograms.details.edit.recurrent" />
						</Checkbox>
					</FormItem>

					{this.state.recurrent ?
						<RRuleGenerator
							event={this.state}
							onChange={(s) => this.setState(s)}
							limitHours
						/>
					:	<FormItem
							label={intl.formatMessage({
								id: 'messagePrograms.details.dateAndTime'
							})}
							{...formItemLayout}
						>
							<DatePicker
								showTime
								format="DD-MM-YYYY HH:mm"
								placeholder={intl.formatMessage({
									id: 'messagePrograms.details.dateAndTime'
								})}
								value={
									this.state.dtstart ?
										moment(this.state.dtstart)
									:	null
								}
								onChange={(v) => {
									if (v) {
										this.setState({ dtstart: v.toDate() })
									}
								}}
							/>
						</FormItem>
					}
				</Form>
			</Modal>
		)
	}
}

export default injectIntl(EditEvent)
