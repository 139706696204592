import gql from 'graphql-tag'

export const ThirdPartyDevicesTableQuery = gql`
	query thirdPartyDevicesTableQuery {
		thirdPartyDevices {
			_id
			name
			onlineDate
			team {
				_id
				n
				path
			}
		}
	}
`

export const ThirdPartyDeviceDetailsQuery = gql`
	query thirdPartyDevice($id: MongoID!) {
		thirdPartyDeviceById(_id: $id) {
			_id
			uuid
			name
			nickname
			teamId
			onlineDate
			location
			timeZone
			created
			programIds
			sonos {
				controlApiNotRegistered
				sonosErrorMessage
				groups {
					id
					name
					metadata {
						container {
							name
							type
							itemId
							itemType
						}
						currentTrack {
							trackId
							name
							imageUrl
							artistName
							durationMillis
							playlistId
						}
						playbackStatus {
							playbackState
							positionMillis
						}
					}
				}
			}
		}
	}
`

export const ThirdPartyDeviceProgramMutation = (
	thirdPartyDeviceId,
	programIds
) => ({
	variables: {
		id: thirdPartyDeviceId,
		programIds,
		programIdsUpdated: new Date()
	},
	mutation: gql`
		mutation updateThirdPartyDevicePrograms(
			$id: MongoID!
			$programIds: [String!]!
			$programIdsUpdated: Date
		) {
			thirdPartyDeviceUpdateById(
				_id: $id
				record: {
					programIds: $programIds
					programIdsUpdated: $programIdsUpdated
				}
			) {
				recordId
			}
		}
	`
})
