import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Button, Row, Col, Select, Modal } from 'antd'
import { injectIntl, FormattedMessage, FormattedTime } from 'react-intl'
import { Link } from 'react-router-dom'
import {
	faFastForward,
	faThumbsDown,
	faEdit,
	faTrash,
	faHistory,
	faPlay,
	faStop,
	faMusic,
	faVolumeUp,
	faBan,
	faHeadphones,
	faDice
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TrackInfoDisplay, { TrackQuery, PlaylistQuery } from './TrackInfoDisplay'
import PlayStatus from './PlayStatus'
import SelectPlaylist from './SelectPlaylist'
import { SelectProgramsModal } from './SelectProgram'
import ActionsMenu from '../../components/ActionsMenu'
import TextInputModal from '../../components/TextInputModal'
import History from '../tracks/PlayHistory'
import { DeviceDetailsQuery, DeviceActionMutation } from '../devices/Queries'
import OutputSettings from './OutputSettings'
import ResponsiveCard from '../../components/ResponsiveCard'
import { Mobile, Default } from '../../components/Responsive'
import { ProgramTracks } from './Tracks'
import Scheduler from '../messages/Scheduler'

import * as Constants from '../../components/Constants'
import S, { Colors } from '../../components/Styles'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'
import * as AudioPlayer from '../../components/AudioPlayer'

const MAX_LISTEN_TIME = 60 * 1000 * 30 // 30 minutes

class IPlayer extends Component {
	state = {
		playlistsModalOpened: false,
		programsModalOpened: false,
		messageProgramsModalOpened: false,
		renameZoneModal: false,
		historyModal: false,
		outputModal: false,
		playMessageHeader: false,
		message: localStorage.getItem(`message-${this.props.zone._id}`),
		liveListen: false,
		forceTrackModal: null,
		playerScheduleModal: false
	}

	componentWillReceiveProps(props) {
		if (this.state.liveListen) {
			if (
				props.zone.stateTrackId &&
				(props.zone.stateTrackId !== this.props.zone.stateTrackId ||
					props.zone.playState !== this.props.zone.playState)
			) {
				const now = new Date()
				if (
					now.getTime() - this.state.liveListenStart.getTime() >
					MAX_LISTEN_TIME
				) {
					// Can't play audio without user interaction on iOS so we need to stop
					console.log(`Stopped live preview after 30 minutes`)
					// Stop preview after a while
					AudioPlayer.stop()
					this.setState({ liveListen: false, liveListenStart: null })
				} else if (Utils.isClientIOS()) {
					// Play only 1 track on iOS
					console.log(
						`Stopped live preview because iOS can only handle 1 track`
					)
					AudioPlayer.stop()
					this.setState({ liveListen: false, liveListenStart: null })
				} else {
					console.log('Live previewing next track')
					this.playZoneTrack(props.zone)
				}
			}
		}
	}

	componentWillUnmount() {
		AudioPlayer.stop()
	}

	playZoneTrack = (zone) => {
		if (zone.playState === 4 || zone.playState === 1) {
			const now = new Date()
			const startTime = new Date(zone.stateTrackStartTime)
			const seekTime = (now.getTime() - startTime.getTime()) / 1000
			AudioPlayer.startTrack({
				track:
					zone.playState === 4 ?
						zone.stateMessageId
					:	zone.stateTrackId,
				crossfade: 3,
				startTime: seekTime,
				message: zone.playState === 4
			})
		} else {
			AudioPlayer.stop()
		}
	}

	canZoneForceTrack = () => {
		if (
			this.props.zone.stateProgram &&
			this.props.zone.stateProgram.playlists
		) {
			for (let pl of this.props.zone.stateProgram.playlists) {
				if (pl._id === this.props.zone.statePlaylistId) {
					return pl.canForceTrack
				}
			}
		}
		return false
	}

	render() {
		const { device, zone, index, onlyOne, intl, client } = this.props
		const isOffline = !Utils.isDeviceOnline(device)

		let zoneActions = []

		let canPlayMessages = false
		if (
			Auth.hasPermission('device:edit:playMessage') &&
			Auth.hasAccess('device:edit', device.teamId) &&
			zone.messagePrograms &&
			zone.messagePrograms.length > 0 &&
			!isOffline
		) {
			for (let mp of zone.messagePrograms) {
				if (mp && mp.medias && mp.medias.length > 0) {
					canPlayMessages = true
					break
				}
			}
		}
		const canForceTrack = this.canZoneForceTrack()

		// Output info
		zoneActions.push({
			title: intl.formatMessage({ id: 'devices.player.zone.output' }),
			key: 'output',
			disabled: true,
			size: 'smallTop'
		})

		let outputTitle = null
		let outputIcon = null

		let outputs = device.outputs
		if (outputs) {
			let selectedOutput = 0
			if (zone.output) {
				for (let i in outputs) {
					let o = outputs[i]
					if (
						zone.output.type === o.type &&
						zone.output.channel === o.channel
					) {
						selectedOutput = i
						break
					}
				}
			}

			outputTitle = outputs[selectedOutput].name
		} else if (zone.stateOutputType) {
			outputTitle = Utils.nameForOutputType(zone)
			outputIcon = Utils.iconForOutputType(zone.stateOutputType)
		}
		zoneActions.push({
			title:
				outputTitle ? outputTitle : (
					intl.formatMessage({
						id: 'devices.player.zone.output.unknown'
					})
				),
			icon: outputIcon,
			key: 'outputName',
			disabled: true,
			size: 'smallBottom'
		})

		if (
			(Auth.hasPermission('device:edit:setZoneVolume') &&
				Auth.hasAccess('device:edit', device.teamId)) ||
			(Auth.hasPermission('zone:edit:setOutput') &&
				Auth.hasAccess('zone:edit', device.teamId))
		) {
			zoneActions.push({
				title: intl.formatMessage({
					id: 'devices.player.zone.outputSettings'
				}),
				key: 'outputSettings',
				icon: faVolumeUp,
				action: () => {
					this.setState({ outputModal: true })
				}
			})
		}
		zoneActions.push({
			divider: true,
			key: 'divider'
		})

		// Zone actions
		if (
			!isOffline &&
			(zone.playState === Constants.PLAY_STATE_PLAYING ||
				zone.playState === Constants.PLAY_STATE_PLAYING_MESSAGE) &&
			Auth.hasPermission('device:read:liveListen') &&
			Auth.hasAccess('device:read', device.teamId)
		) {
			if (this.state.liveListen) {
				zoneActions.push({
					title: intl.formatMessage({
						id: 'devices.info.liveListen.stop'
					}),
					key: 'stopLiveListen',
					icon: faHeadphones,
					action: () => {
						AudioPlayer.stop()
						this.setState({
							liveListen: false,
							liveListenStart: null
						})
					}
				})
			} else {
				zoneActions.push({
					title: intl.formatMessage({
						id: 'devices.info.liveListen'
					}),
					key: 'liveListen',
					icon: faHeadphones,
					action: () => {
						this.playZoneTrack(zone)
						this.setState({
							liveListen: true,
							liveListenStart: new Date()
						})
					}
				})
			}
		}

		if (
			Auth.hasPermission('device:read:history') &&
			Auth.hasAccess('device:read', device.teamId)
		) {
			zoneActions.push({
				title: intl.formatMessage({
					id: 'devices.info.playbackHistory'
				}),
				key: 'showZoneHistory',
				icon: faHistory,
				action: () => {
					this.setState({ historyModal: true })
				}
			})
		}
		if (
			Auth.hasPermission('zone:edit:setName') &&
			Auth.hasAccess('zone:edit', device.teamId)
		) {
			zoneActions.push({
				title: intl.formatMessage({ id: 'devices.player.zone.rename' }),
				key: 'renameZone',
				icon: faEdit,
				action: () => {
					this.setState({ renameZoneModal: true })
				}
			})
		}
		if (Auth.hasAccess('zone:delete', device.teamId)) {
			zoneActions.push({
				title: intl.formatMessage({ id: 'devices.player.zone.delete' }),
				key: 'deleteZone',
				icon: faTrash,
				disabled: onlyOne,
				confirm: intl.formatMessage(
					{ id: 'devices.player.zone.delete.confirm' },
					{ name: zone.name }
				),
				mutation: {
					variables: { id: zone._id, deviceId: device._id },
					mutation: gql`
						mutation removeZone($id: String!, $deviceId: String!) {
							zoneRemoveById(zoneId: $id, deviceId: $deviceId) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: DeviceDetailsQuery,
							variables: { id: device._id }
						}
					]
				}
			})
		}

		let playlistSubtitle = ''
		if (
			zone.statePlaylistId &&
			zone.stateManualPlaylist &&
			zone.stateProgram
		) {
			if (
				zone.stateProgram.scheduleReset ||
				zone.stateProgram.scheduleReset === 0
			) {
				let date = new Date()
				date.setHours(0, zone.stateProgram.scheduleReset)
				playlistSubtitle = (
					<i>
						<FormattedMessage
							id="devices.player.manualWithReset"
							values={{ hour: <FormattedTime value={date} /> }}
						/>
					</i>
				)
			} else {
				playlistSubtitle = (
					<i>
						<FormattedMessage id="devices.player.manual" />
					</i>
				)
			}
		}

		let playlistActions = []
		if (
			Auth.hasPermission('device:edit:setZonePlaylist') &&
			Auth.hasAccess('device:edit', device.teamId) &&
			(zone.playState === Constants.PLAY_STATE_PLAYING ||
				zone.playState === Constants.PLAY_STATE_PLAYING_MESSAGE ||
				zone.playState === Constants.PLAY_STATE_SILENCE)
		) {
			playlistActions.push({
				title: intl.formatMessage({
					id: 'devices.player.forcePlaylist'
				}),
				key: 'forcePlaylist',
				icon: faEdit,
				action: () => {
					this.setState({ playlistsModalOpened: true })
				}
			})
		}

		let trackActions = []
		if (
			zone.playState === Constants.PLAY_STATE_PLAYING ||
			zone.playState === Constants.PLAY_STATE_PLAYING_MESSAGE
		) {
			if (
				Auth.hasPermission('device:edit:skipZoneTrack') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({ id: 'devices.player.skip' }),
					key: 'skip',
					icon: faFastForward,
					mutation: DeviceActionMutation(device, zone, 'skip')
				})
			}
			if (
				Auth.hasPermission('device:edit:blacklistTrack') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({
						id: 'devices.player.skipBlacklist'
					}),
					key: 'skipblacklist',
					icon: faThumbsDown,
					mutation: DeviceActionMutation(device, zone, 'blacklist')
				})
			}
			if (
				Auth.hasPermission('device:edit:playStopZone') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({ id: 'devices.player.stop' }),
					key: 'stop',
					icon: faStop,
					mutation: DeviceActionMutation(device, zone, 'stop')
				})
			}
			if (
				zone.playState === Constants.PLAY_STATE_PLAYING_MESSAGE &&
				Auth.hasPermission('device:edit:playStopZone') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({
						id: 'devices.player.stopMessage'
					}),
					key: 'stopMsg',
					icon: faStop,
					mutation: DeviceActionMutation(device, zone, 'stopMessage')
				})
			}
			if (
				Auth.hasPermission('device:edit:forceZoneTrack') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({
						id: 'devices.player.forceTrack'
					}),
					key: 'forceTrack',
					icon: faPlay,
					action: () => this.setState({ forceTrackModal: 'all' })
				})
			}
		} else if (
			zone.playState === Constants.PLAY_STATE_PAUSED ||
			zone.playState === Constants.PLAY_STATE_STOPPED
		) {
			if (
				Auth.hasPermission('device:edit:playStopZone') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({ id: 'devices.player.play' }),
					key: 'play',
					icon: faPlay,
					mutation: DeviceActionMutation(device, zone, 'play')
				})
			}
			if (
				Auth.hasPermission('device:edit:playStopZone') &&
				Auth.hasAccess('device:edit', device.teamId)
			) {
				trackActions.push({
					title: intl.formatMessage({ id: 'devices.player.stop' }),
					key: 'stop',
					icon: faStop,
					mutation: DeviceActionMutation(device, zone, 'stop')
				})
			}
		}

		let programColumn = [
			<div style={{ ...S.horizontal, alignItems: 'flex-start' }}>
				<FontAwesomeIcon
					icon={faMusic}
					fixedWidth
					style={{ marginTop: 5 }}
				/>
				<div style={{ paddingLeft: 5 }}>
					{zone.program ?
						<Link
							to={`/programs/${zone.program._id}`}
							title={zone.program.note || zone.program.name}
						>
							{zone.program.name}
						</Link>
					:	<span style={S.emptyText}>
							<FormattedMessage id="devices.player.program.none" />
						</span>
					}
					<br />
					{(
						zone.stateProgram &&
						zone.program &&
						zone.program._id !== zone.stateProgram._id
					) ?
						<span>
							<FormattedMessage
								id={
									isOffline ?
										'devices.player.program.current.offline'
									:	'devices.player.program.current'
								}
							/>
							<Link to={`/programs/${zone.stateProgram._id}`}>
								{zone.stateProgram.name}
							</Link>
						</span>
					:	''}
				</div>
			</div>
		]

		if (
			Utils.isDeviceDrax(device) &&
			device.team &&
			(device.team.messagesEnabled || device.team.parentMessagesEnabled)
		) {
			programColumn.push(
				<div
					style={{
						...S.horizontal,
						alignItems: 'flex-start',
						marginTop: 10
					}}
				>
					<FontAwesomeIcon
						icon={faCommentAlt}
						fixedWidth
						style={{ marginTop: 5 }}
					/>
					<div style={{ paddingLeft: 5 }}>
						{(
							zone.messagePrograms &&
							zone.messagePrograms.length > 0
						) ?
							<React.Fragment>
								{zone.messagePrograms.map((mp) => {
									if (!!mp) {
										return (
											<React.Fragment key={mp._id}>
												<Link
													to={`/messages/${mp._id}`}
													title={mp.name}
												>
													{mp.name}
												</Link>
												<br />
											</React.Fragment>
										)
									}
									return null
								})}
								{zone.messagePrograms.length > 1 && (
									<a
										onClick={() =>
											this.setState({
												playerScheduleModal: true
											})
										}
									>
										<FormattedMessage id="devices.player.viewAllMessages" />
									</a>
								)}
							</React.Fragment>
						:	<span style={S.emptyText}>
								<FormattedMessage id="devices.player.messageProgram.none" />
							</span>
						}
					</div>
				</div>
			)
		}

		return (
			<div
				className="ant-card ant-card-bordered"
				style={S.card.container}
			>
				<div className="ant-card-head">
					<ResponsiveCard
						className={this.state.playMessageHeader ? 'hide' : ''}
						title={
							<span
								title={zone.name}
							>{`${onlyOne ? '' : `#${index} `}${zone.name}`}</span>
						}
						actionsButton={
							<ActionsMenu
								menuItems={zoneActions}
								style={S.card.headerItem}
								dataTest="audio-zone-actions"
							/>
						}
					>
						<Default>
							{this.state.liveListen && (
								<Button
									style={{
										...S.card.headerItem,
										...Colors.status.lightBlue
									}}
								>
									<FontAwesomeIcon
										icon={faHeadphones}
										title={intl.formatMessage({
											id: 'devices.info.liveListen.inProgress'
										})}
									/>
								</Button>
							)}
							{canForceTrack && (
								<Button
									onClick={() =>
										this.setState({
											forceTrackModal:
												zone.statePlaylistId
										})
									}
									style={S.card.headerItem}
								>
									<FontAwesomeIcon icon={faDice} />
									&nbsp;
									<FormattedMessage id="devices.player.forceTrack" />
								</Button>
							)}
							{canPlayMessages && (
								<Button
									onClick={() =>
										this.setState({
											playMessageHeader: true
										})
									}
									style={S.card.headerItem}
								>
									<FontAwesomeIcon icon={faCommentAlt} />
									&nbsp;
									<FormattedMessage id="devices.player.playMessage" />
								</Button>
							)}
							<PlayStatus
								style={S.card.headerItem}
								device={device}
								zone={zone}
							/>
						</Default>
						<Mobile>
							<PlayStatus
								style={S.card.headerItem}
								device={device}
								zone={zone}
								className="header-button"
							/>
							{canPlayMessages && (
								<Button
									onClick={() =>
										this.setState({
											playMessageHeader: true
										})
									}
									style={S.card.headerItem}
									className="header-button"
								>
									<FontAwesomeIcon icon={faCommentAlt} />
									&nbsp;
									<FormattedMessage id="devices.player.playMessage" />
								</Button>
							)}
						</Mobile>
					</ResponsiveCard>
					{canPlayMessages && (
						<ResponsiveCard
							className={
								this.state.playMessageHeader ? '' : 'hide'
							}
							title={
								<FormattedMessage id="devices.player.playMessage" />
							}
							hideTitleOnMobile
						>
							<Select
								style={{ ...S.card.headerItem, width: 200 }}
								value={this.state.message}
								onSelect={(v) => {
									this.setState({ message: v })
									localStorage.setItem(
										`message-${this.props.zone._id}`,
										v
									)
								}}
								className="header-button"
								showSearch
								filterOption={(input, option) => {
									return (
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									)
								}}
							>
								{zone.messagePrograms
									.sort((messageA, messageB) =>
										messageA.name.localeCompare(
											messageB.name
										)
									)
									.map((mp) => {
										if (mp) {
											return mp.medias
												.filter(
													(media) => media !== null
												)
												.map((m, i) => (
													<Select.Option
														key={`${mp._id} ${m._id}`}
														value={m._id}
													>
														{m.name}
													</Select.Option>
												))
										}
										return null
									})}
							</Select>
							<Button
								className="header-button"
								type="primary"
								onClick={async () => {
									try {
										const r = await client.mutate(
											DeviceActionMutation(
												device,
												zone,
												'playMessage',
												this.state.message
											)
										)
										Utils.parseMutationResponse(r)
										this.setState({
											playMessageHeader: false
										})
									} catch (e) {
										Utils.displayError(e)
									}
								}}
								style={S.card.headerItem}
							>
								<FontAwesomeIcon icon={faPlay} />
								&nbsp;
								<FormattedMessage id="devices.player.playMessage.playNow" />
							</Button>
							<Button
								className="header-button"
								onClick={() =>
									this.setState({ playMessageHeader: false })
								}
								style={S.card.headerItem}
							>
								<FontAwesomeIcon icon={faBan} />
								&nbsp;
								<FormattedMessage id="actions.cancel" />
							</Button>
						</ResponsiveCard>
					)}
				</div>
				<div className="ant-card-body" style={S.card.body}>
					<Row>
						<Col xs={24} sm={24} md={8} style={S.mobileCol}>
							<TrackInfoDisplay
								title={intl.formatMessage({
									id: 'allPrograms'
								})}
								edit={
									Auth.hasPermission('zone:edit:setProgram') ?
										() =>
											this.setState({
												programsModalOpened: true
											})
									:	null
								}
								texts={programColumn}
							/>
						</Col>
						{!isOffline && (
							<Col xs={24} sm={24} md={8} style={S.mobileCol}>
								<PlaylistQuery
									playlistId={zone.statePlaylistId}
									playlistActions={playlistActions}
									playlistSubtitle={playlistSubtitle}
								/>
							</Col>
						)}
						{!isOffline && zone.stateTrackId && (
							<Col xs={24} sm={24} md={8} style={S.mobileCol}>
								<TrackQuery
									playState={zone.playState}
									trackId={zone.stateTrackId}
									trackActions={trackActions}
								/>
							</Col>
						)}
					</Row>
					{this.state.programsModalOpened && (
						<SelectProgramsModal
							device={device}
							zone={zone}
							onClose={() =>
								this.setState({ programsModalOpened: false })
							}
						/>
					)}
					{this.state.playlistsModalOpened && (
						<SelectPlaylist
							device={device}
							zone={zone}
							onClose={() =>
								this.setState({ playlistsModalOpened: false })
							}
						/>
					)}
					{this.state.renameZoneModal && (
						<TextInputModal
							initialValue={zone.name}
							title={intl.formatMessage({
								id: 'devices.player.zone.rename'
							})}
							actionLabel={intl.formatMessage({
								id: 'actions.rename'
							})}
							placeholder={intl.formatMessage({ id: 'zone' })}
							onClose={() =>
								this.setState({ renameZoneModal: false })
							}
							mutation={(name) => ({
								variables: { id: zone._id, name },
								mutation: gql`
									mutation updateZoneName(
										$id: String!
										$name: String!
									) {
										zoneUpdateById(
											_id: $id
											record: { name: $name }
										) {
											recordId
										}
									}
								`
							})}
						/>
					)}
					{this.state.historyModal && (
						<History
							device={device}
							zone={zone}
							onClose={() =>
								this.setState({ historyModal: false })
							}
						/>
					)}
					{this.state.outputModal && (
						<OutputSettings
							device={device}
							zone={zone}
							onClose={() =>
								this.setState({ outputModal: false })
							}
						/>
					)}
					{this.state.forceTrackModal && (
						<Modal
							title={intl.formatMessage({
								id: 'devices.player.forceTrack.title'
							})}
							visible={true}
							onCancel={() =>
								this.setState({ forceTrackModal: false })
							}
							footer={[]}
						>
							<ProgramTracks
								program={zone.stateProgramId || zone.program}
								device={device}
								playlist={
									this.state.forceTrackModal === 'all' ?
										null
									:	this.state.forceTrackModal
								}
								onSelect={async (track) => {
									try {
										const r = await client.mutate(
											DeviceActionMutation(
												device,
												zone,
												'forceTrack',
												track
											)
										)
										Utils.parseMutationResponse(r)

										Modal.confirm({
											title: intl.formatMessage({
												id: 'devices.player.forcePlaylist.skipOrLetFinish'
											}),
											okText: intl.formatMessage({
												id: 'devices.player.forcePlaylist.skip'
											}),
											okType: 'danger',
											cancelText:
												this.props.intl.formatMessage({
													id: 'devices.player.forcePlaylist.letFinish'
												}),
											onOk: async () => {
												try {
													const r =
														await client.mutate(
															DeviceActionMutation(
																device,
																zone,
																'skip'
															)
														)
													Utils.parseMutationResponse(
														r
													)
												} catch (e) {
													Utils.displayError(e)
												}

												this.setState({
													forceTrackModal: false
												})
											},
											onCancel: () => {
												this.setState({
													forceTrackModal: false
												})
											}
										})
									} catch (e) {
										Utils.displayError(e)
									}
								}}
								canBlacklist
							/>
						</Modal>
					)}
					{this.state.playerScheduleModal && (
						<Modal
							title={intl.formatMessage(
								{ id: 'devices.player.playerSchedule' },
								{
									player: device.nickname || device.name,
									zone: zone.name
								}
							)}
							visible={true}
							onCancel={() =>
								this.setState({ playerScheduleModal: false })
							}
							width="80vw"
							footer={[]}
						>
							<Scheduler messagePrograms={zone.messagePrograms} />
						</Modal>
					)}
				</div>
			</div>
		)
	}
}

export default withApollo(injectIntl(IPlayer))
