export const EVENT_COME_ONLINE = 1
export const EVENT_GO_OFFLINE = 2
export const EVENT_PLAY_TRACK = 3
export const EVENT_SKIP = 4
export const EVENT_BLACKLIST = 5
export const EVENT_STOP = 6
export const EVENT_SELECT_PLAYLIST = 7
export const EVENT_RAN_OUT = 8
export const EVENT_DOWNLOAD = 9
export const EVENT_STOP_DOWNLOAD = 10
export const EVENT_LOG = 11
export const EVENT_WARNING = 12
export const EVENT_ERROR = 13
export const EVENT_PAUSE = 14
export const EVENT_VOLUME = 15
export const EVENT_GO_INACTIVE = 16
export const EVENT_PLAY_MESSAGE_SCHEDULED = 17
export const EVENT_PLAY_MESSAGE_MANUAL = 18
export const EVENT_LOGOUT = 20

export const PLAY_STATE_STOPPED = 0
export const PLAY_STATE_PLAYING = 1
export const PLAY_STATE_PAUSED = 2
export const PLAY_STATE_SILENCE = 3
export const PLAY_STATE_PLAYING_MESSAGE = 4

export const DL_STATE_NOT_DOWNLOADING = 0
export const DL_STATE_DOWNLOADING = 1

export const ONLINE_STATE_OFFLINE = 0
export const ONLINE_STATE_INACTIVE = 1
export const ONLINE_STATE_ONLINE = 2

export const SYNC_STATE_UPTODATE = 1
export const SYNC_STATE_SYNCING = 2
export const SYNC_STATE_OUTDATED = 3
export const SYNC_STATE_NEVERSYNCED = 4

export const DRAX_OS = 'Spectre OS'
export const DRAX_OS_NEW = 'DraxOS'
export const MAC_OS = 'Mac OS X'
