import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import { Alert, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import Loading from '../../components/LoadingPage'
import { displayGraphQLErrors } from '../../components/Utils'
import UsersTable from './UsersTable'

class UserSearchModal extends Component {
	render() {
		const { intl, onClose } = this.props
		return (
			<Modal
				visible={true}
				title={intl.formatMessage({ id: 'teams.tree.searchUsers' })}
				footer={[]}
				width="80vw"
				onCancel={onClose}
			>
				<Query
					query={gql`
						query user {
							users {
								_id
								username
								firstName
								lastName
								team {
									_id
									n
									a
									path
								}
							}
						}
					`}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={this.props.intl.formatMessage({
										id: 'error'
									})}
									description={displayGraphQLErrors(error)}
									type="error"
									showIcon
								/>
							)
						}

						return (
							<UsersTable
								users={data.users}
								defaultColumns={['name', 'email', 'team']}
								showSearch
							/>
						)
					}}
				</Query>
			</Modal>
		)
	}
}

export default injectIntl(UserSearchModal)
