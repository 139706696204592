import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import uniq from 'lodash/uniq'
import { injectIntl } from 'react-intl'
import { RRule, rrulestr } from 'rrule'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import uuid from 'uuid/v4'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import EditScheduleEvent from './EditScheduleEvent'
import { Colors } from '../../components/Styles'
import * as Utils from '../../components/Utils'
import { openCustomConfirmModal } from '../../components/CustomConfirmModal'

const localizer = momentLocalizer(moment)

const formats = {
	dayFormat: (date, culture, localizer) =>
		localizer.format(date, 'ddd D', culture),

	timeGutterFormat: (time, culture, localizer) =>
		localizer.format(time, 'HH:mm', culture),

	eventTimeRangeFormat: ({ start }, culture, localizer) =>
		localizer.format(start, 'HH:mm', culture)
}

const DnDCalendar = withDragAndDrop(Calendar)

class Scheduler extends Component {
	state = {
		calendarEvents: null,
		events: {},
		editing: null,
		height: null,
		start: null,
		end: null
	}

	componentDidMount() {
		this.createEvents(this.props)
	}

	componentWillReceiveProps(props) {
		if (
			(props.messageProgram &&
				(this.props.messageProgram._id !== props.messageProgram._id ||
					this.props.messageProgram.updated !==
						props.messageProgram.updated)) ||
			props.messagePrograms
		) {
			this.createEvents(props)
		}
	}

	programEvents = (messageProgram, color) => {
		let events = {}
		if (messageProgram.schedule) {
			const schedule = JSON.parse(messageProgram.schedule)
			let medias = {}
			for (let f of messageProgram.medias.filter(
				(media) => media !== null
			)) {
				medias[f._id] = f
			}

			for (let evt of schedule) {
				if (evt.rule) {
					const id = uuid()
					events[id] = {
						...evt,
						id,
						media: medias[evt.media],
						color
					}
				}
			}
		}
		return events
	}

	createEvents = (props) => {
		let events = {}
		if (props.messageProgram) {
			events = this.programEvents(props.messageProgram)
		} else if (props.messagePrograms) {
			for (let i in props.messagePrograms) {
				const prog = props.messagePrograms[i]
				events = {
					...events,
					...this.programEvents(
						prog,
						Colors.playlistColors[i % Colors.playlistColors.length]
					)
				}
			}
		}

		let start = this.state.start
		if (!start) {
			start = moment().weekday(0).hour(0).minute(0).second(0).toDate()
		}

		let end = this.state.end
		if (!end) {
			end = moment()
				.weekday(6)
				.add(1, 'days')
				.hour(0)
				.minute(0)
				.second(0)
				.toDate()
		}

		let calendarEvents = this.createCalendarEvents(events, start, end)

		// console.log(events, calendarEvents)
		this.setState({ events, calendarEvents, start, end })
	}

	setRange = (dates) => {
		if (dates.length > 0) {
			const start = dates[0]
			const end = moment(dates[dates.length - 1])
				.add(1, 'days')
				.toDate()
			const calendarEvents = this.createCalendarEvents(
				this.state.events,
				start,
				end
			)

			this.setState({
				start,
				end,
				calendarEvents
			})
		}
	}

	createCalendarEvents = (
		events,
		from = this.state.start,
		until = this.state.end
	) => {
		let calendarEvents = []
		for (let id in events) {
			const evt = events[id]
			const eventRule = rrulestr(evt.rule)
			const localFrom = Utils.localToUtcDate(from)
			const localUntil = Utils.localToUtcDate(until)

			const eventTimes = eventRule.between(
				localFrom.toDate(),
				localUntil.toDate()
			)

			// The times we're getting from rrule are UTC times but the browser converts them to local times
			// What we want are local times with the same hour as the UTC time
			// So we subtract the local tz offset from the UTC time
			const eventDayEvents = eventTimes.map((e) => {
				let localTime = Utils.utcToLocalDate(e)
				return {
					id: evt.id,
					start: localTime.toDate(),
					end: localTime.add(30, 'minutes').toDate()
				}
			})

			calendarEvents = [...calendarEvents, ...eventDayEvents]
		}
		return calendarEvents
	}

	onEventDrop = (drop) => {
		if (this.props.editable && drop.event.id) {
			const event = this.state.events[drop.event.id]

			if (event) {
				// update rule with start date
				let oldRule = rrulestr(event.rule)
				let localTime = Utils.localToUtcDate(drop.start)
				let oldRuleSet

				// for rule sets we need to get the internal rule
				if (oldRule._exdate) {
					oldRuleSet = oldRule
					oldRule = oldRule._rrule[0]
				}

				const eventStartDate = Utils.localToUtcDate(drop.event.start)
				const oldStartDate = moment(oldRule.options.dtstart)

				const editRule = () => {
					let newRule = new RRule({
						dtstart: localTime.toDate(),
						freq: oldRule.options.freq,
						until: oldRule.options.until,
						interval: oldRule.options.interval,
						count: oldRule.options.count,
						byweekday: oldRule.options.byweekday,
						bymonthday: oldRule.options.bymonthday
					})

					// If old rule was a set recreate it
					if (oldRuleSet) {
						newRule = Utils.updatedRuleSetWithRule(
							oldRuleSet,
							newRule
						)
					}

					this.setEvent(drop.event.id, newRule.toString())
				}

				// For reccuring events, ask change occurence only or all except for the first event
				if (
					oldRule.options.count !== 1 &&
					!eventStartDate.isSame(oldStartDate)
				) {
					const { intl } = this.props
					openCustomConfirmModal({
						title: intl.formatMessage({
							id: 'messagePrograms.details.edit.confirm.title'
						}),
						content: intl.formatMessage(
							{
								id: 'messagePrograms.details.edit.confirm.message'
							},
							{
								date: intl.formatDate(drop.event.start, {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit'
								})
							}
						),
						primaryActionText: intl.formatMessage({
							id: 'messagePrograms.details.confirm.all'
						}),
						secondaryActionText: intl.formatMessage({
							id: 'messagePrograms.details.confirm.instanceOnly'
						}),
						cancelText: intl.formatMessage({
							id: 'actions.cancel'
						}),
						onPrimaryAction: () => {
							// Edit all occurences
							// Keep start date on the same day but change time
							localTime = Utils.localToUtcDate(
								drop.start,
								oldStartDate.toDate()
							) // The offset needs to be calculated based on the old date
							localTime.date(oldStartDate.date())
							localTime.month(oldStartDate.month())
							localTime.year(oldStartDate.year())

							editRule()
						},
						onSecondaryAction: () => {
							// Edit single occurence
							// Delete specific event and create a new identical one at the dropped time
							this.setState((state) => {
								let events = state.events

								// Exclude drop date from event
								let rruleSet = rrulestr(event.rule, {
									forceset: true
								})
								rruleSet.exdate(
									Utils.localToUtcDate(
										drop.event.start
									).toDate()
								)
								events[event.id].rule = rruleSet.toString()

								// Create new single event at drop date
								let newEvent = {
									id: uuid(),
									rule: new RRule({
										dtstart: Utils.localToUtcDate(
											drop.start
										).toDate(),
										count: 1
									}).toString(),
									media: event.media
								}
								events[newEvent.id] = newEvent

								const calendarEvents =
									this.createCalendarEvents(events)
								return { calendarEvents, events }
							}, this.saveEvents)
						}
					})
				} else {
					editRule()
				}
			}
		}
	}

	setEvent = (id, rule = null, media = null) => {
		if (this.props.editable) {
			this.setState((state) => {
				let events = state.events
				if (rule || media) {
					let event = state.events[id]

					if (event) {
						if (rule) {
							event.rule = rule
						}
						if (media) {
							event.media = media
						}

						events[id] = event
					}
				} else {
					delete events[id]
				}

				const calendarEvents = this.createCalendarEvents(events)

				return { calendarEvents, events }
			}, this.saveEvents)
		}
	}

	saveEvents = async () => {
		try {
			let mediaIds = [...this.props.messageProgram.mediaIds]
			let savedEvents = []
			for (let evtId in this.state.events) {
				const event = this.state.events[evtId]
				if (event.media) {
					mediaIds.push(event.media._id)
					savedEvents.push({
						rule: event.rule,
						media: event.media._id
					})
				}
			}

			await this.props.save(savedEvents, uniq(mediaIds))
		} catch (e) {
			Utils.displayError(e)
		}
	}

	handleSelect = (calEvent) => {
		if (this.props.editable) {
			this.setState((state) => {
				let event
				let events = { ...state.events }
				if (calEvent.id) {
					event = this.state.events[calEvent.id]
				} else {
					// The dialog subtracts the offset so that the UTC date from events show in local time
					// But calEvent.start is already in local time, so we compensate here
					let localTime = Utils.localToUtcDate(calEvent.start)
					event = {
						id: uuid(),
						rule: new RRule({
							dtstart: localTime.toDate(),
							count: 1
						}).toString()
					}
					events[event.id] = event
				}
				event.start = calEvent.start

				return {
					editing: event,
					events
				}
			})
		}
	}

	titleAccessor = (calEvent) => {
		const event = this.state.events[calEvent.id]
		if (event) {
			return event.media ?
					event.media.name
				:	this.props.intl.formatMessage({
						id: 'messagePrograms.details.noFileSelected'
					})
		}
		return ''
	}

	render() {
		return (
			<div className="noAllDay noResize">
				<DnDCalendar
					localizer={localizer}
					events={this.state.calendarEvents || []}
					formats={formats}
					selectable={this.props.editable}
					style={{ height: this.state.height }}
					views={['week']}
					defaultView="week"
					drilldownView="week"
					step={5}
					timeslots={12}
					defaultDate={new Date()}
					showMultiDayTimes={true}
					titleAccessor={this.titleAccessor}
					tooltipAccessor={this.titleAccessor}
					onEventDrop={this.onEventDrop}
					onEventResize={() => {}}
					onSelectSlot={this.handleSelect}
					onSelectEvent={this.handleSelect}
					onRangeChange={this.setRange}
					onView={(e) =>
						this.setState({ height: e === 'month' ? 600 : null })
					}
					eventPropGetter={(calEvent) => {
						const event = this.state.events[calEvent.id]
						if (event) {
							return {
								style: {
									backgroundColor:
										event.media ?
											event.color ?
												event.color
											:	Colors.status.blue.backgroundColor
										:	Colors.status.orange.backgroundColor,
									border: `1px solid ${
										event.media ?
											event.color ?
												'#FFF'
											:	Colors.status.blue.color
										:	Colors.status.orange.borderColor
									}`,
									color:
										event.media ?
											event.color ?
												'#FFF'
											:	Colors.status.blue.color
										:	Colors.status.orange.color,
									fontSize: 8
								}
							}
						}
						return null
					}}
				/>
				{this.state.editing && (
					<EditScheduleEvent
						event={this.state.editing}
						onClose={(id, rule, media) => {
							this.setState({ editing: false })
							if (id) {
								this.setEvent(id, rule, media)
							}
						}}
					/>
				)}
			</div>
		)
	}
}

export default injectIntl(Scheduler)
