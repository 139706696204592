import React, { Component } from 'react'
import { Form, Button, Tag } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faUsers, faMusic } from '@fortawesome/free-solid-svg-icons'

import AppLayout from '../../components/PageLayout'
import { SelectTeamV2 } from '../teams/SelectTeam'
import { SelectProgram } from '../tracks/SelectProgram'
import { DevicesTableQuery } from './Queries'
import { validateTeam } from './PairDevice'
import * as Utils from '../../components/Utils'

class SonosPairDevice extends Component {
	state = {
		error: null,
		devId: this.props.match.params.devId,
		team: this.props.team,
		program: null
	}

	sendCode = async () => {
		const { client, history } = this.props

		try {
			this.setState({ loading: true })

			const timeZone = new URLSearchParams(
				this.props.location.search
			).get('timeZone')

			const r = await client.mutate({
				variables: {
					deviceId: this.state.devId,
					uuid: this.props.match.params.uuid,
					teamId: this.state.team && this.state.team._id,
					programId: this.state.program,
					timeZone
				},
				mutation: gql`
					mutation setupSonosDevice(
						$deviceId: String!
						$uuid: String!
						$teamId: String
						$programId: String
						$timeZone: String
					) {
						setupSonosDevice(
							deviceId: $deviceId
							uuid: $uuid
							teamId: $teamId
							programId: $programId
							timeZone: $timeZone
						) {
							success
							deviceId
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: DevicesTableQuery
					}
				]
			})

			this.setState({ loading: false })
			Utils.parseMutationResponse(r)
			const res = r.data.setupSonosDevice
			if (res && res.success) {
				// close modal
				history.push(`/error/200/SONOS_SUCCESS/`) // using the error page to display the success message
				window.location.replace('sonos://') // Redirect to the Sonos app
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		return (
			<AppLayout hideNav>
				<div style={styles.body}>
					<h3>
						<FormattedMessage id="devices.pair.sonosTitle" />
					</h3>

					<Form style={{ width: '100%', marginTop: 40 }}>
						<Form.Item
							label={
								<React.Fragment>
									<Icon icon={faUsers} fixedWidth />
									&nbsp;
									<FormattedMessage id="team" />
								</React.Fragment>
							}
						>
							<SelectTeamV2
								onChange={(team) => this.setState({ team })}
								selected={
									this.state.team && this.state.team._id
								}
								keyProp="_id"
								withDevices
								hideNoParents
							/>
							{this.state.team &&
								!validateTeam(this.state.team) && (
									<Tag color="red">
										<FormattedMessage id="devices.pair.tooManyDevices" />
									</Tag>
								)}
						</Form.Item>
						<Form.Item
							label={
								<React.Fragment>
									<Icon icon={faMusic} fixedWidth />
									&nbsp;
									<FormattedMessage id="program" />
								</React.Fragment>
							}
						>
							<SelectProgram
								onSelect={(p) => this.setState({ program: p })}
								selectedId={this.state.program}
								showPlaylists
							/>
						</Form.Item>

						<Form.Item style={{ marginTop: 30 }}>
							<Button
								key="submit"
								ref={(b) => (this.submit = b)}
								type="primary"
								onClick={this.sendCode}
								loading={this.state.loading}
								disabled={
									!this.state.devId ||
									!this.state.program ||
									!validateTeam(this.state.team)
								}
							>
								<FormattedMessage id="devices.pair.pairAction" />
							</Button>
						</Form.Item>
					</Form>
				</div>
			</AppLayout>
		)
	}
}

const styles = {
	body: {
		padding: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
}

export default withApollo(injectIntl(SonosPairDevice))
