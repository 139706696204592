import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import {
	Row,
	Col,
	Card,
	Progress,
	Divider,
	Modal,
	Button,
	Input,
	Tooltip,
	Alert,
	DatePicker
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faSync,
	faSyncAlt,
	faPowerOff,
	faThumbsDown,
	faHistory,
	faCloudDownloadAlt,
	faLightbulb,
	faAlignJustify,
	faBroom,
	faTerminal,
	faBan,
	faClipboard,
	faGlobe,
	faTachometerAlt,
	faWrench,
	faStethoscope,
	faAmbulance,
	faCheckDouble,
	faLocationArrow,
	faBug
} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt, faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'

import Tracks from '../tracks/Tracks'
import S from '../../components/Styles'
import {
	formatBytes,
	deviceTypeIcon,
	deviceSync,
	displayIO,
	displayLoad
} from '../../components/Utils'
import DetailsProperty from '../../components/DetailsProperty'
import ActionsMenu from '../../components/ActionsMenu'
import History from '../tracks/PlayHistory'
import Stats from './Stats'
import Updates from './Updates'
import Reboot from './Reboot'
import TimeZone from './TimeZone'
import * as Auth from '../../auth'
import { DeviceDetailsQuery, DeviceActionMutation } from './Queries'
import * as Utils from '../../components/Utils'
import TextInputModal from '../../components/TextInputModal'
import * as Constants from '../../components/Constants'
import Blame from '../../components/Blame'
import FormattedTimeSince from '../../utils/FormattedTimeSince'

class DeviceInfo extends Component {
	state = {
		blacklistModal: false,
		historyModal: false,
		updateModal: false,
		statsModal: false,
		timeZoneModal: false,
		sshLoading: false,
		filePathModal: false,
		autoRebootModal: false,
		installDateLoading: false,
		locationPopup: false
	}

	render() {
		const { device, intl } = this.props
		const sync = deviceSync(
			device,
			Auth.hasPermission('device:read:syncStatusDate')
		)

		const actionMutation = (command, param) => ({
			variables: { id: device._id, command, param },
			mutation: gql`
				mutation sendDeviceCommand(
					$id: String!
					$command: String!
					$param: String
				) {
					sendDeviceCommand(
						_id: $id
						command: $command
						param: $param
					) {
						success
						message
					}
				}
			`
		})

		let systemActions = []
		let debugActions = []

		if (Utils.isDeviceDrax(device)) {
			if (Auth.hasPermission('device:read:logs')) {
				debugActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.mpdStatus'
					}),
					key: 'mpdStatus',
					icon: faTachometerAlt,
					mutation: actionMutation('mpdStatus')
				})
			}
		}
		if (Auth.hasPermission('device:read:logs')) {
			debugActions.push({
				title: intl.formatMessage({
					id: 'devices.info.actions.fixSocket'
				}),
				key: 'fixSocket',
				icon: faWrench,
				mutation: actionMutation('fixSocket')
			})
		}
		if (Utils.isDeviceDrax(device)) {
			if (Auth.hasPermission('device:edit:clean')) {
				debugActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.clean'
					}),
					key: 'clean',
					icon: faBroom,
					confirm: intl.formatMessage({
						id: 'devices.info.actions.clean.confirm'
					}),
					mutation: actionMutation('clean')
				})
			}
			if (Auth.hasPermission('device:edit:clean')) {
				debugActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.printFile'
					}),
					key: 'printFile',
					icon: faFileAlt,
					action: () => this.setState({ filePathModal: true })
				})
			}
			if (Auth.hasPermission('device:edit:ssh')) {
				debugActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.ssh'
					}),
					key: 'ssh',
					icon: faTerminal,
					action: async () => {
						try {
							this.setState({ sshLoading: true })
							const r = await this.props.client.mutate({
								variables: { id: device._id },
								mutation: gql`
									mutation startDeviceSSH($id: String!) {
										startDeviceSSH(_id: $id) {
											success
											message
										}
									}
								`,
								refetchQueries: [
									{
										query: DeviceDetailsQuery,
										variables: { id: device._id }
									}
								],
								awaitRefetchQueries: true
							})
							Utils.parseMutationResponse(r)
						} catch (e) {
							Utils.displayError(e)
						}
						this.setState({ sshLoading: false })
					}
				})
			}
			if (
				Auth.hasPermission('device:read:autoReboot') &&
				device.type === 'drax'
			) {
				debugActions.push({
					title: (
						<FormattedMessage
							id="devices.info.actions.autoReboot"
							values={{ time: Utils.displayRebootTime(device) }}
						/>
					),
					key: 'autoReboot',
					icon: faSyncAlt,
					action: () => this.setState({ autoRebootModal: true })
				})
			}

			if (
				Auth.hasPermission('device:read:autoUpdates') &&
				device.type === 'drax'
			) {
				systemActions.push({
					title: (
						<FormattedMessage
							id="devices.info.actions.updates"
							values={{ time: Utils.displayUpdateTime(device) }}
						/>
					),
					key: 'updates',
					icon: faCloudDownloadAlt,
					action: () => this.setState({ updateModal: true })
				})
			}
			if (
				Auth.hasPermission('device:edit:setTimeZone') &&
				device.type !== 'drax-mac'
			) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.setTimeZone'
					}),
					key: 'setTimeZone',
					icon: faGlobe,
					action: () => this.setState({ timeZoneModal: true })
				})
			}
			if (
				Auth.hasPermission('device:edit:identify') &&
				device.type === 'drax'
			) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.identify'
					}),
					key: 'identify',
					icon: faLightbulb,
					mutation: actionMutation('identify')
				})
			}
			if (
				Auth.hasPermission('device:edit:identify') &&
				device.type === 'drax'
			) {
				debugActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.selfTest'
					}),
					key: 'selfTest',
					icon: faCheckDouble,
					mutation: actionMutation('selfTest')
				})
			}
			if (Auth.hasPermission('device:read:logs')) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewLogs'
					}),
					key: 'showLogs',
					icon: faAlignJustify,
					action: () => {
						if (Utils.isDeviceVersionAbove(device, '3.0.0')) {
							window.open(
								`https://app.datadoghq.eu/logs?query=service%3Adrax%20uuid%3A${device.uuid}`,
								'_blank'
							)
						} else {
							window.open(
								`https://my.eu-01.cloud.solarwinds.com/205931726424363008/logs?q=host%3A%22${device.name}%22`,
								'_blank'
							)
						}
					}
				})
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewAnomalies'
					}),
					key: 'showAnomalies',
					icon: faBug,
					action: () => {
						window.open(
							`https://spectre-yh.sentry.io/issues/?project=1332808&query=uuid%3A${device.uuid}`,
							'_blank'
						)
					}
				})
			}
			if (Auth.hasPermission('device:read:fullHistory')) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.stats'
					}),
					key: 'stats',
					icon: faCalendarAlt,
					action: () => this.setState({ statsModal: true })
				})
			}
		}
		if (debugActions.length > 0) {
			systemActions.push({
				title: intl.formatMessage({ id: 'devices.info.actions.debug' }),
				key: 'debug',
				icon: faAmbulance,
				submenu: debugActions
			})
		}
		if (device.type === 'largo' && Utils.isDeviceOnline(device)) {
			// Action for Largo: Update the service worker
			systemActions.push({
				title: intl.formatMessage({
					id: 'devices.info.actions.reload'
				}),
				key: 'reload',
				icon: faSync,
				confirm: intl.formatMessage({
					id: 'devices.info.actions.reload.confirm'
				}),
				mutation: actionMutation('reload')
			})
		}
		if (device.type === 'largo') {
			if (Auth.hasPermission('device:read:logs')) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewLogs'
					}),
					key: 'showLogs',
					icon: faAlignJustify,
					action: () => {
						window.open(
							`https://app.datadoghq.eu/logs?query=service%3Alargo%20%40uuid%3A${device.uuid}`,
							'_blank'
						)
					}
				})
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewAnomalies'
					}),
					key: 'showAnomalies',
					icon: faBug,
					action: () => {
						window.open(
							`https://spectre-yh.sentry.io/issues/?project=4506072831819776&query=uuid%3A${device.uuid}`,
							'_blank'
						)
					}
				})
			}
		}
		if (Utils.isDeviceDrax(device)) {
			if (Auth.hasPermission('device:edit:restartService')) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.restart'
					}),
					key: 'restart',
					icon: faSync,
					confirm: intl.formatMessage({
						id: 'devices.info.actions.restart.confirm'
					}),
					mutation: actionMutation('restart')
				})
			}
			if (
				Auth.hasPermission('device:edit:reboot') &&
				device.type !== 'drax-mac'
			) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.reboot'
					}),
					key: 'reboot',
					icon: faSyncAlt,
					confirm: intl.formatMessage({
						id: 'devices.info.actions.reboot.confirm'
					}),
					mutation: actionMutation('reboot')
				})
			}
			if (
				Auth.hasPermission('device:edit:shutdown') &&
				device.type !== 'drax-mac'
			) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.shutdown'
					}),
					key: 'shutdown',
					icon: faPowerOff,
					confirm: intl.formatMessage({
						id: 'devices.info.actions.shutdown.confirm'
					}),
					mutation: actionMutation('shutdown')
				})
			}
		} else if (device.type === 'martini') {
			if (Auth.hasPermission('device:read:logs')) {
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewLogs'
					}),
					key: 'showLogs',
					icon: faAlignJustify,
					action: () =>
						window.open(
							`https://app.datadoghq.eu/logs?query=service%3Amartini%20hostname%3A${device.uuid.toLowerCase()}`,
							'_blank'
						)
				})
				systemActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.viewAnomalies'
					}),
					key: 'showAnomalies',
					icon: faBug,
					action: () => {
						window.open(
							`https://spectre-yh.sentry.io/issues/?project=291084&query=uuid%3A${device.uuid}`,
							'_blank'
						)
					}
				})
			}
		}

		let contentActions = []
		if (Auth.hasPermission('device:edit:syncContent')) {
			if (device.forceUpdate) {
				contentActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.cancelSync'
					}),
					key: 'sync',
					icon: faSyncAlt,
					mutation: actionMutation('cancelSync')
				})
			} else {
				contentActions.push({
					title: intl.formatMessage({
						id: 'devices.info.actions.sync'
					}),
					key: 'sync',
					icon: faSyncAlt,
					mutation: actionMutation('sync')
				})
			}
		}
		if (Auth.hasPermission('device:edit:syncCheckContent')) {
			contentActions.push({
				title: intl.formatMessage({
					id: 'devices.info.actions.syncNotify'
				}),
				key: 'syncAndNotify',
				icon: faSyncAlt,
				mutation: actionMutation('sync', 'notify')
			})
			contentActions.push({
				title: intl.formatMessage({
					id: 'devices.info.actions.syncCheck'
				}),
				key: 'syncCheck',
				icon: faCheckDouble,
				mutation: actionMutation('syncCheck')
			})
			contentActions.push({
				title: intl.formatMessage({
					id: 'devices.info.actions.syncCheckNotify'
				}),
				key: 'syncCheckAndNotify',
				icon: faCheckDouble,
				mutation: actionMutation('syncCheck', 'notify')
			})
		}
		if (Auth.hasPermission('device:read:blacklist')) {
			contentActions.push({
				title: intl.formatMessage(
					{ id: 'devices.details.blacklist' },
					{ count: device.blacklist ? device.blacklist.length : 0 }
				),
				key: 'blacklist',
				icon: faThumbsDown,
				action: () => this.setState({ blacklistModal: true })
			})
		}

		let logisticsActions = []
		if (Auth.hasPermission('device:edit:setLocation')) {
			logisticsActions.push({
				title: intl.formatMessage({ id: 'devices.info.setLocation' }),
				key: 'setLocation',
				icon: faLocationArrow,
				action: () => this.setState({ locationPopup: true })
			})
		}

		return (
			<div>
				<Row gutter={16}>
					<Col xs={24} md={12} style={S.mobileCol}>
						<Card
							title={intl.formatMessage({
								id: 'devices.info.system'
							})}
							bodyStyle={S.card.body}
							extra={
								systemActions.length > 0 && (
									<ActionsMenu menuItems={systemActions} />
								)
							}
						>
							{Auth.hasPermission('device:read:hostname') && (
								<DetailsProperty title="devices.info.hostname">
									{device.name}
								</DetailsProperty>
							)}
							{Auth.hasPermission('device:read:model') && (
								<DetailsProperty title="devices.info.model">
									<FontAwesomeIcon
										icon={deviceTypeIcon(device.OS)}
										style={styles.icon}
									/>
									&nbsp;
									{!!device.brand ? `${device.brand} ` : ''}
									{device.model}{' '}
									{device.simulator && (
										<FormattedMessage id="devices.info.simulator" />
									)}
								</DetailsProperty>
							)}
							{Auth.hasPermission('device:read:OSVersion') &&
								((
									device.OS === 'Spectre OS' ||
									device.OS === 'DraxOS'
								) ?
									<DetailsProperty title="devices.info.OSVersion">
										{device.OSVersion}
									</DetailsProperty>
								:	<DetailsProperty title="devices.info.OS">
										{device.OS} {device.OSVersion}
									</DetailsProperty>)}
							{Auth.hasPermission('device:read:appVersion') && (
								<DetailsProperty title="devices.info.appVersion">
									{device.appVersion}
									{Utils.updateBadge(device)}
								</DetailsProperty>
							)}
							{Auth.hasPermission('device:read:timeZone') &&
								!!device.timeZone && (
									<DetailsProperty title="devices.info.timeZone">
										{device.timeZone}
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:uptime') &&
								Utils.isDeviceOnline(device) &&
								device.uptime && (
									<DetailsProperty title="devices.info.uptime">
										<FormattedMessage
											id="devices.info.uptime.value"
											values={{
												date: (
													<FormattedDate
														value={device.uptime}
														year="numeric"
														month="short"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
													/>
												),
												relativeDate: (
													<FormattedTimeSince
														date={device.uptime}
													/>
												)
											}}
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:status') &&
								Utils.isDeviceOnline(device) && (
									<DetailsProperty title="devices.info.online">
										<FormattedMessage
											id="devices.info.online.value"
											values={{
												date: (
													<FormattedDate
														value={
															device.lastConnection
														}
														year="numeric"
														month="short"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
													/>
												),
												relativeDate: (
													<FormattedTimeSince
														date={
															device.lastConnection
														}
													/>
												)
											}}
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:status') &&
								!Utils.isDeviceOnline(device) && (
									<DetailsProperty title="devices.info.online">
										<FormattedMessage
											id="devices.info.offline.value"
											values={{
												date: (
													<FormattedDate
														value={
															device.lastConnection
														}
														year="numeric"
														month="short"
														day="2-digit"
														hour="2-digit"
														minute="2-digit"
													/>
												),
												relativeDate: (
													<FormattedTimeSince
														date={
															device.lastConnection
														}
													/>
												)
											}}
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:logs') &&
								Utils.isDeviceOnline(device) &&
								device.temperature && (
									<DetailsProperty title="devices.info.temperature">
										{device.temperature} ºC
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:logs') &&
								Utils.isDeviceOnline(device) &&
								device.load && (
									<DetailsProperty title="devices.info.load">
										{displayLoad(device.load)}
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:logs') &&
								Utils.isDeviceOnline(device) &&
								device.io && (
									<DetailsProperty title="devices.info.io">
										{displayIO(device.io)}
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:edit:ssh') &&
								(device.ssh || this.state.sshLoading) && (
									<DetailsProperty title="devices.info.actions.ssh">
										<div style={{ marginTop: 5 }}>
											{this.state.sshLoading ?
												<LoadingOutlined />
											:	<Input
													className="ssh-input"
													value={`ssh spectre@${device.ssh.host} -p ${device.ssh.port}`}
													addonAfter={
														<div>
															{document.queryCommandSupported(
																'copy'
															) && (
																<Tooltip
																	title={intl.formatMessage(
																		{
																			id: 'devices.info.actions.ssh.copy'
																		}
																	)}
																>
																	<CopyToClipboard
																		text={`ssh spectre@${device.ssh.host} -p ${device.ssh.port}`}
																	>
																		<FontAwesomeIcon
																			icon={
																				faClipboard
																			}
																			style={{
																				cursor: 'pointer',
																				marginRight: 10
																			}}
																		/>
																	</CopyToClipboard>
																</Tooltip>
															)}
															<Tooltip
																title={intl.formatMessage(
																	{
																		id: 'devices.info.actions.ssh.stop'
																	}
																)}
															>
																<FontAwesomeIcon
																	icon={faBan}
																	style={{
																		cursor: 'pointer'
																	}}
																	onClick={async () => {
																		try {
																			this.setState(
																				{
																					sshLoading: true
																				}
																			)
																			const r =
																				await this.props.client.mutate(
																					{
																						variables:
																							{
																								id: device._id
																							},
																						mutation: gql`
																							mutation stopDeviceSSH(
																								$id: String!
																							) {
																								stopDeviceSSH(
																									_id: $id
																								) {
																									success
																									message
																								}
																							}
																						`,
																						refetchQueries:
																							[
																								{
																									query: DeviceDetailsQuery,
																									variables:
																										{
																											id: device._id
																										}
																								}
																							],
																						awaitRefetchQueries: true
																					}
																				)
																			Utils.parseMutationResponse(
																				r
																			)
																			this.setState(
																				{
																					sshLoading: false
																				}
																			)
																		} catch (e) {
																			Utils.displayError(
																				e
																			)
																		}
																	}}
																/>
															</Tooltip>
														</div>
													}
													disabled
												/>
											}
										</div>
									</DetailsProperty>
								)}
						</Card>
					</Col>
					<Col xs={24} md={12} style={S.mobileCol}>
						<Card
							title={intl.formatMessage({
								id: 'devices.info.content'
							})}
							bodyStyle={{ ...S.card.body, paddingBottom: 10 }}
							extra={<ActionsMenu menuItems={contentActions} />}
						>
							{Auth.hasPermission('device:read:syncStatus') && (
								<span style={S.itemDetails.property}>
									<FontAwesomeIcon
										icon={faSyncAlt}
										spin={sync.spin}
										style={{ color: sync.color }}
									/>
									&nbsp;{sync.title}
									{device.forceUpdate ?
										`. ${intl.formatMessage({ id: 'devices.info.willForceUpdate' })}`
									:	''}
									{!!sync.progress && (
										<div>
											<Progress
												status="active"
												percent={sync.progress * 100}
												format={(percent) =>
													`${parseInt(percent, 10)}%`
												}
											/>
											{Auth.hasPermission(
												'device:read:numberOfTracks'
											) && (
												<FormattedMessage
													id="devices.table.downloading"
													values={{
														trackNumber:
															sync.downloadingTrack,
														totalTracks:
															sync.totalTracks
													}}
												/>
											)}
										</div>
									)}
								</span>
							)}
							{Auth.hasPermission('device:read:numberOfTracks') &&
								device.missingTracks > 0 && (
									<Alert
										message={intl.formatMessage(
											{
												id: 'devices.info.missingTracks'
											},
											{ count: device.missingTracks }
										)}
										type="warning"
										showIcon
										style={{ margin: '10px 0' }}
									/>
								)}
							{Auth.hasPermission('device:read:numberOfTracks') &&
								!!device.contentUpdated && (
									<DetailsProperty
										title="devices.info.contentUpdated"
										style={{ marginTop: 15 }}
									>
										<FormattedDate
											value={device.contentUpdated}
											year="numeric"
											month="short"
											day="2-digit"
											hour="2-digit"
											minute="2-digit"
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:numberOfTracks') &&
								!!sync.contentUpdated && (
									<DetailsProperty
										title="devices.info.programUpdated"
										style={{ marginTop: 15 }}
									>
										<FormattedDate
											value={sync.contentUpdated}
											year="numeric"
											month="short"
											day="2-digit"
											hour="2-digit"
											minute="2-digit"
										/>
									</DetailsProperty>
								)}
							<Divider
								style={{ marginTop: 10, marginBottom: 10 }}
							/>
							{Auth.hasPermission('device:read:storage') && (
								<div>
									<div style={S.horizontal}>
										<Progress
											width={60}
											type="circle"
											status="active"
											percent={
												((device.totalSpace -
													device.freeSpace) *
													100) /
												(device.totalSpace + 1)
											}
											format={(percent) =>
												`${parseInt(percent, 10)}%`
											}
										/>
										<div style={{ marginLeft: 10 }}>
											<FormattedMessage
												id="devices.info.spaceUsed"
												values={{
													used: formatBytes(
														device.totalSpace -
															device.freeSpace,
														1
													),
													total: formatBytes(
														device.totalSpace,
														1
													)
												}}
											/>
										</div>
									</div>
									<Divider
										style={{
											marginTop: 10,
											marginBottom: 10
										}}
									/>
								</div>
							)}
							{Auth.hasPermission('device:read:history') && (
								<Button
									onClick={() =>
										this.setState({ historyModal: true })
									}
								>
									<FontAwesomeIcon icon={faHistory} />
									&nbsp;
									<FormattedMessage id="devices.info.actions.logs" />
								</Button>
							)}
						</Card>
						<Card
							title={intl.formatMessage({
								id: 'devices.info.logistics'
							})}
							bodyStyle={S.card.body}
							style={{ marginTop: 20 }}
							extra={<ActionsMenu menuItems={logisticsActions} />}
						>
							{Auth.hasPermission('device:read:buildDate') &&
								!!device.created && (
									<DetailsProperty title="devices.info.buildDate">
										<FormattedDate
											value={device.created}
											year="numeric"
											month="short"
											day="2-digit"
										/>
									</DetailsProperty>
								)}
							{Auth.hasPermission('device:read:installDate') && (
								<DetailsProperty title="devices.info.installDate">
									<DatePicker
										className="noBorder noSelect"
										style={{ padding: 0 }}
										disabled={
											!Auth.hasPermission(
												'device:edit:setInstallDate'
											)
										}
										format="DD MMM YYYY"
										bordered={false}
										placeholder={intl.formatMessage({
											id: 'undefined'
										})}
										value={
											device.installationDate ?
												moment(device.installationDate)
											:	null
										}
										onChange={async (v) => {
											this.setState({
												installDateLoading: true
											})
											try {
												const r =
													await this.props.client.mutate(
														{
															variables: {
																id: device._id,
																installDate:
																	v ?
																		v.toDate()
																	:	null
															},
															mutation: gql`
																mutation updateDeviceInstallDate(
																	$id: MongoID!
																	$installDate: Date
																) {
																	deviceUpdateById(
																		_id: $id
																		record: {
																			installDate: $installDate
																		}
																	) {
																		recordId
																	}
																}
															`,
															refetchQueries: [
																{
																	query: DeviceDetailsQuery,
																	variables: {
																		id: device._id
																	}
																}
															],
															awaitRefetchQueries: true
														}
													)
												Utils.parseMutationResponse(r)
											} catch (e) {
												Utils.displayError(e)
											}
											this.setState({
												installDateLoading: false
											})
										}}
									/>
									{this.state.installDateLoading && (
										<LoadingOutlined
											style={{ marginLeft: 10 }}
										/>
									)}
								</DetailsProperty>
							)}
							{Auth.hasPermission('device:read:location') && (
								<DetailsProperty
									title="devices.info.location"
									onClick={() => {
										if (
											Auth.hasPermission(
												'device:edit:setLocation'
											)
										) {
											this.setState({
												locationPopup: true
											})
										}
									}}
								>
									{device.location || (
										<FormattedMessage id="undefined" />
									)}
								</DetailsProperty>
							)}
							{this.state.locationPopup && (
								<TextInputModal
									initialValue={device.location}
									title={this.props.intl.formatMessage({
										id: 'devices.info.setLocation'
									})}
									placeholder={this.props.intl.formatMessage({
										id: 'devices.info.location'
									})}
									actionLabel={this.props.intl.formatMessage({
										id: 'actions.save'
									})}
									onClose={() =>
										this.setState({ locationPopup: false })
									}
									mutation={(loc) => ({
										variables: {
											id: device._id,
											location: loc
										},
										mutation: gql`
											mutation updateDeviceLocation(
												$id: MongoID!
												$location: String
											) {
												deviceUpdateById(
													_id: $id
													record: {
														location: $location
													}
												) {
													recordId
												}
											}
										`,
										refetchQueries: [
											{
												query: DeviceDetailsQuery,
												variables: { id: device._id }
											}
										],
										awaitRefetchQueries: true
									})}
								/>
							)}
						</Card>
					</Col>
				</Row>
				{this.state.blacklistModal && (
					<Modal
						title={intl.formatMessage(
							{ id: 'devices.details.blacklist' },
							{ count: device.blacklist.length }
						)}
						visible={true}
						footer={[]}
						onCancel={() =>
							this.setState({ blacklistModal: false })
						}
					>
						<Tracks
							tracks={device.blacklist}
							device={device}
							canBlacklist
							subtitle={(track) => (
								<Blame
									dateTitle="devices.details.blacklist.blame.date"
									dateUserTitle="devices.details.blacklist.blame.dateUser"
									eventFilters={{
										deviceUuid: device.uuid,
										trackId: track._id,
										event: Constants.EVENT_BLACKLIST
									}}
								/>
							)}
						/>
					</Modal>
				)}
				{this.state.historyModal && (
					<History
						device={device}
						onClose={() => this.setState({ historyModal: false })}
					/>
				)}
				{this.state.updateModal && (
					<Updates
						device={device}
						onClose={() => this.setState({ updateModal: false })}
					/>
				)}
				{this.state.autoRebootModal && (
					<Reboot
						device={device}
						onClose={() =>
							this.setState({ autoRebootModal: false })
						}
					/>
				)}
				{this.state.statsModal && (
					<Stats
						device={device}
						onClose={() => this.setState({ statsModal: false })}
					/>
				)}
				{this.state.timeZoneModal && (
					<TimeZone
						device={device}
						onClose={() => this.setState({ timeZoneModal: false })}
					/>
				)}
				{this.state.filePathModal && (
					<TextInputModal
						initialValue="/data/drax/"
						title={intl.formatMessage({
							id: 'devices.info.actions.printFile'
						})}
						actionLabel={intl.formatMessage({ id: 'actions.view' })}
						placeholder={intl.formatMessage({
							id: 'devices.info.actions.printFile.path'
						})}
						onClose={() => this.setState({ filePathModal: false })}
						mutation={(name) =>
							DeviceActionMutation(
								device,
								null,
								'printFile',
								name
							)
						}
					/>
				)}
			</div>
		)
	}
}

const styles = {
	icon: {
		width: 16
	},
	networkTitle: {
		width: '100%',
		textAlign: 'center',
		textTransform: 'uppercase',
		marginBottom: 10
	},
	lineItem: {
		marginBottom: 10
	}
}

export default withApollo(injectIntl(DeviceInfo))
