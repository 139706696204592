import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Color from 'color'
import { Modal, Button } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import uuid from 'uuid/v4'
import { RRule, rrulestr } from 'rrule'
import { faEdit, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Colors } from '../../components/Styles'
import EditScheduleEvent from './EditScheduleEventV3'
import { ProgramDetailsQuery } from './Queries'
import * as Utils from '../../components/Utils'
import BatchActions from './BatchActions'
import * as Auth from '../../auth'
import { openCustomConfirmModal } from '../../components/CustomConfirmModal'

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

export const formats = {
	dayFormat: (date, culture, localizer) =>
		localizer.format(date, 'ddd D', culture),

	timeGutterFormat: (time, culture, localizer) =>
		localizer.format(time, 'HH:mm', culture),

	eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
		if (end.getSeconds() === 59) {
			end.setSeconds(60)
		}
		return `${localizer.format(start, 'HH:mm', culture)} - ${localizer.format(end, 'HH:mm', culture)}`
	}
}

class SchedulerV3 extends Component {
	state = {
		calendarEvents: null,
		events: {},
		editing: null,
		height: null,
		start: null,
		end: null,
		colors: {},
		editable: false,
		batchActionsModal: false
	}

	componentDidMount() {
		this.createEvents(this.props)
	}

	componentWillReceiveProps(props) {
		if (
			(props.program && this.props.program._id !== props.program._id) ||
			(props.updated && props.updated !== this.props.updated)
		) {
			this.createEvents(props)
		}
	}

	createEvents = (props) => {
		let events = {}
		let colors = {}
		if (props.program.playlists) {
			for (let i in props.program.playlists) {
				const pl = props.program.playlists[i]
				colors[pl._id] = Colors.playlistColors[i]
				if (pl.scheduleV3) {
					for (let evt of pl.scheduleV3) {
						if (evt.rule) {
							const id = uuid()
							events[id] = {
								...evt,
								id,
								playlist: pl
							}
						}
					}
				}
			}
		}

		let start = this.state.start
		if (!start) {
			start = moment()
				.weekday(0)
				.hour(0)
				.minute(0)
				.second(0)
				.subtract(1, 'second')
				.toDate()
		}

		let end = this.state.end
		if (!end) {
			end = moment()
				.weekday(6)
				.add(1, 'days')
				.hour(0)
				.minute(0)
				.second(0)
				.toDate()
		}

		let calendarEvents = this.createCalendarEvents(events, start, end)

		this.setState({ events, calendarEvents, start, end, colors })
	}

	setRange = (dates) => {
		if (dates.length > 0) {
			const start = moment(dates[0]).subtract(1, 'second').toDate()
			const end = moment(dates[dates.length - 1])
				.add(1, 'days')
				.toDate()
			const calendarEvents = this.createCalendarEvents(
				this.state.events,
				start,
				end
			)

			this.setState({
				start,
				end,
				calendarEvents
			})
		}
	}

	createCalendarEvents = (
		events,
		from = this.state.start,
		until = this.state.end
	) => {
		let calendarEvents = []
		for (let id in events) {
			const evt = events[id]
			const eventRule = rrulestr(evt.rule)
			const localFrom = Utils.localToUtcDate(from)
			const localUntil = Utils.localToUtcDate(until)

			const eventTimes = eventRule.between(
				localFrom.toDate(),
				localUntil.toDate()
			)

			// The times we're getting from rrule are UTC times but the browser converts them to local times
			// What we want are local times with the same hour as the UTC time
			// So we subtract the local tz offset from the UTC time
			const eventDayEvents = eventTimes.map((e) => {
				let localTime = Utils.utcToLocalDate(e)
				let localEndTime = moment(localTime).add(
					evt.duration,
					'minutes'
				)

				// passage à l'heure d'été/hiver pendant la plage horaire
				if (localTime.isDST() !== localEndTime.isDST()) {
					console.log(
						`DST shifts during event [${localTime.isDST()} => ${localEndTime.isDST()}]`
					)
					localEndTime.add(localTime.isDST() ? 1 : -1, 'hour')
				}

				return {
					id: evt.id,
					start: localTime.toDate(),
					end: localEndTime.toDate()
				}
			})

			calendarEvents = [...calendarEvents, ...eventDayEvents]
		}
		return calendarEvents
	}

	onEventDrop = (drop) => {
		if (this.state.editable && drop.event.id) {
			const event = this.state.events[drop.event.id]

			if (event) {
				// update rule with start date
				let oldRule = rrulestr(event.rule)
				let localTime = Utils.localToUtcDate(drop.start)
				let oldRuleSet

				let duration = Utils.duration(drop.start, drop.end)

				// for rule sets we need to get the internal rule
				if (oldRule._exdate) {
					oldRuleSet = oldRule
					oldRule = oldRule._rrule[0]
				}

				const oldStartDate = moment(oldRule.options.dtstart)

				const editRule = () => {
					let newRule = new RRule({
						dtstart: localTime.toDate(),
						freq: oldRule.options.freq,
						until: oldRule.options.until,
						interval: oldRule.options.interval,
						count: oldRule.options.count,
						byweekday: oldRule.options.byweekday,
						bymonthday: oldRule.options.bymonthday
					})

					// If old rule was a set recreate it
					if (oldRuleSet) {
						newRule = Utils.updatedRuleSetWithRule(
							oldRuleSet,
							newRule
						)
					}

					this.setEvent(
						drop.event.id,
						newRule.toString(),
						duration,
						event.weight,
						event.playlist
					)
				}

				// For reccuring events, ask change occurence only or all
				if (oldRule.options.count !== 1) {
					const { intl } = this.props
					openCustomConfirmModal({
						title: intl.formatMessage({
							id: 'messagePrograms.details.edit.confirm.title'
						}),
						content: intl.formatMessage(
							{
								id: 'messagePrograms.details.edit.confirm.message'
							},
							{
								date: intl.formatDate(drop.event.start, {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit'
								})
							}
						),
						primaryActionText: intl.formatMessage({
							id: 'messagePrograms.details.confirm.all'
						}),
						secondaryActionText: intl.formatMessage({
							id: 'messagePrograms.details.confirm.instanceOnly'
						}),
						cancelText: intl.formatMessage({
							id: 'actions.cancel'
						}),
						onPrimaryAction: () => {
							// Edit all occurences
							// Keep start date on the same day but change time
							localTime = Utils.localToUtcDate(
								drop.start,
								oldStartDate.toDate()
							) // The offset needs to be calculated based on the old date
							localTime.date(oldStartDate.date())
							localTime.month(oldStartDate.month())
							localTime.year(oldStartDate.year())

							editRule()
						},
						onSecondaryAction: () => {
							// Edit single occurence
							// Delete specific event and create a new identical one at the dropped time

							let events = { ...this.state.events }

							// Exclude drop date from event
							let rruleSet = rrulestr(event.rule, {
								forceset: true
							})
							rruleSet.exdate(
								Utils.localToUtcDate(drop.event.start).toDate()
							)
							events[event.id].rule = rruleSet.toString()

							// Create new single event at drop date
							let newEvent = {
								...event,
								duration,
								id: uuid(),
								rule: new RRule({
									dtstart: Utils.localToUtcDate(
										drop.start
									).toDate(),
									count: 1
								}).toString()
							}
							events[newEvent.id] = newEvent
							this.saveEvents(events)
						}
					})
				} else {
					editRule()
				}
			}
		}
	}

	setEvent = (id, rule = null, duration, weight, playlist = null) => {
		if (this.state.editable) {
			let events = { ...this.state.events }
			if (rule || playlist) {
				let event = this.state.events[id]

				if (event) {
					if (rule) {
						event.rule = rule
					}
					if (playlist) {
						event.playlist = playlist
					}
					if (duration) {
						event.duration = duration
					}
					if (weight) {
						event.weight = weight
					}

					events[id] = event
				}
			} else {
				delete events[id]
			}
			this.saveEvents(events)
		}
	}

	saveEvents = async (events, dryRun) => {
		const calendarEvents = this.createCalendarEvents(events)
		try {
			if (!dryRun) {
				let schedules = {}
				for (let pl of this.props.program.playlists) {
					schedules[pl._id] = []
				}

				for (let evt in events) {
					let event = events[evt]
					if (event.playlist && schedules[event.playlist._id]) {
						schedules[event.playlist._id].push({
							rule: event.rule,
							duration: event.duration,
							weight: event.weight
						})
					}
				}

				const res = await this.props.client.mutate({
					variables: {
						schedule: JSON.stringify(schedules),
						programId: this.props.program._id
					},
					mutation: gql`
						mutation updateProgramPlaylistScheduleV3(
							$schedule: String!
							$programId: String!
						) {
							programPlaylistScheduleV3Update(
								schedule: $schedule
								programId: $programId
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: ProgramDetailsQuery,
							variables: { id: this.props.program._id }
						}
					]
				})
				if (
					res.data?.programPlaylistScheduleV3Update?.success !== true
				) {
					console.error(
						`The event couldn't have been created: ${res.data.programPlaylistScheduleV3Update.message}`
					)
					throw new Error(`EVENT_NOT_SAVED`)
				}
			}
			this.setState({ events, calendarEvents })
		} catch (e) {
			Utils.displayError(e)
		}
	}

	handleSelect = (calEvent) => {
		if (this.state.editable) {
			this.setState((state) => {
				let event
				let events = { ...state.events }
				if (calEvent.id) {
					event = this.state.events[calEvent.id]
				} else {
					// The dialog subtracts the offset so that the UTC date from events show in local time
					// But calEvent.start is already in local time, so we compensate here
					let localTime = Utils.localToUtcDate(calEvent.start)
					event = {
						id: uuid(),
						weight: 10,
						duration: Utils.duration(calEvent.start, calEvent.end),
						playlist:
							(
								this.props.playlists &&
								this.props.playlists.length > 0
							) ?
								this.props.playlists[0]
							:	null,
						rule: new RRule({
							dtstart: localTime.toDate(),
							freq: RRule.WEEKLY,
							interval: 1
						}).toString()
					}
					events[event.id] = event
				}
				event.start = calEvent.start

				return {
					editing: event,
					events
				}
			})
		}
	}

	titleAccessor = (calEvent) => {
		const event = this.state.events[calEvent.id]
		if (event) {
			return `${event.playlist ? event.playlist.name : this.props.intl.formatMessage({ id: 'messagePrograms.details.noFileSelected' })} [${event.weight}]`
		}
		return ''
	}

	render() {
		return (
			<React.Fragment>
				<div
					className={`noAllDay${this.state.editable ? '' : ' noResize'}`}
					style={{ marginBottom: 20 }}
				>
					<DnDCalendar
						localizer={localizer}
						events={this.state.calendarEvents || []}
						formats={formats}
						selectable={this.state.editable}
						style={{ height: this.state.height }}
						views={['week']}
						defaultView="week"
						drilldownView="week"
						step={5}
						timeslots={12}
						defaultDate={new Date()}
						showMultiDayTimes={true}
						titleAccessor={this.titleAccessor}
						tooltipAccessor={this.titleAccessor}
						onEventDrop={this.onEventDrop}
						onEventResize={this.onEventDrop}
						endAccessor={(e) => {
							// make events that end at midnight a second shorter so they dont carry over
							const end = moment(e.end)
							if (end.hour() === 0 && end.minute() === 0) {
								return end.subtract(1, 'second').toDate()
							}
							return e.end
						}}
						onSelectSlot={this.handleSelect}
						onSelectEvent={this.handleSelect}
						onRangeChange={this.setRange}
						onView={(e) =>
							this.setState({
								height: e === 'month' ? 600 : null
							})
						}
						eventPropGetter={(calEvent) => {
							const event = this.state.events[calEvent.id]
							if (event && event.playlist) {
								const c = this.state.colors[event.playlist._id]
								return {
									style: {
										backgroundColor: Color(c).fade(0.5),
										border: `1px solid ${c}`,
										color: '#000',
										fontSize: 10
									}
								}
							}
							return null
						}}
					/>
					{this.state.editing && (
						<EditScheduleEvent
							event={this.state.editing}
							playlists={this.props.program.playlists}
							onClose={(id, rule, duration, weight, playlist) => {
								this.setState({ editing: false })
								if (id) {
									this.setEvent(
										id,
										rule,
										duration,
										weight,
										playlist
									)
								}
							}}
						/>
					)}
					{this.state.batchActionsModal && (
						<BatchActions
							events={this.state.events}
							playlists={this.props.program.playlists}
							setEvents={(evt) => this.saveEvents(evt)}
							onClose={() =>
								this.setState({ batchActionsModal: false })
							}
						/>
					)}
				</div>
				{this.state.editable && (
					<Button.Group style={{ marginBottom: 20, marginRight: 20 }}>
						<Button
							onClick={() =>
								this.setState({ batchActionsModal: true })
							}
						>
							<FontAwesomeIcon icon={faCopy} />
							&nbsp;
							<FormattedMessage id="programs.scheduler.batch" />
						</Button>
					</Button.Group>
				)}
				{Auth.hasPermission('programPlaylist:edit:setSchedule') &&
					Auth.hasAccess(
						'program:edit',
						this.props.program.teamId
					) && (
						<Button
							style={{ marginBottom: 20 }}
							type="primary"
							onClick={() =>
								this.setState({
									editable: !this.state.editable
								})
							}
						>
							<FontAwesomeIcon icon={faEdit} />
							&nbsp;
							<FormattedMessage
								id={
									this.state.editable ?
										'actions.done'
									:	'actions.edit'
								}
							/>
						</Button>
					)}
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(SchedulerV3))
