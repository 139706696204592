import gql from 'graphql-tag'
import config from './config'

export let cache = {
	user: null,
	team: null
}
let userPermissions = []
let userAccess = []

export const meQuery = gql`
	query user {
		me {
			_id
			username
			firstName
			lastName
			role
			language
			montyUser
			teamId
			b64Authorization
			messagesEnabled
			thirdPartyDevicesEnabled
			notifyOfflineType
			notifyOfflineDelay
			notifySince
			notifyActivationType
			notifyDisabledType
			apikeys {
				_id
				name
			}
		}
	}
`

export const refreshUser = async (client) => {
	const response = await client.query({
		query: meQuery,
		fetchPolicy: 'no-cache'
	})

	if (response && response.data) {
		setUser(response.data.me)
	} else {
		throw new Error('No data')
	}
}

export const setUser = (user) => {
	const authorization = JSON.parse(window.atob(user.b64Authorization))
	userPermissions = authorization.permissions
	userAccess = authorization.access
	cache.team = authorization.team

	if (!cache.user) {
		window.Intercom('boot', {
			app_id: config.intercom.appId,
			name: `${user.firstName} ${user.lastName}`,
			email: user.username,
			user_id: user._id,
			company: cache.team.name
		})
	}

	cache.user = user
}

export const hasPermission = (permission) => {
	return (
		userPermissions &&
		userPermissions.length > 0 &&
		(userPermissions.indexOf('*') > -1 ||
			userPermissions.indexOf(permission) > -1)
	)
}

export const hasAccess = (access, teamId) => {
	return (
		userAccess &&
		userAccess.length > 0 &&
		(userAccess.indexOf('*') > -1 ||
			userAccess.indexOf(`${access}:all`) > -1 ||
			(userAccess.indexOf(`${access}:own`) > -1 &&
				ensureTeam([cache.team._id], teamId)) ||
			(userAccess.indexOf(`${access}:children`) > -1 &&
				ensureTeam(cache.team.children, teamId)) ||
			(userAccess.indexOf(`${access}:parents`) > -1 &&
				ensureTeam(cache.team.parents, teamId)))
	)
}

const ensureTeam = (filter, team) => {
	return (
		(Array.isArray(team) &&
			filter.filter((n) => team.indexOf(n) > -1).length > 0) ||
		filter.indexOf(team) > -1
	)
}

export const login = async (creds) => {
	const res = await fetch(`${window.location.origin}/login`, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(creds)
	})
	const jsonResponse = await res.json()
	if (jsonResponse.success) {
		return true
	}
	if (jsonResponse.error) {
		throw new Error(jsonResponse.error.message)
	}
	throw new Error('serverError')
}

export const logout = async () => {
	cache.user = null
	cache.team = null
	localStorage.removeItem('mediaTeam')

	const res = await fetch(`${window.location.origin}/logout`, {
		method: 'POST',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json'
		}
	})
	const jsonResponse = await res.json()
	return jsonResponse
}

export const forgotPassword = async (creds) => {
	try {
		const res = await fetch(`${window.location.origin}/reset_password`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(creds)
		})

		const jsonResponse = await res.json()
		return jsonResponse
	} catch (e) {
		return { error: e }
	}
}

export const changePassword = async (creds) => {
	try {
		const res = await fetch(`${window.location.origin}/change_password`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(creds)
		})

		const jsonResponse = await res.json()
		return jsonResponse
	} catch (e) {
		return { error: e }
	}
}
