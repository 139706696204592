/**
 * The checksum returned is the index.html etag get from the header response
 */
const getIndexChecksum = async () => {
	const res = await fetch(`${window.location.origin}/`, {
		method: 'GET'
	})
	if (res.headers && res.headers.get('etag')) {
		return res.headers.get('etag')
	} else {
		return null
	}
}

/**
 * Check every 24 hours if the index.html is still valid
 */
export const monitorAppUpdate = async () => {
	// Get the initial checksum
	const initialIndexChecksum = await getIndexChecksum()
	if (initialIndexChecksum) {
		setInterval(async () => {
			const indexChecksum = await getIndexChecksum()
			if (
				typeof indexChecksum === 'string' &&
				indexChecksum !== initialIndexChecksum
			) {
				window.location.reload()
			}
		}, 3600000) // 24 hours
	} else {
		console.log('Impossible to get the initial index.html etag')
	}
}
