import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
	Modal,
	Form,
	Input,
	Button,
	Select,
	Col,
	Row,
	Card,
	DatePicker
} from 'antd'
import moment from 'moment'

import { formItemLayout } from '../../../components/Styles'
import * as Utils from '../../../components/Utils'
import { TeamBillingQuery } from '../Queries'
import TaxRateSelect from './TaxRateSelect'
import { VAT_WAIVER_NOTICE } from './NewPlan'
import PlanItems from './PlanItems'

const FormItem = Form.Item

class Plan extends Component {
	state = {
		loading: false,
		currency: 'eur',
		interval: 'month',
		intervalCount: 1,
		daysUntilDue: 30,
		taxRateId: null,
		invoiceFooter: null,
		items: []
	}

	componentDidMount() {
		const { subscription } = this.props
		this.setState({
			currency: subscription.currency,
			interval: subscription.interval,
			intervalCount: subscription.intervalCount,
			daysUntilDue: subscription.daysUntilDue,
			taxRateId: subscription.taxRateId,
			invoiceFooter: subscription.invoiceFooter,
			items: subscription.items.map((i) => ({
				price: i.price,
				quantity: i.quantity,
				description: i.description
			})),
			startDate:
				subscription.startDate ? moment(subscription.startDate) : null,
			endDate: subscription.endDate ? moment(subscription.endDate) : null
		})
	}

	handleCancel = () => {
		this.props.onClose()
	}

	handleSubmit = async (e) => {
		try {
			this.setState({ loading: true })
			const r = await this.props.client.mutate({
				variables: {
					id: this.props.subscription._id,
					items: this.state.items,
					interval: this.state.interval,
					intervalCount: parseInt(this.state.intervalCount, 10),
					currency: this.state.currency,
					daysUntilDue: this.state.daysUntilDue,
					taxRateId: this.state.taxRateId,
					invoiceFooter: this.state.invoiceFooter,
					startDate:
						this.state.startDate ?
							this.state.startDate.toDate()
						:	null,
					endDate:
						this.state.endDate ? this.state.endDate.toDate() : null
				},
				mutation: gql`
					mutation updateSubscription(
						$id: String!
						$items: [UpdateByIdSubscriptionItemsInput]
						$interval: String
						$intervalCount: Float
						$currency: String
						$daysUntilDue: Float
						$taxRateId: String
						$invoiceFooter: String
						$startDate: Date
						$endDate: Date
					) {
						subscriptionUpdateById(
							_id: $id
							record: {
								items: $items
								interval: $interval
								intervalCount: $intervalCount
								currency: $currency
								daysUntilDue: $daysUntilDue
								taxRateId: $taxRateId
								invoiceFooter: $invoiceFooter
								startDate: $startDate
								endDate: $endDate
							}
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: TeamBillingQuery,
						variables: { id: this.props.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)

			this.setState({ loading: false })
			this.props.onClose()
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, team } = this.props

		return (
			<Modal
				visible={true}
				title={intl.formatMessage(
					{ id: 'billing.plan.title' },
					{ team: team.n }
				)}
				onCancel={this.handleCancel}
				width="60vw"
				bodyStyle={{
					overflow: 'hidden',
					backgroundColor: '#f0f2f5',
					padding: 20
				}}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.handleSubmit}
						loading={this.state.loading}
						disabled={
							!this.state.intervalCount ||
							!this.state.daysUntilDue
						}
					>
						<FormattedMessage id="actions.save" />
					</Button>
				]}
			>
				<Card
					title={intl.formatMessage({ id: 'billing.plan.items' })}
					style={{ marginBottom: 20 }}
				>
					<PlanItems
						items={this.state.items}
						currency={this.state.currency}
						valueChanged={(v) => this.setState(v)}
					/>
				</Card>
				<Row gutter={16}>
					<Col xs={24} md={12}>
						<Card
							title={intl.formatMessage({
								id: 'billing.plan.invoicing'
							})}
							style={{ marginBottom: 20 }}
						>
							<Form>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.startDate'
									})}
								>
									<DatePicker
										onChange={(d) =>
											this.setState({ startDate: d })
										}
										value={
											this.state.startDate ?
												moment(this.state.startDate)
											:	null
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.endDate'
									})}
								>
									<DatePicker
										onChange={(d) =>
											this.setState({ endDate: d })
										}
										value={
											this.state.endDate ?
												moment(this.state.endDate)
											:	null
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.interval'
									})}
								>
									<Input
										placeholder="1"
										type="number"
										value={this.state.intervalCount}
										onChange={(e) =>
											this.setState({
												intervalCount: e.target.value
											})
										}
										addonAfter={
											<Select
												defaultValue={
													this.state.interval
												}
												style={{ width: 120 }}
												onChange={(v) =>
													this.setState({
														interval: v
													})
												}
											>
												{['YEARLY', 'MONTHLY'].map(
													(rule) => (
														<Select.Option
															key={`rule-${rule}`}
															value={
																Utils
																	.stripeIntervals[
																	rule
																]
															}
														>
															<FormattedMessage
																id={`RRule.${rule}`}
																values={{
																	count: 2
																}}
															/>
														</Select.Option>
													)
												)}
											</Select>
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.daysUntilDue'
									})}
								>
									<Input
										value={this.state.daysUntilDue}
										onChange={(e) =>
											this.setState({
												daysUntilDue: e.target.value
											})
										}
										placeholder="30"
										addonAfter={
											<FormattedMessage
												id="RRule.DAILY"
												values={{ count: 2 }}
											/>
										}
									/>
								</FormItem>
							</Form>
						</Card>
					</Col>
					<Col xs={24} md={12}>
						<Card title={intl.formatMessage({ id: 'billing.vat' })}>
							<p>
								<FormattedMessage id="billing.subsidiary" />:{' '}
								<FormattedMessage
									id={`billing.subsidiary.${this.props.subscription.subsidiary || 'global'}`}
								/>
							</p>
							<Form.Item
								label={intl.formatMessage({
									id: 'billing.vat.applicableVat'
								})}
							>
								<TaxRateSelect
									subsidiary={
										this.props.subscription.subsidiary
									}
									value={this.state.taxRateId}
									onChange={(v) => {
										let footer = this.state.invoiceFooter
										if (v) {
											if (footer === VAT_WAIVER_NOTICE) {
												footer = null
											}
										} else {
											if (!footer) {
												footer = VAT_WAIVER_NOTICE
											}
										}
										this.setState({
											taxRateId: v,
											invoiceFooter: footer
										})
									}}
									style={{ width: '100%' }}
								/>
							</Form.Item>
							<Form.Item
								label={intl.formatMessage({
									id: 'billing.vat.invoiceFooter'
								})}
							>
								<Input
									value={this.state.invoiceFooter}
									onChange={(v) =>
										this.setState({
											invoiceFooter: v.target.value
										})
									}
								/>
							</Form.Item>
						</Card>
					</Col>
				</Row>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(Plan))
