import React, { Component } from 'react'
import { Modal, Form, Button, Input, message } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

import { SelectTeamV2 } from '../teams/SelectTeam'
import { MessageProgramsTableQuery } from './Queries'
import * as Utils from '../../components/Utils'

const FormItem = Form.Item

class NewProgram extends Component {
	formRef = React.createRef()

	state = {
		showTeamOverlay: false,
		team: this.props.team || [],
		name: this.props.name || '',
		confirmDirty: false,
		loading: false,
		duplicate: this.props.duplicate
	}

	onClickSubmit = async (e) => {
		e.preventDefault()

		if (!this.state.team) {
			message.error(
				this.props.intl.formatMessage({ id: 'server.TEAM_NOT_FOUND' })
			)
			return
		}

		this.setState({ loading: true })
		const { onClose, client, history } = this.props
		try {
			await this.formRef.current.validateFields()
			let res
			if (this.state.duplicate) {
				const r = await client.mutate({
					variables: {
						name: this.state.name,
						teamId: this.state.team._id,
						did: this.state.duplicate._id
					},
					mutation: gql`
						mutation duplicateMessageProgram(
							$name: String!
							$teamId: String
							$did: String!
						) {
							messageProgramDuplicate(
								name: $name
								teamId: $teamId
								duplicateId: $did
							) {
								recordId
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramsTableQuery
						}
					]
				})
				Utils.parseMutationResponse(r)
				res = r.data.messageProgramDuplicate
			} else {
				const r = await client.mutate({
					variables: {
						name: this.state.name,
						teamId: this.state.team._id,
						date: new Date()
					},
					mutation: gql`
						mutation createMessageProgram(
							$name: String!
							$teamId: String
							$date: Date!
						) {
							messageProgramCreate(
								record: {
									name: $name
									teamId: $teamId
									updated: $date
									crossfade: 3
									musicVolume: 0
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramsTableQuery
						}
					]
				})
				Utils.parseMutationResponse(r)
				res = r.data.messageProgramCreate
			}

			this.setState({ loading: false })
			if (res) {
				if (res.recordId) {
					// redirect to device details
					onClose()
					history.push(`/messages/${res.recordId}`)
				} else if (res.message) {
					throw new Error(res.message)
				}
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	handleConfirmBlur = (e) => {
		const value = e.target.value
		this.setState({ confirmDirty: this.state.confirmDirty || !!value })
	}

	handleCancel = () => {
		this.props.onClose()
	}

	render() {
		const { intl } = this.props
		return (
			<Modal
				visible={true}
				title={this.props.intl.formatMessage({
					id: 'messagePrograms.actions.newProgram'
				})}
				onCancel={this.handleCancel}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.onClickSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.create" />
					</Button>
				]}
			>
				<Form ref={this.formRef}>
					<FormItem label={intl.formatMessage({ id: 'name' })}>
						<Input
							name="name"
							value={this.state.name}
							onChange={(e) =>
								this.setState({ name: e.target.value })
							}
						/>
					</FormItem>
					<FormItem
						label={
							<React.Fragment>
								<FontAwesomeIcon icon={faUsers} />
								&nbsp;&nbsp;
								<FormattedMessage id="team" />
							</React.Fragment>
						}
					>
						<SelectTeamV2
							name="team"
							onChange={(team) => this.setState({ team })}
							selected={
								this.state.team ? this.state.team._id : null
							}
							keyprops="_id"
						/>
					</FormItem>
				</Form>
			</Modal>
		)
	}
}

export default withRouter(withApollo(injectIntl(NewProgram)))
