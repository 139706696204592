import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Tag, Tooltip, Alert } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import gql from 'graphql-tag'
import * as gqlBuilder from 'gql-query-builder'
import isEqual from 'lodash/isEqual'

import S from '../Styles'
import Table from './Table'
import Header from './Header'
import config from '../../config'
import Loading from '../LoadingPage'
import * as Utils from '../Utils'
import { withResponsive } from '../Responsive'

class TableV2 extends Component {
	state = {
		displayedColumnKeys: [],
		displayedColumns: [],
		columns: [],
		query: null,
		sort: null,
		sortDirection: 1,
		search: null,
		filters: []
	}

	componentDidMount() {
		this.loadColumns(this.props)
	}

	componentWillReceiveProps(props) {
		// If we have a different table, then reload
		if (!isEqual(props.storageKey, this.props.storageKey)) {
			this.loadColumns(props)
		}
	}

	loadColumns = (props) => {
		// Load saved params
		let storedParams = {}
		if (props.storageKey) {
			const storedJson = localStorage.getItem(props.storageKey)
			if (storedJson) {
				storedParams = JSON.parse(storedJson)
			}
		}

		// Displayed columns
		const columns = this.addGenericColumns(props)
		let displayedColumnKeys = []
		if (storedParams.displayedColumnKeys) {
			displayedColumnKeys = storedParams.displayedColumnKeys
		} else {
			displayedColumnKeys = this.defaultColumns(props)
		}

		const displayedColumns = displayedColumnKeys.map((c) => ({
			...columns[c],
			key: c
		}))

		// Apply
		const query = this.buildQuery(props, displayedColumns)
		this.setState({
			columns,
			displayedColumnKeys,
			displayedColumns,
			query,
			...storedParams
		})
	}

	/**
	 * ACTIONS
	 */

	selectColumns = (selected) => {
		const displayedColumns = selected.map((c) => ({
			...this.state.columns[c],
			key: c
		}))
		const query = this.buildQuery(this.props, displayedColumns)
		this.setState(
			{
				displayedColumnKeys: selected,
				displayedColumns,
				query
			},
			this.saveParams
		)
	}

	saveParams = () => {
		if (this.props.storageKey) {
			let s = {
				displayedColumnKeys: this.state.displayedColumnKeys,
				sort: this.state.sort,
				sortDirection: this.state.sortDirection,
				filters: this.state.filters,
				search: this.state.search
			}

			localStorage.setItem(this.props.storageKey, JSON.stringify(s))
		}
	}

	/**
	 * HELPERS
	 */

	defaultColumns = (props) => {
		if (props.mobileVersion && props.defaultMobileColumns) {
			return props.defaultMobileColumns
		}
		return props.defaultColumns
	}

	buildQuery = (props, displayedColumns) => {
		let qProps = ['_id']
		if (props.extraQueryProps) {
			qProps = [...qProps, ...props.extraQueryProps]
		}
		for (let c of displayedColumns) {
			if (c.queryProps) {
				qProps = [...qProps, ...c.queryProps]
			}
		}
		const query = gqlBuilder.query(
			{
				operation: props.queryType,
				fields: qProps
			},
			null,
			{
				operationName: `${props.queryType}TableBuiltQuery`
			}
		)

		// eslint-disable-next-line
		return gql`
			${query.query}
		`
	}

	addGenericColumns = (props) => {
		const {
			link,
			path,
			intl,
			renderName,
			nameProp,
			nameQueryProps,
			teamProp,
			teamsProp,
			columns
		} = props
		let cols = { ...columns }
		if (nameProp) {
			cols['name'] = {
				queryProps: nameQueryProps || ['name'],
				name: intl.formatMessage({ id: 'name' }),
				accessor: nameProp,
				render: (record) => {
					if (renderName) {
						return renderName(record)
					}
					if (link) {
						return (
							<Link to={`${path}/${record._id}`}>
								{nameProp(record)}
							</Link>
						)
					}
					return nameProp(record)
				},
				noFilter: true
			}
		}

		if (teamProp) {
			cols['team'] = {
				queryProps: [{ team: ['_id', 'n', 'path'] }],
				name: intl.formatMessage({ id: 'team' }),
				title: <FontAwesomeIcon icon={faUsers} />,
				accessor: (v) => {
					const c = teamProp(v)
					if (c) {
						return c.n
					}
					return ''
				},
				onFilter: (value, record) => {
					let rt = teamProp(record)
					if (rt && rt.path) {
						return rt.path.includes(value || '')
					}
					return false
				},
				render: (props) => {
					const t = teamProp(props)
					if (!t) {
						return ''
					}
					return (
						<Tag
							key={`${props._id}-${t.path}`}
							title={t.path}
							color="blue"
							style={{ cursor: 'pointer' }}
							onClick={(e) => {
								e.stopPropagation()
								this.props.history.push(`/teams/${t._id}`)
							}}
						>
							{t.n}
						</Tag>
					)
				},
				type: 'Team'
			}
		}

		if (teamsProp) {
			cols['team'] = {
				queryProps: [{ teams: ['_id', 'n', 'path'] }],
				name: intl.formatMessage({ id: 'teams' }),
				title: <FontAwesomeIcon icon={faUsers} />,
				accessor: (v) => {
					const t = teamsProp(v)
					if (t) {
						return t.map((team) => team.n).join(', ')
					}
					return ''
				},
				onFilter: (value, record) => {
					let rt = teamsProp(record)
					if (rt && rt.length) {
						for (let t of rt) {
							if (t.path.includes(value || '')) {
								return true
							}
						}
					}
					return false
				},
				render: (props) => {
					const t = teamsProp(props)
					if (!t || !t.length) {
						return ''
					}
					if (t.length > 2) {
						return (
							<Tooltip
								title={
									<ul style={S.noBulletList}>
										{t.map((team) => (
											<li
												key={`${props._id}-${team.path}`}
											>
												{team.n}
											</li>
										))}
									</ul>
								}
							>
								<Tag color="blue">
									{t.length} <FormattedMessage id="teams" />
								</Tag>
							</Tooltip>
						)
					}
					return t.map((team) => (
						<Tag
							key={`${props._id}-${team.path}`}
							title={team.path}
							color="blue"
						>
							{team.n}
						</Tag>
					))
				},
				type: 'Team'
			}
		}

		return cols
	}

	render() {
		const {
			path,
			selectedId,
			onSelect,
			history,
			redirect,
			intl,
			title,
			actions,
			searchProps,
			nameProp,
			showSearch,
			emptyText,
			rowDisabled
		} = this.props
		const poll = !!title

		const result = (data) => (
			<React.Fragment>
				{(title || showSearch) && (
					<Header
						title={title}
						columns={this.state.columns}
						selectedColumns={this.state.displayedColumnKeys}
						selectedColumnsChanged={this.selectColumns}
						actions={actions}
						search={this.state.search}
						searchChanged={(v) =>
							this.setState({ search: v }, this.saveParams)
						}
						data={data}
						filters={this.state.filters}
						filtersChanged={(v) =>
							this.setState({ filters: v }, this.saveParams)
						}
					/>
				)}
				<Table
					rowKey={this.props.rowKey || '_id'}
					dataTest={this.props.dataTest}
					columns={this.state.displayedColumns}
					allColumns={this.state.columns}
					data={data}
					selected={[selectedId]}
					sort={this.state.sort}
					sortDirection={this.state.sortDirection}
					sortChanged={(sort, sortDirection) =>
						this.setState({ sort, sortDirection }, this.saveParams)
					}
					search={this.state.search}
					searchProps={searchProps}
					nameProp={nameProp}
					filters={this.state.filters}
					emptyText={intl.formatMessage({
						id: emptyText || 'noResults'
					})}
					rowDisabled={rowDisabled}
					onSelect={(r) => {
						if (onSelect) {
							onSelect(r)
						} else if (redirect) {
							history.push(`${path}/${r._id}`)
						}
					}}
				/>
			</React.Fragment>
		)

		if (this.props.data) {
			// data is supplied, no need for a query
			return result(this.props.data)
		}

		if (!this.state.query) {
			return <Loading />
		}

		return (
			<Query
				pollInterval={poll ? config.longPollInterval : null}
				query={this.state.query}
			>
				{({ loading, error, data }) => {
					if ((!data || !data[this.props.queryType]) && loading) {
						return (
							<React.Fragment>
								{title && <Header title={title} />}
								<Loading />
							</React.Fragment>
						)
					}
					if (error) {
						return (
							<React.Fragment>
								{title && <Header title={title} />}
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={Utils.displayGraphQLErrors(
										error
									)}
									type="error"
									showIcon
								/>
							</React.Fragment>
						)
					}

					return result(data[this.props.queryType])
				}}
			</Query>
		)
	}
}

export default withRouter(injectIntl(withResponsive(TableV2)))
