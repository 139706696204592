import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import S, { Colors } from './Styles'

export default ({ icon }) => (
	<div style={S.layout.pageMiddle}>
		<FontAwesomeIcon
			icon={icon}
			style={{ fontSize: 150, color: Colors.emptyIcons }}
		/>
	</div>
)
