import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import removeAccents from 'remove-accents'
import semver from 'semver'

import S, { Colors } from '../Styles'
import * as Utils from '../Utils'

export default class Table extends Component {
	state = {
		sortedData: []
	}

	componentDidMount() {
		this.setState({ sortedData: this.filterSearchAndSort(this.props) })
	}

	componentWillReceiveProps(newProps) {
		let d
		if (
			!this.state.sortedData ||
			(newProps.data && !isEqual(this.props.data, newProps.data))
		) {
			// new data
			d = this.filterSearchAndSort(newProps)
		} else if (
			newProps.sort !== this.props.sort ||
			newProps.sortDirection !== this.props.sortDirection
		) {
			// new sort
			d = this.sortData(this.state.sortedData, newProps)
		} else if (newProps.search !== this.props.search) {
			if (
				newProps.search &&
				newProps.search.indexOf(this.props.search) > -1
			) {
				// same search longer word
				d = this.searchData(this.state.sortedData, newProps)
			} else {
				// new search or shorter word
				d = this.filterSearchAndSort(newProps)
			}
		} else if (!isEqual(newProps.filters, this.props.filters)) {
			if (newProps.filters.length > this.props.filter) {
				// more filters, avoid resorting or searching
				d = this.filterData(this.state.sortedData, newProps)
			} else {
				d = this.filterSearchAndSort(newProps)
			}
		}
		if (d) {
			this.setState({ sortedData: d })
		}
	}

	filterSearchAndSort = (newProps) => {
		let d = this.filterData(newProps.data, newProps)
		d = this.searchData(d, newProps)
		d = this.sortData(d, newProps)
		return d
	}

	filterData = (data, props) => {
		const { filters, allColumns, rowKey } = props
		let d = data ? [...data].filter((d) => d && d[rowKey]) : []
		if (filters && filters.length > 0) {
			for (let f of filters) {
				const column = allColumns[f.column]

				// Prepare values if string
				let cleanValueStrings
				if (Utils.isString(f.values[0])) {
					cleanValueStrings = f.values.map((v) =>
						removeAccents(v.trim().toLowerCase())
					)
				}

				const filterSingleValue = (record, value, index) => {
					// Special filter method
					if (column.onFilter) {
						return column.onFilter(value, record)
					}
					const recordValue = column.accessor(record)
					if (!recordValue) {
						return false
					}
					// Filter strings
					if (cleanValueStrings) {
						return (
							removeAccents(recordValue.trim().toLowerCase()) ===
							cleanValueStrings[index]
						)
					}
					// Filter everything else
					return recordValue === value
				}

				d = d.filter((record) => {
					for (let i in f.values) {
						if (filterSingleValue(record, f.values[i], i)) {
							return true
						}
					}
					return false
				})
			}
		}
		return d
	}

	searchData = (data, props) => {
		const { search, searchProps, nameProp } = props
		if (search && search.length > 2) {
			return data.filter((record) => {
				let aa = ['']
				if (searchProps) {
					aa = searchProps(record)
				} else if (nameProp) {
					aa = [nameProp(record)]
				}
				for (let prop of aa) {
					if (
						prop &&
						removeAccents(prop.toLowerCase()).indexOf(
							removeAccents(search.toLowerCase())
						) > -1
					) {
						return true
					}
				}
				return false
			})
		}
		return data
	}

	sortData = (data, props) => {
		const { sort, sortDirection, allColumns } = props
		if (!data) {
			return []
		}

		let column
		if (sort) {
			column = allColumns[sort]
		}
		if (column) {
			// sort
			return data.sort((a, b) => {
				const aa = column.accessor(a)
				const bb = column.accessor(b)

				if (aa === null || aa === undefined) {
					return 1
				}
				if (bb === null || bb === undefined) {
					return -1
				}
				if (column.type === 'Version') {
					// special sorting for versions
					const aaa = semver.coerce(aa)
					const bbb = semver.coerce(bb)
					if (!aaa) {
						return 1
					}
					if (!bbb) {
						return -1
					}
					return semver.compare(aaa, bbb) * sortDirection
				}
				if (Utils.isString(aa)) {
					// string sorting
					return sortDirection === 1 ?
							aa.localeCompare(bb)
						:	bb.localeCompare(aa)
				}

				// sorting for numbers, dates and everything else
				return (aa - bb) * sortDirection
			})
		}
		return data
	}

	render() {
		const {
			columns,
			rowKey,
			onSelect,
			updated,
			selected,
			sort,
			sortDirection,
			sortChanged,
			style,
			emptyText,
			rowDisabled,
			dataTest
		} = this.props
		return (
			<React.Fragment>
				<div
					style={{
						marginTop: 5,
						marginLeft: -24,
						marginRight: -24,
						overflowX: 'auto',
						paddingLeft: 24,
						...style
					}}
				>
					<table className="list-table" data-test={dataTest}>
						<thead>
							<tr style={styles.row}>
								{columns.map((c, i) => (
									<th
										key={c.key}
										style={{
											...styles.cell,
											borderTop: `3px solid ${c.key === sort && sortDirection === -1 ? '#999' : '#fff'}`,
											borderBottom:
												(
													c.key === sort &&
													sortDirection === 1
												) ?
													`3px solid #999`
												:	null
										}}
										onClick={() =>
											sortChanged(
												c.key,
												c.key === sort ?
													sortDirection * -1
												:	1
											)
										}
									>
										{c.title || c.name}
										{i === 0 && (
											<span style={S.emptyText}>
												&nbsp;(
												{this.state.sortedData.length})
											</span>
										)}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{(
								this.state.sortedData &&
								this.state.sortedData.length
							) ?
								this.state.sortedData.map((r, i) => (
									<Row
										key={`row-${r[rowKey]}-${i}`}
										rowKey={rowKey}
										columns={columns}
										record={r}
										onSelect={onSelect}
										updated={updated}
										selected={
											selected.indexOf(r[rowKey]) > -1
										}
										disabled={
											rowDisabled ? rowDisabled(r) : false
										}
									/>
								))
							:	<tr>
									<td
										colSpan={columns.length}
										style={{
											color: 'rgba(0, 0, 0, 0.45)',
											textAlign: 'center',
											padding: 20
										}}
									>
										{emptyText}
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</React.Fragment>
		)
	}
}

class Row extends Component {
	shouldComponentUpdate(newProps) {
		return (
			newProps.updated !== this.props.updated ||
			newProps.selected !== this.props.selected ||
			!isEqual(newProps.record, this.props.record)
		)
	}

	render() {
		const { rowKey, columns, record, onSelect, selected, disabled } =
			this.props
		let style = styles.cell
		if (selected) {
			style = { ...styles.cell, ...styles.selected }
		} else if (disabled) {
			style = { ...styles.cell, color: 'rgba(0, 0, 0, 0.25)' }
		}
		return (
			<tr onClick={() => onSelect(record)} style={styles.row}>
				{columns.map((c) => (
					<td key={`col-${record[rowKey]}-${c.key}`} style={style}>
						{c.render ?
							c.render(record)
						: c.accessor ?
							c.accessor(record)
						:	null}
					</td>
				))}
			</tr>
		)
	}
}

const styles = {
	row: {
		borderBottom: `1px solid ${Colors.lines}`,
		cursor: 'pointer',
		width: '100%',
		textAlign: 'left'
	},
	cell: {
		padding: 8
	},
	selected: {
		background: '#1890ff',
		color: '#fff'
	}
}
