import React from 'react'
import Responsive from 'react-responsive'

export const Desktop = (props) => <Responsive {...props} minWidth={1500} />
export const SubDesktop = (props) => <Responsive {...props} maxWidth={1499} />
export const Tablet = (props) => (
	<Responsive {...props} minWidth={768} maxWidth={1499} />
)
export const Default = (props) => <Responsive {...props} minWidth={768} />
export const Mobile = (props) => <Responsive {...props} maxWidth={767} />
export const NotTinyMobile = (props) => <Responsive {...props} minWidth={350} />

export const withResponsive = (Component) => {
	return class extends React.Component {
		render() {
			return (
				<React.Fragment>
					<Mobile>
						<Component mobileVersion {...this.props} />
					</Mobile>
					<Tablet>
						<Component tabletVersion {...this.props} />
					</Tablet>
					<Desktop>
						<Component {...this.props} />
					</Desktop>
				</React.Fragment>
			)
		}
	}
}
