import React, { Component } from 'react'
import {
	Timeline,
	Modal,
	Alert,
	DatePicker,
	Switch,
	Row,
	Col,
	Divider
} from 'antd'
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment-timezone'

import Loading from '../../components/LoadingPage'
import * as Utils from '../../components/Utils'
import S, { Colors } from '../../components/Styles'
import {
	normalizeTimezoneToIANAFormat,
	convertTimezoneIANAToUTCFormat
} from '../../utils/date'
import config from '../../config'

class SelectProgram extends Component {
	state = {
		error: null,
		// We store date as an offset-less ISO8061 string
		// e.g. "2024-10-04T17:31"
		date: moment()
			.tz(this.props.device.timeZone)
			.format('YYYY-MM-DDTHH:mm:ss'),
		deviceTimezone: normalizeTimezoneToIANAFormat(
			this.props.device.timeZone
		),
		useDeviceTimezone: true
	}

	handleCancel = () => {
		this.props.onClose()
	}

	changeTimezone = (useDeviceTimezone) => {
		const browserTimezone = moment.tz.guess()
		const deviceTimezone = this.state.deviceTimezone
		const date = moment
			.tz(
				this.state.date,
				useDeviceTimezone ? browserTimezone : deviceTimezone
			)
			.tz(useDeviceTimezone ? deviceTimezone : browserTimezone)
			.format('YYYY-MM-DDTHH:mm:ss')
		this.setState({
			date,
			useDeviceTimezone
		})
	}

	render() {
		const { device, zone, intl } = this.props
		const useDeviceTimezone = this.state.useDeviceTimezone
		const deviceTimezone = this.state.deviceTimezone
		const browserTimezone = moment.tz.guess()
		const isDifferentTimezone = browserTimezone !== deviceTimezone

		let vars = { uuid: device.uuid }
		if (this.state.date) {
			vars.date = moment
				.tz(
					this.state.date,
					this.state.useDeviceTimezone ?
						deviceTimezone
					:	browserTimezone
				)
				.toDate()
		}
		if (zone) {
			vars.zone = zone._id
		}

		return (
			<Modal
				visible={true}
				title={intl.formatMessage(
					{ id: 'history.devicePlaybackHistory' },
					{ name: device.nickname || device.name || '' }
				)}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<Row style={S.centered} align="middle">
					<Col span="12" style={S.centered}>
						<DatePicker
							showTime
							allowClear={false}
							value={moment.tz(
								this.state.date,
								this.state.useDeviceTimezone ?
									deviceTimezone
								:	browserTimezone
							)}
							format="DD-MM-YYYY HH:mm:ss"
							placeholder={intl.formatMessage({
								id: 'history.selectTime'
							})}
							// onChange={this.pickedTime}
							onChange={(value) => {
								this.setState({
									date: value.format('YYYY-MM-DDTHH:mm:ss')
								})
							}}
						/>
					</Col>
					<Col span="12" style={S.centered}>
						{isDifferentTimezone && (
							<Switch
								style={S.switchOneColor}
								checkedChildren={
									intl.formatMessage({
										id: 'history.deviceTimezone'
									}) +
									`: ${convertTimezoneIANAToUTCFormat(deviceTimezone)}`
								}
								unCheckedChildren={
									intl.formatMessage({
										id: 'history.localTimezone'
									}) +
									`: ${convertTimezoneIANAToUTCFormat(browserTimezone)}`
								}
								onChange={this.changeTimezone}
								checked={this.state.useDeviceTimezone}
							/>
						)}
					</Col>
				</Row>
				<Divider />
				<Query
					variables={vars}
					query={gql`
						query fetchDevicePlayHistory(
							$uuid: String!
							$date: Date
							$zone: String
							$evtType: Float
						) {
							histories(
								deviceUuid: $uuid
								date: $date
								zoneId: $zone
								event: $evtType
							) {
								deviceUuid
								trackId
								programId
								playlistId
								manualPlaylist
								zoneId
								date
								event
								message
								username
								messageId
							}
						}
					`}
					pollInterval={config.longPollInterval}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return <Loading />
						}
						if (error) {
							return (
								<Alert
									message={intl.formatMessage({
										id: 'error'
									})}
									description={Utils.displayGraphQLErrors(
										error
									)}
									type="error"
									showIcon
								/>
							)
						}

						if (!data.histories || data.histories.length === 0) {
							return (
								<div style={styles.noData}>
									<FormattedMessage id="history.noData" />
								</div>
							)
						}

						this.zones = {}
						for (let z in device.zones) {
							const zone = device.zones[z]
							this.zones[zone._id] =
								`#${parseInt(z, 10) + 1} ${zone.name}`
						}

						return (
							<Timeline>
								{data.histories.map((h, i) => {
									const hd = Utils.historyDisplay(
										intl,
										h,
										device
									)
									if (!hd) {
										return null
									}
									return (
										<Timeline.Item
											dot={
												hd.icon && (
													<FontAwesomeIcon
														icon={hd.icon}
														style={{
															fontSize: '14px'
														}}
														color={hd.color}
													/>
												)
											}
											key={`${i}`}
										>
											<div style={styles.eventContainer}>
												<div style={styles.eventTitle}>
													{hd.contents}
												</div>
												{hd.details}
												<div style={styles.time}>
													<FormattedDate
														hour="2-digit"
														minute="2-digit"
														hour12={false}
														weekday="short"
														day="numeric"
														month="short"
														value={h.date}
														// We need to normalize the timezone here because this component expects the IANA format
														timeZone={
															useDeviceTimezone ?
																deviceTimezone
															:	browserTimezone
														}
													/>
													{isDifferentTimezone && (
														<React.Fragment>
															<Divider type="vertical" />
															{useDeviceTimezone ?
																deviceTimezone
															:	browserTimezone}
														</React.Fragment>
													)}
													{h.zoneId && (
														<React.Fragment>
															<Divider type="vertical" />
															{this.zones[
																h.zoneId
															] || h.zoneId}
														</React.Fragment>
													)}
													{h.username && (
														<React.Fragment>
															<Divider type="vertical" />
															{h.username}
														</React.Fragment>
													)}
												</div>
											</div>
										</Timeline.Item>
									)
								})}
							</Timeline>
						)
					}}
				</Query>
			</Modal>
		)
	}
}

const styles = {
	noData: {
		position: 'relative',
		padding: '16px 16px',
		background: '#fff',
		textAlign: 'center',
		fontSize: '14px',
		color: Colors.disabled.text
	},
	eventContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	eventTitle: {},
	time: {
		fontSize: '11px',
		color: Colors.disabled.text
	}
}

export default withApollo(injectIntl(SelectProgram))
