import React, { Component } from 'react'
import {
	Card,
	Modal,
	Form,
	Button,
	DatePicker,
	Select,
	InputNumber
} from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileUpload } from '@fortawesome/free-solid-svg-icons'

import BrowseMedia from './BrowseMedia'
import { SelectMessagePrograms } from '../../views/tracks/SelectProgram'
import * as Auth from '../../auth'
import S from '../../components/Styles'
import * as Utils from '../../components/Utils'
import { MessageProgramDetailsQuery } from './Queries'

const FormItem = Form.Item

class ImportRamadanEvents extends Component {
	formRef = React.createRef()

	state = {
		delimiter: ',',
		uploading: false,
		selectMedia: false,
		media: null,
		file: null,
		messageProgramId: null,
		columns: [],
		offset: 0
	}

	onClickSubmit = async (e) => {
		e.preventDefault()

		const intl = this.props.intl

		Modal.confirm({
			title: intl.formatMessage({
				id: 'messagePrograms.table.importRamadanEvent.confirm'
			}),
			okText: intl.formatMessage({ id: 'ok' }),
			cancelText: intl.formatMessage({ id: 'actions.cancel' }),
			onOk: async () => {
				this.uploadFile()
			}
		})
	}

	handleCancel = () => {
		this.props.onClose()
	}

	selectFile = (e) => {
		const file = e.target.files[0]
		this.setState({ file })
	}

	uploadFile = async () => {
		const file = this.state.file

		if (file && file.name) {
			try {
				this.setState({ uploading: true })
				const result = await this.props.client.mutate({
					variables: {
						delimiter: this.state.delimiter,
						file,
						startDate: this.formRef.current.getFieldValue('start'),
						endDate: this.formRef.current.getFieldValue('end'),
						mediaId: this.state.media._id,
						messageProgramId: this.state.messageProgramId,
						columns: this.state.columns,
						offset: this.state.offset
					},
					mutation: gql`
						mutation ramadanUpload(
							$delimiter: String!
							$file: Upload!
							$startDate: String!
							$endDate: String!
							$mediaId: String!
							$messageProgramId: String!
							$columns: [String]
							$offset: Int
						) {
							ramadanUpload(
								delimiter: $delimiter
								file: $file
								startDate: $startDate
								endDate: $endDate
								mediaId: $mediaId
								messageProgramId: $messageProgramId
								columns: $columns
								offset: $offset
							) {
								success
							}
						}
					`,
					awaitRefetchQueries: true,
					refetchQueries: [
						{
							query: MessageProgramDetailsQuery,
							variables: {
								id: this.state.messageProgramId
							}
						}
					]
				})
				this.setState({ uploading: false })
				if (!result.data.ramadanUpload.success) {
					Utils.displayError(
						new Error(
							'Something went wrong. No events were imported. This can be due to a wrong file format or a wrong delimiter.'
						)
					)
				} else {
					this.props.onClose()
				}
			} catch (e) {
				this.setState({ uploading: false })
				Utils.displayError(e)
			}
		}
	}

	render() {
		const { intl } = this.props
		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id: 'messagePrograms.table.importRamadanEvent.title'
				})}
				onOk={this.onClickSubmit}
				onCancel={this.handleCancel}
				footer={[
					<Button key="back" onClick={this.handleCancel}>
						<FormattedMessage id="actions.cancel" />
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={this.state.uploading}
						onClick={this.onClickSubmit}
						// Disable the submit button if the form is not valid
						disabled={
							!this.formRef.current ||
							!this.formRef.current.getFieldValue('start') ||
							!this.formRef.current.getFieldValue('end') ||
							!this.state.media ||
							!this.state.file
						}
					>
						<FormattedMessage id="actions.submit" />
					</Button>
				]}
			>
				<Form
					ref={this.formRef}
					layout="vertical"
					onFinish={this.onClickSubmit}
					initialValues={{
						delimiter: this.state.delimiter,
						offset: this.state.offset
					}}
				>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectDelimiter'
						})}
						name="delimiter"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.delimiterRequired'
								})
							}
						]}
					>
						<Select
							onChange={(value) =>
								this.setState({ delimiter: value })
							}
						>
							<Select.Option value=",">,</Select.Option>
							<Select.Option value=";">;</Select.Option>
						</Select>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectStartDate'
						})}
						name="start"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.startDateRequired'
								})
							}
						]}
					>
						<DatePicker
							format="YYYY-MM-DD"
							placeholder={intl.formatMessage({
								id: 'messagePrograms.table.importRamadanEvent.selectStartDate'
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectEndDate'
						})}
						name="end"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.endDateRequired'
								})
							}
						]}
					>
						<DatePicker
							format="YYYY-MM-DD"
							placeholder={intl.formatMessage({
								id: 'messagePrograms.table.importRamadanEvent.selectEndDate'
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectOffset'
						})}
						name="offset"
					>
						<InputNumber
							min={-30}
							max={30}
							onChange={(value) =>
								this.setState({ offset: value })
							}
						/>
					</FormItem>

					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectMessageProgram'
						})}
						name="messageProgram"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.messageProgramRequired'
								})
							}
						]}
					>
						<SelectMessagePrograms
							selectedIds={this.state.messageProgramId}
							onSelect={(mps) => {
								this.setState({ messageProgramId: mps })
							}}
							single
							placeholder={intl.formatMessage({
								id: 'messagePrograms.table.importRamadanEvent.selectMessageProgram'
							})}
						/>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectMedia'
						})}
						name="media"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.mediaRequired'
								})
							}
						]}
					>
						{this.state.media ?
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<div
									style={{
										...S.card.trackInfo.textContainer,
										textAlign: 'center'
									}}
								>
									<div>{this.state.media.name}</div>
									{Auth.hasPermission('*') && (
										<div
											style={{
												...S.emptyText,
												fontSize: 10
											}}
										>
											{this.state.media._id}
										</div>
									)}
									<div>{this.state.media.duration}</div>
									<Button
										onClick={this.playAudio}
										style={{ width: 100, marginTop: 10 }}
									>
										<FontAwesomeIcon icon={faPlay} />
									</Button>
									<Button
										onClick={() =>
											this.setState({ selectMedia: true })
										}
										style={{
											width: 100,
											marginTop: 10,
											marginLeft: 10
										}}
									>
										<FormattedMessage id="actions.change" />
									</Button>
								</div>
							</Card>
						:	<Button
								style={{ width: '100%', height: 50 }}
								onClick={() =>
									this.setState({ selectMedia: true })
								}
							>
								<FormattedMessage id="messagePrograms.details.selectFile" />
							</Button>
						}
						{this.state.selectMedia && (
							<BrowseMedia
								selectMedia={(media) => media.isAudio}
								onClose={(media) =>
									this.setState({ selectMedia: false, media })
								}
							/>
						)}
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectColumns'
						})}
						name="columns"
					>
						<Select
							mode="multiple"
							placeholder={intl.formatMessage({
								id: 'messagePrograms.table.importRamadanEvent.selectColumns'
							})}
							onChange={(value) =>
								this.setState({ columns: value })
							}
						>
							<Select.Option value="fajr">Fajr</Select.Option>
							<Select.Option value="dhuhr">Dhuhr</Select.Option>
							<Select.Option value="asr">Asr</Select.Option>
							<Select.Option value="maghrib">
								Maghrib
							</Select.Option>
							<Select.Option value="isha">Isha</Select.Option>
						</Select>
					</FormItem>
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.table.importRamadanEvent.selectCSVFile'
						})}
						name="file"
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'messagePrograms.table.importRamadanEvent.csvFileRequired'
								})
							}
						]}
					>
						<p>{this.state.file ? this.state.file.name : ''}</p>
						<Button loading={this.state.uploading}>
							{!this.state.uploading && (
								<FontAwesomeIcon icon={faFileUpload} />
							)}
							&nbsp;&nbsp;
							<FormattedMessage id="media.table.actions.uploadFile" />
							<input
								type="file"
								accept=".csv"
								style={S.playlistDetails.fileInput}
								ref={(e) => (this.fileInput = e)}
								onChange={this.selectFile}
							/>
						</Button>
					</FormItem>
				</Form>
			</Modal>
		)
	}
}

export default withRouter(withApollo(injectIntl(ImportRamadanEvents)))
