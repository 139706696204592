import React, { Component } from 'react'
import { Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import { FormattedMessage } from 'react-intl'

import * as Utils from '../../../components/Utils'

export default class TaxRateSelect extends Component {
	render() {
		return (
			<Query
				query={gql`
					query fetchTaxRates($subsidiary: String) {
						taxRates(subsidiary: $subsidiary) {
							json
						}
					}
				`}
				variables={{ subsidiary: this.props.subsidiary }}
			>
				{({ loading, error, data }) => {
					if (error) {
						Utils.displayError(error)
						return <FormattedMessage id="error.message" />
					}

					if (loading) {
						return <LoadingOutlined />
					}

					let options = []
					if (data && data.taxRates && data.taxRates.json) {
						const response = JSON.parse(data.taxRates.json)
						options = response.data
					}
					return (
						<Select
							value={this.props.value}
							onChange={this.props.onChange}
							style={this.props.style}
						>
							<Select.Option value={null}>
								<FormattedMessage id="billing.vat.applicableVat.noTax" />{' '}
								(0%)
							</Select.Option>
							{options.map((o) => (
								<Select.Option value={o.id} key={o.id}>
									{o.display_name} ({o.percentage}%)
								</Select.Option>
							))}
						</Select>
					)
				}}
			</Query>
		)
	}
}
