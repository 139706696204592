import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Card, Tag, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
	faThumbsDown,
	faHeadphones,
	faThumbsUp
} from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'
import S from '../../components/Styles'
import * as Player from '../../components/AudioPlayer'

class ITrackInfoDisplay extends Component {
	blacklist = async (isBlacklisted) => {
		try {
			const { trackId, device, client } = this.props

			const r = await client.mutate({
				mutation: gql`
					mutation blacklistTracks(
						$deviceUuid: String
						$trackId: String
						$blacklist: Boolean
					) {
						blacklistTrack(
							deviceUuid: $deviceUuid
							trackId: $trackId
							blacklist: $blacklist
						) {
							success
							message
						}
					}
				`,
				variables: {
					deviceUuid: device.uuid,
					trackId,
					blacklist: !isBlacklisted
				}
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { trackId, intl, device, playlistId, manualPlaylist } = this.props
		if (!trackId) {
			return null
		}

		const isBlacklisted = device.blacklist?.indexOf(trackId) > -1
		return (
			<Query
				variables={{ id: trackId, playlistId }}
				query={
					playlistId ?
						gql`
							query fetchTrackInfoWithProgram(
								$id: String!
								$playlistId: MongoID!
							) {
								trackById(_id: $id) {
									_id
									title
									artist
									artwork
								}
								programPlaylistById(_id: $playlistId) {
									_id
									name
									artwork
								}
							}
						`
					:	gql`
							query fetchtTrackInfo($id: String!) {
								trackById(_id: $id) {
									_id
									title
									artist
									artwork
								}
							}
						`
				}
			>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<LoadingOutlined />
							</Card>
						)
					}
					if (error) {
						console.log(error)
					}
					if (data) {
						const track = data.trackById
						const playlist = data.programPlaylistById
						if (track) {
							return (
								<Card
									style={S.card.trackInfo.card}
									bodyStyle={{ padding: 0 }}
								>
									<div style={S.card.trackInfo.cardBody}>
										<img
											src={Utils.trackArtworkThumbUrl(
												track
											)}
											style={
												S.card.trackInfo.compactImage
											}
											alt=""
										/>
										<div
											style={
												S.card.trackInfo.textContainer
											}
										>
											<div>{track.title}</div>
											<div>{track.artist}</div>
										</div>
										<a
											onClick={() =>
												Player.playExtract(trackId)
											}
											title={intl.formatMessage({
												id: 'devices.player.track.preview'
											})}
										>
											<FontAwesomeIcon
												icon={faHeadphones}
											/>
										</a>
										{Auth.hasPermission(
											'device:edit:blacklistTrack'
										) && (
											<>
												<Divider type="vertical" />
												<a
													onClick={() =>
														this.blacklist(
															isBlacklisted
														)
													}
													title={intl.formatMessage({
														id:
															isBlacklisted ?
																'devices.actions.unblacklist'
															:	'devices.player.blacklist'
													})}
												>
													<FontAwesomeIcon
														icon={
															isBlacklisted ?
																faThumbsUp
															:	faThumbsDown
														}
													/>
												</a>
											</>
										)}
									</div>
									{playlist && (
										<React.Fragment>
											<Divider
												type="horizontal"
												style={{ margin: 0 }}
											/>
											<div
												style={
													S.card.trackInfo.cardBody
												}
											>
												<img
													src={Utils.playlistImageUrl(
														playlist
													)}
													style={
														S.card.trackInfo
															.compactImage
													}
													alt={playlist.name}
												/>
												<div
													style={
														S.card.trackInfo
															.textContainer
													}
												>
													<div>
														{playlist.name}{' '}
														{manualPlaylist && (
															<Tag color="gold">
																<FormattedMessage id="devices.player.manual" />
															</Tag>
														)}
													</div>
												</div>
											</div>
										</React.Fragment>
									)}
								</Card>
							)
						}
					}
					return null
				}}
			</Query>
		)
	}
}

export const TrackInfoDisplay = injectIntl(withApollo(ITrackInfoDisplay))

export class PlaylistInfoDisplay extends Component {
	render() {
		const { playlistId, manual } = this.props
		if (!playlistId) {
			return null
		}

		return (
			<Query
				variables={{ id: playlistId }}
				query={gql`
					query fetchProgramPlaylist($id: MongoID!) {
						programPlaylistById(_id: $id) {
							_id
							name
							artwork
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<LoadingOutlined />
							</Card>
						)
					}
					if (error) {
						console.log(error)
					}
					if (data) {
						const playlist = data.programPlaylistById
						return (
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<img
									src={Utils.playlistImageUrl(playlist)}
									style={S.card.trackInfo.compactImage}
									alt={playlist?.name}
								/>
								<div style={S.card.trackInfo.textContainer}>
									<div>
										{playlist?.name ?
											playlist.name
										:	'Deleted playlist'}
									</div>
									<div>
										{manual ?
											<Tag color="gold">
												<FormattedMessage id="devices.player.status.forced" />
											</Tag>
										:	<Tag color="gold">
												<FormattedMessage id="devices.player.status.scheduled" />
											</Tag>
										}
									</div>
								</div>
							</Card>
						)
					}
					return null
				}}
			</Query>
		)
	}
}

export class MessageInfoDisplay extends Component {
	render() {
		const { mediaId, manual } = this.props
		if (!mediaId) {
			return null
		}

		return (
			<Query
				variables={{ id: mediaId }}
				query={gql`
					query fetchMedia($id: String!) {
						mediaById(_id: $id) {
							_id
							name
						}
					}
				`}
			>
				{({ loading, error, data }) => {
					if (loading) {
						return (
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<LoadingOutlined />
							</Card>
						)
					}
					if (error) {
						console.log(error)
					}
					if (data) {
						const media = data.mediaById
						return (
							<Card
								style={S.card.trackInfo.card}
								bodyStyle={S.card.trackInfo.cardBody}
							>
								<FontAwesomeIcon
									icon={faCommentAlt}
									style={{ marginRight: 15 }}
									size="2x"
								/>
								<div style={S.card.trackInfo.textContainer}>
									<div>{media.name}</div>
									<div>
										{manual ?
											<Tag color="blue">
												<FormattedMessage id="manual" />
											</Tag>
										:	<Tag color="gold">
												<FormattedMessage id="scheduled" />
											</Tag>
										}
									</div>
								</div>
							</Card>
						)
					}
					return null
				}}
			</Query>
		)
	}
}
