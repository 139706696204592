import React, { Component } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import * as Utils from '../../components/Utils'
import { DeviceDetailsQuery } from '../devices/Queries'

class PlayStatus extends Component {
	state = {
		outputs: null,
		selected: 0
	}

	componentDidMount() {
		this.getDeviceOutputs(this.props)
	}

	componentWillReceiveProps(props) {
		this.getDeviceOutputs(props)
	}

	getDeviceOutputs = (props) => {
		if (props.device && props.zone) {
			let outputs = props.device.outputs
			let selected = 0
			if (props.zone.output) {
				for (let i in outputs) {
					let o = outputs[i]
					if (
						props.zone.output.type === o.type &&
						props.zone.output.channel === o.channel
					) {
						selected = i
						break
					}
				}
			}

			this.setState({ outputs, selected })
		}
	}

	changedOutput = async (e) => {
		const { zone, device } = this.props
		const output = this.state.outputs[parseInt(e.key, 10)]

		try {
			const r = await this.props.client.mutate({
				variables: {
					deviceId: device._id,
					zoneId: zone._id,
					output: JSON.stringify(output)
				},
				mutation: gql`
					mutation setZoneOutput(
						$zoneId: String
						$deviceId: String
						$output: String
					) {
						setZoneOutput(
							deviceId: $deviceId
							zoneId: $zoneId
							output: $output
						) {
							success
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)
		} catch (err) {
			Utils.displayError(err)
		}
	}

	render() {
		const { device, zone, disabled } = this.props
		const { outputs, selected } = this.state

		if (device && zone) {
			if (outputs) {
				if (disabled) {
					return (
						<Button style={{ ...this.props.style }}>
							{outputs[selected].name}
						</Button>
					)
				} else {
					const menu = (
						<Menu
							onClick={this.changedOutput}
							style={{ maxHeight: 300, overflowY: 'scroll' }}
						>
							{outputs.map((o, i) => (
								<Menu.Item key={i}>{o.name}</Menu.Item>
							))}
						</Menu>
					)
					return (
						<Dropdown
							overlay={menu}
							placement="bottomRight"
							trigger={['click']}
						>
							<Button style={{ ...this.props.style }}>
								{outputs[selected].name} <DownOutlined />
							</Button>
						</Dropdown>
					)
				}
			} else {
				// Martini devices have only one output, we display the route (speaker, jack, BT...)
				if (zone.stateOutputType) {
					return (
						<Button style={{ ...this.props.style }}>
							<FontAwesomeIcon
								icon={Utils.iconForOutputType(
									zone.stateOutputType
								)}
							/>
							&nbsp;{Utils.nameForOutputType(zone)}
						</Button>
					)
				}
			}
		}
		return <FormattedMessage id="devices.player.zone.output.unknown" />
	}
}

export default withApollo(PlayStatus)
