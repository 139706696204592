import React, { Component } from 'react'
import { Select } from 'antd'

export default class MultiSelect extends Component {
	handleSelect = (selectedItems) => {
		this.props.onSelect(selectedItems)
	}

	render() {
		let { selectedItems, options, placeholder, style } = this.props
		if (!selectedItems) {
			selectedItems = []
		}
		const filteredOptions = options.filter(
			(o) => !selectedItems.includes(o)
		)
		return (
			<Select
				mode="multiple"
				placeholder={placeholder}
				value={selectedItems}
				onChange={this.handleSelect}
				style={style}
			>
				{filteredOptions.map((item) => (
					<Select.Option key={item.value} value={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		)
	}
}
