import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'

import S from './Styles'
import ActionsMenu from './ActionsMenu'
import { Mobile, Default, withResponsive } from './Responsive'

export default withRouter(
	withResponsive(
		({
			title,
			actions,
			children,
			left,
			menuIcon,
			backTitle,
			backUrl,
			history,
			button,
			tabletVersion,
			mobileVersion,
			wideMode,
			dataTest
		}) => (
			<div style={S.itemDetails.titleSection} data-test={dataTest}>
				{backUrl && (tabletVersion || mobileVersion || wideMode) && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<Button onClick={() => history.push(backUrl)}>
							<FontAwesomeIcon icon={faAngleLeft} />
							&nbsp;&nbsp;
							<FormattedMessage id={backTitle || 'back'} />
						</Button>
						{actions && actions.length > 0 && (
							<ActionsMenu icon={menuIcon} menuItems={actions} />
						)}
					</div>
				)}
				{(tabletVersion || mobileVersion || wideMode) && (
					<h1
						style={
							left ?
								S.itemDetails.titleLeft
							:	S.itemDetails.titleMobile
						}
					>
						{title || '...'}
					</h1>
				)}
				{!(tabletVersion || mobileVersion || wideMode) && (
					<h1
						style={
							left ? S.itemDetails.titleLeft : S.itemDetails.title
						}
					>
						{title || '...'}
					</h1>
				)}
				{actions && actions.length > 0 && (
					<React.Fragment>
						{!(tabletVersion || mobileVersion || wideMode) && (
							<ActionsMenu
								icon={menuIcon}
								style={S.itemDetails.titleActions}
								menuItems={actions}
							/>
						)}
						{(tabletVersion || mobileVersion || wideMode) &&
							!backUrl && (
								<ActionsMenu
									icon={menuIcon}
									style={S.itemDetails.titleActions}
									menuItems={actions}
								/>
							)}
					</React.Fragment>
				)}
				<Mobile>
					{button && (
						<Button onClick={button.onClick}>
							<FontAwesomeIcon icon={button.icon} />
							&nbsp;&nbsp;{button.title}
						</Button>
					)}
				</Mobile>
				<Default>
					{button && (
						<Button
							onClick={button.onClick}
							style={S.itemDetails.titleActions}
						>
							<FontAwesomeIcon icon={button.icon} />
							&nbsp;&nbsp;{button.title}
						</Button>
					)}
				</Default>

				{children}
			</div>
		)
	)
)
