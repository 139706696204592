import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import S from '../components/Styles'

export default class DetailsProperty extends Component {
	render() {
		return (
			<div style={this.props.style} onClick={this.props.onClick}>
				<div style={S.itemDetails.propertyTitle}>
					<FormattedMessage id={this.props.title} />
				</div>
				<div style={S.itemDetails.propertyValue}>
					{this.props.children}
				</div>
			</div>
		)
	}
}
