import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { Divider, Switch, Button } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faEdit,
	faTrash,
	faCreditCard,
	faPlus,
	faBookmark,
	faCertificate
} from '@fortawesome/free-solid-svg-icons'

import * as Utils from '../../../components/Utils'
import * as Auth from '../../../auth'
import Table from '../../../components/Table'
import ActionsMenu from '../../../components/ActionsMenu'
import AddPaymentMethod from './AddPaymentMethod'
import { TeamBillingQuery } from '../Queries'

const SORTKEY = 'paymentMethodsTableSort'

class PaymentMethods extends Component {
	state = {
		addPaymentModal: false,
		chargeLoading: false,
		editingPayment: null
	}

	paymentMethodMenu = (pm) => {
		const { subscription, intl, team } = this.props
		const customerInfo = JSON.parse(subscription.customerInfo)

		let actions = [
			{
				// Set default source
				title: intl.formatMessage({
					id: 'billing.paymentMethods.makeDefault'
				}),
				key: 'makeDefault',
				icon: faBookmark,
				disabled: customerInfo.default_source === pm.id,
				mutation: {
					variables: { id: subscription._id, source: pm.id },
					mutation: gql`
						mutation updateSubscriptionPaymentMethod(
							$id: String!
							$source: String
						) {
							subscriptionUpdateDefaultPaymentMethod(
								_id: $id
								source: $source
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: team._id }
						}
					],
					awaitRefetchQueries: true
				}
			},
			{
				// Edit source
				title: intl.formatMessage({ id: 'actions.edit' }),
				key: 'edit',
				icon: faEdit,
				action: () =>
					this.setState({ addPaymentModal: true, editingPayment: pm })
			},
			{
				// Delete source
				title: intl.formatMessage({ id: 'actions.delete' }),
				key: 'delete',
				icon: faTrash,
				disabled: customerInfo.default_source === pm.id,
				confirm: intl.formatMessage({
					id: 'billing.paymentMethods.deleteConfirm'
				}),
				mutation: {
					variables: { id: subscription._id, source: pm.id },
					mutation: gql`
						mutation deleteSubscriptionPaymentMethod(
							$id: String!
							$source: String
						) {
							subscriptionDeletePaymentMethod(
								_id: $id
								source: $source
							) {
								success
								message
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: team._id }
						}
					],
					awaitRefetchQueries: true
				}
			}
		]

		if (pm.type === 'sepa_debit' && pm.mandate && pm.mandate.url) {
			actions.push({
				// View mandate
				title: intl.formatMessage({ id: 'billing.sepa.viewMandate' }),
				key: 'mandate',
				icon: faCertificate,
				action: () => {
					window.open(pm.mandate.url, '_blank')
				}
			})
		}

		return actions
	}

	switchCharge = async (type) => {
		try {
			this.setState({ chargeLoading: true })
			const r = await this.props.client.mutate({
				variables: {
					id: this.props.subscription._id,
					collectionMethod:
						type ? 'charge_automatically' : 'send_invoice'
				},
				mutation: gql`
					mutation updateSubscriptionCollectionMethod(
						$id: String!
						$collectionMethod: String
					) {
						subscriptionUpdateById(
							_id: $id
							record: { collectionMethod: $collectionMethod }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: TeamBillingQuery,
						variables: { id: this.props.team._id }
					}
				],
				awaitRefetchQueries: true
			})
			Utils.parseMutationResponse(r)
			this.setState({ chargeLoading: false })
		} catch (e) {
			this.setState({ chargeLoading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { subscription, intl, team } = this.props

		let paymentMethods = []
		const customerInfo = JSON.parse(subscription.customerInfo)
		if (customerInfo.sources && customerInfo.sources.data) {
			paymentMethods = customerInfo.sources.data
			// hasMore = billing.sources.has_more TODO show more
		}

		let savedSort, savedSortColumn
		const storedSort = localStorage.getItem(SORTKEY)
		if (storedSort) {
			savedSort = JSON.parse(storedSort)
			savedSortColumn = savedSort ? savedSort.column : null
		}

		let columns = [
			{
				title: intl.formatMessage({ id: 'details' }),
				key: 'type',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'type' ?
						savedSort.order
					:	null,
				render: (_, record) => Utils.displayPaymentMethodType(record)
			},
			{
				title: intl.formatMessage({ id: 'billing.invoices.status' }),
				key: 'status',
				defaultSortOrder:
					savedSortColumn && savedSortColumn === 'status' ?
						savedSort.order
					:	null,
				render: (_, record) =>
					Utils.displayPaymentMethodStatus(
						record,
						customerInfo.default_source === record.id
					)
			},
			{
				key: 'action',
				align: 'right',
				render: (_, record) => (
					<ActionsMenu
						size="small"
						menuItems={this.paymentMethodMenu(record)}
					/>
				)
			}
		]

		return (
			<React.Fragment>
				<Divider orientation="left">
					<FontAwesomeIcon icon={faCreditCard} />
					&nbsp;&nbsp;
					<FormattedMessage id="billing.paymentMethods" />
				</Divider>
				{subscription && (
					<div style={{ marginTop: 24, marginBottom: 24 }}>
						<Switch
							checked={
								subscription.collectionMethod ===
								'charge_automatically'
							}
							onChange={this.switchCharge}
							loading={this.state.chargeLoading}
							disabled={
								!Auth.hasPermission(
									'subscription:edit:setPaymentMethods'
								) || paymentMethods.length === 0
							}
						/>
						&nbsp;&nbsp;
						<FormattedMessage id="billing.autoCharge" />
					</div>
				)}
				<Button
					onClick={() => this.setState({ addPaymentModal: true })}
					style={{ marginBottom: 15 }}
				>
					<FontAwesomeIcon icon={faPlus} />
					&nbsp;&nbsp;
					<FormattedMessage id="billing.paymentMethods.add" />
				</Button>
				<Table
					rowKey="id"
					columns={columns}
					dataSource={paymentMethods}
					sortKey={SORTKEY}
					nameProp={(record) => {
						if (record.metadata && record.metadata.name) {
							return record.metadata.name
						} else if (record.card && record.card.brand) {
							return record.card.brand
						}
						return ''
					}}
					searchProps={(record) => {
						let sp = []
						if (record.metadata && record.metadata.name) {
							sp.push(record.metadata.name)
						}
						if (record.card && record.card.brand) {
							sp.push(record.card.brand)
						}
						return sp
					}}
					emptyText="billing.paymentMethods.empty"
				/>

				{this.state.addPaymentModal && (
					<AddPaymentMethod
						team={team}
						subscription={subscription}
						source={this.state.editingPayment}
						onClose={() =>
							this.setState({
								addPaymentModal: false,
								editingPayment: null
							})
						}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(PaymentMethods))
