import React, { Component } from 'react'
import { Tag, Avatar } from 'antd'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import ActionsMenu from '../../components/ActionsMenu'
import {
	faUsers,
	faTrash,
	faEdit,
	faEnvelopeOpen
} from '@fortawesome/free-solid-svg-icons'
import gql from 'graphql-tag'

import { SelectTeamModal } from '../teams/SelectTeam'
import { TeamDetailsQuery, TeamsTreeQuery } from '../teams/Queries'
import NewUserForm from './NewUser'
import * as Auth from '../../auth'
import Table from '../../components/TableV2'
import * as Utils from '../../components/Utils'

const roleColors = {
	superadmin: 'red',
	admin: 'orange',
	manager: 'green',
	user: 'blue'
}
const roleOrder = { superadmin: 5, admin: 4, manager: 3, user: 2 }

class UsersListDisplay extends Component {
	state = {
		selectTeamModal: null,
		editUserModal: null
	}

	columns = {
		email: {
			name: this.props.intl.formatMessage({ id: 'teams.details.email' }),
			accessor: (record) => record.username,
			queryProps: ['username']
		},
		role: {
			name: this.props.intl.formatMessage({ id: 'teams.addUser.role' }),
			queryProps: ['role', 'montyUser'],
			render: (record) => {
				const r = (
					<Tag
						key={`${record._id}-${record.role}`}
						color={roleColors[record.role] || 'cyan'}
					>
						{record.role}
					</Tag>
				)
				if (
					Auth.hasPermission('user:read:montyRole') &&
					record.montyUser
				) {
					return (
						<span>
							{r}
							<Tag key={`${record._id}-monty`} color="gold">
								monty
							</Tag>
						</span>
					)
				}
				return r
			},
			accessor: (record) => roleOrder[record.role] || 1
		},
		actions: {
			name: '',
			style: { textAlign: 'right' },
			queryProps: [],
			render: (record) => (
				<ActionsMenu
					size="small"
					menuItems={this.userActions(record)}
				/>
			),
			accessor: (record) => '',
			hiddenFromHeader: true,
			acl:
				this.props.team ?
					Auth.hasAccess('user:edit', this.props.team._id)
				:	false
		}
	}

	userActions = (user) => {
		const { team } = this.props
		let actions = []
		if (Auth.hasAccess('user:edit', team._id)) {
			actions.push({
				title: this.props.intl.formatMessage({ id: 'actions.edit' }),
				key: 'editUser',
				icon: faEdit,
				action: () => this.setState({ editUserModal: user })
			})
		}
		if (Auth.hasAccess('user:edit', team._id)) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'teams.users.sendInvite'
				}),
				key: 'sendInvite',
				icon: faEnvelopeOpen,
				confirm: this.props.intl.formatMessage(
					{ id: 'teams.users.sendInvite.confirm' },
					{ name: user.username }
				),
				action: async () => {
					await Auth.forgotPassword({
						username: user.username,
						invite: 'onInvite'
					})
				}
			})
		}
		if (
			Auth.hasPermission('user:edit:setTeam') &&
			Auth.hasAccess('user:edit', team._id)
		) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'actions.assignToTeam'
				}),
				key: 'assignTeam',
				icon: faUsers,
				action: () => this.setState({ selectTeamModal: user })
			})
		}
		if (Auth.hasAccess('user:delete', team._id)) {
			actions.push({
				title: this.props.intl.formatMessage({ id: 'actions.delete' }),
				key: 'deleteUser',
				icon: faTrash,
				confirm: this.props.intl.formatMessage(
					{ id: 'teams.actions.deleteUser.confirm' },
					{ name: user.username }
				),
				mutation: {
					variables: { id: user._id },
					mutation: gql`
						mutation removeUser($id: String!) {
							userRemoveById(_id: $id) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamsTreeQuery
						},
						{
							query: TeamDetailsQuery,
							variables: { id: user.teamId }
						}
					]
				}
			})
		}
		return actions
	}

	render() {
		const { users, intl, defaultColumns, showSearch } = this.props

		return (
			<React.Fragment>
				{users && (
					<Table
						rowKey="_id"
						dataTest="teams-list"
						columns={this.columns}
						defaultColumns={defaultColumns}
						data={users}
						teamProp={(record) => record.team}
						nameProp={renderName}
						renderName={(record) => {
							const n = renderName(record)
							return (
								<React.Fragment>
									<Avatar src={Utils.userAvatar(record)} />
									<span style={{ marginLeft: 10 }}>{n}</span>
								</React.Fragment>
							)
						}}
						searchProps={(record) => [
							record.firstName,
							record.lastName,
							record.username
						]}
						showSearch={showSearch}
					/>
				)}
				{this.state.selectTeamModal && (
					<SelectTeamModal
						title={intl.formatMessage({
							id: 'teams.actions.moveToParentTeam'
						})}
						onClose={() =>
							this.setState({ selectTeamModal: false })
						}
						selected={this.state.selectTeamModal.teamId}
						onSelect={async (t) => {
							try {
								const user = this.state.selectTeamModal
								if (t && t !== user.teamId) {
									const r = this.props.client.mutate({
										variables: { id: user._id, team: t },
										mutation: gql`
											mutation updateUserTeam(
												$id: String!
												$team: String!
											) {
												userUpdateById(
													_id: $id
													record: { teamId: $team }
												) {
													recordId
												}
											}
										`,
										refetchQueries: [
											{
												query: TeamsTreeQuery
											},
											{
												query: TeamDetailsQuery,
												variables: { id: user.teamId }
											},
											{
												query: TeamDetailsQuery,
												variables: { id: t }
											}
										]
									})
									Utils.parseMutationResponse(r)
								}
							} catch (e) {
								Utils.displayError(e)
							}
						}}
					/>
				)}
				{this.state.editUserModal && (
					<NewUserForm
						user={this.state.editUserModal}
						team={this.props.team}
						onClose={() => {
							this.setState({ editUserModal: null })
						}}
					/>
				)}
			</React.Fragment>
		)
	}
}

const renderName = (record) => {
	let comps = []
	if (record.firstName) {
		comps.push(record.firstName)
	}
	if (record.lastName) {
		comps.push(record.lastName)
	}
	return comps.join(' ') || '-'
}

export default withApollo(injectIntl(UsersListDisplay))
