export default {
	pollInterval: 2000,
	longPollInterval: 10000,
	aws: {
		region: 'eu-west-3',
		s3: {
			buckets: {
				musicOptimized: 'spectre.staging.music.optimized',
				artworks: 'spectre.staging.artworks',
				artworksOptimized: 'spectre.staging.artworks.optimized',
				userAssets: 'spectre.staging.user-assets',
				messages: 'spectre.staging.messages.originals',
				messagesOptimized: 'spectre.staging.messages.optimized'
			},
			baseUrl: 'https://s3-eu-west-3.amazonaws.com/'
		}
	},
	intercom: {
		docsUrl: 'https://intercom.help/spectre-music',
		appId: 'uul2iavk'
	},
	stripe: {
		global: {
			pk: 'pk_test_RcgZ7IxXpOx42WjSEHETq6Lz'
		},
		apac: {
			pk: 'pk_test_51HJh8CCtFjdBaYEeocIF9eQJ8PruaXzntemrtUX56J3HtCwGLmv8EsVQnvu5HV2vU3k4hMuUiqEoWviGOpAMbDEy00PtYtBeqT'
		}
	},
	sonos: {
		baseUrl: 'https://api.sonos.com/',
		clientId: '939971c1-3ba5-4b69-badc-8f413183f095'
	},
	vauxhall: {
		baseUrl: 'http://localhost:3006/'
	}
}
