import React, { Component } from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUser,
	faLock,
	faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

import Logo from '../../components/SpectreLogo'
import S from '../../components/Styles'
import { setPageTitle } from '../../components/Utils'
import * as Auth from '../../auth'

const FormItem = Form.Item

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			loginError: null
		}
	}

	handleSubmit = async (values) => {
		this.setState({ loading: true })
		try {
			await Auth.login(values)
			this.setState({ loading: false, loginError: false })
			const redirect = `${this.props.match.params.from ? decodeURIComponent(this.props.match.params.from) : '/devices'}`
			this.props.history.push(redirect)
		} catch (e) {
			this.setState({ loginError: e.message, loading: false })
		}
	}

	render() {
		setPageTitle(this.props.intl, 'auth.login.title')
		return (
			<div>
				<div style={S.login.bgImage}></div>
				<div style={S.login.bg}>
					<div style={S.login.container} className="login">
						<Form onFinish={this.handleSubmit} style={S.login.form}>
							<div style={S.login.logoContainer}>
								<Logo
									color="#000"
									style={{
										margin: '10px',
										height: 50,
										width: 30
									}}
								/>
							</div>
							<FormItem
								name="username"
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: 'auth.error.noUsername'
										})
									}
								]}
							>
								<Input
									prefix={
										<FontAwesomeIcon
											icon={faUser}
											style={S.login.fieldIcon}
										/>
									}
									placeholder={this.props.intl.formatMessage({
										id: 'auth.fields.username'
									})}
									autoCorrect="off"
									autoCapitalize="none"
									autoComplete="username"
									autocompletetype="email"
									keyboardtype="email-address"
									type="email"
									name="email"
								/>
							</FormItem>
							<FormItem
								name="password"
								rules={[
									{
										required: true,
										message: this.props.intl.formatMessage({
											id: 'auth.error.noPassword'
										})
									}
								]}
							>
								<Input
									prefix={
										<FontAwesomeIcon
											icon={faLock}
											style={S.login.fieldIcon}
										/>
									}
									type="password"
									autoComplete="current-password"
									name="password"
									placeholder={this.props.intl.formatMessage({
										id: 'auth.fields.password'
									})}
								/>
							</FormItem>
							<FormItem>
								<Link
									style={S.login.forgotPass}
									to="/forgot_password"
								>
									<FormattedMessage id="auth.login.forgotPassword" />
								</Link>
								<Button
									type="primary"
									htmlType="submit"
									style={S.login.submit}
									loading={this.state.loading}
								>
									<FormattedMessage id="auth.login.logIn" />
								</Button>
								{this.state.loginError && (
									<Alert
										message={this.props.intl.formatMessage({
											id: 'error'
										})}
										description={this.props.intl.formatMessage(
											{
												id: `server.${this.state.loginError}`
											}
										)}
										style={{ width: '100%', marginTop: 20 }}
										type="error"
										showIcon
									/>
								)}
							</FormItem>
						</Form>
					</div>
				</div>
				<div style={{ width: '100%', height: '100%' }}>
					<FontAwesomeIcon
						size="2x"
						icon={faQuestionCircle}
						id="help"
						className="absoluteHelp"
					/>
				</div>
			</div>
		)
	}
}

export default injectIntl(withRouter(Login))
