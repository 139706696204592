import React from 'react'
import { Menu, Tooltip, Avatar } from 'antd'
import { withApollo } from '@apollo/client/react/hoc'
import { Link, withRouter } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import Logo from '../SpectreLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faUsers,
	faHdd,
	faMusic,
	faQuestionCircle,
	faVial,
	faMobile
} from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faCommentAlt } from '@fortawesome/free-regular-svg-icons'
import config from '../../config'
import * as Auth from '../../auth'
import * as Utils from '../Utils'
import NewUserForm from '../../views/users/NewUser'
import NotificationSettings from '../../views/users/NotificationSettings'
import ApiKeysSettings from '../../views/users/ApiKeysSettings'

const SubMenu = Menu.SubMenu

class MenuMarkup extends React.Component {
	state = {
		editUserModal: false,
		notificationsModal: false,
		apikeysModal: false
	}

	componentDidMount() {
		if (this.props.match.url.indexOf('notifications') > -1) {
			this.setState({ notificationsModal: true })
		}
	}

	logout = async (e) => {
		if (e.key === 'logout') {
			try {
				await Auth.logout()
			} catch (e) {
				Utils.displayError(e)
			}
			this.props.client.resetStore()
			this.props.history.push('/login')
		} else if (e.key === 'userInfo') {
			this.setState({ editUserModal: true })
		} else if (e.key === 'notificationSettings') {
			this.setState({ notificationsModal: true })
		} else if (e.key === 'apikeysSettings') {
			this.setState({ apikeysModal: true })
		}
	}

	render() {
		const { mobileVersion, match, intl } = this.props
		const user = Auth.cache.user
		let name = 'Dashboard User'
		if (user && user.firstName && user.lastName) {
			name = `${user.firstName} ${user.lastName}`
		}
		const s = mobileVersion ? mobileStyles : styles
		const urlComponents = match.url.split('/')

		const userMenu = (
			<SubMenu
				className="user-submenu"
				title={<FontAwesomeIcon icon={faUserCircle} style={s.icon} />}
				onClick={this.logout}
			>
				<Menu.Item style={commonStyles.user}>
					<Avatar src={Utils.userAvatar(user)} />
					<div style={commonStyles.userInfo}>
						<div id="userName" style={commonStyles.userName}>
							{name}
						</div>
						<div id="userEmail" style={commonStyles.userEmail}>
							{user.username}
						</div>
					</div>
				</Menu.Item>
				{mobileVersion && (
					<Menu.Item key="/help">
						<a href={config.intercom.docsUrl} target="_blank">
							<FormattedMessage id="help" />
						</a>
					</Menu.Item>
				)}
				<Menu.Item key="userInfo" data-test="btn-account-settings">
					<FormattedMessage id="account.userInfo" />
				</Menu.Item>
				<Menu.Item
					key="notificationSettings"
					data-test="btn-notification-settings"
				>
					<FormattedMessage id="account.notificationSettings" />
				</Menu.Item>
				<Menu.Item key="apikeysSettings">
					<FormattedMessage id="account.apikeysSettings" />
				</Menu.Item>
				<Menu.Item key="logout">
					<FormattedMessage id="account.logout" />
				</Menu.Item>
			</SubMenu>
		)

		return (
			<div style={s.container}>
				<div style={s.logoContainer}>
					<Logo color="#FFF" />
				</div>
				<div style={s.menuSeparator}>
					<Menu
						theme={'dark'}
						mode={mobileVersion ? 'horizontal' : 'vertical'}
						selectedKeys={[`/${urlComponents[1]}`]}
						style={s.menu}
					>
						<Menu.Item key="/devices">
							<Tooltip
								title={intl.formatMessage({ id: 'devices' })}
								placement={mobileVersion ? 'bottom' : 'right'}
							>
								<Link to="/devices">
									<FontAwesomeIcon
										icon={faHdd}
										style={s.icon}
									/>
								</Link>
							</Tooltip>
						</Menu.Item>
						{user.thirdPartyDevicesEnabled && (
							<Menu.Item key="/thirdpartydevices">
								<Tooltip
									title={intl.formatMessage({
										id: 'thirdPartyDevices'
									})}
									placement={
										mobileVersion ? 'bottom' : 'right'
									}
								>
									<Link to="/thirdpartydevices">
										<FontAwesomeIcon
											icon={faMobile}
											style={s.icon}
										/>
									</Link>
								</Tooltip>
							</Menu.Item>
						)}
						<Menu.Item key="/teams">
							<Tooltip
								title={intl.formatMessage({ id: 'teams' })}
								placement={mobileVersion ? 'bottom' : 'right'}
							>
								<Link to="/teams">
									<FontAwesomeIcon
										icon={faUsers}
										style={s.icon}
									/>
								</Link>
							</Tooltip>
						</Menu.Item>
						<Menu.Item key="/programs">
							<Tooltip
								title={intl.formatMessage({ id: 'programs' })}
								placement={mobileVersion ? 'bottom' : 'right'}
							>
								<Link to="/programs">
									<FontAwesomeIcon
										icon={faMusic}
										style={s.icon}
									/>
								</Link>
							</Tooltip>
						</Menu.Item>
						{Auth.cache.user && Auth.cache.user.messagesEnabled && (
							<Menu.Item key="/messages">
								<Tooltip
									title={intl.formatMessage({
										id: 'messagePrograms'
									})}
									placement={
										mobileVersion ? 'bottom' : 'right'
									}
								>
									<Link to="/messages">
										<FontAwesomeIcon
											icon={faCommentAlt}
											style={s.icon}
										/>
									</Link>
								</Tooltip>
							</Menu.Item>
						)}
						{Auth.hasAccess('sample:browse:all') && (
							<Menu.Item key="/samples">
								<Tooltip
									title={intl.formatMessage({
										id: 'samples'
									})}
									placement={
										mobileVersion ? 'bottom' : 'right'
									}
								>
									<Link to="/samples">
										<FontAwesomeIcon
											icon={faVial}
											style={s.icon}
										/>
									</Link>
								</Tooltip>
							</Menu.Item>
						)}
						{mobileVersion && userMenu}
					</Menu>
					{user && !mobileVersion && (
						<Menu
							theme="dark"
							mode="vertical"
							selectedKeys={[`${match.url}`]}
							style={s.menu}
						>
							<Menu.Item key="/help">
								<Tooltip
									title={intl.formatMessage({ id: 'help' })}
									placement="right"
								>
									<FontAwesomeIcon
										icon={faQuestionCircle}
										style={s.icon}
										id="help"
									/>
								</Tooltip>
							</Menu.Item>
							{userMenu}
						</Menu>
					)}
				</div>
				{this.state.editUserModal && (
					<NewUserForm
						user={Auth.cache.user}
						team={Auth.cache.team}
						onClose={() => {
							this.setState({ editUserModal: false })
						}}
					/>
				)}
				{this.state.notificationsModal && (
					<NotificationSettings
						onClose={() => {
							this.setState({ notificationsModal: false })
						}}
					/>
				)}
				{this.state.apikeysModal && (
					<ApiKeysSettings
						onClose={() => {
							this.setState({ apikeysModal: false })
						}}
					/>
				)}
			</div>
		)
	}
}

const commonStyles = {
	user: {
		height: 50,
		padding: 15,
		margin: 0,
		display: 'flex'
	},
	userInfo: {
		marginLeft: 10
	},
	userName: {
		lineHeight: 1.2,
		fontWeight: 600,
		opacity: 0.6
	},
	userEmail: {
		lineHeight: 1,
		opacity: 0.4
	}
}

const styles = {
	container: {
		position: 'fixed',
		top: 0,
		left: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	menu: {
		lineHeight: '64px',
		width: 60
	},
	icon: {
		fontSize: 26,
		width: 25,
		marginTop: 5
	},
	logoContainer: {
		marginTop: 20,
		marginBottom: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	menuSeparator: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'column',
		width: 60
	}
}

const mobileStyles = {
	container: {
		position: 'relative',
		height: '100%',
		width: '100%'
	},
	logoContainer: {
		marginTop: 7,
		marginLeft: 3,
		marginRight: 6,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		float: 'left',
		width: 50
	},
	menu: {
		lineHeight: '40px',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 63,
		right: 10,
		overflowX: 'auto'
	},
	icon: {
		fontSize: 18,
		marginRight: 0,
		marginTop: 3
	},
	menuSeparator: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}

MenuMarkup.defaultProps = {
	mobileVersion: false
}

export default withRouter(withApollo(injectIntl(MenuMarkup)))
