import React, { Component } from 'react'
import { Form, DatePicker, Select, Input, Checkbox } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { RRule } from 'rrule'

import { formItemLayout } from './Styles'
import { weekDays } from './Utils'
import { isSameOrNextDay } from './utils/dateUtils'
import MultiSelect from './MultiSelect'

const FormItem = Form.Item

class RRuleGenerator extends Component {
	render() {
		const { intl, event, dtend } = this.props
		return (
			<React.Fragment>
				<FormItem
					label={intl.formatMessage({
						id:
							dtend ?
								'programs.scheduler.from'
							:	'messagePrograms.details.edit.from'
					})}
					{...formItemLayout}
				>
					<DatePicker
						showTime
						format="DD-MM-YYYY HH:mm"
						placeholder={intl.formatMessage({
							id:
								dtend ?
									'programs.scheduler.from'
								:	'messagePrograms.details.edit.from'
						})}
						value={event.dtstart ? moment(event.dtstart) : null}
						onChange={(v) => {
							if (v) {
								this.props.onChange({ dtstart: v.toDate() })
							}
						}}
					/>
				</FormItem>
				{dtend && (
					<FormItem
						label={intl.formatMessage({
							id: 'programs.scheduler.to'
						})}
						{...formItemLayout}
					>
						<DatePicker
							showTime
							format="DD-MM-YYYY HH:mm"
							placeholder={intl.formatMessage({
								id: 'programs.scheduler.to'
							})}
							value={event.dtend ? moment(event.dtend) : null}
							disabledDate={(dt) =>
								!isSameOrNextDay(dt, event.dtstart)
							}
							onChange={(v) => {
								if (v) {
									this.props.onChange({ dtend: v.toDate() })
								}
							}}
						/>
					</FormItem>
				)}
				<FormItem
					label={intl.formatMessage({
						id: 'messagePrograms.details.edit.repeatInterval'
					})}
					{...formItemLayout}
				>
					<Input
						addonAfter={
							<Select
								defaultValue={event.freq}
								style={{ width: 120 }}
								onChange={(v) =>
									this.props.onChange({ freq: v })
								}
							>
								{[
									'YEARLY',
									'MONTHLY',
									'WEEKLY',
									'DAILY',
									'HOURLY',
									'MINUTELY'
								].map((rule) => (
									<Select.Option
										key={`rule-${rule}`}
										value={RRule[rule]}
									>
										<FormattedMessage
											id={`RRule.${rule}`}
											values={{
												count: parseInt(
													event.interval,
													10
												)
											}}
										/>
									</Select.Option>
								))}
							</Select>
						}
						value={event.interval}
						onChange={(e) =>
							this.props.onChange({ interval: e.target.value })
						}
						style={{ width: 200 }}
					/>
				</FormItem>
				{event.freq === RRule['WEEKLY'] && (
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.details.edit.weekly.detail'
						})}
						{...formItemLayout}
					>
						<MultiSelect
							options={weekDays.map((d, i) => ({
								value: i,
								label: (
									<FormattedMessage id={`week.long.${d}`} />
								)
							}))}
							placeholder={intl.formatMessage({
								id: 'messagePrograms.details.edit.weekly.detail.placeholder'
							})}
							onSelect={(days) =>
								this.props.onChange({ byweekday: days })
							}
							selectedItems={event.byweekday}
						/>
					</FormItem>
				)}
				{event.freq === RRule['MONTHLY'] && (
					<FormItem
						label={intl.formatMessage({
							id: 'messagePrograms.details.edit.monthly.detail'
						})}
						{...formItemLayout}
					>
						<MultiSelect
							options={Array(31)
								.fill(0)
								.map((_, i) => ({
									value: `${i + 1}`,
									label: `${i + 1}`
								}))}
							placeholder={intl.formatMessage({
								id: 'messagePrograms.details.edit.monthly.detail.placeholder'
							})}
							onSelect={(days) =>
								this.props.onChange({ bymonthday: days })
							}
							selectedItems={event.bymonthday}
						/>
					</FormItem>
				)}

				<FormItem
					label={intl.formatMessage({
						id: 'messagePrograms.details.edit.until'
					})}
					{...formItemLayout}
				>
					<DatePicker
						showTime
						format="DD-MM-YYYY HH:mm"
						placeholder={intl.formatMessage({
							id: 'messagePrograms.details.edit.until'
						})}
						value={event.until ? moment(event.until) : null}
						onChange={(v) =>
							this.props.onChange({
								until: v ? v.toDate() : null
							})
						}
					/>
				</FormItem>

				{this.props.limitHours && (
					<React.Fragment>
						<FormItem>
							<Checkbox
								checked={event.limitHours}
								onChange={(r) =>
									this.props.onChange({
										limitHours: r.target.checked
									})
								}
							>
								<FormattedMessage id="messagePrograms.details.edit.openingHours" />
							</Checkbox>
						</FormItem>

						{event.limitHours && (
							<FormItem
								label={intl.formatMessage({
									id: 'messagePrograms.details.edit.betweenHours'
								})}
								{...formItemLayout}
							>
								<Input
									addonAfter=":00"
									value={event.byHourStart}
									onChange={(e) =>
										this.props.onChange({
											byHourStart: e.target.value
										})
									}
									style={{ width: 100 }}
								/>
								&nbsp;&nbsp;
								<FormattedMessage id="and" />
								&nbsp;&nbsp;
								<Input
									addonAfter=":00"
									value={event.byHourEnd}
									onChange={(e) =>
										this.props.onChange({
											byHourEnd: e.target.value
										})
									}
									style={{ width: 100 }}
								/>
							</FormItem>
						)}
					</React.Fragment>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(RRuleGenerator)
