import React, { Component } from 'react'
import { Button } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import FormattedDuration from 'react-intl-formatted-duration'
import uniq from 'lodash/uniq'
import ActionsMenu from '../../components/ActionsMenu'
import {
	faTrash,
	faPlay,
	faPlus,
	faCheck,
	faCalendarTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import gql from 'graphql-tag'
import { rrulestr } from 'rrule'

import config from '../../config'
import * as Auth from '../../auth'
import Table from '../../components/Table'
import { MessageProgramDetailsQuery } from './Queries'
import S from '../../components/Styles'
import BrowseMedia from './BrowseMedia'
import * as Utils from '../../components/Utils'

class MediaListDisplay extends Component {
	state = {
		addMedia: false
	}

	mediaActions = (media, scheduled) => {
		const { messageProgram } = this.props

		let actions = []
		actions.push({
			title: this.props.intl.formatMessage({ id: 'media.table.play' }),
			key: 'play',
			icon: faPlay,
			action: () => {
				try {
					if (this.audio) {
						this.audio.pause()
					}
					const url =
						config.cdn ?
							`${config.cdn.baseUrl}${config.cdn.paths.messagesOptimized}no-meta/stereo/${media._id}`
						:	`${config.aws.s3.baseUrl}${config.aws.s3.buckets.messagesOptimized}/no-meta/stereo/${media._id}`
					this.audio = new Audio(url)
					this.audio.play()
				} catch (e) {
					Utils.displayError(e)
				}
			}
		})
		if (Auth.hasAccess('messageProgram:edit', messageProgram.teamId)) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'messagePrograms.details.removeFromList'
				}),
				key: 'deleteMedia',
				icon: faTrash,
				disabled: scheduled,
				variableMethod: () => ({
					id: messageProgram._id,
					mediaIds: messageProgram.mediaIds.filter(
						(mid) => mid !== media._id
					),
					date: new Date()
				}),
				mutation: {
					mutation: gql`
						mutation deleteMessageProgramMedia(
							$id: String!
							$mediaIds: [String]
							$date: Date!
						) {
							messageProgramUpdateById(
								_id: $id
								record: { updated: $date, mediaIds: $mediaIds }
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramDetailsQuery,
							variables: { id: messageProgram._id }
						}
					]
				}
			})
		}
		if (Auth.hasAccess('messageProgram:edit', messageProgram.teamId)) {
			actions.push({
				title: this.props.intl.formatMessage({
					id: 'messagePrograms.details.unschedule'
				}),
				key: 'unschedule',
				icon: faCalendarTimes,
				disabled: !scheduled,
				variableMethod: () => {
					let schedule = JSON.parse(messageProgram.schedule)
					schedule = schedule.filter((evt) => evt.media !== media._id)
					return {
						id: messageProgram._id,
						schedule: JSON.stringify(schedule),
						date: new Date()
					}
				},
				mutation: {
					mutation: gql`
						mutation updateMessageProgramSchedule(
							$id: String!
							$schedule: String
							$date: Date!
						) {
							messageProgramUpdateById(
								_id: $id
								record: { updated: $date, schedule: $schedule }
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: MessageProgramDetailsQuery,
							variables: { id: messageProgram._id }
						}
					]
				}
			})
		}
		return actions
	}

	addMedia = async (media) => {
		const { messageProgram, client } = this.props

		try {
			const r = await client.mutate({
				variables: {
					id: messageProgram._id,
					mediaIds: uniq([...messageProgram.mediaIds, media._id]),
					date: new Date()
				},
				mutation: gql`
					mutation updateMessageProgramAddMedia(
						$id: String!
						$mediaIds: [String]
						$date: Date!
					) {
						messageProgramUpdateById(
							_id: $id
							record: { updated: $date, mediaIds: $mediaIds }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: MessageProgramDetailsQuery,
						variables: { id: messageProgram._id }
					}
				]
			})
			Utils.parseMutationResponse(r)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { messageProgram, showActions, intl } = this.props

		let scheduledMedia = []
		if (messageProgram.schedule) {
			const events = JSON.parse(messageProgram.schedule)
			for (let e of events) {
				if (scheduledMedia.indexOf(e.media) < 0 && e.rule) {
					// only show as scheduled if scheduled in the future
					const rule = rrulestr(e.rule)
					const nextEvent = rule.after(new Date())
					console.log(e, nextEvent)
					if (nextEvent) {
						scheduledMedia.push(e.media)
					}
				}
			}
		}

		let columns = [
			{
				title: intl.formatMessage({
					id: 'media.table.details.duration'
				}),
				key: 'duration',
				render: (text, record, index) => {
					return (
						<FormattedDuration
							seconds={Math.floor(record.duration / 1000)}
							textComponent="span"
							format="{hours} {minutes} {seconds}"
							unitDisplay="narrow"
						/>
					)
				},
				sorter: (a, b) => {
					let aa = a.duration || 0
					let bb = b.duration || 0
					return aa - bb
				}
			},
			{
				title: intl.formatMessage({
					id: 'messagePrograms.details.scheduled'
				}),
				align: 'center',
				center: true,
				render: (text, record, index) => {
					return scheduledMedia.indexOf(record._id) > -1 ?
							<FontAwesomeIcon icon={faCheck} />
						:	null
				},
				sorter: (a, b) => {
					let aa = scheduledMedia.indexOf(a._id)
					let bb = scheduledMedia.indexOf(b._id)
					return aa - bb
				}
			}
		]

		if (showActions) {
			columns.push({
				key: 'action',
				align: 'right',
				render: (text, record) => (
					<ActionsMenu
						size="small"
						menuItems={this.mediaActions(
							record,
							scheduledMedia.indexOf(record._id) > -1
						)}
					/>
				)
			})
		}

		return (
			<React.Fragment>
				<div
					style={{ ...S.horizontal, marginTop: 10, marginBottom: 10 }}
				>
					<Button onClick={() => this.setState({ addMedia: true })}>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp;&nbsp;
						<FormattedMessage id="messagePrograms.details.addMedia" />
					</Button>
					<span style={{ marginLeft: 10 }}>
						<FormattedMessage id="messagePrograms.details.addMedia.info" />
					</span>
				</div>
				<Table
					rowKey="_id"
					columns={columns}
					dataSource={messageProgram.medias.filter(
						(media) => media !== null
					)}
					nameProp={(record) => record.name}
					sortKey="mediasTableSort"
				/>
				{this.state.addMedia && (
					<BrowseMedia
						selectMedia={(media) => media.isAudio}
						onClose={(media) => {
							if (media) {
								this.addMedia(media)
							}
							this.setState({ addMedia: false })
						}}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default withApollo(injectIntl(MediaListDisplay))
