import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { List, Table } from 'antd'
import {
	faThumbsUp,
	faThumbsDown,
	faQuoteLeft,
	faQuoteRight
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Utils from '../../components/Utils'
import S from '../../components/Styles'

class Review extends Component {
	getTrackReview = (track) => {
		return this.props.review.tracks.filter(
			(t) => t.trackId === track.trackId
		)[0]
	}

	render() {
		const { sample } = this.props
		return (
			<Table
				showHeader={false}
				pagination={false}
				dataSource={sample.tracks.map((t, i) => ({
					...t,
					...sample.trackObjects[i]
				}))}
				rowKey="_id"
			>
				<Table.Column
					key="track"
					render={(_, t, i) => (
						<List.Item.Meta
							avatar={
								<img
									src={Utils.trackArtworkThumbUrl(t)}
									alt=""
									style={S.trackCover}
								/>
							}
							title={t.title}
							description={t.artist}
						/>
					)}
				/>
				<Table.Column
					key="rating"
					width={80}
					render={(_, t, i) => {
						const fb = this.getTrackReview(t)
						if (fb && fb.rating) {
							return (
								<FontAwesomeIcon
									style={{
										color:
											fb.rating === 2 ?
												colors.yes
											:	colors.no
									}}
									icon={
										fb.rating === 2 ?
											faThumbsUp
										:	faThumbsDown
									}
								/>
							)
						}
						return null
					}}
				/>
				<Table.Column
					key="comment"
					width="40%"
					render={(_, t, i) => {
						const fb = this.getTrackReview(t)
						if (fb && fb.comment) {
							return (
								<div
									style={{
										color: colors.subtitle,
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<FontAwesomeIcon icon={faQuoteLeft} />
									<p style={{ margin: '0 10px' }}>
										{fb.comment}
									</p>
									<FontAwesomeIcon icon={faQuoteRight} />
								</div>
							)
						}
						return ''
					}}
				/>
			</Table>
		)
	}
}

const colors = {
	yes: '#52c41a',
	no: '#f5222d'
}

export default injectIntl(Review)
