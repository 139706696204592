import React, { Component } from 'react'
import { Modal, Input, Form } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import * as Utils from '../../../components/Utils'

class SubmitModal extends Component {
	formRef = React.createRef()

	state = {
		loading: false,
		email: null,
		name: null,
		comment: null
	}

	componentDidMount() {
		this.setState({ text: this.props.initialValue })
		setTimeout(() => {
			if (this.field) {
				this.field.focus()
			}
		}, 200)
	}

	handleCancel = () => {
		this.props.onClose(false)
	}

	handleOK = async () => {
		const { mutation, client, onClose } = this.props

		let success = true
		try {
			const values = await this.formRef.current.validateFields()
			this.setState({ loading: true })
			const response = await client.mutate(mutation(values))
			Utils.parseMutationResponse(response)
			onClose(success)
		} catch (e) {
			Utils.displayError(e)
		}
		this.setState({ loading: false })
	}

	render() {
		const { intl } = this.props
		return (
			<Modal
				visible={true}
				title={intl.formatMessage({ id: 'samples.submit' })}
				cancelText={intl.formatMessage({ id: 'actions.cancel' })}
				onCancel={this.handleCancel}
				okText={intl.formatMessage({ id: 'actions.submit' })}
				onOk={this.handleOK}
				confirmLoading={this.state.loading}
			>
				<p>
					<FormattedMessage id="samples.listen.submitMessage" />
				</p>
				<Form ref={this.formRef}>
					<Form.Item
						name="email"
						label={intl.formatMessage({
							id: 'teams.details.email'
						})}
						rules={[
							{
								type: 'email',
								message: intl.formatMessage({
									id: 'teams.addUser.emailInvalid'
								})
							},
							{
								required: true,
								message: intl.formatMessage({
									id: 'teams.addUser.emailEmpty'
								})
							}
						]}
					>
						<Input
							ref={(i) => (this.field = i)}
							autoCapitalize="none"
							autoCompleteType="email"
							autoCorrect="false"
							keyboardType="email-address"
						/>
					</Form.Item>
					<Form.Item
						name="name"
						label={intl.formatMessage({ id: 'name' })}
						rules={[
							{
								required: true,
								message: intl.formatMessage({
									id: 'teams.addUser.nameEmpty'
								})
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="comment"
						label={intl.formatMessage({
							id: 'samples.listen.feedback'
						})}
					>
						<Input.TextArea rows={5} />
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(SubmitModal))
