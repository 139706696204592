import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Modal, Form, Input, Button, List, Typography, Alert } from 'antd'
import * as Utils from '../../components/Utils'
import * as Auth from '../../auth'

class ApiKeysSettings extends Component {
	state = {
		loading: false,
		newApiKeyName: '',
		recentlyAddedKeys: []
	}

	onAdd = async (e) => {
		const { onClose, client } = this.props
		this.setState({ loading: true })

		try {
			let params = {
				name: this.state.newApiKeyName
			}
			let mutation = gql`
				mutation addUserApiKey($name: String!) {
					userAddApikey(name: $name) {
						success
						message
						apikeyId
						apikey
						apikeyName
					}
				}
			`

			const result = await client.mutate({
				variables: params,
				mutation: mutation
			})
			Utils.parseMutationResponse(result)
			await Auth.refreshUser(client)
			this.setState({
				loading: false,
				recentlyAddedKeys: [
					...this.state.recentlyAddedKeys,
					result.data.userAddApikey
				]
			})
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	onDelete = async (apikeyId, apikeyName) => {
		const { client, intl } = this.props

		Modal.confirm({
			title: intl.formatMessage(
				{ id: 'account.apikeysSettings.actions.delete.confirm' },
				{ name: apikeyName }
			),
			okText: intl.formatMessage({ id: 'ok' }),
			cancelText: intl.formatMessage({ id: 'actions.cancel' }),
			onOk: async () => {
				try {
					let params = {
						apikeyId
					}
					let mutation = gql`
						mutation deleteUserApiKey($apikeyId: String!) {
							userDeleteApikey(id: $apikeyId) {
								success
								message
							}
						}
					`
					const result = await client.mutate({
						variables: params,
						mutation: mutation
					})
					Utils.parseMutationResponse(result)
					await Auth.refreshUser(client)

					this.setState({
						recentlyAddedKeys: this.state.recentlyAddedKeys.filter(
							(item) => item.apikeyId !== apikeyId
						)
					})
				} catch (e) {
					Utils.displayError(e)
				}
			}
		})
	}

	render() {
		const { intl, onClose } = this.props
		const { user } = Auth.cache
		const apikeys = user.apikeys || []

		return (
			<Modal
				visible={true}
				width="80vw"
				style={{ maxWidth: '768px' }}
				title={intl.formatMessage({
					id: 'account.apikeysSettings.title'
				})}
				onCancel={onClose}
				footer={[
					<Button type="secondary" key="close" onClick={onClose}>
						<FormattedMessage id="actions.close" />
					</Button>
				]}
			>
				<Form>
					<Form.Item
						labelCol={{
							sm: { span: 6 }
						}}
						wrapperCol={{
							sm: { span: 18 }
						}}
						label={intl.formatMessage({
							id: 'account.apikeysSettings.newApikeyName'
						})}
					>
						<Input
							style={{ width: 250, marginLeft: 16 }}
							onChange={(v) =>
								this.setState({ newApiKeyName: v.target.value })
							}
							value={this.state.newApiKeyName}
							type="string"
							name="apikey-name"
						/>
						<Button
							style={{ marginLeft: '16px' }}
							type="primary"
							htmlType="submit"
							key="submit"
							onClick={this.onAdd}
							loading={this.state.loading}
							disabled={this.state.newApiKeyName.length < 1}
						>
							<FormattedMessage id="account.apikeysSettings.generateApiKey" />
						</Button>
					</Form.Item>
					<List
						bordered
						dataSource={apikeys}
						locale={{
							emptyText: intl.formatMessage({
								id: 'account.apikeysSettings.noData'
							})
						}}
						renderItem={({ name, _id }) => {
							const recentlyAddedKey =
								this.state.recentlyAddedKeys.find(
									(item) => item.apikeyId === _id
								)
							return (
								<List.Item>
									{name}{' '}
									<Button
										style={{ float: 'right' }}
										size="small"
										shape="round"
										type="danger"
										onClick={() => this.onDelete(_id, name)}
									>
										{intl.formatMessage({
											id: 'actions.delete'
										})}
									</Button>
								</List.Item>
							)
						}}
					/>
					{this.state.recentlyAddedKeys.length > 0 && (
						<>
							<Alert
								style={{ marginTop: 16 }}
								message={intl.formatMessage({
									id: 'account.apikeysSettings.confirmationMessage'
								})}
								type="success"
								showIcon
							/>
							<Alert
								style={{ marginTop: 16 }}
								message={intl.formatMessage({
									id: 'account.apikeysSettings.reminderCopyKey'
								})}
								description={`API-key ${
									this.state.recentlyAddedKeys[
										this.state.recentlyAddedKeys.length - 1
									].apikey
								}`}
								type="warning"
								showIcon
							/>
						</>
					)}
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(ApiKeysSettings))
