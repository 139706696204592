import React, { Component } from 'react'
import { Form, Button, Tag } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faMusic } from '@fortawesome/free-solid-svg-icons'

import AppLayout from '../../components/PageLayout'
import { SelectTeamV2 } from '../teams/SelectTeam'
import { SelectProgram } from '../tracks/SelectProgram'
import { DevicesTableQuery } from './Queries'
import { validateTeam } from './PairDevice'
import * as Utils from '../../components/Utils'

class MartiniPairDevice extends Component {
	state = {
		error: null,
		pin: this.props.match.params.pin,
		team: this.props.team,
		program: null,
		accordionActive: null
	}

	sendCode = async () => {
		const { client, history } = this.props

		try {
			this.setState({ loading: true })
			const r = await client.mutate({
				variables: {
					pin: this.state.pin,
					teamId: this.state.team && this.state.team._id,
					programId: this.state.program
				},
				mutation: gql`
					mutation setupMartiniDevice(
						$pin: String!
						$teamId: String
						$programId: String
					) {
						setupDevice(
							pin: $pin
							teamId: $teamId
							programId: $programId
						) {
							success
							deviceId
							message
						}
					}
				`,
				refetchQueries: [
					{
						query: DevicesTableQuery
					}
				]
			})

			this.setState({ loading: false })
			Utils.parseMutationResponse(r)
			const res = r.data.setupDevice
			if (res && res.success) {
				// close modal
				history.push(`/success`)
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		return (
			<AppLayout hideNav>
				<div style={styles.body}>
					<h3>
						<FormattedMessage id="devices.pair.martiniTitle" />
					</h3>

					<Form style={{ width: '100%', marginTop: 40 }}>
						<Form.Item
							label={
								<React.Fragment>
									<FontAwesomeIcon
										icon={faUsers}
										fixedWidth
									/>
									&nbsp;
									<FormattedMessage id="team" />
								</React.Fragment>
							}
						>
							<SelectTeamV2
								onChange={(team) => this.setState({ team })}
								selected={
									this.state.team && this.state.team._id
								}
								keyprops="_id"
								withDevices
								hideNoParents
							/>
							{this.state.team &&
								!validateTeam(this.state.team) && (
									<Tag color="red">
										<FormattedMessage id="devices.pair.tooManyDevices" />
									</Tag>
								)}
						</Form.Item>
						<Form.Item
							label={
								<React.Fragment>
									<FontAwesomeIcon
										icon={faMusic}
										fixedWidth
									/>
									&nbsp;
									<FormattedMessage id="program" />
								</React.Fragment>
							}
						>
							<SelectProgram
								onSelect={(p) => this.setState({ program: p })}
								selectedId={this.state.program}
								showPlaylists
							/>
						</Form.Item>

						<Form.Item style={{ marginTop: 30 }}>
							<Button
								key="submit"
								ref={(b) => (this.submit = b)}
								type="primary"
								onClick={this.sendCode}
								loading={this.state.loading}
								disabled={
									!this.state.pin ||
									!this.state.program ||
									!validateTeam(this.state.team)
								}
							>
								<FormattedMessage id="devices.pair.pairAction" />
							</Button>
						</Form.Item>
					</Form>
				</div>
			</AppLayout>
		)
	}
}

const styles = {
	body: {
		padding: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
}

export default withApollo(injectIntl(MartiniPairDevice))
