import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import {
	Divider,
	Row,
	Col,
	Card,
	Modal,
	Form,
	Input,
	Switch,
	Select,
	Tabs,
	Button,
	Alert
} from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faNetworkWired,
	faWifi,
	faEthernet
} from '@fortawesome/free-solid-svg-icons'

import S, { Colors, formItemLayout } from '../../components/Styles'
import DetailsProperty from '../../components/DetailsProperty'
import * as Auth from '../../auth'
import ActionsMenu from '../../components/ActionsMenu'
import * as Utils from '../../components/Utils'

const IP4_REGEXP =
	/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const IP6_REGEXP =
	/^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/

class IEditNetworkInfo extends Component {
	formRef = React.createRef()

	state = {
		applying: false,
		revertIfOffline: true
	}

	componentDidMount() {
		const { iface } = this.props
		let s = {}
		if (iface.ip4) {
			s.ip4manual = iface.ip4.method === 'manual'
		}
		if (iface.ip6) {
			s.ip6manual = iface.ip6.method === 'manual'
		}
		this.setState(s)
	}

	createConfig = (values) => {
		let params = []
		if (this.state.ip4manual) {
			if (values.ip4ip && values.ip4mask) {
				const cidr = Utils.netmaskToCidr(values.ip4mask)
				params.push(['ipv4.addresses', `${values.ip4ip}/${cidr}`])
			} else {
				throw new Error('Invalid settings')
			}

			if (values.ip4gateway) {
				params.push(['ipv4.gateway', values.ip4gateway])
			}

			params.push(['ipv4.method', 'manual'])
		} else {
			params.push(['ipv4.method', 'auto'])
			params.push(['ipv4.addresses', ''])
			params.push(['ipv4.gateway', ''])
		}

		if (values.ip4dns && values.ip4dns.length > 0) {
			params.push(['ipv4.dns', values.ip4dns.join(' ')])
			params.push(['ipv4.ignore-auto-dns', 'true'])
		} else {
			params.push(['ipv4.dns', ''])
			params.push(['ipv4.ignore-auto-dns', 'false'])
		}

		if (this.state.ip6manual) {
			if (values.ip6ip && values.ip6prefix) {
				params.push([
					'ipv6.addresses',
					`${values.ip6ip}/${values.ip6prefix}`
				])
			} else {
				throw new Error('Invalid settings')
			}

			if (values.ip6gateway) {
				params.push(['ipv6.gateway', values.ip6gateway])
			}

			params.push(['ipv6.method', 'manual'])
		} else {
			params.push(['ipv6.method', 'auto'])
			params.push(['ipv6.addresses', ''])
			params.push(['ipv6.gateway', ''])
		}

		if (values.ip6dns && values.ip6dns.length > 0) {
			params.push(['ipv6.dns', values.ip6dns.join(' ')])
			params.push(['ipv6.ignore-auto-dns', 'true'])
		} else {
			params.push(['ipv6.dns', ''])
			params.push(['ipv6.ignore-auto-dns', 'false'])
		}

		params.push(['connection.autoconnect', 'yes'])
		return { revertIfOffline: this.state.revertIfOffline, nmcli: params }
	}

	apply = async () => {
		const { client, device } = this.props

		try {
			const values = await this.formRef.current.validateFields()
			this.setState({ applying: true })
			const r = await client.mutate({
				variables: {
					id: device._id,
					param: JSON.stringify(this.createConfig(values))
				},
				mutation: gql`
					mutation sendDeviceCommandNetworkConfig(
						$id: String!
						$param: String!
					) {
						sendDeviceCommand(
							_id: $id
							command: "networkConfig"
							param: $param
						) {
							success
							message
						}
					}
				`
			})
			Utils.parseMutationResponse(r)
			this.setState({ applying: false })
			this.props.onClose()
		} catch (e) {
			Utils.displayError(e)
			this.setState({ applying: false })
		}
	}

	downloadFile = async () => {
		try {
			const values = await this.formRef.current.validateFields()
			const element = document.createElement('a')
			const file = new Blob(
				[btoa(JSON.stringify(this.createConfig(values)))],
				{ type: 'text/plain' }
			)
			element.href = URL.createObjectURL(file)
			element.download = 'spectre.conf'
			document.body.appendChild(element)
			element.click()
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, device, iface } = this.props
		const isOnline = Utils.isDeviceOnline(device)

		const validIpRule = [
			{
				validator: (rule, value, callback) => {
					if (!value || IP4_REGEXP.test(value)) {
						callback()
					} else {
						callback(
							intl.formatMessage({ id: 'devices.info.ipInvalid' })
						)
					}
				}
			}
		]
		const validIpsRule = [
			{
				validator: (rule, value, callback) => {
					if (value && value.length > 0) {
						for (let v of value) {
							if (!IP4_REGEXP.test(v)) {
								callback(
									intl.formatMessage({
										id: 'devices.info.ipInvalid'
									})
								)
								return
							}
						}
					}
					callback()
				}
			}
		]
		const validIp6Rule = [
			{
				validator: (rule, value, callback) => {
					if (!value || IP6_REGEXP.test(value)) {
						callback()
					} else {
						callback(
							intl.formatMessage({ id: 'devices.info.ipInvalid' })
						)
					}
				}
			}
		]
		const validIp6sRule = [
			{
				validator: (rule, value, callback) => {
					if (value && value.length > 0) {
						for (let v of value) {
							if (!IP6_REGEXP.test(v)) {
								callback(
									intl.formatMessage({
										id: 'devices.info.ipInvalid'
									})
								)
								return
							}
						}
					}
					callback()
				}
			}
		]

		return (
			<Modal
				visible={true}
				title={this.props.intl.formatMessage({
					id: 'devices.info.setupNetwork'
				})}
				onCancel={this.props.onClose}
				footer={[
					<Button
						key="dl"
						type="primary"
						onClick={this.downloadFile}
						htmlType="submit"
					>
						<FormattedMessage id="devices.info.network.saveToFile" />
					</Button>,

					<Button
						key="send"
						type="primary"
						disabled={!isOnline}
						loading={this.state.applying}
						onClick={this.apply}
						htmlType="submit"
					>
						<FormattedMessage id="devices.info.network.sendToDevice" />
					</Button>
				]}
				width="60vw"
			>
				<Form ref={this.formRef}>
					<Tabs>
						<Tabs.TabPane
							tab={<FormattedMessage id="devices.info.ipv4" />}
							key="ip4"
						>
							<Form.Item>
								<Row>
									<Col
										xs={9}
										md={11}
										style={{ textAlign: 'right' }}
									>
										<FormattedMessage id="devices.info.ip.dhcp" />
									</Col>
									<Col
										xs={6}
										md={2}
										style={{ textAlign: 'center' }}
									>
										<Switch
											checked={this.state.ip4manual}
											onChange={(v) =>
												this.setState({ ip4manual: v })
											}
										/>
									</Col>
									<Col
										xs={9}
										md={11}
										style={{ textAlign: 'left' }}
									>
										<FormattedMessage id="devices.info.ip.static" />
									</Col>
								</Row>
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip4ip"
								initialValue={iface.ip4 ? iface.ip4.ip : null}
								label={intl.formatMessage({
									id: 'devices.info.networkIp'
								})}
								rules={validIpRule}
							>
								<Input disabled={!this.state.ip4manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip4mask"
								initialValue={iface.ip4 ? iface.ip4.mask : null}
								label={intl.formatMessage({
									id: 'devices.info.subnetMask'
								})}
								rules={validIpRule}
							>
								<Input disabled={!this.state.ip4manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip4gateway"
								initialValue={
									iface.ip4 ? iface.ip4.gateway : null
								}
								label={intl.formatMessage({
									id: 'devices.info.gateway'
								})}
								rules={validIpRule}
							>
								<Input disabled={!this.state.ip4manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip4dns"
								label={intl.formatMessage({
									id: 'devices.info.DNS'
								})}
								rules={validIpsRule}
							>
								<Select
									tokenSeparators={[' ', ',']}
									mode="tags"
									style={{ width: '100%' }}
									placeholder="Automatic"
								>
									<Select.Option value="1.1.1.1">
										1.1.1.1
									</Select.Option>
									<Select.Option value="8.8.8.8">
										8.8.8.8
									</Select.Option>
								</Select>
							</Form.Item>
						</Tabs.TabPane>
						<Tabs.TabPane
							tab={<FormattedMessage id="devices.info.ipv6" />}
							key="ip6"
						>
							<Form.Item>
								<Row>
									<Col
										xs={9}
										md={11}
										style={{ textAlign: 'right' }}
									>
										<FormattedMessage id="devices.info.ip.dhcp" />
									</Col>
									<Col
										xs={6}
										md={2}
										style={{ textAlign: 'center' }}
									>
										<Switch
											checked={this.state.ip6manual}
											onChange={(v) =>
												this.setState({ ip6manual: v })
											}
										/>
									</Col>
									<Col
										xs={9}
										md={11}
										style={{ textAlign: 'left' }}
									>
										<FormattedMessage id="devices.info.ip.static" />
									</Col>
								</Row>
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip6ip"
								label={intl.formatMessage({
									id: 'devices.info.networkIp'
								})}
								rules={validIp6Rule}
							>
								<Input disabled={!this.state.ip6manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip6prefix"
								initialValue={
									iface.ip6 ? iface.ip6.prefix : null
								}
								label={intl.formatMessage({
									id: 'devices.info.prefix'
								})}
								rules={[]}
							>
								<Input disabled={!this.state.ip6manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip6gateway"
								initialValue={
									iface.ip6 ? iface.ip6.gateway : null
								}
								label={intl.formatMessage({
									id: 'devices.info.gateway'
								})}
								rules={validIp6Rule}
							>
								<Input disabled={!this.state.ip6manual} />
							</Form.Item>
							<Form.Item
								{...formItemLayout}
								name="ip6gateway"
								initialValue={iface.ip6 ? iface.ip6.dns : null}
								label={intl.formatMessage({
									id: 'devices.info.DNS'
								})}
								rules={validIp6sRule}
							>
								<Select
									disabled={!this.state.ip6manual}
									tokenSeparators={[' ', ',']}
									mode="tags"
									style={{ width: '100%' }}
								></Select>
							</Form.Item>
						</Tabs.TabPane>
					</Tabs>
					<Divider />
					<Form.Item style={{ textAlign: 'center' }}>
						<Switch
							checked={this.state.revertIfOffline}
							onChange={(v) =>
								this.setState({ revertIfOffline: v })
							}
						/>
						&nbsp;&nbsp;
						<FormattedMessage id="devices.info.network.revertIfOffline" />
					</Form.Item>
					<Alert
						message={intl.formatMessage({
							id: 'devices.info.network.explanation'
						})}
						type="info"
						showIcon
					/>
				</Form>
			</Modal>
		)
	}
}

const EditNetworkInfo = withApollo(injectIntl(IEditNetworkInfo))

class NetworkInfo extends Component {
	state = {
		editModal: null
	}

	getInterfaces = (network) => {
		let interfaces = []
		let wifis = 1,
			ethernets = 1
		for (let name in network) {
			if (name !== 'online' && name !== 'lo' && name !== 'externalIp') {
				const isWifi = name.indexOf('w') > -1
				let ifaceTitle = `${this.props.intl.formatMessage({ id: isWifi ? 'devices.info.wifi' : 'devices.info.ethernet' })} #${isWifi ? wifis++ : ethernets++}`
				if (name !== 'wifi') {
					ifaceTitle += ` (${name})`
				}

				interfaces.push({
					...network[name],
					interface: ifaceTitle,
					icon: isWifi ? faWifi : faEthernet
				})
			}
		}
		return interfaces
	}

	getModeLabel = (mode) => {
		if (mode === 'manual') {
			return 'devices.info.ip.static'
		}
		return 'devices.info.ip.dhcp'
	}

	render() {
		const { device, intl } = this.props
		const interfaces = this.getInterfaces(device.network)

		return (
			<React.Fragment>
				<Divider orientation="left">
					<FontAwesomeIcon icon={faNetworkWired} />{' '}
					<FormattedMessage
						id="devices.info.interfaces"
						values={{ count: interfaces.length }}
					/>
				</Divider>
				{interfaces.length > 0 ?
					interfaces.map((iface) => {
						let networkActions = []
						if (
							Auth.hasPermission('device:edit:setNetwork') &&
							Utils.isDeviceDrax(device) &&
							Utils.isDeviceVersionAbove(device, '3.0.0')
						) {
							networkActions.push({
								title: intl.formatMessage({
									id: 'devices.info.setupNetwork'
								}),
								key: 'network',
								icon: faNetworkWired,
								id: 'btn-setupNetwork',
								action: () =>
									this.setState({ editModal: iface })
							})
						}
						return (
							<Row
								className="network-device"
								style={{ marginBottom: 20 }}
								key={iface.interface}
							>
								<Card
									title={
										<React.Fragment>
											<FontAwesomeIcon
												icon={iface.icon}
											/>
											&nbsp;&nbsp;{iface.interface}
										</React.Fragment>
									}
									style={S.card.fullWidthContainer}
									bodyStyle={S.card.body}
									extra={
										<ActionsMenu
											menuItems={networkActions}
										/>
									}
								>
									<Row style={S.infoCard} gutter={10}>
										<Col xs={24} md={8}>
											{device.network.externalIp && (
												<DetailsProperty title="devices.info.ip">
													{device.network.externalIp}
												</DetailsProperty>
											)}
											{iface.mac &&
												iface.mac !==
													'02:00:00:00:00:00' && (
													<DetailsProperty title="devices.info.mac">
														{iface.mac.toLowerCase()}
													</DetailsProperty>
												)}
										</Col>
										<Col xs={24} md={8}>
											{iface.ip4 ?
												<React.Fragment>
													<h4>
														<FormattedMessage id="devices.info.ipv4" />{' '}
														(
														<FormattedMessage
															id={this.getModeLabel(
																iface.ip4.method
															)}
														/>
														)
													</h4>
													<DetailsProperty title="devices.info.networkIp">
														{iface.ip4.ip || '-'}
													</DetailsProperty>
													<DetailsProperty title="devices.info.subnetMask">
														{iface.ip4.mask || '-'}
													</DetailsProperty>
													<DetailsProperty title="devices.info.gateway">
														{iface.ip4.gateway ||
															'-'}
													</DetailsProperty>
													{iface.ip4.dns && (
														<DetailsProperty title="devices.info.DNS">
															{iface.ip4.dns.join(
																', '
															)}
														</DetailsProperty>
													)}
												</React.Fragment>
											:	<DetailsProperty title="devices.info.networkIp">
													{iface.ip}
												</DetailsProperty>
											}
										</Col>
										{iface.ip6 && (
											<Col xs={24} md={8}>
												<h4>
													<FormattedMessage id="devices.info.ipv6" />{' '}
													(
													<FormattedMessage
														id={this.getModeLabel(
															iface.ip6.method
														)}
													/>
													)
												</h4>
												<DetailsProperty title="devices.info.networkIp">
													{iface.ip6.ip || '-'}
												</DetailsProperty>
												<DetailsProperty title="devices.info.prefix">
													{iface.ip6.prefix || '-'}
												</DetailsProperty>
												<DetailsProperty title="devices.info.gateway">
													{iface.ip6.gateway || '-'}
												</DetailsProperty>
											</Col>
										)}
									</Row>
								</Card>
							</Row>
						)
					})
				:	(!device.network ||
						Object.keys(device.network).length === 0) && (
						<div style={S.infoCard}>
							<span style={{ color: Colors.disabled.text }}>
								<FormattedMessage id="devices.info.noNetworkInformation" />
							</span>
						</div>
					)
				}
				{this.state.editModal && (
					<EditNetworkInfo
						device={device}
						iface={this.state.editModal}
						onClose={() => this.setState({ editModal: null })}
					/>
				)}
			</React.Fragment>
		)
	}
}

export default injectIntl(NetworkInfo)
