import React, { Component } from 'react'
import gql from 'graphql-tag'
import { withApollo } from '@apollo/client/react/hoc'
import { injectIntl, FormattedMessage } from 'react-intl'
import {
	Modal,
	Form,
	Input,
	Button,
	Select,
	Card,
	Row,
	Col,
	DatePicker,
	Radio
} from 'antd'
import moment from 'moment'
import keys from 'lodash/keys'

import { formItemLayout } from '../../../components/Styles'
import * as Utils from '../../../components/Utils'
import { TeamBillingQuery } from '../Queries'
import TaxRateSelect from './TaxRateSelect'
import PlanItems from './PlanItems'

const FormItem = Form.Item

export const VAT_WAIVER_NOTICE =
	'Exonération de TVA selon article n°259 B du Code Général de Impôts'

const taxCountries = {
	'United Arab Emirates': 'ae_trn',
	Australia: 'au_abn',
	'Brazil - CNPJ': 'br_cnpj',
	'Brazil - CPF': 'br_cpf',
	'Canada - BN': 'ca_bn',
	'Canada - QST': 'ca_qst',
	Switzerland: 'ch_vat',
	Chile: 'cl_tin',
	'Spain - CIF': 'es_cif',
	Austria: 'eu_vat',
	Belgium: 'eu_vat',
	Bulgaria: 'eu_vat',
	Cyprus: 'eu_vat',
	'Czech Republic': 'eu_vat',
	Germany: 'eu_vat',
	Denmark: 'eu_vat',
	Estonia: 'eu_vat',
	'Spain - EU VAT': 'eu_vat',
	Finland: 'eu_vat',
	France: 'eu_vat',
	'United Kingdom': 'eu_vat',
	Greece: 'eu_vat',
	Croatia: 'eu_vat',
	Hungary: 'eu_vat',
	Ireland: 'eu_vat',
	Italy: 'eu_vat',
	Lithuania: 'eu_vat',
	Luxembourg: 'eu_vat',
	Latvia: 'eu_vat',
	Malta: 'eu_vat',
	Netherlands: 'eu_vat',
	Poland: 'eu_vat',
	Portugal: 'eu_vat',
	Romania: 'eu_vat',
	Sweden: 'eu_vat',
	Slovenia: 'eu_vat',
	Slovakia: 'eu_vat',
	'Hong Kong': 'hk_br',
	Indonesia: 'id_npwp',
	India: 'in_gst',
	'Japan - Corporate Number': 'jp_cn',
	'Japan - Registered Foreign Businesses’ Registration Number': 'jp_rn',
	'Korea, Republic of': 'kr_brn',
	Liechtenstein: 'li_uid',
	Mexico: 'mx_rfc',
	'Malaysia - FRP': 'my_frp',
	'Malaysia - ITN': 'my_itn',
	'Malaysia - SST': 'my_sst',
	Norway: 'no_vat',
	'New Zealand': 'nz_gst',
	'Russian Federation - INN': 'ru_inn',
	'Russian Federation - KPP': 'ru_kpp',
	'Saudi Arabia': 'sa_vat',
	'Singapore - GST': 'sg_gst',
	'Singapore - UEN': 'sg_uen',
	Thailand: 'th_vat',
	Taiwan: 'tw_vat',
	'United States': 'us_ein',
	'South Africa': 'za_vat'
}

class NewPlan extends Component {
	formRef = React.createRef()

	state = {
		loading: false,
		currency: 'eur',
		interval: 'month',
		intervalCount: 1,
		daysUntilDue: 30,
		applicableTax: null,
		invoiceFooter: null,
		items: [
			{
				description: '',
				quantity: 1
			}
		],
		language: 'fr',
		subsidiary: 'global',
		taxIdCountry: 'France'
	}

	handleCancel = () => {
		this.props.onClose()
	}

	onClickSubmit = async (e) => {
		e.preventDefault()
		this.setState({ loading: true })

		try {
			const values = await this.formRef.current.validateFields()
			const customerCreate = await this.props.client.mutate({
				variables: {
					address: JSON.stringify({
						...values,
						taxIdType:
							this.state.taxIdCountry ?
								taxCountries[this.state.taxIdCountry]
							:	null,
						language: this.state.language
					}),
					teamId: this.props.team._id,
					subsidiary: this.state.subsidiary
				},
				mutation: gql`
					mutation createCustomerSubscription(
						$teamId: String!
						$address: String!
						$subsidiary: String
					) {
						subscriptionCustomerCreate(
							teamId: $teamId
							address: $address
							subsidiary: $subsidiary
						) {
							customerId
						}
					}
				`
			})

			if (customerCreate.data.subscriptionCustomerCreate.customerId) {
				const r = await this.props.client.mutate({
					variables: {
						teamId: this.props.team._id,
						customerId:
							customerCreate.data.subscriptionCustomerCreate
								.customerId,
						items: this.state.items,
						intervalUnit: this.state.interval,
						intervalCount: parseInt(values.intervalCount, 10),
						currency: this.state.currency,
						daysUntilDue: parseInt(values.daysUntilDue, 10),
						applicableVat: this.state.applicableTax,
						invoiceFooter: this.state.invoiceFooter,
						startDate:
							this.state.startDate ?
								this.state.startDate.toDate()
							:	null,
						endDate:
							this.state.endDate ?
								this.state.endDate.toDate()
							:	null,
						collectionMethod: 'send_invoice',
						subsidiary: this.state.subsidiary
					},
					mutation: gql`
						mutation createSubscription(
							$teamId: String!
							$customerId: String!
							$items: [SubscriptionItemsInput]
							$intervalUnit: String!
							$intervalCount: Float!
							$currency: String!
							$daysUntilDue: Float!
							$applicableVat: String
							$invoiceFooter: String
							$startDate: Date
							$endDate: Date
							$collectionMethod: String
							$subsidiary: String
						) {
							subscriptionCreate(
								record: {
									teamId: $teamId
									stripeCustomer: $customerId
									items: $items
									interval: $intervalUnit
									intervalCount: $intervalCount
									currency: $currency
									daysUntilDue: $daysUntilDue
									taxRateId: $applicableVat
									invoiceFooter: $invoiceFooter
									startDate: $startDate
									endDate: $endDate
									collectionMethod: $collectionMethod
									subsidiary: $subsidiary
								}
							) {
								recordId
							}
						}
					`,
					refetchQueries: [
						{
							query: TeamBillingQuery,
							variables: { id: this.props.team._id }
						}
					],
					awaitRefetchQueries: true
				})
				Utils.parseMutationResponse(r)
				this.setState({ loading: false })
				this.props.onClose()
			} else {
				throw new Error('No customer id')
			}
		} catch (e) {
			this.setState({ loading: false })
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, team } = this.props

		const emptyRule = [
			{
				required: true,
				message: intl.formatMessage({ id: 'errors.empty' })
			}
		]

		return (
			<Modal
				visible={true}
				title={intl.formatMessage(
					{ id: 'billing.plan.title' },
					{ team: team.n }
				)}
				onCancel={this.handleCancel}
				width="60vw"
				maskClosable={false}
				bodyStyle={{
					overflow: 'hidden',
					backgroundColor: '#f0f2f5',
					padding: 20
				}}
				footer={[
					<Button
						type="primary"
						htmlType="submit"
						key="submit"
						onClick={this.onClickSubmit}
						loading={this.state.loading}
					>
						<FormattedMessage id="actions.save" />
					</Button>
				]}
			>
				<Form ref={this.formRef}>
					<Card
						title={intl.formatMessage({ id: 'billing.plan.items' })}
						style={{ marginBottom: 20 }}
					>
						<PlanItems
							items={this.state.items}
							currency={this.state.currency}
							valueChanged={(v) => this.setState(v)}
						/>
					</Card>
					<Row gutter={16}>
						<Col xs={24} md={12}>
							<Card
								title={intl.formatMessage({
									id: 'billing.address'
								})}
								style={{ marginBottom: 20 }}
							>
								{addressFields.map((l) => (
									<Form.Item
										name={l}
										style={{ marginBottom: 10 }}
										label={intl.formatMessage({
											id: `address.${l}`
										})}
										{...formItemLayout}
										key={l}
										rules={
											(
												mandatoryAddressFields.indexOf(
													l
												) > -1
											) ?
												emptyRule
											:	[]
										}
									>
										<Input />
									</Form.Item>
								))}
							</Card>
							<Card
								title={intl.formatMessage({
									id: 'billing.subsidiary'
								})}
								bodyStyle={{ display: 'flex' }}
							>
								<Radio.Group
									value={this.state.subsidiary}
									onChange={(v) =>
										this.setState({
											subsidiary: v.target.value,
											currency:
												v.target.value === 'apac' ?
													'hkd'
												:	'eur'
										})
									}
								>
									<div style={{ flex: 1, marginBottom: 10 }}>
										<Radio value="global">
											<b>
												<FormattedMessage id="billing.subsidiary.global" />
											</b>
											<br />
											39 rue de Rome
											<br />
											75008 Paris
											<br />
											France
										</Radio>
									</div>
									<div style={{ flex: 1 }}>
										<Radio value="apac">
											<b>
												<FormattedMessage id="billing.subsidiary.apac" />
											</b>
											<br />
											Room 1501, 15/F, Prosperity Tower,
											<br />
											39 Queen's Road Central,
											<br />
											Central,
											<br />
											Hong Kong
										</Radio>
									</div>
								</Radio.Group>
							</Card>
						</Col>
						<Col xs={24} md={12}>
							<Card
								title={intl.formatMessage({
									id: 'billing.plan.invoicing'
								})}
								style={{ marginBottom: 20 }}
							>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.startDate'
									})}
								>
									<DatePicker
										onChange={(d) =>
											this.setState({ startDate: d })
										}
										value={
											this.state.startDate ?
												moment(this.state.startDate)
											:	null
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									label={intl.formatMessage({
										id: 'billing.plan.endDate'
									})}
								>
									<DatePicker
										onChange={(d) =>
											this.setState({ endDate: d })
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									name="intervalCount"
									initialValue={1}
									rules={[]}
									label={intl.formatMessage({
										id: 'billing.plan.interval'
									})}
								>
									<Input
										placeholder="1"
										type="number"
										addonAfter={
											<Select
												defaultValue={
													this.state.interval
												}
												style={{ width: 120 }}
												onChange={(v) =>
													this.setState({
														interval: v
													})
												}
											>
												{['YEARLY', 'MONTHLY'].map(
													(rule) => (
														<Select.Option
															key={`rule-${rule}`}
															value={
																Utils
																	.stripeIntervals[
																	rule
																]
															}
														>
															<FormattedMessage
																id={`RRule.${rule}`}
																values={{
																	count: 2
																}}
															/>
														</Select.Option>
													)
												)}
											</Select>
										}
									/>
								</FormItem>
								<FormItem
									{...formItemLayout}
									name="daysUntilDue"
									initialValue={30}
									rules={[]}
									label={intl.formatMessage({
										id: 'billing.plan.daysUntilDue'
									})}
								>
									<Input
										placeholder="30"
										addonAfter={
											<FormattedMessage
												id="RRule.DAILY"
												values={{ count: 2 }}
											/>
										}
									/>
								</FormItem>
							</Card>
							<Card
								title={intl.formatMessage({
									id: 'billing.vat'
								})}
							>
								<Form.Item
									name="taxId"
									label={intl.formatMessage({
										id: 'billing.vat.taxId'
									})}
									{...formItemLayout}
								>
									<Input
										addonBefore={
											<Select
												defaultValue={
													this.state.taxIdCountry
												}
												style={{ width: 120 }}
												dropdownMatchSelectWidth={false}
												onChange={(v) =>
													this.setState({
														taxIdCountry: v
													})
												}
											>
												{_.keys(taxCountries).map(
													(c) => (
														<Select.Option
															key={`tax-${c}`}
															value={c}
														>
															{c}
														</Select.Option>
													)
												)}
											</Select>
										}
									/>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'billing.vat.applicableVat'
									})}
									{...formItemLayout}
								>
									<TaxRateSelect
										subsidiary={this.state.subsidiary}
										value={this.state.applicableTax}
										onChange={(v) => {
											let footer =
												this.state.invoiceFooter
											if (v) {
												if (
													footer === VAT_WAIVER_NOTICE
												) {
													footer = null
												}
											} else {
												if (!footer) {
													footer = VAT_WAIVER_NOTICE
												}
											}
											this.setState({
												applicableTax: v,
												invoiceFooter: footer
											})
										}}
										style={{ width: '100%' }}
									/>
								</Form.Item>
								<Form.Item
									label={intl.formatMessage({
										id: 'billing.vat.invoiceFooter'
									})}
								>
									<Input
										value={this.state.invoiceFooter}
										onChange={(v) =>
											this.setState({
												invoiceFooter: v.target.value
											})
										}
									/>
								</Form.Item>
								<FormItem
									label={intl.formatMessage({
										id: 'teams.details.language'
									})}
								>
									<Select
										value={this.state.language}
										onChange={(v) =>
											this.setState({ language: v })
										}
									>
										{Utils.languages.map((l) => (
											<Select.Option key={l} value={l}>
												<FormattedMessage
													id={`lang.${l}`}
												/>
											</Select.Option>
										))}
									</Select>
								</FormItem>
							</Card>
						</Col>
					</Row>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(NewPlan))

const addressFields = [
	'name',
	'line1',
	'line2',
	'postal_code',
	'city',
	'state',
	'country',
	'email'
]
const mandatoryAddressFields = [
	'name',
	'line1',
	'postal_code',
	'city',
	'country',
	'email'
]
