import gql from 'graphql-tag'

export const ProgramsTableQuery = gql`
	query programsTableQuery {
		programs {
			_id
			name
			team {
				_id
				path
				n
			}
			playlists {
				_id
			}
		}
	}
`

export const ProgramDetailsQuery = gql`
	query program($id: String!) {
		programById(_id: $id) {
			_id
			name
			programPlaylistIds
			playlists {
				_id
				name
				scheduleV3 {
					rule
					duration
					weight
				}
				artwork
				crossfade
				canForceTrack
				isCurrent
				playlist {
					_id
					n
					a
					created
					tracks {
						size
						genre
						duration
					}
				}
			}
			teamId
			team {
				_id
				path
				n
			}
			updatedAt
			manifestVersion
		}
	}
`

export const ProgramDetailsDevicesQuery = gql`
	query fetchProgramDetails($id: String!) {
		programById(_id: $id) {
			_id
			zones {
				_id
				name
				device {
					_id
					name
					nickname
					OS
					onlineDate
					onlineState
					lastConnection
					dlState
					contentUpdated
					zones {
						_id
						name
						playState
						program {
							_id
							updatedAt
						}
						messagePrograms {
							_id
							updated
						}
					}
				}
			}
		}
	}
`
