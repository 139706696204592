import React, { Component } from 'react'
import { Cascader } from 'antd'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'

import TZ from '../../resources/timezones'

class ISelectTimezone extends Component {
	getTimezones = () => {
		const timezones = TZ.map((region) => ({
			value: region.group,
			label: region.group,
			children: region.zones.map((zone) => ({
				value: zone.value,
				label: zone.name
			}))
		}))

		return timezones
	}

	render() {
		return (
			<Cascader
				style={{ width: '100%' }}
				placeholder={this.props.intl.formatMessage({
					id: 'deviceTimezone'
				})}
				options={this.getTimezones()}
				value={this.props.selectedValue}
				onChange={this.props.onSelect}
			/>
		)
	}
}

export const SelectTimezone = withApollo(injectIntl(ISelectTimezone))
