import React, { Component } from 'react'
import { Modal, Form, TimePicker, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import { withApollo } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import moment from 'moment'

import { formItemLayoutEqual } from '../../components/Styles'
import { DeviceDetailsQuery, DeviceActionMutation } from './Queries'
import * as Auth from '../../auth'
import * as Utils from '../../components/Utils'

const FormItem = Form.Item

class Reboot extends Component {
	state = {
		error: null
	}

	handleCancel = () => {
		this.props.onClose()
	}

	handleEnabledChange = async (value) => {
		const { device, client } = this.props
		console.log(value)

		try {
			const r = await client.mutate({
				variables: {
					device: device._id,
					autoReboot: value,
					autoRebootTime: device.autoRebootTime || '5'
				},
				mutation: gql`
					mutation updateDeviceAutoReboot(
						$device: MongoID!
						$autoReboot: Boolean!
						$autoRebootTime: String!
					) {
						deviceUpdateById(
							_id: $device
							record: {
								autoReboot: $autoReboot
								autoRebootTime: $autoRebootTime
							}
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				]
			})
			Utils.parseMutationResponse(r)

			const dr = await client.mutate(
				DeviceActionMutation(device, null, 'options')
			)
			Utils.parseMutationResponse(dr)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	handleTimeChange = async (v) => {
		const value = v.format('H')

		const { device, client } = this.props

		try {
			const r = await client.mutate({
				variables: {
					device: device._id,
					autoRebootTime: value
				},
				mutation: gql`
					mutation updateDeviceAutoRebootTime(
						$device: MongoID!
						$autoRebootTime: String!
					) {
						deviceUpdateById(
							_id: $device
							record: { autoRebootTime: $autoRebootTime }
						) {
							recordId
						}
					}
				`,
				refetchQueries: [
					{
						query: DeviceDetailsQuery,
						variables: { id: device._id }
					}
				]
			})
			Utils.parseMutationResponse(r)

			const dr = await client.mutate(
				DeviceActionMutation(device, null, 'options')
			)
			Utils.parseMutationResponse(dr)
		} catch (e) {
			Utils.displayError(e)
		}
	}

	render() {
		const { intl, device } = this.props
		const canEdit = Auth.hasPermission('device:edit:setAutoReboot')

		return (
			<Modal
				visible={true}
				title={intl.formatMessage({
					id: 'devices.info.actions.autoReboot.title'
				})}
				onCancel={this.handleCancel}
				footer={[]}
			>
				<Form>
					<FormItem
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'devices.info.actions.autoReboot.toggle'
						})}
					>
						<Switch
							checked={device.autoReboot}
							onChange={this.handleEnabledChange}
						/>
					</FormItem>
					<FormItem
						{...formItemLayoutEqual}
						label={intl.formatMessage({
							id: 'devices.info.actions.autoReboot.time'
						})}
					>
						<TimePicker
							disabled={!device.autoReboot || !canEdit}
							minuteStep={60}
							secondStep={60}
							defaultValue={moment(`05:00:00`, 'HH:mm:ss')}
							value={moment(
								`${device.autoRebootTime || '5'}:00:00`,
								'H:mm:ss'
							)}
							onChange={this.handleTimeChange}
						/>
					</FormItem>
				</Form>
			</Modal>
		)
	}
}

export default withApollo(injectIntl(Reboot))
